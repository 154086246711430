import { createTheme, responsiveFontSizes, alpha } from '@mui/material/styles'
import palette from './palette'
import typography from './typography'
import spacing from './spacing'
import components from './components'
import shape from './shape'

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    policy: {
      sustainability: React.CSSProperties['color']
      humanRights: React.CSSProperties['color']
      diversity: React.CSSProperties['color']
      financialRisk: React.CSSProperties['color']
      logisticsRisk: React.CSSProperties['color']
      operations: React.CSSProperties['color']
    }
    backgroundDark: React.CSSProperties['color']
  }
  interface PaletteOptions {
    policy: {
      sustainability: React.CSSProperties['color']
      humanRights: React.CSSProperties['color']
      diversity: React.CSSProperties['color']
      financialRisk: React.CSSProperties['color']
      logisticsRisk: React.CSSProperties['color']
      operations: React.CSSProperties['color']
    }
    backgroundDark: React.CSSProperties['color']
  }
}

const theme = createTheme({
  palette,
  typography,
  shape,
  spacing,
  components: {
    ...components,
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiAlert: {
      defaultProps: {
        severity: 'info',
      },
      styleOverrides: {
        standardInfo: {
          backgroundColor: alpha(palette.info.main, 0.15),
        },
      },
    },
  },
})

export default responsiveFontSizes(theme)
