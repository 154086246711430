import { Grid, Button } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { Alert, Paper, PaperContent } from 'components'

interface Props {
  errorMessage: string
}

const GeneralError = ({ errorMessage }: Props) => {
  const { logout } = useAuth0()

  const handleLogout = () => {
    logout({
      returnTo: 'https://www.responsibly.tech/book-a-demo',
    })
  }

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
        <Paper sx={{ maxWidth: 400, margin: 'auto' }}>
          <PaperContent>
            <Alert message={errorMessage} severity="error" />
            <Button
              onClick={handleLogout}
              color="primary"
              fullWidth
              style={{
                textTransform: 'unset',
                marginTop: 24,
                borderColor: '#f3766b',
                color: '#f3766b',
              }}
              variant="outlined"
            >
              Log out
            </Button>
          </PaperContent>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default GeneralError
