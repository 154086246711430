import { Components } from '@mui/material/styles'

const MuiDialogContent: Components['MuiDialogContent'] = {
  styleOverrides: {
    root: {
      paddingTop: '0px',
      padding: '8px 24px',
    },
  },
}

export default MuiDialogContent
