export interface ScorecardFilters {
  pageSize: number
  supplierIds?: string[]
  scorecardIds?: string[]
}

const scorecards = ['scorecards'] as const
const allScorecardPages = [...scorecards, 'allScorecardPages'] as const
const scorecardPages = (filters: ScorecardFilters) =>
  [...allScorecardPages, 'pages', filters] as const
const singleScorecards = [...scorecards, 'singleScorecards'] as const
const singleScorecard = (id: string, supplierId?: string) =>
  [...singleScorecards, id, supplierId] as const
const overallScoreAndCoverageForSuppliers = ({ supplierIds }: { supplierIds: string[] }) =>
  [...scorecards, 'overallScoreAndCoverageForSuppliers', supplierIds] as const

export default {
  scorecards,
  allScorecardPages,
  scorecardPages,
  singleScorecards,
  singleScorecard,
  overallScoreAndCoverageForSuppliers,
}
