import { Components } from '@mui/material/styles'

const MuiTable: Components['MuiTable'] = {
  styleOverrides: {
    root: {
      borderSpacing: '0 3px',
      borderCollapse: 'unset',
    },
  },
}

export default MuiTable
