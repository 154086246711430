"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Responsibly App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultApi = exports.DefaultApiFactory = exports.DefaultApiFp = exports.DefaultApiAxiosParamCreator = exports.ValueType = exports.SupplierStatus = exports.SupplierFilterPropertyList = exports.SupplierFilterProperty = exports.SupplierFilterNumericProperty = exports.SupplierEmissionScope = exports.ScoreTypeFilterProperty = exports.RiskFilterPropertyNumeric = exports.RiskFilterProperty = exports.RiskCategory = exports.PaymentPlan = exports.GicsHierarchyLevel = exports.FileAvailability = exports.EmailTemplateType = exports.DataSourceType = exports.DataSourceMetadata = exports.DataRequestState = exports.DataRequestQuestionEditPermission = exports.DataRequestAnswerState = exports.DataRequestAnswerReviewState = exports.DataEntryOrigin = exports.DataEntryCreator = exports.AssessmentType = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
exports.AssessmentType = {
    SelfAssessed: 'SelfAssessed',
    Audited: 'Audited',
    Unknown: 'Unknown'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.DataEntryCreator = {
    Supplier: 'Supplier',
    Customer: 'Customer',
    Responsibly: 'Responsibly'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.DataEntryOrigin = {
    Customer: 'Customer',
    Engagement: 'Engagement',
    ThirdParty: 'ThirdParty',
    Website: 'Website'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.DataRequestAnswerReviewState = {
    Approved: 'Approved',
    Rejected: 'Rejected'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.DataRequestAnswerState = {
    Draft: 'Draft',
    Irrelevant: 'Irrelevant',
    Completed: 'Completed',
    Pending: 'Pending',
    AutomaticallyAnswered: 'AutomaticallyAnswered'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.DataRequestQuestionEditPermission = {
    Allowed: 'Allowed',
    AllowedWithWarning: 'AllowedWithWarning',
    ExtensionAllowed: 'ExtensionAllowed'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.DataRequestState = {
    Created: 'Created',
    Sent: 'Sent',
    Opened: 'Opened',
    Answered: 'Answered'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.DataSourceMetadata = {
    Name: 'Name',
    Country: 'Country',
    Address: 'Address',
    IssueDate: 'IssueDate',
    ExpiryDate: 'ExpiryDate',
    WithdrawalDate: 'WithdrawalDate',
    Status: 'Status'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.DataSourceType = {
    Certificate: 'Certificate',
    Questionnaire: 'Questionnaire',
    Guideline: 'Guideline',
    Custom: 'Custom',
    Unknown: 'Unknown'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.EmailTemplateType = {
    DataRequestSend: 'DataRequestSend',
    DataRequestClosed: 'DataRequestClosed',
    DataRequestReminder: 'DataRequestReminder'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.FileAvailability = {
    Public: 'Public',
    Private: 'Private',
    Shared: 'Shared'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.GicsHierarchyLevel = {
    Sector: 'Sector',
    IndustryGroup: 'IndustryGroup',
    Industry: 'Industry',
    SubIndustry: 'SubIndustry'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.PaymentPlan = {
    Freemium: 'Freemium',
    Paid: 'Paid'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.RiskCategory = {
    Geographic: 'Geographic',
    Industry: 'Industry'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.RiskFilterProperty = {
    PolicyParameterId: 'PolicyParameterId'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.RiskFilterPropertyNumeric = {
    FilterScore: 'FilterScore'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.ScoreTypeFilterProperty = {
    NotAvailable: 'NotAvailable'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.SupplierEmissionScope = {
    _1: '1',
    _2: '2',
    _3: '3',
    _12: '1+2',
    _123: '1+2+3'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.SupplierFilterNumericProperty = {
    Score: 'Score',
    Coverage: 'Coverage'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.SupplierFilterProperty = {
    Id: 'Id',
    Name: 'Name',
    CountryIso3Code: 'CountryIso3Code',
    Status: 'Status',
    ScoreNotAvailable: 'ScoreNotAvailable'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.SupplierFilterPropertyList = {
    CategoryIds: 'CategoryIds',
    ContactIds: 'ContactIds',
    DataRequestIds: 'DataRequestIds',
    IndustryCodes: 'IndustryCodes'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.SupplierStatus = {
    Current: 'Current',
    Prospective: 'Prospective'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.ValueType = {
    Boolean: 'Boolean',
    Ordinal: 'Ordinal',
    Continuous: 'Continuous',
    Unknown: 'Unknown'
};
/**
 * DefaultApi - axios parameter creator
 * @export
 */
exports.DefaultApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1ApiKeysId: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('deleteV1ApiKeysId', 'id', id);
                            localVarPath = "/v1/api-keys/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1SupplierCategoriesId: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('deleteV1SupplierCategoriesId', 'id', id);
                            localVarPath = "/v1/supplier-categories/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} userInviteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1UsersInvitesUserinviteid: function (userInviteId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'userInviteId' is not null or undefined
                            common_1.assertParamExists('deleteV1UsersInvitesUserinviteid', 'userInviteId', userInviteId);
                            localVarPath = "/v1/users-invites/{userInviteId}"
                                .replace("{" + "userInviteId" + "}", encodeURIComponent(String(userInviteId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {string} reviewId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV2DataRequestAnswersIdReviewReviewid: function (id, reviewId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('deleteV2DataRequestAnswersIdReviewReviewid', 'id', id);
                            // verify required parameter 'reviewId' is not null or undefined
                            common_1.assertParamExists('deleteV2DataRequestAnswersIdReviewReviewid', 'reviewId', reviewId);
                            localVarPath = "/v2/data-request-answers/{id}/review/{reviewId}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)))
                                .replace("{" + "reviewId" + "}", encodeURIComponent(String(reviewId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteV2ScorecardsId: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('deleteV2ScorecardsId', 'id', id);
                            localVarPath = "/v2/scorecards/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} organizationSupplierId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV2SuppliersOrganizationsupplieridContactsId: function (organizationSupplierId, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'organizationSupplierId' is not null or undefined
                            common_1.assertParamExists('deleteV2SuppliersOrganizationsupplieridContactsId', 'organizationSupplierId', organizationSupplierId);
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('deleteV2SuppliersOrganizationsupplieridContactsId', 'id', id);
                            localVarPath = "/v2/suppliers/{organizationSupplierId}/contacts/{id}"
                                .replace("{" + "organizationSupplierId" + "}", encodeURIComponent(String(organizationSupplierId)))
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {string} reviewId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV3DataRequestAnswersIdReviewReviewid: function (id, reviewId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('deleteV3DataRequestAnswersIdReviewReviewid', 'id', id);
                            // verify required parameter 'reviewId' is not null or undefined
                            common_1.assertParamExists('deleteV3DataRequestAnswersIdReviewReviewid', 'reviewId', reviewId);
                            localVarPath = "/v3/data-request-answers/{id}/review/{reviewId}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)))
                                .replace("{" + "reviewId" + "}", encodeURIComponent(String(reviewId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV3ScorecardsId: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('deleteV3ScorecardsId', 'id', id);
                            localVarPath = "/v3/scorecards/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentalAdmins: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/experimental/admins";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} organizationId
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getExperimentalDataEntries: function (organizationId, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'organizationId' is not null or undefined
                            common_1.assertParamExists('getExperimentalDataEntries', 'organizationId', organizationId);
                            localVarPath = "/experimental/data-entries";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication apiKeyAuth required
                            return [4 /*yield*/, common_1.setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)];
                        case 1:
                            // authentication apiKeyAuth required
                            _a.sent();
                            if (organizationId !== undefined) {
                                localVarQueryParameter['organizationId'] = organizationId;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [organizationId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentalDataRequestsReminders: function (organizationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/experimental/data-requests/reminders";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication apiKeyAuth required
                            return [4 /*yield*/, common_1.setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)];
                        case 1:
                            // authentication apiKeyAuth required
                            _a.sent();
                            if (organizationId !== undefined) {
                                localVarQueryParameter['organizationId'] = organizationId;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentalOrganizations: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/experimental/organizations";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication apiKeyAuth required
                            return [4 /*yield*/, common_1.setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)];
                        case 1:
                            // authentication apiKeyAuth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} organizationId
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getExperimentalScorecards: function (organizationId, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'organizationId' is not null or undefined
                            common_1.assertParamExists('getExperimentalScorecards', 'organizationId', organizationId);
                            localVarPath = "/experimental/scorecards";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication apiKeyAuth required
                            return [4 /*yield*/, common_1.setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)];
                        case 1:
                            // authentication apiKeyAuth required
                            _a.sent();
                            if (organizationId !== undefined) {
                                localVarQueryParameter['organizationId'] = organizationId;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} organizationId
         * @param {string} scorecardId
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getExperimentalScorecardsSupplierScores: function (organizationId, scorecardId, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'organizationId' is not null or undefined
                            common_1.assertParamExists('getExperimentalScorecardsSupplierScores', 'organizationId', organizationId);
                            // verify required parameter 'scorecardId' is not null or undefined
                            common_1.assertParamExists('getExperimentalScorecardsSupplierScores', 'scorecardId', scorecardId);
                            localVarPath = "/experimental/scorecards/supplier-scores";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication apiKeyAuth required
                            return [4 /*yield*/, common_1.setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)];
                        case 1:
                            // authentication apiKeyAuth required
                            _a.sent();
                            if (organizationId !== undefined) {
                                localVarQueryParameter['organizationId'] = organizationId;
                            }
                            if (scorecardId !== undefined) {
                                localVarQueryParameter['scorecardId'] = scorecardId;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} organizationId
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentalV2Scorecards: function (organizationId, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'organizationId' is not null or undefined
                            common_1.assertParamExists('getExperimentalV2Scorecards', 'organizationId', organizationId);
                            localVarPath = "/experimental/v2/scorecards";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication apiKeyAuth required
                            return [4 /*yield*/, common_1.setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)];
                        case 1:
                            // authentication apiKeyAuth required
                            _a.sent();
                            if (organizationId !== undefined) {
                                localVarQueryParameter['organizationId'] = organizationId;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} organizationId
         * @param {string} scorecardId
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentalV2ScorecardsSupplierScores: function (organizationId, scorecardId, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'organizationId' is not null or undefined
                            common_1.assertParamExists('getExperimentalV2ScorecardsSupplierScores', 'organizationId', organizationId);
                            // verify required parameter 'scorecardId' is not null or undefined
                            common_1.assertParamExists('getExperimentalV2ScorecardsSupplierScores', 'scorecardId', scorecardId);
                            localVarPath = "/experimental/v2/scorecards/supplier-scores";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication apiKeyAuth required
                            return [4 /*yield*/, common_1.setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)];
                        case 1:
                            // authentication apiKeyAuth required
                            _a.sent();
                            if (organizationId !== undefined) {
                                localVarQueryParameter['organizationId'] = organizationId;
                            }
                            if (scorecardId !== undefined) {
                                localVarQueryParameter['scorecardId'] = scorecardId;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1ApiKeys: function (page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/api-keys";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1Currencies: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/currencies";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {EmailTemplateType} [type]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1EmailTemplates: function (type, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/email-templates";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (type !== undefined) {
                                localVarQueryParameter['type'] = type;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1GeoCountries: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/geo/countries";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} iso3Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1GeoCountriesIso3code: function (iso3Code, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'iso3Code' is not null or undefined
                            common_1.assertParamExists('getV1GeoCountriesIso3code', 'iso3Code', iso3Code);
                            localVarPath = "/v1/geo/countries/{iso3Code}"
                                .replace("{" + "iso3Code" + "}", encodeURIComponent(String(iso3Code)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1GeoRegions: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/geo/regions";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} regionName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1GeoRegionsRegionname: function (regionName, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'regionName' is not null or undefined
                            common_1.assertParamExists('getV1GeoRegionsRegionname', 'regionName', regionName);
                            localVarPath = "/v1/geo/regions/{regionName}"
                                .replace("{" + "regionName" + "}", encodeURIComponent(String(regionName)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1Languages: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/languages";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1OnboardingFiles: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/onboarding/files";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1PolicyParameters: function (page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/policy/parameters";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1PolicyParametersId: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV1PolicyParametersId', 'id', id);
                            localVarPath = "/v1/policy/parameters/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1SettingsOrganization: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/settings/organization";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1SupplierCategories: function (page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/supplier-categories";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1SupplierCategoriesId: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV1SupplierCategoriesId', 'id', id);
                            localVarPath = "/v1/supplier-categories/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1Users: function (page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/users";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1UsersInvites: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/users-invites";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1UsersMe: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v1/users/me";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1UsersUserid: function (userId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            common_1.assertParamExists('getV1UsersUserid', 'userId', userId);
                            localVarPath = "/v1/users/{userId}"
                                .replace("{" + "userId" + "}", encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {string} [supplierId]
         * @param {string} [dataSourceId]
         * @param {string} [dataRequestId]
         * @param {number} [daysTillExpiredMin]
         * @param {number} [daysTillExpiredMax]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2DataEntries: function (id, supplierId, dataSourceId, dataRequestId, daysTillExpiredMin, daysTillExpiredMax, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/data-entries";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (id) {
                                localVarQueryParameter['id'] = id;
                            }
                            if (supplierId !== undefined) {
                                localVarQueryParameter['supplierId'] = supplierId;
                            }
                            if (dataSourceId !== undefined) {
                                localVarQueryParameter['dataSourceId'] = dataSourceId;
                            }
                            if (dataRequestId !== undefined) {
                                localVarQueryParameter['dataRequestId'] = dataRequestId;
                            }
                            if (daysTillExpiredMin !== undefined) {
                                localVarQueryParameter['daysTillExpiredMin'] = daysTillExpiredMin;
                            }
                            if (daysTillExpiredMax !== undefined) {
                                localVarQueryParameter['daysTillExpiredMax'] = daysTillExpiredMax;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2DataEntriesId: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV2DataEntriesId', 'id', id);
                            localVarPath = "/v2/data-entries/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [supplierId]
         * @param {Array<string>} [dataRequestId]
         * @param {Array<string>} [dataRequestQuestionId]
         * @param {Array<DataRequestAnswerReviewState>} [reviewState]
         * @param {boolean} [latest]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestAnswers: function (supplierId, dataRequestId, dataRequestQuestionId, reviewState, latest, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/data-request-answers";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (supplierId) {
                                localVarQueryParameter['supplierId'] = supplierId;
                            }
                            if (dataRequestId) {
                                localVarQueryParameter['dataRequestId'] = dataRequestId;
                            }
                            if (dataRequestQuestionId) {
                                localVarQueryParameter['dataRequestQuestionId'] = dataRequestQuestionId;
                            }
                            if (reviewState) {
                                localVarQueryParameter['reviewState'] = reviewState;
                            }
                            if (latest !== undefined) {
                                localVarQueryParameter['latest'] = latest;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestAnswersIdDataEntries: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV2DataRequestAnswersIdDataEntries', 'id', id);
                            localVarPath = "/v2/data-request-answers/{id}/data-entries"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestAnswersIdFiles: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV2DataRequestAnswersIdFiles', 'id', id);
                            localVarPath = "/v2/data-request-answers/{id}/files"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [name]
         * @param {Array<string>} [ids]
         * @param {Array<string>} [questionIds]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestTemplates: function (name, ids, questionIds, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/data-request-templates";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (name !== undefined) {
                                localVarQueryParameter['name'] = name;
                            }
                            if (ids) {
                                localVarQueryParameter['ids'] = ids;
                            }
                            if (questionIds) {
                                localVarQueryParameter['questionIds'] = questionIds;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestsId: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV2DataRequestsId', 'id', id);
                            localVarPath = "/v2/data-requests/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestsIdDataEntries: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV2DataRequestsIdDataEntries', 'id', id);
                            localVarPath = "/v2/data-requests/{id}/data-entries"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestsIdFiles: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV2DataRequestsIdFiles', 'id', id);
                            localVarPath = "/v2/data-requests/{id}/files"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {string} [dataSourceId]
         * @param {string} [policyParameterId]
         * @param {Array<string>} [dataRequestId]
         * @param {string} [title]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestsQuestions: function (id, dataSourceId, policyParameterId, dataRequestId, title, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/data-requests/questions";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (id) {
                                localVarQueryParameter['id'] = id;
                            }
                            if (dataSourceId !== undefined) {
                                localVarQueryParameter['dataSourceId'] = dataSourceId;
                            }
                            if (policyParameterId !== undefined) {
                                localVarQueryParameter['policyParameterId'] = policyParameterId;
                            }
                            if (dataRequestId) {
                                localVarQueryParameter['dataRequestId'] = dataRequestId;
                            }
                            if (title !== undefined) {
                                localVarQueryParameter['title'] = title;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestsQuestionsIdEditValidation: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV2DataRequestsQuestionsIdEditValidation', 'id', id);
                            localVarPath = "/v2/data-requests/questions/{id}/edit-validation"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {string} [name]
         * @param {Array<string>} [policyParameter]
         * @param {boolean} [includeEmpty]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataSources: function (id, name, policyParameter, includeEmpty, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/data-sources";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (id) {
                                localVarQueryParameter['id'] = id;
                            }
                            if (name !== undefined) {
                                localVarQueryParameter['name'] = name;
                            }
                            if (policyParameter) {
                                localVarQueryParameter['policy_parameter'] = policyParameter;
                            }
                            if (includeEmpty !== undefined) {
                                localVarQueryParameter['includeEmpty'] = includeEmpty;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataSourcesId: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV2DataSourcesId', 'id', id);
                            localVarPath = "/v2/data-sources/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataSourcesIdCoveredparameters: function (id, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV2DataSourcesIdCoveredparameters', 'id', id);
                            localVarPath = "/v2/data-sources/{id}/coveredParameters"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [dataEntryId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2FilesDataEntries: function (dataEntryId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/files/data-entries";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (dataEntryId) {
                                localVarQueryParameter['dataEntryId'] = dataEntryId;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} dataRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2FilesDataRequestsDatarequestid: function (dataRequestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'dataRequestId' is not null or undefined
                            common_1.assertParamExists('getV2FilesDataRequestsDatarequestid', 'dataRequestId', dataRequestId);
                            localVarPath = "/v2/files/data-requests/{dataRequestId}"
                                .replace("{" + "dataRequestId" + "}", encodeURIComponent(String(dataRequestId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2FilesFileid: function (fileId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'fileId' is not null or undefined
                            common_1.assertParamExists('getV2FilesFileid', 'fileId', fileId);
                            localVarPath = "/v2/files/{fileId}"
                                .replace("{" + "fileId" + "}", encodeURIComponent(String(fileId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [supplierId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2FilesSuppliers: function (supplierId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/files/suppliers";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (supplierId) {
                                localVarQueryParameter['supplierId'] = supplierId;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2GlobalSuppliers: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/global-suppliers";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (id) {
                                localVarQueryParameter['id'] = id;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [code]
         * @param {boolean} [includeUnassigned]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2IndustryGics: function (code, includeUnassigned, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/industry/gics";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (code) {
                                localVarQueryParameter['code'] = code;
                            }
                            if (includeUnassigned !== undefined) {
                                localVarQueryParameter['includeUnassigned'] = includeUnassigned;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [fromDate]
         * @param {string} [toDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2MetricsOrganizationSuppliersTimelineEngaged: function (fromDate, toDate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/metrics/organization-suppliers/timeline/engaged";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (fromDate !== undefined) {
                                localVarQueryParameter['fromDate'] = (fromDate instanceof Date) ?
                                    fromDate.toISOString().substr(0, 10) :
                                    fromDate;
                            }
                            if (toDate !== undefined) {
                                localVarQueryParameter['toDate'] = (toDate instanceof Date) ?
                                    toDate.toISOString().substr(0, 10) :
                                    toDate;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2MetricsOrganizationSuppliersTimelineTotal: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/metrics/organization-suppliers/timeline/total";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2MetricsOrganizationSuppliersTimelineTotalEngaged: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/metrics/organization-suppliers/timeline/total-engaged";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2RefDataSources: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/ref-data-sources";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2Risk: function (supplierId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'supplierId' is not null or undefined
                            common_1.assertParamExists('getV2Risk', 'supplierId', supplierId);
                            localVarPath = "/v2/risk";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (supplierId !== undefined) {
                                localVarQueryParameter['supplierId'] = supplierId;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2RiskDataEntries: function (id, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/risk/data-entries";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (id) {
                                localVarQueryParameter['id'] = id;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2RiskDataSources: function (page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/risk/data-sources";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2RiskDataSourcesId: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV2RiskDataSourcesId', 'id', id);
                            localVarPath = "/v2/risk/data-sources/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {Array<string>} [policyParameterId]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2RiskMitigationRules: function (page, pageSize, policyParameterId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/risk/mitigation-rules";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            if (policyParameterId) {
                                localVarQueryParameter['policyParameterId'] = policyParameterId;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2RiskMitigationRulesId: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV2RiskMitigationRulesId', 'id', id);
                            localVarPath = "/v2/risk/mitigation-rules/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2RiskObjective: function (page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/risk/objective";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {Array<string>} [supplierId]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2Scorecards: function (id, supplierId, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/scorecards";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (id) {
                                localVarQueryParameter['id'] = id;
                            }
                            if (supplierId) {
                                localVarQueryParameter['supplierId'] = supplierId;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {Array<string>} [supplierId]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2ScorecardsId: function (id, supplierId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV2ScorecardsId', 'id', id);
                            localVarPath = "/v2/scorecards/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (supplierId) {
                                localVarQueryParameter['supplierId'] = supplierId;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [supplierId]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2ScorecardsSupplierScoreAndCoverage: function (supplierId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/scorecards/supplier-score-and-coverage";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (supplierId) {
                                localVarQueryParameter['supplierId'] = supplierId;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequests: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/supplier-data-requests";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsContextEmissions: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/supplier-data-requests/context/emissions";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsContextExistingData: function (page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/supplier-data-requests/context/existing-data";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [dataSourceName]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsContextNoData: function (dataSourceName, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/supplier-data-requests/context/no-data";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (dataSourceName !== undefined) {
                                localVarQueryParameter['dataSourceName'] = dataSourceName;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsContextRisk: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/supplier-data-requests/context/risk";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [policyParameterId]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsContextScore: function (policyParameterId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/supplier-data-requests/context/score";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (policyParameterId) {
                                localVarQueryParameter['policyParameterId'] = policyParameterId;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsContextScoreOverall: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/supplier-data-requests/context/score/overall";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} id
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsDataEntries: function (id, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV2SupplierDataRequestsDataEntries', 'id', id);
                            localVarPath = "/v2/supplier-data-requests/data-entries";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (id) {
                                localVarQueryParameter['id'] = id;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [name]
         * @param {Array<string>} [id]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsDataSources: function (name, id, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/supplier-data-requests/data-sources";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (name !== undefined) {
                                localVarQueryParameter['name'] = name;
                            }
                            if (id) {
                                localVarQueryParameter['id'] = id;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsLanguages: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/supplier-data-requests/languages";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsPolicyParameters: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/supplier-data-requests/policy-parameters";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2SuppliersId: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV2SuppliersId', 'id', id);
                            localVarPath = "/v2/suppliers/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} organizationSupplierId
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2SuppliersOrganizationsupplieridContacts: function (organizationSupplierId, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'organizationSupplierId' is not null or undefined
                            common_1.assertParamExists('getV2SuppliersOrganizationsupplieridContacts', 'organizationSupplierId', organizationSupplierId);
                            localVarPath = "/v2/suppliers/{organizationSupplierId}/contacts"
                                .replace("{" + "organizationSupplierId" + "}", encodeURIComponent(String(organizationSupplierId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} organizationSupplierId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2SuppliersOrganizationsupplieridContactsId: function (organizationSupplierId, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'organizationSupplierId' is not null or undefined
                            common_1.assertParamExists('getV2SuppliersOrganizationsupplieridContactsId', 'organizationSupplierId', organizationSupplierId);
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV2SuppliersOrganizationsupplieridContactsId', 'id', id);
                            localVarPath = "/v2/suppliers/{organizationSupplierId}/contacts/{id}"
                                .replace("{" + "organizationSupplierId" + "}", encodeURIComponent(String(organizationSupplierId)))
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} supplierId
         * @param {number} [startYear]
         * @param {number} [endYear]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2SuppliersSupplieridEmissionData: function (supplierId, startYear, endYear, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'supplierId' is not null or undefined
                            common_1.assertParamExists('getV2SuppliersSupplieridEmissionData', 'supplierId', supplierId);
                            localVarPath = "/v2/suppliers/{supplierId}/emission-data"
                                .replace("{" + "supplierId" + "}", encodeURIComponent(String(supplierId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (startYear !== undefined) {
                                localVarQueryParameter['start-year'] = startYear;
                            }
                            if (endYear !== undefined) {
                                localVarQueryParameter['end-year'] = endYear;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {string} [supplierId]
         * @param {string} [dataSourceId]
         * @param {string} [dataRequestId]
         * @param {number} [daysTillExpiredMin]
         * @param {number} [daysTillExpiredMax]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3DataEntries: function (id, supplierId, dataSourceId, dataRequestId, daysTillExpiredMin, daysTillExpiredMax, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v3/data-entries";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (id) {
                                localVarQueryParameter['id'] = id;
                            }
                            if (supplierId !== undefined) {
                                localVarQueryParameter['supplierId'] = supplierId;
                            }
                            if (dataSourceId !== undefined) {
                                localVarQueryParameter['dataSourceId'] = dataSourceId;
                            }
                            if (dataRequestId !== undefined) {
                                localVarQueryParameter['dataRequestId'] = dataRequestId;
                            }
                            if (daysTillExpiredMin !== undefined) {
                                localVarQueryParameter['daysTillExpiredMin'] = daysTillExpiredMin;
                            }
                            if (daysTillExpiredMax !== undefined) {
                                localVarQueryParameter['daysTillExpiredMax'] = daysTillExpiredMax;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3DataEntriesId: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV3DataEntriesId', 'id', id);
                            localVarPath = "/v3/data-entries/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [supplierId]
         * @param {Array<string>} [dataRequestId]
         * @param {Array<string>} [dataRequestQuestionId]
         * @param {Array<DataRequestAnswerReviewState>} [reviewState]
         * @param {boolean} [latest]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3DataRequestAnswers: function (supplierId, dataRequestId, dataRequestQuestionId, reviewState, latest, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v3/data-request-answers";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (supplierId) {
                                localVarQueryParameter['supplierId'] = supplierId;
                            }
                            if (dataRequestId) {
                                localVarQueryParameter['dataRequestId'] = dataRequestId;
                            }
                            if (dataRequestQuestionId) {
                                localVarQueryParameter['dataRequestQuestionId'] = dataRequestQuestionId;
                            }
                            if (reviewState) {
                                localVarQueryParameter['reviewState'] = reviewState;
                            }
                            if (latest !== undefined) {
                                localVarQueryParameter['latest'] = latest;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV3DataRequestAnswersIdDataEntries: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV3DataRequestAnswersIdDataEntries', 'id', id);
                            localVarPath = "/v3/data-request-answers/{id}/data-entries"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3DataRequestAnswersIdFiles: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV3DataRequestAnswersIdFiles', 'id', id);
                            localVarPath = "/v3/data-request-answers/{id}/files"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [name]
         * @param {Array<string>} [ids]
         * @param {Array<string>} [questionIds]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3DataRequestTemplates: function (name, ids, questionIds, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v3/data-request-templates";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (name !== undefined) {
                                localVarQueryParameter['name'] = name;
                            }
                            if (ids) {
                                localVarQueryParameter['ids'] = ids;
                            }
                            if (questionIds) {
                                localVarQueryParameter['questionIds'] = questionIds;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3DataRequestsId: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV3DataRequestsId', 'id', id);
                            localVarPath = "/v3/data-requests/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV3DataRequestsIdDataEntries: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV3DataRequestsIdDataEntries', 'id', id);
                            localVarPath = "/v3/data-requests/{id}/data-entries"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3DataRequestsIdFiles: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV3DataRequestsIdFiles', 'id', id);
                            localVarPath = "/v3/data-requests/{id}/files"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {Array<string>} [policyParameterId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3RiskMitigationRules: function (page, pageSize, policyParameterId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v3/risk/mitigation-rules";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            if (policyParameterId) {
                                localVarQueryParameter['policyParameterId'] = policyParameterId;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3RiskMitigationRulesId: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV3RiskMitigationRulesId', 'id', id);
                            localVarPath = "/v3/risk/mitigation-rules/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {Array<string>} [supplierId]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3Scorecards: function (id, supplierId, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v3/scorecards";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (id) {
                                localVarQueryParameter['id'] = id;
                            }
                            if (supplierId) {
                                localVarQueryParameter['supplierId'] = supplierId;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {Array<string>} [supplierId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3ScorecardsId: function (id, supplierId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV3ScorecardsId', 'id', id);
                            localVarPath = "/v3/scorecards/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (supplierId) {
                                localVarQueryParameter['supplierId'] = supplierId;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [supplierId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3ScorecardsSupplierScoreAndCoverage: function (supplierId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v3/scorecards/supplier-score-and-coverage";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (supplierId) {
                                localVarQueryParameter['supplierId'] = supplierId;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequests: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v3/supplier-data-requests";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsContextEmissions: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v3/supplier-data-requests/context/emissions";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsContextExistingData: function (page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v3/supplier-data-requests/context/existing-data";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [dataSourceName]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsContextNoData: function (dataSourceName, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v3/supplier-data-requests/context/no-data";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (dataSourceName !== undefined) {
                                localVarQueryParameter['dataSourceName'] = dataSourceName;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsContextRisk: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v3/supplier-data-requests/context/risk";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [policyParameterId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsContextScore: function (policyParameterId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v3/supplier-data-requests/context/score";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (policyParameterId) {
                                localVarQueryParameter['policyParameterId'] = policyParameterId;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsContextScoreOverall: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v3/supplier-data-requests/context/score/overall";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} id
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsDataEntries: function (id, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV3SupplierDataRequestsDataEntries', 'id', id);
                            localVarPath = "/v3/supplier-data-requests/data-entries";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (id) {
                                localVarQueryParameter['id'] = id;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [name]
         * @param {Array<string>} [id]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsDataSources: function (name, id, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v3/supplier-data-requests/data-sources";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (name !== undefined) {
                                localVarQueryParameter['name'] = name;
                            }
                            if (id) {
                                localVarQueryParameter['id'] = id;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsLanguages: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v3/supplier-data-requests/languages";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsPolicyParameters: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v3/supplier-data-requests/policy-parameters";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV4DataRequestAnswersIdDataEntries: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV4DataRequestAnswersIdDataEntries', 'id', id);
                            localVarPath = "/v4/data-request-answers/{id}/data-entries"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV4DataRequestsIdDataEntries: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getV4DataRequestsIdDataEntries', 'id', id);
                            localVarPath = "/v4/data-requests/{id}/data-entries"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {UpdateEmailTemplateIn} updateEmailTemplateIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchV1EmailTemplatesId: function (id, updateEmailTemplateIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('patchV1EmailTemplatesId', 'id', id);
                            // verify required parameter 'updateEmailTemplateIn' is not null or undefined
                            common_1.assertParamExists('patchV1EmailTemplatesId', 'updateEmailTemplateIn', updateEmailTemplateIn);
                            localVarPath = "/v1/email-templates/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(updateEmailTemplateIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {SupplierCategoryPatchApiDto} supplierCategoryPatchApiDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchV1SupplierCategoriesId: function (id, supplierCategoryPatchApiDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('patchV1SupplierCategoriesId', 'id', id);
                            // verify required parameter 'supplierCategoryPatchApiDto' is not null or undefined
                            common_1.assertParamExists('patchV1SupplierCategoriesId', 'supplierCategoryPatchApiDto', supplierCategoryPatchApiDto);
                            localVarPath = "/v1/supplier-categories/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(supplierCategoryPatchApiDto, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {string} reviewId
         * @param {InsertDataRequestAnswerReviewIn} insertDataRequestAnswerReviewIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchV2DataRequestAnswersIdReviewReviewid: function (id, reviewId, insertDataRequestAnswerReviewIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('patchV2DataRequestAnswersIdReviewReviewid', 'id', id);
                            // verify required parameter 'reviewId' is not null or undefined
                            common_1.assertParamExists('patchV2DataRequestAnswersIdReviewReviewid', 'reviewId', reviewId);
                            // verify required parameter 'insertDataRequestAnswerReviewIn' is not null or undefined
                            common_1.assertParamExists('patchV2DataRequestAnswersIdReviewReviewid', 'insertDataRequestAnswerReviewIn', insertDataRequestAnswerReviewIn);
                            localVarPath = "/v2/data-request-answers/{id}/review/{reviewId}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)))
                                .replace("{" + "reviewId" + "}", encodeURIComponent(String(reviewId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(insertDataRequestAnswerReviewIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {string} reviewId
         * @param {InsertDataRequestAnswerReviewIn} insertDataRequestAnswerReviewIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchV3DataRequestAnswersIdReviewReviewid: function (id, reviewId, insertDataRequestAnswerReviewIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('patchV3DataRequestAnswersIdReviewReviewid', 'id', id);
                            // verify required parameter 'reviewId' is not null or undefined
                            common_1.assertParamExists('patchV3DataRequestAnswersIdReviewReviewid', 'reviewId', reviewId);
                            // verify required parameter 'insertDataRequestAnswerReviewIn' is not null or undefined
                            common_1.assertParamExists('patchV3DataRequestAnswersIdReviewReviewid', 'insertDataRequestAnswerReviewIn', insertDataRequestAnswerReviewIn);
                            localVarPath = "/v3/data-request-answers/{id}/review/{reviewId}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)))
                                .replace("{" + "reviewId" + "}", encodeURIComponent(String(reviewId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(insertDataRequestAnswerReviewIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {UpdateAdminHackIn} updateAdminHackIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExperimentalAdmins: function (updateAdminHackIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'updateAdminHackIn' is not null or undefined
                            common_1.assertParamExists('postExperimentalAdmins', 'updateAdminHackIn', updateAdminHackIn);
                            localVarPath = "/experimental/admins";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(updateAdminHackIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {SignUpWithEmailDomainIn} signUpWithEmailDomainIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMgmtAuth0Signup: function (signUpWithEmailDomainIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'signUpWithEmailDomainIn' is not null or undefined
                    common_1.assertParamExists('postMgmtAuth0Signup', 'signUpWithEmailDomainIn', signUpWithEmailDomainIn);
                    localVarPath = "/_mgmt/auth0/signup";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(signUpWithEmailDomainIn, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMgmtAwsSns: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('postMgmtAwsSns', 'body', body);
                    localVarPath = "/_mgmt/aws/sns";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1ApiKeysName: function (name, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'name' is not null or undefined
                            common_1.assertParamExists('postV1ApiKeysName', 'name', name);
                            localVarPath = "/v1/api-keys/{name}"
                                .replace("{" + "name" + "}", encodeURIComponent(String(name)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CreateEmailTemplateIn} createEmailTemplateIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1EmailTemplates: function (createEmailTemplateIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'createEmailTemplateIn' is not null or undefined
                            common_1.assertParamExists('postV1EmailTemplates', 'createEmailTemplateIn', createEmailTemplateIn);
                            localVarPath = "/v1/email-templates";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(createEmailTemplateIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {GenerateOnboardingPreSignedUploadUrlInput} generateOnboardingPreSignedUploadUrlInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1OnboardingFiles: function (generateOnboardingPreSignedUploadUrlInput, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'generateOnboardingPreSignedUploadUrlInput' is not null or undefined
                            common_1.assertParamExists('postV1OnboardingFiles', 'generateOnboardingPreSignedUploadUrlInput', generateOnboardingPreSignedUploadUrlInput);
                            localVarPath = "/v1/onboarding/files";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(generateOnboardingPreSignedUploadUrlInput, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1Signup: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'body' is not null or undefined
                            common_1.assertParamExists('postV1Signup', 'body', body);
                            localVarPath = "/v1/signup";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {SupplierCategoryIn} supplierCategoryIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1SupplierCategories: function (supplierCategoryIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'supplierCategoryIn' is not null or undefined
                            common_1.assertParamExists('postV1SupplierCategories', 'supplierCategoryIn', supplierCategoryIn);
                            localVarPath = "/v1/supplier-categories";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(supplierCategoryIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {InsertUserInput} insertUserInput JSON body for inserting a User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1Users: function (insertUserInput, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'insertUserInput' is not null or undefined
                            common_1.assertParamExists('postV1Users', 'insertUserInput', insertUserInput);
                            localVarPath = "/v1/users";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(insertUserInput, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {UserInviteIn} userInviteIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1UsersInvites: function (userInviteIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'userInviteIn' is not null or undefined
                            common_1.assertParamExists('postV1UsersInvites', 'userInviteIn', userInviteIn);
                            localVarPath = "/v1/users-invites";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(userInviteIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {InsertDataRequestAnswerReviewIn} insertDataRequestAnswerReviewIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2DataRequestAnswersIdReview: function (id, insertDataRequestAnswerReviewIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('postV2DataRequestAnswersIdReview', 'id', id);
                            // verify required parameter 'insertDataRequestAnswerReviewIn' is not null or undefined
                            common_1.assertParamExists('postV2DataRequestAnswersIdReview', 'insertDataRequestAnswerReviewIn', insertDataRequestAnswerReviewIn);
                            localVarPath = "/v2/data-request-answers/{id}/review"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(insertDataRequestAnswerReviewIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CreateDataRequestTemplateV2In} createDataRequestTemplateV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2DataRequestTemplates: function (createDataRequestTemplateV2In, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'createDataRequestTemplateV2In' is not null or undefined
                            common_1.assertParamExists('postV2DataRequestTemplates', 'createDataRequestTemplateV2In', createDataRequestTemplateV2In);
                            localVarPath = "/v2/data-request-templates";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(createDataRequestTemplateV2In, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {RenderDataRequestTemplateV2In} renderDataRequestTemplateV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2DataRequestTemplatesRenderEmail: function (id, renderDataRequestTemplateV2In, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('postV2DataRequestTemplatesRenderEmail', 'id', id);
                            // verify required parameter 'renderDataRequestTemplateV2In' is not null or undefined
                            common_1.assertParamExists('postV2DataRequestTemplatesRenderEmail', 'renderDataRequestTemplateV2In', renderDataRequestTemplateV2In);
                            localVarPath = "/v2/data-request-templates/render-email";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(renderDataRequestTemplateV2In, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {DataRequestFilterV2ApiDto} dataRequestFilterV2ApiDto
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2DataRequests: function (dataRequestFilterV2ApiDto, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'dataRequestFilterV2ApiDto' is not null or undefined
                            common_1.assertParamExists('postV2DataRequests', 'dataRequestFilterV2ApiDto', dataRequestFilterV2ApiDto);
                            localVarPath = "/v2/data-requests";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(dataRequestFilterV2ApiDto, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {SendDataRequestV2In} sendDataRequestV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2DataRequestsActionsSend: function (sendDataRequestV2In, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'sendDataRequestV2In' is not null or undefined
                            common_1.assertParamExists('postV2DataRequestsActionsSend', 'sendDataRequestV2In', sendDataRequestV2In);
                            localVarPath = "/v2/data-requests/actions/send";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(sendDataRequestV2In, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {UpdateDataRequestDueDateIn} updateDataRequestDueDateIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2DataRequestsActionsUpdateDueDate: function (updateDataRequestDueDateIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'updateDataRequestDueDateIn' is not null or undefined
                            common_1.assertParamExists('postV2DataRequestsActionsUpdateDueDate', 'updateDataRequestDueDateIn', updateDataRequestDueDateIn);
                            localVarPath = "/v2/data-requests/actions/update-due-date";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(updateDataRequestDueDateIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {InsertDataRequestQuestionV2In} insertDataRequestQuestionV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2DataRequestsQuestions: function (insertDataRequestQuestionV2In, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'insertDataRequestQuestionV2In' is not null or undefined
                            common_1.assertParamExists('postV2DataRequestsQuestions', 'insertDataRequestQuestionV2In', insertDataRequestQuestionV2In);
                            localVarPath = "/v2/data-requests/questions";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(insertDataRequestQuestionV2In, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {RiskFilterIn} riskFilterIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2Risk: function (riskFilterIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'riskFilterIn' is not null or undefined
                            common_1.assertParamExists('postV2Risk', 'riskFilterIn', riskFilterIn);
                            localVarPath = "/v2/risk";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(riskFilterIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {RiskContextRiskOverviewFilterIn} riskContextRiskOverviewFilterIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2RiskOverviewContext: function (riskContextRiskOverviewFilterIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'riskContextRiskOverviewFilterIn' is not null or undefined
                            common_1.assertParamExists('postV2RiskOverviewContext', 'riskContextRiskOverviewFilterIn', riskContextRiskOverviewFilterIn);
                            localVarPath = "/v2/risk/overview/context";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(riskContextRiskOverviewFilterIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {PolicyParameterRiskOverViewFilterIn} policyParameterRiskOverViewFilterIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2RiskOverviewPolicyParameters: function (policyParameterRiskOverViewFilterIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'policyParameterRiskOverViewFilterIn' is not null or undefined
                            common_1.assertParamExists('postV2RiskOverviewPolicyParameters', 'policyParameterRiskOverViewFilterIn', policyParameterRiskOverViewFilterIn);
                            localVarPath = "/v2/risk/overview/policy-parameters";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(policyParameterRiskOverViewFilterIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {InsertScorecardIn} insertScorecardIn
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postV2Scorecards: function (insertScorecardIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'insertScorecardIn' is not null or undefined
                            common_1.assertParamExists('postV2Scorecards', 'insertScorecardIn', insertScorecardIn);
                            localVarPath = "/v2/scorecards";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(insertScorecardIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {FilterAndSortSupplierByScoreAndCoverageIn} [filterAndSortSupplierByScoreAndCoverageIn]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postV2ScorecardsIdSupplierScoreAndCoverage: function (id, page, pageSize, filterAndSortSupplierByScoreAndCoverageIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('postV2ScorecardsIdSupplierScoreAndCoverage', 'id', id);
                            localVarPath = "/v2/scorecards/{id}/supplier-score-and-coverage"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(filterAndSortSupplierByScoreAndCoverageIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {InsertDataRequestAnswerV2In} insertDataRequestAnswerV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SupplierDataRequestsAnswers: function (insertDataRequestAnswerV2In, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'insertDataRequestAnswerV2In' is not null or undefined
                            common_1.assertParamExists('postV2SupplierDataRequestsAnswers', 'insertDataRequestAnswerV2In', insertDataRequestAnswerV2In);
                            localVarPath = "/v2/supplier-data-requests/answers";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(insertDataRequestAnswerV2In, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CreateDataRequestClaimV2In} createDataRequestClaimV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SupplierDataRequestsDataEntries: function (createDataRequestClaimV2In, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'createDataRequestClaimV2In' is not null or undefined
                            common_1.assertParamExists('postV2SupplierDataRequestsDataEntries', 'createDataRequestClaimV2In', createDataRequestClaimV2In);
                            localVarPath = "/v2/supplier-data-requests/data-entries";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(createDataRequestClaimV2In, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {GenerateDataRequestPreSignedUploadUrlV2In} generateDataRequestPreSignedUploadUrlV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SupplierDataRequestsFiles: function (generateDataRequestPreSignedUploadUrlV2In, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'generateDataRequestPreSignedUploadUrlV2In' is not null or undefined
                            common_1.assertParamExists('postV2SupplierDataRequestsFiles', 'generateDataRequestPreSignedUploadUrlV2In', generateDataRequestPreSignedUploadUrlV2In);
                            localVarPath = "/v2/supplier-data-requests/files";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(generateDataRequestPreSignedUploadUrlV2In, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {Array<string>} [dataRequestQuestionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SupplierDataRequestsIdActionsAnswerAutomatically: function (id, dataRequestQuestionId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('postV2SupplierDataRequestsIdActionsAnswerAutomatically', 'id', id);
                            localVarPath = "/v2/supplier-data-requests/{id}/actions/answer-automatically"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (dataRequestQuestionId) {
                                localVarQueryParameter['dataRequestQuestionId'] = dataRequestQuestionId;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SupplierDataRequestsIdActionsComplete: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('postV2SupplierDataRequestsIdActionsComplete', 'id', id);
                            localVarPath = "/v2/supplier-data-requests/{id}/actions/complete"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {ListSuppliersIn} listSuppliersIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2Suppliers: function (listSuppliersIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'listSuppliersIn' is not null or undefined
                            common_1.assertParamExists('postV2Suppliers', 'listSuppliersIn', listSuppliersIn);
                            localVarPath = "/v2/suppliers";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(listSuppliersIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} name
         * @param {string} [countryIso3Code]
         * @param {SupplierStatus} [status]
         * @param {string} [website]
         * @param {Array<string>} [alias]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersActionsAdd: function (name, countryIso3Code, status, website, alias, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'name' is not null or undefined
                            common_1.assertParamExists('postV2SuppliersActionsAdd', 'name', name);
                            localVarPath = "/v2/suppliers/actions/add";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (name !== undefined) {
                                localVarQueryParameter['name'] = name;
                            }
                            if (countryIso3Code !== undefined) {
                                localVarQueryParameter['countryIso3Code'] = countryIso3Code;
                            }
                            if (status !== undefined) {
                                localVarQueryParameter['status'] = status;
                            }
                            if (website !== undefined) {
                                localVarQueryParameter['website'] = website;
                            }
                            if (alias) {
                                localVarQueryParameter['alias'] = alias;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {SuppliersAddToCategoryIn} suppliersAddToCategoryIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersActionsAddToCategory: function (suppliersAddToCategoryIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'suppliersAddToCategoryIn' is not null or undefined
                            common_1.assertParamExists('postV2SuppliersActionsAddToCategory', 'suppliersAddToCategoryIn', suppliersAddToCategoryIn);
                            localVarPath = "/v2/suppliers/actions/add-to-category";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(suppliersAddToCategoryIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {SuppliersRemoveFromCategoryIn} suppliersRemoveFromCategoryIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersActionsRemoveFromCategory: function (suppliersRemoveFromCategoryIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'suppliersRemoveFromCategoryIn' is not null or undefined
                            common_1.assertParamExists('postV2SuppliersActionsRemoveFromCategory', 'suppliersRemoveFromCategoryIn', suppliersRemoveFromCategoryIn);
                            localVarPath = "/v2/suppliers/actions/remove-from-category";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(suppliersRemoveFromCategoryIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {SearchSupplierIn} searchSupplierIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersActionsSearchGlobalSuppliers: function (searchSupplierIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'searchSupplierIn' is not null or undefined
                            common_1.assertParamExists('postV2SuppliersActionsSearchGlobalSuppliers', 'searchSupplierIn', searchSupplierIn);
                            localVarPath = "/v2/suppliers/actions/search-global-suppliers";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(searchSupplierIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {SuppliersLinkGlobalSupplierIn} suppliersLinkGlobalSupplierIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersIdActionsLinkGlobalSupplier: function (id, suppliersLinkGlobalSupplierIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('postV2SuppliersIdActionsLinkGlobalSupplier', 'id', id);
                            // verify required parameter 'suppliersLinkGlobalSupplierIn' is not null or undefined
                            common_1.assertParamExists('postV2SuppliersIdActionsLinkGlobalSupplier', 'suppliersLinkGlobalSupplierIn', suppliersLinkGlobalSupplierIn);
                            localVarPath = "/v2/suppliers/{id}/actions/link-global-supplier"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(suppliersLinkGlobalSupplierIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {string} [globalSupplierId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersIdActionsUnlinkGlobalSupplier: function (id, globalSupplierId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('postV2SuppliersIdActionsUnlinkGlobalSupplier', 'id', id);
                            localVarPath = "/v2/suppliers/{id}/actions/unlink-global-supplier"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (globalSupplierId !== undefined) {
                                localVarQueryParameter['globalSupplierId'] = globalSupplierId;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {CreateDataEntryIn} createDataEntryIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersIdDataEntries: function (id, createDataEntryIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('postV2SuppliersIdDataEntries', 'id', id);
                            // verify required parameter 'createDataEntryIn' is not null or undefined
                            common_1.assertParamExists('postV2SuppliersIdDataEntries', 'createDataEntryIn', createDataEntryIn);
                            localVarPath = "/v2/suppliers/{id}/data-entries"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(createDataEntryIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {GenerateSupplierPreSignedUploadUrlInput} generateSupplierPreSignedUploadUrlInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersIdFiles: function (id, generateSupplierPreSignedUploadUrlInput, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('postV2SuppliersIdFiles', 'id', id);
                            // verify required parameter 'generateSupplierPreSignedUploadUrlInput' is not null or undefined
                            common_1.assertParamExists('postV2SuppliersIdFiles', 'generateSupplierPreSignedUploadUrlInput', generateSupplierPreSignedUploadUrlInput);
                            localVarPath = "/v2/suppliers/{id}/files"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(generateSupplierPreSignedUploadUrlInput, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} organizationSupplierId
         * @param {InsertSupplierContactIn} insertSupplierContactIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersOrganizationsupplieridContacts: function (organizationSupplierId, insertSupplierContactIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'organizationSupplierId' is not null or undefined
                            common_1.assertParamExists('postV2SuppliersOrganizationsupplieridContacts', 'organizationSupplierId', organizationSupplierId);
                            // verify required parameter 'insertSupplierContactIn' is not null or undefined
                            common_1.assertParamExists('postV2SuppliersOrganizationsupplieridContacts', 'insertSupplierContactIn', insertSupplierContactIn);
                            localVarPath = "/v2/suppliers/{organizationSupplierId}/contacts"
                                .replace("{" + "organizationSupplierId" + "}", encodeURIComponent(String(organizationSupplierId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(insertSupplierContactIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} organizationSupplierId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersOrganizationsupplieridContactsIdActionsMakeDefault: function (organizationSupplierId, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'organizationSupplierId' is not null or undefined
                            common_1.assertParamExists('postV2SuppliersOrganizationsupplieridContactsIdActionsMakeDefault', 'organizationSupplierId', organizationSupplierId);
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('postV2SuppliersOrganizationsupplieridContactsIdActionsMakeDefault', 'id', id);
                            localVarPath = "/v2/suppliers/{organizationSupplierId}/contacts/{id}/actions/make-default"
                                .replace("{" + "organizationSupplierId" + "}", encodeURIComponent(String(organizationSupplierId)))
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} supplierId
         * @param {string} industryCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersSupplieridActionsAddIndustryIndustrycode: function (supplierId, industryCode, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'supplierId' is not null or undefined
                            common_1.assertParamExists('postV2SuppliersSupplieridActionsAddIndustryIndustrycode', 'supplierId', supplierId);
                            // verify required parameter 'industryCode' is not null or undefined
                            common_1.assertParamExists('postV2SuppliersSupplieridActionsAddIndustryIndustrycode', 'industryCode', industryCode);
                            localVarPath = "/v2/suppliers/{supplierId}/actions/add-industry/{industryCode}"
                                .replace("{" + "supplierId" + "}", encodeURIComponent(String(supplierId)))
                                .replace("{" + "industryCode" + "}", encodeURIComponent(String(industryCode)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} supplierId
         * @param {string} industryCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersSupplieridActionsRemoveIndustryIndustrycode: function (supplierId, industryCode, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'supplierId' is not null or undefined
                            common_1.assertParamExists('postV2SuppliersSupplieridActionsRemoveIndustryIndustrycode', 'supplierId', supplierId);
                            // verify required parameter 'industryCode' is not null or undefined
                            common_1.assertParamExists('postV2SuppliersSupplieridActionsRemoveIndustryIndustrycode', 'industryCode', industryCode);
                            localVarPath = "/v2/suppliers/{supplierId}/actions/remove-industry/{industryCode}"
                                .replace("{" + "supplierId" + "}", encodeURIComponent(String(supplierId)))
                                .replace("{" + "industryCode" + "}", encodeURIComponent(String(industryCode)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {InsertDataRequestAnswerReviewIn} insertDataRequestAnswerReviewIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3DataRequestAnswersIdReview: function (id, insertDataRequestAnswerReviewIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('postV3DataRequestAnswersIdReview', 'id', id);
                            // verify required parameter 'insertDataRequestAnswerReviewIn' is not null or undefined
                            common_1.assertParamExists('postV3DataRequestAnswersIdReview', 'insertDataRequestAnswerReviewIn', insertDataRequestAnswerReviewIn);
                            localVarPath = "/v3/data-request-answers/{id}/review"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(insertDataRequestAnswerReviewIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CreateDataRequestTemplateV3In} createDataRequestTemplateV3In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3DataRequestTemplates: function (createDataRequestTemplateV3In, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'createDataRequestTemplateV3In' is not null or undefined
                            common_1.assertParamExists('postV3DataRequestTemplates', 'createDataRequestTemplateV3In', createDataRequestTemplateV3In);
                            localVarPath = "/v3/data-request-templates";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(createDataRequestTemplateV3In, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {DataRequestFilterV2ApiDto} dataRequestFilterV2ApiDto
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3DataRequests: function (dataRequestFilterV2ApiDto, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'dataRequestFilterV2ApiDto' is not null or undefined
                            common_1.assertParamExists('postV3DataRequests', 'dataRequestFilterV2ApiDto', dataRequestFilterV2ApiDto);
                            localVarPath = "/v3/data-requests";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(dataRequestFilterV2ApiDto, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {SendDataRequestV2In} sendDataRequestV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3DataRequestsActionsSend: function (sendDataRequestV2In, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'sendDataRequestV2In' is not null or undefined
                            common_1.assertParamExists('postV3DataRequestsActionsSend', 'sendDataRequestV2In', sendDataRequestV2In);
                            localVarPath = "/v3/data-requests/actions/send";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(sendDataRequestV2In, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {UpdateDataRequestDueDateIn} updateDataRequestDueDateIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3DataRequestsActionsUpdateDueDate: function (updateDataRequestDueDateIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'updateDataRequestDueDateIn' is not null or undefined
                            common_1.assertParamExists('postV3DataRequestsActionsUpdateDueDate', 'updateDataRequestDueDateIn', updateDataRequestDueDateIn);
                            localVarPath = "/v3/data-requests/actions/update-due-date";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(updateDataRequestDueDateIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3DataRequestsIdGenerateLink: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('postV3DataRequestsIdGenerateLink', 'id', id);
                            localVarPath = "/v3/data-requests/{id}/generate-link"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {InsertScorecardIn} insertScorecardIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3Scorecards: function (insertScorecardIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'insertScorecardIn' is not null or undefined
                            common_1.assertParamExists('postV3Scorecards', 'insertScorecardIn', insertScorecardIn);
                            localVarPath = "/v3/scorecards";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(insertScorecardIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {FilterAndSortSupplierByScoreAndCoverageIn} [filterAndSortSupplierByScoreAndCoverageIn]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3ScorecardsIdSupplierScoreAndCoverage: function (id, page, pageSize, filterAndSortSupplierByScoreAndCoverageIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('postV3ScorecardsIdSupplierScoreAndCoverage', 'id', id);
                            localVarPath = "/v3/scorecards/{id}/supplier-score-and-coverage"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['pageSize'] = pageSize;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(filterAndSortSupplierByScoreAndCoverageIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {UpdateOrganizationMasterDataIn} updateOrganizationMasterDataIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV1SettingsOrganization: function (updateOrganizationMasterDataIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'updateOrganizationMasterDataIn' is not null or undefined
                            common_1.assertParamExists('putV1SettingsOrganization', 'updateOrganizationMasterDataIn', updateOrganizationMasterDataIn);
                            localVarPath = "/v1/settings/organization";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(updateOrganizationMasterDataIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} userId
         * @param {UpdateUserInput} updateUserInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV1UsersUserid: function (userId, updateUserInput, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            common_1.assertParamExists('putV1UsersUserid', 'userId', userId);
                            // verify required parameter 'updateUserInput' is not null or undefined
                            common_1.assertParamExists('putV1UsersUserid', 'updateUserInput', updateUserInput);
                            localVarPath = "/v1/users/{userId}"
                                .replace("{" + "userId" + "}", encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(updateUserInput, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {UpdateDataRequestTemplateV2In} updateDataRequestTemplateV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV2DataRequestTemplates: function (id, updateDataRequestTemplateV2In, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('putV2DataRequestTemplates', 'id', id);
                            // verify required parameter 'updateDataRequestTemplateV2In' is not null or undefined
                            common_1.assertParamExists('putV2DataRequestTemplates', 'updateDataRequestTemplateV2In', updateDataRequestTemplateV2In);
                            localVarPath = "/v2/data-request-templates";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(updateDataRequestTemplateV2In, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {UpdateDataRequestQuestionV2In} updateDataRequestQuestionV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV2DataRequestsQuestionsId: function (id, updateDataRequestQuestionV2In, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('putV2DataRequestsQuestionsId', 'id', id);
                            // verify required parameter 'updateDataRequestQuestionV2In' is not null or undefined
                            common_1.assertParamExists('putV2DataRequestsQuestionsId', 'updateDataRequestQuestionV2In', updateDataRequestQuestionV2In);
                            localVarPath = "/v2/data-requests/questions/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(updateDataRequestQuestionV2In, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {UpdateScorecardIn} updateScorecardIn
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        putV2ScorecardsId: function (id, updateScorecardIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('putV2ScorecardsId', 'id', id);
                            // verify required parameter 'updateScorecardIn' is not null or undefined
                            common_1.assertParamExists('putV2ScorecardsId', 'updateScorecardIn', updateScorecardIn);
                            localVarPath = "/v2/scorecards/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(updateScorecardIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {UpdateSupplierIn} updateSupplierIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV2SuppliersId: function (id, updateSupplierIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('putV2SuppliersId', 'id', id);
                            // verify required parameter 'updateSupplierIn' is not null or undefined
                            common_1.assertParamExists('putV2SuppliersId', 'updateSupplierIn', updateSupplierIn);
                            localVarPath = "/v2/suppliers/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(updateSupplierIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} organizationSupplierId
         * @param {string} id
         * @param {UpdateSupplierContactIn} updateSupplierContactIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV2SuppliersOrganizationsupplieridContactsId: function (organizationSupplierId, id, updateSupplierContactIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'organizationSupplierId' is not null or undefined
                            common_1.assertParamExists('putV2SuppliersOrganizationsupplieridContactsId', 'organizationSupplierId', organizationSupplierId);
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('putV2SuppliersOrganizationsupplieridContactsId', 'id', id);
                            // verify required parameter 'updateSupplierContactIn' is not null or undefined
                            common_1.assertParamExists('putV2SuppliersOrganizationsupplieridContactsId', 'updateSupplierContactIn', updateSupplierContactIn);
                            localVarPath = "/v2/suppliers/{organizationSupplierId}/contacts/{id}"
                                .replace("{" + "organizationSupplierId" + "}", encodeURIComponent(String(organizationSupplierId)))
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(updateSupplierContactIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {UpdateDataRequestTemplateV3In} updateDataRequestTemplateV3In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV3DataRequestTemplates: function (id, updateDataRequestTemplateV3In, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('putV3DataRequestTemplates', 'id', id);
                            // verify required parameter 'updateDataRequestTemplateV3In' is not null or undefined
                            common_1.assertParamExists('putV3DataRequestTemplates', 'updateDataRequestTemplateV3In', updateDataRequestTemplateV3In);
                            localVarPath = "/v3/data-request-templates";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(updateDataRequestTemplateV3In, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {UpdateScorecardIn} updateScorecardIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV3ScorecardsId: function (id, updateScorecardIn, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('putV3ScorecardsId', 'id', id);
                            // verify required parameter 'updateScorecardIn' is not null or undefined
                            common_1.assertParamExists('putV3ScorecardsId', 'updateScorecardIn', updateScorecardIn);
                            localVarPath = "/v3/scorecards/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication httpAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication httpAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(updateScorecardIn, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * DefaultApi - functional programming interface
 * @export
 */
exports.DefaultApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.DefaultApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1ApiKeysId: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteV1ApiKeysId(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1SupplierCategoriesId: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteV1SupplierCategoriesId(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} userInviteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1UsersInvitesUserinviteid: function (userInviteId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteV1UsersInvitesUserinviteid(userInviteId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {string} reviewId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV2DataRequestAnswersIdReviewReviewid: function (id, reviewId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteV2DataRequestAnswersIdReviewReviewid(id, reviewId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteV2ScorecardsId: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteV2ScorecardsId(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} organizationSupplierId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV2SuppliersOrganizationsupplieridContactsId: function (organizationSupplierId, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteV2SuppliersOrganizationsupplieridContactsId(organizationSupplierId, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {string} reviewId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV3DataRequestAnswersIdReviewReviewid: function (id, reviewId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteV3DataRequestAnswersIdReviewReviewid(id, reviewId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV3ScorecardsId: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteV3ScorecardsId(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentalAdmins: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getExperimentalAdmins(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} organizationId
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getExperimentalDataEntries: function (organizationId, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getExperimentalDataEntries(organizationId, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [organizationId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentalDataRequestsReminders: function (organizationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getExperimentalDataRequestsReminders(organizationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentalOrganizations: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getExperimentalOrganizations(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} organizationId
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getExperimentalScorecards: function (organizationId, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getExperimentalScorecards(organizationId, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} organizationId
         * @param {string} scorecardId
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getExperimentalScorecardsSupplierScores: function (organizationId, scorecardId, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getExperimentalScorecardsSupplierScores(organizationId, scorecardId, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} organizationId
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentalV2Scorecards: function (organizationId, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getExperimentalV2Scorecards(organizationId, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} organizationId
         * @param {string} scorecardId
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentalV2ScorecardsSupplierScores: function (organizationId, scorecardId, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getExperimentalV2ScorecardsSupplierScores(organizationId, scorecardId, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1ApiKeys: function (page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV1ApiKeys(page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1Currencies: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV1Currencies(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {EmailTemplateType} [type]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1EmailTemplates: function (type, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV1EmailTemplates(type, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1GeoCountries: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV1GeoCountries(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} iso3Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1GeoCountriesIso3code: function (iso3Code, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV1GeoCountriesIso3code(iso3Code, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1GeoRegions: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV1GeoRegions(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} regionName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1GeoRegionsRegionname: function (regionName, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV1GeoRegionsRegionname(regionName, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1Languages: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV1Languages(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1OnboardingFiles: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV1OnboardingFiles(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1PolicyParameters: function (page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV1PolicyParameters(page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1PolicyParametersId: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV1PolicyParametersId(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1SettingsOrganization: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV1SettingsOrganization(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1SupplierCategories: function (page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV1SupplierCategories(page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1SupplierCategoriesId: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV1SupplierCategoriesId(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1Users: function (page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV1Users(page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1UsersInvites: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV1UsersInvites(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1UsersMe: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV1UsersMe(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1UsersUserid: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV1UsersUserid(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {string} [supplierId]
         * @param {string} [dataSourceId]
         * @param {string} [dataRequestId]
         * @param {number} [daysTillExpiredMin]
         * @param {number} [daysTillExpiredMax]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2DataEntries: function (id, supplierId, dataSourceId, dataRequestId, daysTillExpiredMin, daysTillExpiredMax, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2DataEntries(id, supplierId, dataSourceId, dataRequestId, daysTillExpiredMin, daysTillExpiredMax, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2DataEntriesId: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2DataEntriesId(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [supplierId]
         * @param {Array<string>} [dataRequestId]
         * @param {Array<string>} [dataRequestQuestionId]
         * @param {Array<DataRequestAnswerReviewState>} [reviewState]
         * @param {boolean} [latest]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestAnswers: function (supplierId, dataRequestId, dataRequestQuestionId, reviewState, latest, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2DataRequestAnswers(supplierId, dataRequestId, dataRequestQuestionId, reviewState, latest, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestAnswersIdDataEntries: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2DataRequestAnswersIdDataEntries(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestAnswersIdFiles: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2DataRequestAnswersIdFiles(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [name]
         * @param {Array<string>} [ids]
         * @param {Array<string>} [questionIds]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestTemplates: function (name, ids, questionIds, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2DataRequestTemplates(name, ids, questionIds, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestsId: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2DataRequestsId(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestsIdDataEntries: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2DataRequestsIdDataEntries(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestsIdFiles: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2DataRequestsIdFiles(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {string} [dataSourceId]
         * @param {string} [policyParameterId]
         * @param {Array<string>} [dataRequestId]
         * @param {string} [title]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestsQuestions: function (id, dataSourceId, policyParameterId, dataRequestId, title, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2DataRequestsQuestions(id, dataSourceId, policyParameterId, dataRequestId, title, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestsQuestionsIdEditValidation: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2DataRequestsQuestionsIdEditValidation(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {string} [name]
         * @param {Array<string>} [policyParameter]
         * @param {boolean} [includeEmpty]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataSources: function (id, name, policyParameter, includeEmpty, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2DataSources(id, name, policyParameter, includeEmpty, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataSourcesId: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2DataSourcesId(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataSourcesIdCoveredparameters: function (id, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2DataSourcesIdCoveredparameters(id, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [dataEntryId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2FilesDataEntries: function (dataEntryId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2FilesDataEntries(dataEntryId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} dataRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2FilesDataRequestsDatarequestid: function (dataRequestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2FilesDataRequestsDatarequestid(dataRequestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2FilesFileid: function (fileId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2FilesFileid(fileId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [supplierId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2FilesSuppliers: function (supplierId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2FilesSuppliers(supplierId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2GlobalSuppliers: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2GlobalSuppliers(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [code]
         * @param {boolean} [includeUnassigned]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2IndustryGics: function (code, includeUnassigned, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2IndustryGics(code, includeUnassigned, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [fromDate]
         * @param {string} [toDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2MetricsOrganizationSuppliersTimelineEngaged: function (fromDate, toDate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2MetricsOrganizationSuppliersTimelineEngaged(fromDate, toDate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2MetricsOrganizationSuppliersTimelineTotal: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2MetricsOrganizationSuppliersTimelineTotal(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2MetricsOrganizationSuppliersTimelineTotalEngaged: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2MetricsOrganizationSuppliersTimelineTotalEngaged(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2RefDataSources: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2RefDataSources(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2Risk: function (supplierId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2Risk(supplierId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2RiskDataEntries: function (id, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2RiskDataEntries(id, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2RiskDataSources: function (page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2RiskDataSources(page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2RiskDataSourcesId: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2RiskDataSourcesId(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {Array<string>} [policyParameterId]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2RiskMitigationRules: function (page, pageSize, policyParameterId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2RiskMitigationRules(page, pageSize, policyParameterId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2RiskMitigationRulesId: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2RiskMitigationRulesId(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2RiskObjective: function (page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2RiskObjective(page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {Array<string>} [supplierId]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2Scorecards: function (id, supplierId, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2Scorecards(id, supplierId, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {Array<string>} [supplierId]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2ScorecardsId: function (id, supplierId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2ScorecardsId(id, supplierId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [supplierId]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2ScorecardsSupplierScoreAndCoverage: function (supplierId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2ScorecardsSupplierScoreAndCoverage(supplierId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequests: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2SupplierDataRequests(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsContextEmissions: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2SupplierDataRequestsContextEmissions(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsContextExistingData: function (page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2SupplierDataRequestsContextExistingData(page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [dataSourceName]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsContextNoData: function (dataSourceName, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2SupplierDataRequestsContextNoData(dataSourceName, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsContextRisk: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2SupplierDataRequestsContextRisk(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [policyParameterId]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsContextScore: function (policyParameterId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2SupplierDataRequestsContextScore(policyParameterId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsContextScoreOverall: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2SupplierDataRequestsContextScoreOverall(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} id
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsDataEntries: function (id, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2SupplierDataRequestsDataEntries(id, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [name]
         * @param {Array<string>} [id]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsDataSources: function (name, id, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2SupplierDataRequestsDataSources(name, id, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsLanguages: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2SupplierDataRequestsLanguages(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsPolicyParameters: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2SupplierDataRequestsPolicyParameters(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2SuppliersId: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2SuppliersId(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} organizationSupplierId
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2SuppliersOrganizationsupplieridContacts: function (organizationSupplierId, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2SuppliersOrganizationsupplieridContacts(organizationSupplierId, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} organizationSupplierId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2SuppliersOrganizationsupplieridContactsId: function (organizationSupplierId, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2SuppliersOrganizationsupplieridContactsId(organizationSupplierId, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} supplierId
         * @param {number} [startYear]
         * @param {number} [endYear]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2SuppliersSupplieridEmissionData: function (supplierId, startYear, endYear, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV2SuppliersSupplieridEmissionData(supplierId, startYear, endYear, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {string} [supplierId]
         * @param {string} [dataSourceId]
         * @param {string} [dataRequestId]
         * @param {number} [daysTillExpiredMin]
         * @param {number} [daysTillExpiredMax]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3DataEntries: function (id, supplierId, dataSourceId, dataRequestId, daysTillExpiredMin, daysTillExpiredMax, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3DataEntries(id, supplierId, dataSourceId, dataRequestId, daysTillExpiredMin, daysTillExpiredMax, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3DataEntriesId: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3DataEntriesId(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [supplierId]
         * @param {Array<string>} [dataRequestId]
         * @param {Array<string>} [dataRequestQuestionId]
         * @param {Array<DataRequestAnswerReviewState>} [reviewState]
         * @param {boolean} [latest]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3DataRequestAnswers: function (supplierId, dataRequestId, dataRequestQuestionId, reviewState, latest, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3DataRequestAnswers(supplierId, dataRequestId, dataRequestQuestionId, reviewState, latest, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV3DataRequestAnswersIdDataEntries: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3DataRequestAnswersIdDataEntries(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3DataRequestAnswersIdFiles: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3DataRequestAnswersIdFiles(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [name]
         * @param {Array<string>} [ids]
         * @param {Array<string>} [questionIds]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3DataRequestTemplates: function (name, ids, questionIds, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3DataRequestTemplates(name, ids, questionIds, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3DataRequestsId: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3DataRequestsId(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV3DataRequestsIdDataEntries: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3DataRequestsIdDataEntries(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3DataRequestsIdFiles: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3DataRequestsIdFiles(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {Array<string>} [policyParameterId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3RiskMitigationRules: function (page, pageSize, policyParameterId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3RiskMitigationRules(page, pageSize, policyParameterId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3RiskMitigationRulesId: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3RiskMitigationRulesId(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {Array<string>} [supplierId]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3Scorecards: function (id, supplierId, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3Scorecards(id, supplierId, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {Array<string>} [supplierId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3ScorecardsId: function (id, supplierId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3ScorecardsId(id, supplierId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [supplierId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3ScorecardsSupplierScoreAndCoverage: function (supplierId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3ScorecardsSupplierScoreAndCoverage(supplierId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequests: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3SupplierDataRequests(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsContextEmissions: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3SupplierDataRequestsContextEmissions(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsContextExistingData: function (page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3SupplierDataRequestsContextExistingData(page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [dataSourceName]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsContextNoData: function (dataSourceName, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3SupplierDataRequestsContextNoData(dataSourceName, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsContextRisk: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3SupplierDataRequestsContextRisk(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [policyParameterId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsContextScore: function (policyParameterId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3SupplierDataRequestsContextScore(policyParameterId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsContextScoreOverall: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3SupplierDataRequestsContextScoreOverall(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} id
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsDataEntries: function (id, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3SupplierDataRequestsDataEntries(id, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [name]
         * @param {Array<string>} [id]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsDataSources: function (name, id, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3SupplierDataRequestsDataSources(name, id, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsLanguages: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3SupplierDataRequestsLanguages(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsPolicyParameters: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV3SupplierDataRequestsPolicyParameters(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV4DataRequestAnswersIdDataEntries: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV4DataRequestAnswersIdDataEntries(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV4DataRequestsIdDataEntries: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getV4DataRequestsIdDataEntries(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {UpdateEmailTemplateIn} updateEmailTemplateIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchV1EmailTemplatesId: function (id, updateEmailTemplateIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patchV1EmailTemplatesId(id, updateEmailTemplateIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {SupplierCategoryPatchApiDto} supplierCategoryPatchApiDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchV1SupplierCategoriesId: function (id, supplierCategoryPatchApiDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patchV1SupplierCategoriesId(id, supplierCategoryPatchApiDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {string} reviewId
         * @param {InsertDataRequestAnswerReviewIn} insertDataRequestAnswerReviewIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchV2DataRequestAnswersIdReviewReviewid: function (id, reviewId, insertDataRequestAnswerReviewIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patchV2DataRequestAnswersIdReviewReviewid(id, reviewId, insertDataRequestAnswerReviewIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {string} reviewId
         * @param {InsertDataRequestAnswerReviewIn} insertDataRequestAnswerReviewIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchV3DataRequestAnswersIdReviewReviewid: function (id, reviewId, insertDataRequestAnswerReviewIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patchV3DataRequestAnswersIdReviewReviewid(id, reviewId, insertDataRequestAnswerReviewIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {UpdateAdminHackIn} updateAdminHackIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExperimentalAdmins: function (updateAdminHackIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postExperimentalAdmins(updateAdminHackIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {SignUpWithEmailDomainIn} signUpWithEmailDomainIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMgmtAuth0Signup: function (signUpWithEmailDomainIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postMgmtAuth0Signup(signUpWithEmailDomainIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMgmtAwsSns: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postMgmtAwsSns(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1ApiKeysName: function (name, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV1ApiKeysName(name, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CreateEmailTemplateIn} createEmailTemplateIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1EmailTemplates: function (createEmailTemplateIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV1EmailTemplates(createEmailTemplateIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {GenerateOnboardingPreSignedUploadUrlInput} generateOnboardingPreSignedUploadUrlInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1OnboardingFiles: function (generateOnboardingPreSignedUploadUrlInput, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV1OnboardingFiles(generateOnboardingPreSignedUploadUrlInput, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1Signup: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV1Signup(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {SupplierCategoryIn} supplierCategoryIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1SupplierCategories: function (supplierCategoryIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV1SupplierCategories(supplierCategoryIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {InsertUserInput} insertUserInput JSON body for inserting a User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1Users: function (insertUserInput, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV1Users(insertUserInput, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {UserInviteIn} userInviteIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1UsersInvites: function (userInviteIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV1UsersInvites(userInviteIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {InsertDataRequestAnswerReviewIn} insertDataRequestAnswerReviewIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2DataRequestAnswersIdReview: function (id, insertDataRequestAnswerReviewIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2DataRequestAnswersIdReview(id, insertDataRequestAnswerReviewIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CreateDataRequestTemplateV2In} createDataRequestTemplateV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2DataRequestTemplates: function (createDataRequestTemplateV2In, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2DataRequestTemplates(createDataRequestTemplateV2In, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {RenderDataRequestTemplateV2In} renderDataRequestTemplateV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2DataRequestTemplatesRenderEmail: function (id, renderDataRequestTemplateV2In, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2DataRequestTemplatesRenderEmail(id, renderDataRequestTemplateV2In, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {DataRequestFilterV2ApiDto} dataRequestFilterV2ApiDto
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2DataRequests: function (dataRequestFilterV2ApiDto, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2DataRequests(dataRequestFilterV2ApiDto, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {SendDataRequestV2In} sendDataRequestV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2DataRequestsActionsSend: function (sendDataRequestV2In, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2DataRequestsActionsSend(sendDataRequestV2In, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {UpdateDataRequestDueDateIn} updateDataRequestDueDateIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2DataRequestsActionsUpdateDueDate: function (updateDataRequestDueDateIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2DataRequestsActionsUpdateDueDate(updateDataRequestDueDateIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {InsertDataRequestQuestionV2In} insertDataRequestQuestionV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2DataRequestsQuestions: function (insertDataRequestQuestionV2In, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2DataRequestsQuestions(insertDataRequestQuestionV2In, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {RiskFilterIn} riskFilterIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2Risk: function (riskFilterIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2Risk(riskFilterIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {RiskContextRiskOverviewFilterIn} riskContextRiskOverviewFilterIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2RiskOverviewContext: function (riskContextRiskOverviewFilterIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2RiskOverviewContext(riskContextRiskOverviewFilterIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {PolicyParameterRiskOverViewFilterIn} policyParameterRiskOverViewFilterIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2RiskOverviewPolicyParameters: function (policyParameterRiskOverViewFilterIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2RiskOverviewPolicyParameters(policyParameterRiskOverViewFilterIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {InsertScorecardIn} insertScorecardIn
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postV2Scorecards: function (insertScorecardIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2Scorecards(insertScorecardIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {FilterAndSortSupplierByScoreAndCoverageIn} [filterAndSortSupplierByScoreAndCoverageIn]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postV2ScorecardsIdSupplierScoreAndCoverage: function (id, page, pageSize, filterAndSortSupplierByScoreAndCoverageIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2ScorecardsIdSupplierScoreAndCoverage(id, page, pageSize, filterAndSortSupplierByScoreAndCoverageIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {InsertDataRequestAnswerV2In} insertDataRequestAnswerV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SupplierDataRequestsAnswers: function (insertDataRequestAnswerV2In, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2SupplierDataRequestsAnswers(insertDataRequestAnswerV2In, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CreateDataRequestClaimV2In} createDataRequestClaimV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SupplierDataRequestsDataEntries: function (createDataRequestClaimV2In, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2SupplierDataRequestsDataEntries(createDataRequestClaimV2In, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {GenerateDataRequestPreSignedUploadUrlV2In} generateDataRequestPreSignedUploadUrlV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SupplierDataRequestsFiles: function (generateDataRequestPreSignedUploadUrlV2In, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2SupplierDataRequestsFiles(generateDataRequestPreSignedUploadUrlV2In, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {Array<string>} [dataRequestQuestionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SupplierDataRequestsIdActionsAnswerAutomatically: function (id, dataRequestQuestionId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2SupplierDataRequestsIdActionsAnswerAutomatically(id, dataRequestQuestionId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SupplierDataRequestsIdActionsComplete: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2SupplierDataRequestsIdActionsComplete(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {ListSuppliersIn} listSuppliersIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2Suppliers: function (listSuppliersIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2Suppliers(listSuppliersIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} name
         * @param {string} [countryIso3Code]
         * @param {SupplierStatus} [status]
         * @param {string} [website]
         * @param {Array<string>} [alias]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersActionsAdd: function (name, countryIso3Code, status, website, alias, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2SuppliersActionsAdd(name, countryIso3Code, status, website, alias, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {SuppliersAddToCategoryIn} suppliersAddToCategoryIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersActionsAddToCategory: function (suppliersAddToCategoryIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2SuppliersActionsAddToCategory(suppliersAddToCategoryIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {SuppliersRemoveFromCategoryIn} suppliersRemoveFromCategoryIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersActionsRemoveFromCategory: function (suppliersRemoveFromCategoryIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2SuppliersActionsRemoveFromCategory(suppliersRemoveFromCategoryIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {SearchSupplierIn} searchSupplierIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersActionsSearchGlobalSuppliers: function (searchSupplierIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2SuppliersActionsSearchGlobalSuppliers(searchSupplierIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {SuppliersLinkGlobalSupplierIn} suppliersLinkGlobalSupplierIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersIdActionsLinkGlobalSupplier: function (id, suppliersLinkGlobalSupplierIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2SuppliersIdActionsLinkGlobalSupplier(id, suppliersLinkGlobalSupplierIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {string} [globalSupplierId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersIdActionsUnlinkGlobalSupplier: function (id, globalSupplierId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2SuppliersIdActionsUnlinkGlobalSupplier(id, globalSupplierId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {CreateDataEntryIn} createDataEntryIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersIdDataEntries: function (id, createDataEntryIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2SuppliersIdDataEntries(id, createDataEntryIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {GenerateSupplierPreSignedUploadUrlInput} generateSupplierPreSignedUploadUrlInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersIdFiles: function (id, generateSupplierPreSignedUploadUrlInput, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2SuppliersIdFiles(id, generateSupplierPreSignedUploadUrlInput, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} organizationSupplierId
         * @param {InsertSupplierContactIn} insertSupplierContactIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersOrganizationsupplieridContacts: function (organizationSupplierId, insertSupplierContactIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2SuppliersOrganizationsupplieridContacts(organizationSupplierId, insertSupplierContactIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} organizationSupplierId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersOrganizationsupplieridContactsIdActionsMakeDefault: function (organizationSupplierId, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2SuppliersOrganizationsupplieridContactsIdActionsMakeDefault(organizationSupplierId, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} supplierId
         * @param {string} industryCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersSupplieridActionsAddIndustryIndustrycode: function (supplierId, industryCode, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2SuppliersSupplieridActionsAddIndustryIndustrycode(supplierId, industryCode, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} supplierId
         * @param {string} industryCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersSupplieridActionsRemoveIndustryIndustrycode: function (supplierId, industryCode, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV2SuppliersSupplieridActionsRemoveIndustryIndustrycode(supplierId, industryCode, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {InsertDataRequestAnswerReviewIn} insertDataRequestAnswerReviewIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3DataRequestAnswersIdReview: function (id, insertDataRequestAnswerReviewIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV3DataRequestAnswersIdReview(id, insertDataRequestAnswerReviewIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CreateDataRequestTemplateV3In} createDataRequestTemplateV3In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3DataRequestTemplates: function (createDataRequestTemplateV3In, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV3DataRequestTemplates(createDataRequestTemplateV3In, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {DataRequestFilterV2ApiDto} dataRequestFilterV2ApiDto
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3DataRequests: function (dataRequestFilterV2ApiDto, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV3DataRequests(dataRequestFilterV2ApiDto, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {SendDataRequestV2In} sendDataRequestV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3DataRequestsActionsSend: function (sendDataRequestV2In, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV3DataRequestsActionsSend(sendDataRequestV2In, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {UpdateDataRequestDueDateIn} updateDataRequestDueDateIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3DataRequestsActionsUpdateDueDate: function (updateDataRequestDueDateIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV3DataRequestsActionsUpdateDueDate(updateDataRequestDueDateIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3DataRequestsIdGenerateLink: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV3DataRequestsIdGenerateLink(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {InsertScorecardIn} insertScorecardIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3Scorecards: function (insertScorecardIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV3Scorecards(insertScorecardIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {FilterAndSortSupplierByScoreAndCoverageIn} [filterAndSortSupplierByScoreAndCoverageIn]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3ScorecardsIdSupplierScoreAndCoverage: function (id, page, pageSize, filterAndSortSupplierByScoreAndCoverageIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postV3ScorecardsIdSupplierScoreAndCoverage(id, page, pageSize, filterAndSortSupplierByScoreAndCoverageIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {UpdateOrganizationMasterDataIn} updateOrganizationMasterDataIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV1SettingsOrganization: function (updateOrganizationMasterDataIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.putV1SettingsOrganization(updateOrganizationMasterDataIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} userId
         * @param {UpdateUserInput} updateUserInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV1UsersUserid: function (userId, updateUserInput, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.putV1UsersUserid(userId, updateUserInput, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {UpdateDataRequestTemplateV2In} updateDataRequestTemplateV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV2DataRequestTemplates: function (id, updateDataRequestTemplateV2In, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.putV2DataRequestTemplates(id, updateDataRequestTemplateV2In, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {UpdateDataRequestQuestionV2In} updateDataRequestQuestionV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV2DataRequestsQuestionsId: function (id, updateDataRequestQuestionV2In, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.putV2DataRequestsQuestionsId(id, updateDataRequestQuestionV2In, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {UpdateScorecardIn} updateScorecardIn
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        putV2ScorecardsId: function (id, updateScorecardIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.putV2ScorecardsId(id, updateScorecardIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {UpdateSupplierIn} updateSupplierIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV2SuppliersId: function (id, updateSupplierIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.putV2SuppliersId(id, updateSupplierIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} organizationSupplierId
         * @param {string} id
         * @param {UpdateSupplierContactIn} updateSupplierContactIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV2SuppliersOrganizationsupplieridContactsId: function (organizationSupplierId, id, updateSupplierContactIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.putV2SuppliersOrganizationsupplieridContactsId(organizationSupplierId, id, updateSupplierContactIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {UpdateDataRequestTemplateV3In} updateDataRequestTemplateV3In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV3DataRequestTemplates: function (id, updateDataRequestTemplateV3In, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.putV3DataRequestTemplates(id, updateDataRequestTemplateV3In, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {UpdateScorecardIn} updateScorecardIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV3ScorecardsId: function (id, updateScorecardIn, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.putV3ScorecardsId(id, updateScorecardIn, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * DefaultApi - factory interface
 * @export
 */
exports.DefaultApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.DefaultApiFp(configuration);
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1ApiKeysId: function (id, options) {
            return localVarFp.deleteV1ApiKeysId(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1SupplierCategoriesId: function (id, options) {
            return localVarFp.deleteV1SupplierCategoriesId(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} userInviteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1UsersInvitesUserinviteid: function (userInviteId, options) {
            return localVarFp.deleteV1UsersInvitesUserinviteid(userInviteId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {string} reviewId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV2DataRequestAnswersIdReviewReviewid: function (id, reviewId, options) {
            return localVarFp.deleteV2DataRequestAnswersIdReviewReviewid(id, reviewId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteV2ScorecardsId: function (id, options) {
            return localVarFp.deleteV2ScorecardsId(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} organizationSupplierId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV2SuppliersOrganizationsupplieridContactsId: function (organizationSupplierId, id, options) {
            return localVarFp.deleteV2SuppliersOrganizationsupplieridContactsId(organizationSupplierId, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {string} reviewId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV3DataRequestAnswersIdReviewReviewid: function (id, reviewId, options) {
            return localVarFp.deleteV3DataRequestAnswersIdReviewReviewid(id, reviewId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV3ScorecardsId: function (id, options) {
            return localVarFp.deleteV3ScorecardsId(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentalAdmins: function (options) {
            return localVarFp.getExperimentalAdmins(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} organizationId
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getExperimentalDataEntries: function (organizationId, page, pageSize, options) {
            return localVarFp.getExperimentalDataEntries(organizationId, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [organizationId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentalDataRequestsReminders: function (organizationId, options) {
            return localVarFp.getExperimentalDataRequestsReminders(organizationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentalOrganizations: function (options) {
            return localVarFp.getExperimentalOrganizations(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} organizationId
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getExperimentalScorecards: function (organizationId, page, pageSize, options) {
            return localVarFp.getExperimentalScorecards(organizationId, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} organizationId
         * @param {string} scorecardId
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getExperimentalScorecardsSupplierScores: function (organizationId, scorecardId, page, pageSize, options) {
            return localVarFp.getExperimentalScorecardsSupplierScores(organizationId, scorecardId, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} organizationId
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentalV2Scorecards: function (organizationId, page, pageSize, options) {
            return localVarFp.getExperimentalV2Scorecards(organizationId, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} organizationId
         * @param {string} scorecardId
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentalV2ScorecardsSupplierScores: function (organizationId, scorecardId, page, pageSize, options) {
            return localVarFp.getExperimentalV2ScorecardsSupplierScores(organizationId, scorecardId, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1ApiKeys: function (page, pageSize, options) {
            return localVarFp.getV1ApiKeys(page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1Currencies: function (options) {
            return localVarFp.getV1Currencies(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {EmailTemplateType} [type]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1EmailTemplates: function (type, page, pageSize, options) {
            return localVarFp.getV1EmailTemplates(type, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1GeoCountries: function (options) {
            return localVarFp.getV1GeoCountries(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} iso3Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1GeoCountriesIso3code: function (iso3Code, options) {
            return localVarFp.getV1GeoCountriesIso3code(iso3Code, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1GeoRegions: function (options) {
            return localVarFp.getV1GeoRegions(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} regionName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1GeoRegionsRegionname: function (regionName, options) {
            return localVarFp.getV1GeoRegionsRegionname(regionName, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1Languages: function (options) {
            return localVarFp.getV1Languages(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1OnboardingFiles: function (options) {
            return localVarFp.getV1OnboardingFiles(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1PolicyParameters: function (page, pageSize, options) {
            return localVarFp.getV1PolicyParameters(page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1PolicyParametersId: function (id, options) {
            return localVarFp.getV1PolicyParametersId(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1SettingsOrganization: function (options) {
            return localVarFp.getV1SettingsOrganization(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1SupplierCategories: function (page, pageSize, options) {
            return localVarFp.getV1SupplierCategories(page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1SupplierCategoriesId: function (id, options) {
            return localVarFp.getV1SupplierCategoriesId(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1Users: function (page, pageSize, options) {
            return localVarFp.getV1Users(page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1UsersInvites: function (options) {
            return localVarFp.getV1UsersInvites(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1UsersMe: function (options) {
            return localVarFp.getV1UsersMe(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1UsersUserid: function (userId, options) {
            return localVarFp.getV1UsersUserid(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {string} [supplierId]
         * @param {string} [dataSourceId]
         * @param {string} [dataRequestId]
         * @param {number} [daysTillExpiredMin]
         * @param {number} [daysTillExpiredMax]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2DataEntries: function (id, supplierId, dataSourceId, dataRequestId, daysTillExpiredMin, daysTillExpiredMax, page, pageSize, options) {
            return localVarFp.getV2DataEntries(id, supplierId, dataSourceId, dataRequestId, daysTillExpiredMin, daysTillExpiredMax, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2DataEntriesId: function (id, options) {
            return localVarFp.getV2DataEntriesId(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} [supplierId]
         * @param {Array<string>} [dataRequestId]
         * @param {Array<string>} [dataRequestQuestionId]
         * @param {Array<DataRequestAnswerReviewState>} [reviewState]
         * @param {boolean} [latest]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestAnswers: function (supplierId, dataRequestId, dataRequestQuestionId, reviewState, latest, page, pageSize, options) {
            return localVarFp.getV2DataRequestAnswers(supplierId, dataRequestId, dataRequestQuestionId, reviewState, latest, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestAnswersIdDataEntries: function (id, options) {
            return localVarFp.getV2DataRequestAnswersIdDataEntries(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestAnswersIdFiles: function (id, options) {
            return localVarFp.getV2DataRequestAnswersIdFiles(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [name]
         * @param {Array<string>} [ids]
         * @param {Array<string>} [questionIds]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestTemplates: function (name, ids, questionIds, page, pageSize, options) {
            return localVarFp.getV2DataRequestTemplates(name, ids, questionIds, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestsId: function (id, options) {
            return localVarFp.getV2DataRequestsId(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestsIdDataEntries: function (id, options) {
            return localVarFp.getV2DataRequestsIdDataEntries(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestsIdFiles: function (id, options) {
            return localVarFp.getV2DataRequestsIdFiles(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {string} [dataSourceId]
         * @param {string} [policyParameterId]
         * @param {Array<string>} [dataRequestId]
         * @param {string} [title]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestsQuestions: function (id, dataSourceId, policyParameterId, dataRequestId, title, page, pageSize, options) {
            return localVarFp.getV2DataRequestsQuestions(id, dataSourceId, policyParameterId, dataRequestId, title, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataRequestsQuestionsIdEditValidation: function (id, options) {
            return localVarFp.getV2DataRequestsQuestionsIdEditValidation(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {string} [name]
         * @param {Array<string>} [policyParameter]
         * @param {boolean} [includeEmpty]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataSources: function (id, name, policyParameter, includeEmpty, page, pageSize, options) {
            return localVarFp.getV2DataSources(id, name, policyParameter, includeEmpty, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataSourcesId: function (id, options) {
            return localVarFp.getV2DataSourcesId(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2DataSourcesIdCoveredparameters: function (id, page, pageSize, options) {
            return localVarFp.getV2DataSourcesIdCoveredparameters(id, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} [dataEntryId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2FilesDataEntries: function (dataEntryId, options) {
            return localVarFp.getV2FilesDataEntries(dataEntryId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} dataRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2FilesDataRequestsDatarequestid: function (dataRequestId, options) {
            return localVarFp.getV2FilesDataRequestsDatarequestid(dataRequestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2FilesFileid: function (fileId, options) {
            return localVarFp.getV2FilesFileid(fileId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} [supplierId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2FilesSuppliers: function (supplierId, options) {
            return localVarFp.getV2FilesSuppliers(supplierId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2GlobalSuppliers: function (id, options) {
            return localVarFp.getV2GlobalSuppliers(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} [code]
         * @param {boolean} [includeUnassigned]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2IndustryGics: function (code, includeUnassigned, options) {
            return localVarFp.getV2IndustryGics(code, includeUnassigned, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [fromDate]
         * @param {string} [toDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2MetricsOrganizationSuppliersTimelineEngaged: function (fromDate, toDate, options) {
            return localVarFp.getV2MetricsOrganizationSuppliersTimelineEngaged(fromDate, toDate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2MetricsOrganizationSuppliersTimelineTotal: function (options) {
            return localVarFp.getV2MetricsOrganizationSuppliersTimelineTotal(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2MetricsOrganizationSuppliersTimelineTotalEngaged: function (options) {
            return localVarFp.getV2MetricsOrganizationSuppliersTimelineTotalEngaged(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2RefDataSources: function (options) {
            return localVarFp.getV2RefDataSources(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2Risk: function (supplierId, options) {
            return localVarFp.getV2Risk(supplierId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2RiskDataEntries: function (id, page, pageSize, options) {
            return localVarFp.getV2RiskDataEntries(id, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2RiskDataSources: function (page, pageSize, options) {
            return localVarFp.getV2RiskDataSources(page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2RiskDataSourcesId: function (id, options) {
            return localVarFp.getV2RiskDataSourcesId(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {Array<string>} [policyParameterId]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2RiskMitigationRules: function (page, pageSize, policyParameterId, options) {
            return localVarFp.getV2RiskMitigationRules(page, pageSize, policyParameterId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2RiskMitigationRulesId: function (id, options) {
            return localVarFp.getV2RiskMitigationRulesId(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2RiskObjective: function (page, pageSize, options) {
            return localVarFp.getV2RiskObjective(page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {Array<string>} [supplierId]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2Scorecards: function (id, supplierId, page, pageSize, options) {
            return localVarFp.getV2Scorecards(id, supplierId, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {Array<string>} [supplierId]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2ScorecardsId: function (id, supplierId, options) {
            return localVarFp.getV2ScorecardsId(id, supplierId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} [supplierId]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2ScorecardsSupplierScoreAndCoverage: function (supplierId, options) {
            return localVarFp.getV2ScorecardsSupplierScoreAndCoverage(supplierId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequests: function (options) {
            return localVarFp.getV2SupplierDataRequests(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsContextEmissions: function (options) {
            return localVarFp.getV2SupplierDataRequestsContextEmissions(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsContextExistingData: function (page, pageSize, options) {
            return localVarFp.getV2SupplierDataRequestsContextExistingData(page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [dataSourceName]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsContextNoData: function (dataSourceName, page, pageSize, options) {
            return localVarFp.getV2SupplierDataRequestsContextNoData(dataSourceName, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsContextRisk: function (options) {
            return localVarFp.getV2SupplierDataRequestsContextRisk(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} [policyParameterId]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsContextScore: function (policyParameterId, options) {
            return localVarFp.getV2SupplierDataRequestsContextScore(policyParameterId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsContextScoreOverall: function (options) {
            return localVarFp.getV2SupplierDataRequestsContextScoreOverall(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} id
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsDataEntries: function (id, page, pageSize, options) {
            return localVarFp.getV2SupplierDataRequestsDataEntries(id, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [name]
         * @param {Array<string>} [id]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsDataSources: function (name, id, page, pageSize, options) {
            return localVarFp.getV2SupplierDataRequestsDataSources(name, id, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsLanguages: function (options) {
            return localVarFp.getV2SupplierDataRequestsLanguages(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV2SupplierDataRequestsPolicyParameters: function (options) {
            return localVarFp.getV2SupplierDataRequestsPolicyParameters(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2SuppliersId: function (id, options) {
            return localVarFp.getV2SuppliersId(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} organizationSupplierId
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2SuppliersOrganizationsupplieridContacts: function (organizationSupplierId, page, pageSize, options) {
            return localVarFp.getV2SuppliersOrganizationsupplieridContacts(organizationSupplierId, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} organizationSupplierId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2SuppliersOrganizationsupplieridContactsId: function (organizationSupplierId, id, options) {
            return localVarFp.getV2SuppliersOrganizationsupplieridContactsId(organizationSupplierId, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} supplierId
         * @param {number} [startYear]
         * @param {number} [endYear]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2SuppliersSupplieridEmissionData: function (supplierId, startYear, endYear, options) {
            return localVarFp.getV2SuppliersSupplieridEmissionData(supplierId, startYear, endYear, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {string} [supplierId]
         * @param {string} [dataSourceId]
         * @param {string} [dataRequestId]
         * @param {number} [daysTillExpiredMin]
         * @param {number} [daysTillExpiredMax]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3DataEntries: function (id, supplierId, dataSourceId, dataRequestId, daysTillExpiredMin, daysTillExpiredMax, page, pageSize, options) {
            return localVarFp.getV3DataEntries(id, supplierId, dataSourceId, dataRequestId, daysTillExpiredMin, daysTillExpiredMax, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3DataEntriesId: function (id, options) {
            return localVarFp.getV3DataEntriesId(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} [supplierId]
         * @param {Array<string>} [dataRequestId]
         * @param {Array<string>} [dataRequestQuestionId]
         * @param {Array<DataRequestAnswerReviewState>} [reviewState]
         * @param {boolean} [latest]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3DataRequestAnswers: function (supplierId, dataRequestId, dataRequestQuestionId, reviewState, latest, page, pageSize, options) {
            return localVarFp.getV3DataRequestAnswers(supplierId, dataRequestId, dataRequestQuestionId, reviewState, latest, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV3DataRequestAnswersIdDataEntries: function (id, options) {
            return localVarFp.getV3DataRequestAnswersIdDataEntries(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3DataRequestAnswersIdFiles: function (id, options) {
            return localVarFp.getV3DataRequestAnswersIdFiles(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [name]
         * @param {Array<string>} [ids]
         * @param {Array<string>} [questionIds]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3DataRequestTemplates: function (name, ids, questionIds, page, pageSize, options) {
            return localVarFp.getV3DataRequestTemplates(name, ids, questionIds, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3DataRequestsId: function (id, options) {
            return localVarFp.getV3DataRequestsId(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getV3DataRequestsIdDataEntries: function (id, options) {
            return localVarFp.getV3DataRequestsIdDataEntries(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3DataRequestsIdFiles: function (id, options) {
            return localVarFp.getV3DataRequestsIdFiles(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {Array<string>} [policyParameterId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3RiskMitigationRules: function (page, pageSize, policyParameterId, options) {
            return localVarFp.getV3RiskMitigationRules(page, pageSize, policyParameterId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3RiskMitigationRulesId: function (id, options) {
            return localVarFp.getV3RiskMitigationRulesId(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} [id]
         * @param {Array<string>} [supplierId]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3Scorecards: function (id, supplierId, page, pageSize, options) {
            return localVarFp.getV3Scorecards(id, supplierId, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {Array<string>} [supplierId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3ScorecardsId: function (id, supplierId, options) {
            return localVarFp.getV3ScorecardsId(id, supplierId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} [supplierId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3ScorecardsSupplierScoreAndCoverage: function (supplierId, options) {
            return localVarFp.getV3ScorecardsSupplierScoreAndCoverage(supplierId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequests: function (options) {
            return localVarFp.getV3SupplierDataRequests(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsContextEmissions: function (options) {
            return localVarFp.getV3SupplierDataRequestsContextEmissions(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsContextExistingData: function (page, pageSize, options) {
            return localVarFp.getV3SupplierDataRequestsContextExistingData(page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [dataSourceName]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsContextNoData: function (dataSourceName, page, pageSize, options) {
            return localVarFp.getV3SupplierDataRequestsContextNoData(dataSourceName, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsContextRisk: function (options) {
            return localVarFp.getV3SupplierDataRequestsContextRisk(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} [policyParameterId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsContextScore: function (policyParameterId, options) {
            return localVarFp.getV3SupplierDataRequestsContextScore(policyParameterId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsContextScoreOverall: function (options) {
            return localVarFp.getV3SupplierDataRequestsContextScoreOverall(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} id
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsDataEntries: function (id, page, pageSize, options) {
            return localVarFp.getV3SupplierDataRequestsDataEntries(id, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [name]
         * @param {Array<string>} [id]
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsDataSources: function (name, id, page, pageSize, options) {
            return localVarFp.getV3SupplierDataRequestsDataSources(name, id, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsLanguages: function (options) {
            return localVarFp.getV3SupplierDataRequestsLanguages(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV3SupplierDataRequestsPolicyParameters: function (options) {
            return localVarFp.getV3SupplierDataRequestsPolicyParameters(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV4DataRequestAnswersIdDataEntries: function (id, options) {
            return localVarFp.getV4DataRequestAnswersIdDataEntries(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV4DataRequestsIdDataEntries: function (id, options) {
            return localVarFp.getV4DataRequestsIdDataEntries(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {UpdateEmailTemplateIn} updateEmailTemplateIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchV1EmailTemplatesId: function (id, updateEmailTemplateIn, options) {
            return localVarFp.patchV1EmailTemplatesId(id, updateEmailTemplateIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {SupplierCategoryPatchApiDto} supplierCategoryPatchApiDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchV1SupplierCategoriesId: function (id, supplierCategoryPatchApiDto, options) {
            return localVarFp.patchV1SupplierCategoriesId(id, supplierCategoryPatchApiDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {string} reviewId
         * @param {InsertDataRequestAnswerReviewIn} insertDataRequestAnswerReviewIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchV2DataRequestAnswersIdReviewReviewid: function (id, reviewId, insertDataRequestAnswerReviewIn, options) {
            return localVarFp.patchV2DataRequestAnswersIdReviewReviewid(id, reviewId, insertDataRequestAnswerReviewIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {string} reviewId
         * @param {InsertDataRequestAnswerReviewIn} insertDataRequestAnswerReviewIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchV3DataRequestAnswersIdReviewReviewid: function (id, reviewId, insertDataRequestAnswerReviewIn, options) {
            return localVarFp.patchV3DataRequestAnswersIdReviewReviewid(id, reviewId, insertDataRequestAnswerReviewIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {UpdateAdminHackIn} updateAdminHackIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExperimentalAdmins: function (updateAdminHackIn, options) {
            return localVarFp.postExperimentalAdmins(updateAdminHackIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {SignUpWithEmailDomainIn} signUpWithEmailDomainIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMgmtAuth0Signup: function (signUpWithEmailDomainIn, options) {
            return localVarFp.postMgmtAuth0Signup(signUpWithEmailDomainIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMgmtAwsSns: function (body, options) {
            return localVarFp.postMgmtAwsSns(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1ApiKeysName: function (name, options) {
            return localVarFp.postV1ApiKeysName(name, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CreateEmailTemplateIn} createEmailTemplateIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1EmailTemplates: function (createEmailTemplateIn, options) {
            return localVarFp.postV1EmailTemplates(createEmailTemplateIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {GenerateOnboardingPreSignedUploadUrlInput} generateOnboardingPreSignedUploadUrlInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1OnboardingFiles: function (generateOnboardingPreSignedUploadUrlInput, options) {
            return localVarFp.postV1OnboardingFiles(generateOnboardingPreSignedUploadUrlInput, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1Signup: function (body, options) {
            return localVarFp.postV1Signup(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {SupplierCategoryIn} supplierCategoryIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1SupplierCategories: function (supplierCategoryIn, options) {
            return localVarFp.postV1SupplierCategories(supplierCategoryIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {InsertUserInput} insertUserInput JSON body for inserting a User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1Users: function (insertUserInput, options) {
            return localVarFp.postV1Users(insertUserInput, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {UserInviteIn} userInviteIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1UsersInvites: function (userInviteIn, options) {
            return localVarFp.postV1UsersInvites(userInviteIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {InsertDataRequestAnswerReviewIn} insertDataRequestAnswerReviewIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2DataRequestAnswersIdReview: function (id, insertDataRequestAnswerReviewIn, options) {
            return localVarFp.postV2DataRequestAnswersIdReview(id, insertDataRequestAnswerReviewIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CreateDataRequestTemplateV2In} createDataRequestTemplateV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2DataRequestTemplates: function (createDataRequestTemplateV2In, options) {
            return localVarFp.postV2DataRequestTemplates(createDataRequestTemplateV2In, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {RenderDataRequestTemplateV2In} renderDataRequestTemplateV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2DataRequestTemplatesRenderEmail: function (id, renderDataRequestTemplateV2In, options) {
            return localVarFp.postV2DataRequestTemplatesRenderEmail(id, renderDataRequestTemplateV2In, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {DataRequestFilterV2ApiDto} dataRequestFilterV2ApiDto
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2DataRequests: function (dataRequestFilterV2ApiDto, page, pageSize, options) {
            return localVarFp.postV2DataRequests(dataRequestFilterV2ApiDto, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {SendDataRequestV2In} sendDataRequestV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2DataRequestsActionsSend: function (sendDataRequestV2In, options) {
            return localVarFp.postV2DataRequestsActionsSend(sendDataRequestV2In, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {UpdateDataRequestDueDateIn} updateDataRequestDueDateIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2DataRequestsActionsUpdateDueDate: function (updateDataRequestDueDateIn, options) {
            return localVarFp.postV2DataRequestsActionsUpdateDueDate(updateDataRequestDueDateIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {InsertDataRequestQuestionV2In} insertDataRequestQuestionV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2DataRequestsQuestions: function (insertDataRequestQuestionV2In, options) {
            return localVarFp.postV2DataRequestsQuestions(insertDataRequestQuestionV2In, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {RiskFilterIn} riskFilterIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2Risk: function (riskFilterIn, options) {
            return localVarFp.postV2Risk(riskFilterIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {RiskContextRiskOverviewFilterIn} riskContextRiskOverviewFilterIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2RiskOverviewContext: function (riskContextRiskOverviewFilterIn, options) {
            return localVarFp.postV2RiskOverviewContext(riskContextRiskOverviewFilterIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {PolicyParameterRiskOverViewFilterIn} policyParameterRiskOverViewFilterIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2RiskOverviewPolicyParameters: function (policyParameterRiskOverViewFilterIn, options) {
            return localVarFp.postV2RiskOverviewPolicyParameters(policyParameterRiskOverViewFilterIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {InsertScorecardIn} insertScorecardIn
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postV2Scorecards: function (insertScorecardIn, options) {
            return localVarFp.postV2Scorecards(insertScorecardIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {FilterAndSortSupplierByScoreAndCoverageIn} [filterAndSortSupplierByScoreAndCoverageIn]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postV2ScorecardsIdSupplierScoreAndCoverage: function (id, page, pageSize, filterAndSortSupplierByScoreAndCoverageIn, options) {
            return localVarFp.postV2ScorecardsIdSupplierScoreAndCoverage(id, page, pageSize, filterAndSortSupplierByScoreAndCoverageIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {InsertDataRequestAnswerV2In} insertDataRequestAnswerV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SupplierDataRequestsAnswers: function (insertDataRequestAnswerV2In, options) {
            return localVarFp.postV2SupplierDataRequestsAnswers(insertDataRequestAnswerV2In, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CreateDataRequestClaimV2In} createDataRequestClaimV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SupplierDataRequestsDataEntries: function (createDataRequestClaimV2In, options) {
            return localVarFp.postV2SupplierDataRequestsDataEntries(createDataRequestClaimV2In, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {GenerateDataRequestPreSignedUploadUrlV2In} generateDataRequestPreSignedUploadUrlV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SupplierDataRequestsFiles: function (generateDataRequestPreSignedUploadUrlV2In, options) {
            return localVarFp.postV2SupplierDataRequestsFiles(generateDataRequestPreSignedUploadUrlV2In, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {Array<string>} [dataRequestQuestionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SupplierDataRequestsIdActionsAnswerAutomatically: function (id, dataRequestQuestionId, options) {
            return localVarFp.postV2SupplierDataRequestsIdActionsAnswerAutomatically(id, dataRequestQuestionId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SupplierDataRequestsIdActionsComplete: function (id, options) {
            return localVarFp.postV2SupplierDataRequestsIdActionsComplete(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {ListSuppliersIn} listSuppliersIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2Suppliers: function (listSuppliersIn, options) {
            return localVarFp.postV2Suppliers(listSuppliersIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} name
         * @param {string} [countryIso3Code]
         * @param {SupplierStatus} [status]
         * @param {string} [website]
         * @param {Array<string>} [alias]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersActionsAdd: function (name, countryIso3Code, status, website, alias, options) {
            return localVarFp.postV2SuppliersActionsAdd(name, countryIso3Code, status, website, alias, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {SuppliersAddToCategoryIn} suppliersAddToCategoryIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersActionsAddToCategory: function (suppliersAddToCategoryIn, options) {
            return localVarFp.postV2SuppliersActionsAddToCategory(suppliersAddToCategoryIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {SuppliersRemoveFromCategoryIn} suppliersRemoveFromCategoryIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersActionsRemoveFromCategory: function (suppliersRemoveFromCategoryIn, options) {
            return localVarFp.postV2SuppliersActionsRemoveFromCategory(suppliersRemoveFromCategoryIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {SearchSupplierIn} searchSupplierIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersActionsSearchGlobalSuppliers: function (searchSupplierIn, options) {
            return localVarFp.postV2SuppliersActionsSearchGlobalSuppliers(searchSupplierIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {SuppliersLinkGlobalSupplierIn} suppliersLinkGlobalSupplierIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersIdActionsLinkGlobalSupplier: function (id, suppliersLinkGlobalSupplierIn, options) {
            return localVarFp.postV2SuppliersIdActionsLinkGlobalSupplier(id, suppliersLinkGlobalSupplierIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {string} [globalSupplierId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersIdActionsUnlinkGlobalSupplier: function (id, globalSupplierId, options) {
            return localVarFp.postV2SuppliersIdActionsUnlinkGlobalSupplier(id, globalSupplierId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {CreateDataEntryIn} createDataEntryIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersIdDataEntries: function (id, createDataEntryIn, options) {
            return localVarFp.postV2SuppliersIdDataEntries(id, createDataEntryIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {GenerateSupplierPreSignedUploadUrlInput} generateSupplierPreSignedUploadUrlInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersIdFiles: function (id, generateSupplierPreSignedUploadUrlInput, options) {
            return localVarFp.postV2SuppliersIdFiles(id, generateSupplierPreSignedUploadUrlInput, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} organizationSupplierId
         * @param {InsertSupplierContactIn} insertSupplierContactIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersOrganizationsupplieridContacts: function (organizationSupplierId, insertSupplierContactIn, options) {
            return localVarFp.postV2SuppliersOrganizationsupplieridContacts(organizationSupplierId, insertSupplierContactIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} organizationSupplierId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersOrganizationsupplieridContactsIdActionsMakeDefault: function (organizationSupplierId, id, options) {
            return localVarFp.postV2SuppliersOrganizationsupplieridContactsIdActionsMakeDefault(organizationSupplierId, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} supplierId
         * @param {string} industryCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersSupplieridActionsAddIndustryIndustrycode: function (supplierId, industryCode, options) {
            return localVarFp.postV2SuppliersSupplieridActionsAddIndustryIndustrycode(supplierId, industryCode, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} supplierId
         * @param {string} industryCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2SuppliersSupplieridActionsRemoveIndustryIndustrycode: function (supplierId, industryCode, options) {
            return localVarFp.postV2SuppliersSupplieridActionsRemoveIndustryIndustrycode(supplierId, industryCode, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {InsertDataRequestAnswerReviewIn} insertDataRequestAnswerReviewIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3DataRequestAnswersIdReview: function (id, insertDataRequestAnswerReviewIn, options) {
            return localVarFp.postV3DataRequestAnswersIdReview(id, insertDataRequestAnswerReviewIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CreateDataRequestTemplateV3In} createDataRequestTemplateV3In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3DataRequestTemplates: function (createDataRequestTemplateV3In, options) {
            return localVarFp.postV3DataRequestTemplates(createDataRequestTemplateV3In, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {DataRequestFilterV2ApiDto} dataRequestFilterV2ApiDto
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3DataRequests: function (dataRequestFilterV2ApiDto, page, pageSize, options) {
            return localVarFp.postV3DataRequests(dataRequestFilterV2ApiDto, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {SendDataRequestV2In} sendDataRequestV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3DataRequestsActionsSend: function (sendDataRequestV2In, options) {
            return localVarFp.postV3DataRequestsActionsSend(sendDataRequestV2In, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {UpdateDataRequestDueDateIn} updateDataRequestDueDateIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3DataRequestsActionsUpdateDueDate: function (updateDataRequestDueDateIn, options) {
            return localVarFp.postV3DataRequestsActionsUpdateDueDate(updateDataRequestDueDateIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3DataRequestsIdGenerateLink: function (id, options) {
            return localVarFp.postV3DataRequestsIdGenerateLink(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {InsertScorecardIn} insertScorecardIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3Scorecards: function (insertScorecardIn, options) {
            return localVarFp.postV3Scorecards(insertScorecardIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {number} [page] Page number to fetch
         * @param {number} [pageSize] Page size
         * @param {FilterAndSortSupplierByScoreAndCoverageIn} [filterAndSortSupplierByScoreAndCoverageIn]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV3ScorecardsIdSupplierScoreAndCoverage: function (id, page, pageSize, filterAndSortSupplierByScoreAndCoverageIn, options) {
            return localVarFp.postV3ScorecardsIdSupplierScoreAndCoverage(id, page, pageSize, filterAndSortSupplierByScoreAndCoverageIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {UpdateOrganizationMasterDataIn} updateOrganizationMasterDataIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV1SettingsOrganization: function (updateOrganizationMasterDataIn, options) {
            return localVarFp.putV1SettingsOrganization(updateOrganizationMasterDataIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} userId
         * @param {UpdateUserInput} updateUserInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV1UsersUserid: function (userId, updateUserInput, options) {
            return localVarFp.putV1UsersUserid(userId, updateUserInput, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {UpdateDataRequestTemplateV2In} updateDataRequestTemplateV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV2DataRequestTemplates: function (id, updateDataRequestTemplateV2In, options) {
            return localVarFp.putV2DataRequestTemplates(id, updateDataRequestTemplateV2In, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {UpdateDataRequestQuestionV2In} updateDataRequestQuestionV2In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV2DataRequestsQuestionsId: function (id, updateDataRequestQuestionV2In, options) {
            return localVarFp.putV2DataRequestsQuestionsId(id, updateDataRequestQuestionV2In, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {UpdateScorecardIn} updateScorecardIn
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        putV2ScorecardsId: function (id, updateScorecardIn, options) {
            return localVarFp.putV2ScorecardsId(id, updateScorecardIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {UpdateSupplierIn} updateSupplierIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV2SuppliersId: function (id, updateSupplierIn, options) {
            return localVarFp.putV2SuppliersId(id, updateSupplierIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} organizationSupplierId
         * @param {string} id
         * @param {UpdateSupplierContactIn} updateSupplierContactIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV2SuppliersOrganizationsupplieridContactsId: function (organizationSupplierId, id, updateSupplierContactIn, options) {
            return localVarFp.putV2SuppliersOrganizationsupplieridContactsId(organizationSupplierId, id, updateSupplierContactIn, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {UpdateDataRequestTemplateV3In} updateDataRequestTemplateV3In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV3DataRequestTemplates: function (id, updateDataRequestTemplateV3In, options) {
            return localVarFp.putV3DataRequestTemplates(id, updateDataRequestTemplateV3In, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {UpdateScorecardIn} updateScorecardIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV3ScorecardsId: function (id, updateScorecardIn, options) {
            return localVarFp.putV3ScorecardsId(id, updateScorecardIn, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
var DefaultApi = /** @class */ (function (_super) {
    __extends(DefaultApi, _super);
    function DefaultApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.deleteV1ApiKeysId = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).deleteV1ApiKeysId(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.deleteV1SupplierCategoriesId = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).deleteV1SupplierCategoriesId(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} userInviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.deleteV1UsersInvitesUserinviteid = function (userInviteId, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).deleteV1UsersInvitesUserinviteid(userInviteId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {string} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.deleteV2DataRequestAnswersIdReviewReviewid = function (id, reviewId, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).deleteV2DataRequestAnswersIdReviewReviewid(id, reviewId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.deleteV2ScorecardsId = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).deleteV2ScorecardsId(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} organizationSupplierId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.deleteV2SuppliersOrganizationsupplieridContactsId = function (organizationSupplierId, id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).deleteV2SuppliersOrganizationsupplieridContactsId(organizationSupplierId, id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {string} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.deleteV3DataRequestAnswersIdReviewReviewid = function (id, reviewId, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).deleteV3DataRequestAnswersIdReviewReviewid(id, reviewId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.deleteV3ScorecardsId = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).deleteV3ScorecardsId(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getExperimentalAdmins = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getExperimentalAdmins(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} organizationId
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getExperimentalDataEntries = function (organizationId, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getExperimentalDataEntries(organizationId, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [organizationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getExperimentalDataRequestsReminders = function (organizationId, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getExperimentalDataRequestsReminders(organizationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getExperimentalOrganizations = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getExperimentalOrganizations(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} organizationId
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getExperimentalScorecards = function (organizationId, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getExperimentalScorecards(organizationId, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} organizationId
     * @param {string} scorecardId
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getExperimentalScorecardsSupplierScores = function (organizationId, scorecardId, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getExperimentalScorecardsSupplierScores(organizationId, scorecardId, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} organizationId
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getExperimentalV2Scorecards = function (organizationId, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getExperimentalV2Scorecards(organizationId, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} organizationId
     * @param {string} scorecardId
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getExperimentalV2ScorecardsSupplierScores = function (organizationId, scorecardId, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getExperimentalV2ScorecardsSupplierScores(organizationId, scorecardId, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV1ApiKeys = function (page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV1ApiKeys(page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV1Currencies = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV1Currencies(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {EmailTemplateType} [type]
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV1EmailTemplates = function (type, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV1EmailTemplates(type, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV1GeoCountries = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV1GeoCountries(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} iso3Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV1GeoCountriesIso3code = function (iso3Code, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV1GeoCountriesIso3code(iso3Code, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV1GeoRegions = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV1GeoRegions(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} regionName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV1GeoRegionsRegionname = function (regionName, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV1GeoRegionsRegionname(regionName, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV1Languages = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV1Languages(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV1OnboardingFiles = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV1OnboardingFiles(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV1PolicyParameters = function (page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV1PolicyParameters(page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV1PolicyParametersId = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV1PolicyParametersId(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV1SettingsOrganization = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV1SettingsOrganization(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV1SupplierCategories = function (page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV1SupplierCategories(page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV1SupplierCategoriesId = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV1SupplierCategoriesId(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV1Users = function (page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV1Users(page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV1UsersInvites = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV1UsersInvites(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV1UsersMe = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV1UsersMe(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV1UsersUserid = function (userId, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV1UsersUserid(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} [id]
     * @param {string} [supplierId]
     * @param {string} [dataSourceId]
     * @param {string} [dataRequestId]
     * @param {number} [daysTillExpiredMin]
     * @param {number} [daysTillExpiredMax]
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2DataEntries = function (id, supplierId, dataSourceId, dataRequestId, daysTillExpiredMin, daysTillExpiredMax, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2DataEntries(id, supplierId, dataSourceId, dataRequestId, daysTillExpiredMin, daysTillExpiredMax, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2DataEntriesId = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2DataEntriesId(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} [supplierId]
     * @param {Array<string>} [dataRequestId]
     * @param {Array<string>} [dataRequestQuestionId]
     * @param {Array<DataRequestAnswerReviewState>} [reviewState]
     * @param {boolean} [latest]
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2DataRequestAnswers = function (supplierId, dataRequestId, dataRequestQuestionId, reviewState, latest, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2DataRequestAnswers(supplierId, dataRequestId, dataRequestQuestionId, reviewState, latest, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2DataRequestAnswersIdDataEntries = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2DataRequestAnswersIdDataEntries(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2DataRequestAnswersIdFiles = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2DataRequestAnswersIdFiles(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [name]
     * @param {Array<string>} [ids]
     * @param {Array<string>} [questionIds]
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2DataRequestTemplates = function (name, ids, questionIds, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2DataRequestTemplates(name, ids, questionIds, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2DataRequestsId = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2DataRequestsId(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2DataRequestsIdDataEntries = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2DataRequestsIdDataEntries(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2DataRequestsIdFiles = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2DataRequestsIdFiles(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} [id]
     * @param {string} [dataSourceId]
     * @param {string} [policyParameterId]
     * @param {Array<string>} [dataRequestId]
     * @param {string} [title]
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2DataRequestsQuestions = function (id, dataSourceId, policyParameterId, dataRequestId, title, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2DataRequestsQuestions(id, dataSourceId, policyParameterId, dataRequestId, title, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2DataRequestsQuestionsIdEditValidation = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2DataRequestsQuestionsIdEditValidation(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} [id]
     * @param {string} [name]
     * @param {Array<string>} [policyParameter]
     * @param {boolean} [includeEmpty]
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2DataSources = function (id, name, policyParameter, includeEmpty, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2DataSources(id, name, policyParameter, includeEmpty, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2DataSourcesId = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2DataSourcesId(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2DataSourcesIdCoveredparameters = function (id, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2DataSourcesIdCoveredparameters(id, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} [dataEntryId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2FilesDataEntries = function (dataEntryId, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2FilesDataEntries(dataEntryId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} dataRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2FilesDataRequestsDatarequestid = function (dataRequestId, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2FilesDataRequestsDatarequestid(dataRequestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2FilesFileid = function (fileId, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2FilesFileid(fileId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} [supplierId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2FilesSuppliers = function (supplierId, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2FilesSuppliers(supplierId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2GlobalSuppliers = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2GlobalSuppliers(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} [code]
     * @param {boolean} [includeUnassigned]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2IndustryGics = function (code, includeUnassigned, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2IndustryGics(code, includeUnassigned, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2MetricsOrganizationSuppliersTimelineEngaged = function (fromDate, toDate, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2MetricsOrganizationSuppliersTimelineEngaged(fromDate, toDate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2MetricsOrganizationSuppliersTimelineTotal = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2MetricsOrganizationSuppliersTimelineTotal(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2MetricsOrganizationSuppliersTimelineTotalEngaged = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2MetricsOrganizationSuppliersTimelineTotalEngaged(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2RefDataSources = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2RefDataSources(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} supplierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2Risk = function (supplierId, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2Risk(supplierId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} [id]
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2RiskDataEntries = function (id, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2RiskDataEntries(id, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2RiskDataSources = function (page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2RiskDataSources(page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2RiskDataSourcesId = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2RiskDataSourcesId(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {Array<string>} [policyParameterId]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2RiskMitigationRules = function (page, pageSize, policyParameterId, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2RiskMitigationRules(page, pageSize, policyParameterId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2RiskMitigationRulesId = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2RiskMitigationRulesId(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2RiskObjective = function (page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2RiskObjective(page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} [id]
     * @param {Array<string>} [supplierId]
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2Scorecards = function (id, supplierId, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2Scorecards(id, supplierId, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {Array<string>} [supplierId]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2ScorecardsId = function (id, supplierId, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2ScorecardsId(id, supplierId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} [supplierId]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2ScorecardsSupplierScoreAndCoverage = function (supplierId, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2ScorecardsSupplierScoreAndCoverage(supplierId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2SupplierDataRequests = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2SupplierDataRequests(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2SupplierDataRequestsContextEmissions = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2SupplierDataRequestsContextEmissions(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2SupplierDataRequestsContextExistingData = function (page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2SupplierDataRequestsContextExistingData(page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [dataSourceName]
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2SupplierDataRequestsContextNoData = function (dataSourceName, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2SupplierDataRequestsContextNoData(dataSourceName, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2SupplierDataRequestsContextRisk = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2SupplierDataRequestsContextRisk(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} [policyParameterId]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2SupplierDataRequestsContextScore = function (policyParameterId, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2SupplierDataRequestsContextScore(policyParameterId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2SupplierDataRequestsContextScoreOverall = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2SupplierDataRequestsContextScoreOverall(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} id
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2SupplierDataRequestsDataEntries = function (id, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2SupplierDataRequestsDataEntries(id, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [name]
     * @param {Array<string>} [id]
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2SupplierDataRequestsDataSources = function (name, id, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2SupplierDataRequestsDataSources(name, id, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2SupplierDataRequestsLanguages = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2SupplierDataRequestsLanguages(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2SupplierDataRequestsPolicyParameters = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2SupplierDataRequestsPolicyParameters(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2SuppliersId = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2SuppliersId(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} organizationSupplierId
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2SuppliersOrganizationsupplieridContacts = function (organizationSupplierId, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2SuppliersOrganizationsupplieridContacts(organizationSupplierId, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} organizationSupplierId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2SuppliersOrganizationsupplieridContactsId = function (organizationSupplierId, id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2SuppliersOrganizationsupplieridContactsId(organizationSupplierId, id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} supplierId
     * @param {number} [startYear]
     * @param {number} [endYear]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV2SuppliersSupplieridEmissionData = function (supplierId, startYear, endYear, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV2SuppliersSupplieridEmissionData(supplierId, startYear, endYear, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} [id]
     * @param {string} [supplierId]
     * @param {string} [dataSourceId]
     * @param {string} [dataRequestId]
     * @param {number} [daysTillExpiredMin]
     * @param {number} [daysTillExpiredMax]
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3DataEntries = function (id, supplierId, dataSourceId, dataRequestId, daysTillExpiredMin, daysTillExpiredMax, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3DataEntries(id, supplierId, dataSourceId, dataRequestId, daysTillExpiredMin, daysTillExpiredMax, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3DataEntriesId = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3DataEntriesId(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} [supplierId]
     * @param {Array<string>} [dataRequestId]
     * @param {Array<string>} [dataRequestQuestionId]
     * @param {Array<DataRequestAnswerReviewState>} [reviewState]
     * @param {boolean} [latest]
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3DataRequestAnswers = function (supplierId, dataRequestId, dataRequestQuestionId, reviewState, latest, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3DataRequestAnswers(supplierId, dataRequestId, dataRequestQuestionId, reviewState, latest, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3DataRequestAnswersIdDataEntries = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3DataRequestAnswersIdDataEntries(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3DataRequestAnswersIdFiles = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3DataRequestAnswersIdFiles(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [name]
     * @param {Array<string>} [ids]
     * @param {Array<string>} [questionIds]
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3DataRequestTemplates = function (name, ids, questionIds, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3DataRequestTemplates(name, ids, questionIds, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3DataRequestsId = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3DataRequestsId(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3DataRequestsIdDataEntries = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3DataRequestsIdDataEntries(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3DataRequestsIdFiles = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3DataRequestsIdFiles(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {Array<string>} [policyParameterId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3RiskMitigationRules = function (page, pageSize, policyParameterId, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3RiskMitigationRules(page, pageSize, policyParameterId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3RiskMitigationRulesId = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3RiskMitigationRulesId(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} [id]
     * @param {Array<string>} [supplierId]
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3Scorecards = function (id, supplierId, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3Scorecards(id, supplierId, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {Array<string>} [supplierId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3ScorecardsId = function (id, supplierId, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3ScorecardsId(id, supplierId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} [supplierId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3ScorecardsSupplierScoreAndCoverage = function (supplierId, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3ScorecardsSupplierScoreAndCoverage(supplierId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3SupplierDataRequests = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3SupplierDataRequests(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3SupplierDataRequestsContextEmissions = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3SupplierDataRequestsContextEmissions(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3SupplierDataRequestsContextExistingData = function (page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3SupplierDataRequestsContextExistingData(page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [dataSourceName]
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3SupplierDataRequestsContextNoData = function (dataSourceName, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3SupplierDataRequestsContextNoData(dataSourceName, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3SupplierDataRequestsContextRisk = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3SupplierDataRequestsContextRisk(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} [policyParameterId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3SupplierDataRequestsContextScore = function (policyParameterId, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3SupplierDataRequestsContextScore(policyParameterId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3SupplierDataRequestsContextScoreOverall = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3SupplierDataRequestsContextScoreOverall(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} id
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3SupplierDataRequestsDataEntries = function (id, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3SupplierDataRequestsDataEntries(id, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [name]
     * @param {Array<string>} [id]
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3SupplierDataRequestsDataSources = function (name, id, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3SupplierDataRequestsDataSources(name, id, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3SupplierDataRequestsLanguages = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3SupplierDataRequestsLanguages(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV3SupplierDataRequestsPolicyParameters = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV3SupplierDataRequestsPolicyParameters(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV4DataRequestAnswersIdDataEntries = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV4DataRequestAnswersIdDataEntries(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getV4DataRequestsIdDataEntries = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getV4DataRequestsIdDataEntries(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {UpdateEmailTemplateIn} updateEmailTemplateIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.patchV1EmailTemplatesId = function (id, updateEmailTemplateIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).patchV1EmailTemplatesId(id, updateEmailTemplateIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {SupplierCategoryPatchApiDto} supplierCategoryPatchApiDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.patchV1SupplierCategoriesId = function (id, supplierCategoryPatchApiDto, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).patchV1SupplierCategoriesId(id, supplierCategoryPatchApiDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {string} reviewId
     * @param {InsertDataRequestAnswerReviewIn} insertDataRequestAnswerReviewIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.patchV2DataRequestAnswersIdReviewReviewid = function (id, reviewId, insertDataRequestAnswerReviewIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).patchV2DataRequestAnswersIdReviewReviewid(id, reviewId, insertDataRequestAnswerReviewIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {string} reviewId
     * @param {InsertDataRequestAnswerReviewIn} insertDataRequestAnswerReviewIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.patchV3DataRequestAnswersIdReviewReviewid = function (id, reviewId, insertDataRequestAnswerReviewIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).patchV3DataRequestAnswersIdReviewReviewid(id, reviewId, insertDataRequestAnswerReviewIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {UpdateAdminHackIn} updateAdminHackIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postExperimentalAdmins = function (updateAdminHackIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postExperimentalAdmins(updateAdminHackIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {SignUpWithEmailDomainIn} signUpWithEmailDomainIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postMgmtAuth0Signup = function (signUpWithEmailDomainIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postMgmtAuth0Signup(signUpWithEmailDomainIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postMgmtAwsSns = function (body, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postMgmtAwsSns(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV1ApiKeysName = function (name, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV1ApiKeysName(name, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CreateEmailTemplateIn} createEmailTemplateIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV1EmailTemplates = function (createEmailTemplateIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV1EmailTemplates(createEmailTemplateIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {GenerateOnboardingPreSignedUploadUrlInput} generateOnboardingPreSignedUploadUrlInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV1OnboardingFiles = function (generateOnboardingPreSignedUploadUrlInput, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV1OnboardingFiles(generateOnboardingPreSignedUploadUrlInput, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV1Signup = function (body, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV1Signup(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {SupplierCategoryIn} supplierCategoryIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV1SupplierCategories = function (supplierCategoryIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV1SupplierCategories(supplierCategoryIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {InsertUserInput} insertUserInput JSON body for inserting a User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV1Users = function (insertUserInput, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV1Users(insertUserInput, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {UserInviteIn} userInviteIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV1UsersInvites = function (userInviteIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV1UsersInvites(userInviteIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {InsertDataRequestAnswerReviewIn} insertDataRequestAnswerReviewIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2DataRequestAnswersIdReview = function (id, insertDataRequestAnswerReviewIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2DataRequestAnswersIdReview(id, insertDataRequestAnswerReviewIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CreateDataRequestTemplateV2In} createDataRequestTemplateV2In
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2DataRequestTemplates = function (createDataRequestTemplateV2In, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2DataRequestTemplates(createDataRequestTemplateV2In, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {RenderDataRequestTemplateV2In} renderDataRequestTemplateV2In
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2DataRequestTemplatesRenderEmail = function (id, renderDataRequestTemplateV2In, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2DataRequestTemplatesRenderEmail(id, renderDataRequestTemplateV2In, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {DataRequestFilterV2ApiDto} dataRequestFilterV2ApiDto
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2DataRequests = function (dataRequestFilterV2ApiDto, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2DataRequests(dataRequestFilterV2ApiDto, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {SendDataRequestV2In} sendDataRequestV2In
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2DataRequestsActionsSend = function (sendDataRequestV2In, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2DataRequestsActionsSend(sendDataRequestV2In, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {UpdateDataRequestDueDateIn} updateDataRequestDueDateIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2DataRequestsActionsUpdateDueDate = function (updateDataRequestDueDateIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2DataRequestsActionsUpdateDueDate(updateDataRequestDueDateIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {InsertDataRequestQuestionV2In} insertDataRequestQuestionV2In
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2DataRequestsQuestions = function (insertDataRequestQuestionV2In, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2DataRequestsQuestions(insertDataRequestQuestionV2In, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {RiskFilterIn} riskFilterIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2Risk = function (riskFilterIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2Risk(riskFilterIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {RiskContextRiskOverviewFilterIn} riskContextRiskOverviewFilterIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2RiskOverviewContext = function (riskContextRiskOverviewFilterIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2RiskOverviewContext(riskContextRiskOverviewFilterIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {PolicyParameterRiskOverViewFilterIn} policyParameterRiskOverViewFilterIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2RiskOverviewPolicyParameters = function (policyParameterRiskOverViewFilterIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2RiskOverviewPolicyParameters(policyParameterRiskOverViewFilterIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {InsertScorecardIn} insertScorecardIn
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2Scorecards = function (insertScorecardIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2Scorecards(insertScorecardIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {FilterAndSortSupplierByScoreAndCoverageIn} [filterAndSortSupplierByScoreAndCoverageIn]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2ScorecardsIdSupplierScoreAndCoverage = function (id, page, pageSize, filterAndSortSupplierByScoreAndCoverageIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2ScorecardsIdSupplierScoreAndCoverage(id, page, pageSize, filterAndSortSupplierByScoreAndCoverageIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {InsertDataRequestAnswerV2In} insertDataRequestAnswerV2In
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2SupplierDataRequestsAnswers = function (insertDataRequestAnswerV2In, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2SupplierDataRequestsAnswers(insertDataRequestAnswerV2In, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CreateDataRequestClaimV2In} createDataRequestClaimV2In
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2SupplierDataRequestsDataEntries = function (createDataRequestClaimV2In, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2SupplierDataRequestsDataEntries(createDataRequestClaimV2In, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {GenerateDataRequestPreSignedUploadUrlV2In} generateDataRequestPreSignedUploadUrlV2In
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2SupplierDataRequestsFiles = function (generateDataRequestPreSignedUploadUrlV2In, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2SupplierDataRequestsFiles(generateDataRequestPreSignedUploadUrlV2In, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {Array<string>} [dataRequestQuestionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2SupplierDataRequestsIdActionsAnswerAutomatically = function (id, dataRequestQuestionId, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2SupplierDataRequestsIdActionsAnswerAutomatically(id, dataRequestQuestionId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2SupplierDataRequestsIdActionsComplete = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2SupplierDataRequestsIdActionsComplete(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {ListSuppliersIn} listSuppliersIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2Suppliers = function (listSuppliersIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2Suppliers(listSuppliersIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} name
     * @param {string} [countryIso3Code]
     * @param {SupplierStatus} [status]
     * @param {string} [website]
     * @param {Array<string>} [alias]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2SuppliersActionsAdd = function (name, countryIso3Code, status, website, alias, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2SuppliersActionsAdd(name, countryIso3Code, status, website, alias, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {SuppliersAddToCategoryIn} suppliersAddToCategoryIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2SuppliersActionsAddToCategory = function (suppliersAddToCategoryIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2SuppliersActionsAddToCategory(suppliersAddToCategoryIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {SuppliersRemoveFromCategoryIn} suppliersRemoveFromCategoryIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2SuppliersActionsRemoveFromCategory = function (suppliersRemoveFromCategoryIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2SuppliersActionsRemoveFromCategory(suppliersRemoveFromCategoryIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {SearchSupplierIn} searchSupplierIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2SuppliersActionsSearchGlobalSuppliers = function (searchSupplierIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2SuppliersActionsSearchGlobalSuppliers(searchSupplierIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {SuppliersLinkGlobalSupplierIn} suppliersLinkGlobalSupplierIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2SuppliersIdActionsLinkGlobalSupplier = function (id, suppliersLinkGlobalSupplierIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2SuppliersIdActionsLinkGlobalSupplier(id, suppliersLinkGlobalSupplierIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {string} [globalSupplierId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2SuppliersIdActionsUnlinkGlobalSupplier = function (id, globalSupplierId, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2SuppliersIdActionsUnlinkGlobalSupplier(id, globalSupplierId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {CreateDataEntryIn} createDataEntryIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2SuppliersIdDataEntries = function (id, createDataEntryIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2SuppliersIdDataEntries(id, createDataEntryIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {GenerateSupplierPreSignedUploadUrlInput} generateSupplierPreSignedUploadUrlInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2SuppliersIdFiles = function (id, generateSupplierPreSignedUploadUrlInput, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2SuppliersIdFiles(id, generateSupplierPreSignedUploadUrlInput, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} organizationSupplierId
     * @param {InsertSupplierContactIn} insertSupplierContactIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2SuppliersOrganizationsupplieridContacts = function (organizationSupplierId, insertSupplierContactIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2SuppliersOrganizationsupplieridContacts(organizationSupplierId, insertSupplierContactIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} organizationSupplierId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2SuppliersOrganizationsupplieridContactsIdActionsMakeDefault = function (organizationSupplierId, id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2SuppliersOrganizationsupplieridContactsIdActionsMakeDefault(organizationSupplierId, id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} supplierId
     * @param {string} industryCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2SuppliersSupplieridActionsAddIndustryIndustrycode = function (supplierId, industryCode, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2SuppliersSupplieridActionsAddIndustryIndustrycode(supplierId, industryCode, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} supplierId
     * @param {string} industryCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV2SuppliersSupplieridActionsRemoveIndustryIndustrycode = function (supplierId, industryCode, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV2SuppliersSupplieridActionsRemoveIndustryIndustrycode(supplierId, industryCode, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {InsertDataRequestAnswerReviewIn} insertDataRequestAnswerReviewIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV3DataRequestAnswersIdReview = function (id, insertDataRequestAnswerReviewIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV3DataRequestAnswersIdReview(id, insertDataRequestAnswerReviewIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CreateDataRequestTemplateV3In} createDataRequestTemplateV3In
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV3DataRequestTemplates = function (createDataRequestTemplateV3In, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV3DataRequestTemplates(createDataRequestTemplateV3In, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {DataRequestFilterV2ApiDto} dataRequestFilterV2ApiDto
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV3DataRequests = function (dataRequestFilterV2ApiDto, page, pageSize, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV3DataRequests(dataRequestFilterV2ApiDto, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {SendDataRequestV2In} sendDataRequestV2In
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV3DataRequestsActionsSend = function (sendDataRequestV2In, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV3DataRequestsActionsSend(sendDataRequestV2In, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {UpdateDataRequestDueDateIn} updateDataRequestDueDateIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV3DataRequestsActionsUpdateDueDate = function (updateDataRequestDueDateIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV3DataRequestsActionsUpdateDueDate(updateDataRequestDueDateIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV3DataRequestsIdGenerateLink = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV3DataRequestsIdGenerateLink(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {InsertScorecardIn} insertScorecardIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV3Scorecards = function (insertScorecardIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV3Scorecards(insertScorecardIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {number} [page] Page number to fetch
     * @param {number} [pageSize] Page size
     * @param {FilterAndSortSupplierByScoreAndCoverageIn} [filterAndSortSupplierByScoreAndCoverageIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postV3ScorecardsIdSupplierScoreAndCoverage = function (id, page, pageSize, filterAndSortSupplierByScoreAndCoverageIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).postV3ScorecardsIdSupplierScoreAndCoverage(id, page, pageSize, filterAndSortSupplierByScoreAndCoverageIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {UpdateOrganizationMasterDataIn} updateOrganizationMasterDataIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.putV1SettingsOrganization = function (updateOrganizationMasterDataIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).putV1SettingsOrganization(updateOrganizationMasterDataIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} userId
     * @param {UpdateUserInput} updateUserInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.putV1UsersUserid = function (userId, updateUserInput, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).putV1UsersUserid(userId, updateUserInput, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {UpdateDataRequestTemplateV2In} updateDataRequestTemplateV2In
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.putV2DataRequestTemplates = function (id, updateDataRequestTemplateV2In, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).putV2DataRequestTemplates(id, updateDataRequestTemplateV2In, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {UpdateDataRequestQuestionV2In} updateDataRequestQuestionV2In
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.putV2DataRequestsQuestionsId = function (id, updateDataRequestQuestionV2In, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).putV2DataRequestsQuestionsId(id, updateDataRequestQuestionV2In, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {UpdateScorecardIn} updateScorecardIn
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.putV2ScorecardsId = function (id, updateScorecardIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).putV2ScorecardsId(id, updateScorecardIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {UpdateSupplierIn} updateSupplierIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.putV2SuppliersId = function (id, updateSupplierIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).putV2SuppliersId(id, updateSupplierIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} organizationSupplierId
     * @param {string} id
     * @param {UpdateSupplierContactIn} updateSupplierContactIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.putV2SuppliersOrganizationsupplieridContactsId = function (organizationSupplierId, id, updateSupplierContactIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).putV2SuppliersOrganizationsupplieridContactsId(organizationSupplierId, id, updateSupplierContactIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {UpdateDataRequestTemplateV3In} updateDataRequestTemplateV3In
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.putV3DataRequestTemplates = function (id, updateDataRequestTemplateV3In, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).putV3DataRequestTemplates(id, updateDataRequestTemplateV3In, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {UpdateScorecardIn} updateScorecardIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.putV3ScorecardsId = function (id, updateScorecardIn, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).putV3ScorecardsId(id, updateScorecardIn, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return DefaultApi;
}(base_1.BaseAPI));
exports.DefaultApi = DefaultApi;
