import { Skeleton, Typography, Box, SxProps } from '@mui/material'

interface Props {
  sx?: SxProps
  icon?: React.ReactNode
  title?: React.ReactNode
  subtitle?: React.ReactNode
  muted?: boolean
  loading?: boolean
}

const PaperTitle = ({ sx, icon, title, subtitle, muted, loading }: Props) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        color: muted ? 'text.secondary' : 'text.primary',
        ...sx,
      }}
    >
      {icon && (
        <Box
          sx={{
            marginRight: 1,
            color: 'text.secondary',
          }}
        >
          {icon}
        </Box>
      )}
      <Box>
        {title && typeof title === 'string' && (
          <Typography variant="h5" color="inherit" gutterBottom={Boolean(subtitle)}>
            <strong>{loading ? <Skeleton width={125} /> : title}</strong>
          </Typography>
        )}
        {title && typeof title !== 'string' && title}
        {subtitle && (
          <>
            {/** eslint-disable-next-line */}
            {typeof subtitle === 'string' ? (
              <Typography
                sx={{
                  fontWeight: 400,
                  color: 'text.secondary',
                }}
                variant="body2"
                color="inherit"
              >
                {subtitle}
              </Typography>
            ) : (
              subtitle
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export default PaperTitle
