import { useMutation } from 'react-query'
import { assignIndustry, removeIndustry } from './api'

export const useAssignIndustry = () => {
  return useMutation(assignIndustry)
}

export const useRemoveIndustry = () => {
  return useMutation(removeIndustry)
}
