import { Components, alpha } from '@mui/material/styles'
import shape from '../shape'
import spacing from '../spacing'
import shadow from '../shadow'
import typography from '../typography'
import palette from '../palette'

const MuiAutocomplete: Components['MuiAutocomplete'] = {
  styleOverrides: {
    paper: {
      marginTop: 2,
      boxShadow: shadow.shadow,
    },
    listbox: {
      paddingLeft: spacing,
      paddingRight: spacing,
    },
    option: {
      paddingTop: `${spacing}px !important`,
      paddingBottom: `${spacing}px !important`,
      paddingLeft: `${spacing}px !important`,
      borderRadius: shape.borderRadius,
      fontSize: typography.body2.fontSize,
      minHeight: 38,
      cursor: 'pointer',
      '&[aria-selected="true"]': {
        backgroundColor: `${alpha(palette.common.black, 0.04)} !important`,
      },
      '&.Mui-focused': {
        backgroundColor: `${alpha(palette.common.black, 0.04)} !important`,
      },
    },
    groupLabel: {
      zIndex: spacing,
      fontSize: typography.body2.fontSize,
      color: palette.text.secondary,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: spacing / 2,
      lineHeight: 0,
      height: 38,
    },
    inputRoot: {
      '&.MuiInputBase-sizeSmall': {
        paddingRight: '18px !important',
      },
    },
  },
}

export default MuiAutocomplete
