import { useQuery, UseQueryOptions } from 'react-query'
import { ResourceFoundSupplierEmissionDataApiDto } from 'typescript-axios'
import { AxiosError } from 'axios'
import { getSupplierEmissions } from './api'
import queryKeys from './queryKeys'

const { singleSupplierEmissions } = queryKeys

export const useGetSupplierEmissions = (
  { supplierId, startYear, endYear }: { supplierId: string; startYear?: number; endYear?: number },
  options?: UseQueryOptions<
    ResourceFoundSupplierEmissionDataApiDto,
    AxiosError,
    ResourceFoundSupplierEmissionDataApiDto,
    ReturnType<typeof singleSupplierEmissions>
  >
) => {
  return useQuery(
    singleSupplierEmissions({ supplierId, startYear, endYear }),
    () => getSupplierEmissions({ supplierId, startYear, endYear }),
    {
      ...options,
    }
  )
}
