import React from 'react'
import { Box, SxProps } from '@mui/material'

interface Props {
  children: React.ReactNode
  noPadding?: boolean
  withHeader?: boolean
  sx?: SxProps
}

const PaperContent = ({ sx, children, withHeader, noPadding }: Props) => {
  return (
    <Box
      sx={{
        padding: noPadding ? 0 : 2,
        paddingTop: noPadding || withHeader ? 0 : 1,
        flexGrow: 1,
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

export default PaperContent
