const dataDiscoveryListRuns = ['dataDiscoveryListRuns'] as const
const dataDiscoveryLastRun = ['dataDiscoveryLastRun'] as const
const dataDiscoveryLastRunDataSetItems = ['dataDiscoveryLastRunDataSetItems'] as const
const supplierDiscoveryLastRunDataSetItems = ['supplierDiscoveryLastRunDataSetItems'] as const
const websiteDiscoveryRun = (supplierName: string) => ['websiteDiscoveryRun', supplierName] as const
const dataDiscovery = (website: string) => ['dataDiscovery', website] as const
const testDataDiscovery = (website: string) => ['testDataDiscovery', website] as const
const industryDiscovery = (supplierName: string, supplierCountry: string) =>
  ['industryDiscovery', supplierName, supplierCountry] as const

export default {
  dataDiscoveryListRuns,
  dataDiscoveryLastRun,
  dataDiscoveryLastRunDataSetItems,
  supplierDiscoveryLastRunDataSetItems,
  websiteDiscoveryRun,
  dataDiscovery,
  testDataDiscovery,
  industryDiscovery,
}
