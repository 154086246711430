import apiClient from 'services/apiClient'
import {
  UpdateOrganizationMasterDataIn,
  UpdateUserInput,
  UserInviteIn,
  SignUpWithEmailDomainIn,
} from 'typescript-axios'

export const getLanguages = async () => {
  const { data } = await apiClient.getV1Languages()
  return data
}

export const getTeamMembers = async () => {
  const { data } = await apiClient.getV1Users()
  return data
}

interface UpdateUserProfileProps extends UpdateUserInput {
  id: string
}

export const updateUserProfile = async ({
  id,
  name,
  emailAddress,
  avatarUrl,
}: UpdateUserProfileProps) => {
  return apiClient.putV1UsersUserid(id, { name, emailAddress, avatarUrl })
}

export const getTeamInvites = () => {
  return apiClient.getV1UsersInvites()
}

export const inviteTeamMember = ({ inviteeEmail }: UserInviteIn) => {
  return apiClient.postV1UsersInvites({ inviteeEmail })
}

export const deleteInvitation = ({ id }: { id: string }) => {
  return apiClient.deleteV1UsersInvitesUserinviteid(id)
}

export const getSettings = async () => {
  const { data } = await apiClient.getV1SettingsOrganization()
  return data
}

export const updateCustomerSettings = async ({
  name,
  avatarUrl,
}: UpdateOrganizationMasterDataIn) => {
  return apiClient.putV1SettingsOrganization({ name, avatarUrl })
}

export const getMe = async () => {
  const { data } = await apiClient.getV1UsersMe()
  return data
}

export const signUp = async ({ emailAddress, password }: SignUpWithEmailDomainIn) => {
  return apiClient.postMgmtAuth0Signup({ emailAddress, password })
}
