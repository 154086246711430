import apiClient from 'services/apiClient'

export const getApiKeys = async ({ page, pageSize }: { page: number; pageSize: number }) => {
  const { data } = await apiClient.getV1ApiKeys(page, pageSize)
  return data
}

export const createApiKey = async ({ name }: { name: string }) => {
  const { data } = await apiClient.postV1ApiKeysName(name)
  return data
}

export const deleteApiKey = async ({ id }: { id: string }) => {
  const { data } = await apiClient.deleteV1ApiKeysId(id)
  return data
}
