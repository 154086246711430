import { Paper, SxProps, Theme } from '@mui/material'

interface Props {
  children: React.ReactNode
  sx?: SxProps<Theme>
  maxHeight?: boolean
}

const CustomPaper = ({ children, sx, maxHeight = false }: Props) => {
  return (
    <Paper
      elevation={0}
      square={false}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 1,
        overflow: 'hidden',
        height: maxHeight ? '100%' : 'auto',
        ...sx,
      }}
    >
      {children}
    </Paper>
  )
}

export default CustomPaper
