import { v4 as uuid } from 'uuid'

export type ParameterCategory =
  | 'environmentalImpact'
  | 'socialImpact'
  | 'operations'
  | 'supplierManagement'
  | 'dataSource'

export interface QuestionnaireDataEntrySection {
  title: string
  description: string
  questions: QuestionnaireDataEntryProps[]
}

export interface QuestionnaireDataEntryProps {
  question: string
  parameters: {
    title: string
    category: ParameterCategory
  }[]
  answerType: 'percentage' | 'text' | 'documentUpload' | 'nominalList'
  answer: any
  isManualAssessment: boolean
  translationType: 'increments' | 'binary' | 'value to nominal categories'
  translation: string
  score: number
  selectOptions?: string[]
}
export interface DashboardProps {
  customersAmount: number
  customerIndustries: string[]
  totalDataCompletion: number
}

export const dataRequestStatusTypes = {
  OPEN: 'open',
  APPROVED: 'approved',
  CORRECTIONS_NEEDED: 'correctionsNeeded',
  REJECTED_BY_CUSTOMER: 'rejectedByCustomer',
  REJECTED_BY_SUPPLIER: 'rejectedBySupplier',
  SENT: 'sent',
} as const // Typescript will start seeing it as an "enum like" object, enabling us to use "typeof dataRequestStatusTypes.OPEN" and get "open" instead of string

export const dataRequestDataTypes = {
  CERTIFICATE: 'certificate',
  QUESTIONNAIRE: 'questionnaire',
} as const

export interface DataRequestNew {
  /** data request received, but not fulfilled by supplier */
  status: typeof dataRequestStatusTypes.OPEN
  timestamp: string
  message?: string
}

export interface DataRequestApproved {
  /** data request fulfilled, sent and approved by customer */
  status: typeof dataRequestStatusTypes.APPROVED
  timestamp: string
  message?: string
}

export interface DataRequestCorrectionsNeeded {
  /** data request fulfilled, sent and rejected by customer */
  status: typeof dataRequestStatusTypes.CORRECTIONS_NEEDED
  message?: string
  timestamp: string
  /** wouldn't do this, buuuuut dummy */
  corrections?: {
    sectionIndex?: number
    itemIndex?: number
    message?: string
  }[]
}

export interface DataRequestRejectedByCustomer {
  /** data request fulfilled, sent and rejected by customer */
  status: typeof dataRequestStatusTypes.REJECTED_BY_CUSTOMER
  message?: string
  timestamp: string
}

export interface DataRequestRejectedBySupplier {
  /** data request not fulfilled, sent back to customer with reject status and reason */
  status: typeof dataRequestStatusTypes.REJECTED_BY_SUPPLIER
  message?: string
  timestamp: string
}

export interface DataRequestSent {
  /** data request fulfilled and sent to customer. Awaiting approval */
  status: typeof dataRequestStatusTypes.SENT
  timestamp: string
  message?: string
}

export type DataRequestStatus =
  | DataRequestNew
  | DataRequestApproved
  | DataRequestRejectedByCustomer
  | DataRequestRejectedBySupplier
  | DataRequestSent
  | DataRequestCorrectionsNeeded

export interface DataRequestCustomer {
  companyName: string
  requesterName: string
  requesterEmail: string
}

export interface DataRequestCertificate {
  type: typeof dataRequestDataTypes.CERTIFICATE
  title: string
}

export interface DataRequestQuestionnaire {
  type: typeof dataRequestDataTypes.QUESTIONNAIRE
  title: string
  content: QuestionnaireDataEntrySection[]
}

export interface DataRequestsProps {
  id: string
  /** latest status is current */
  history: DataRequestStatus[]
  customer: DataRequestCustomer
  dataRequest: DataRequestCertificate | DataRequestQuestionnaire
}

export const dataRequests: DataRequestsProps[] = [
  /** example showing new data request. Specific certificate needed. "You have this certificate in your archive, but it's not publicly available. Insert?" & "11 other customers have previously requested this certificate. Consider making it publicly available on your supplier profile." */
  {
    id: uuid(),
    customer: {
      companyName: 'Company Inc',
      requesterName: 'Mick Mick',
      requesterEmail: 'mick@company.com',
    },
    dataRequest: {
      type: 'questionnaire',
      title: 'Supplier questionnaire',
      content: [
        {
          title: 'Responsible Wood Fibre Sourcing',
          description:
            'How much of this product category paper production currently comes from responsible fibre sources and what is done to increase resource efficiency in the supply chain?',
          questions: [
            {
              question:
                'Current overall recycled fibre and agricultural waste use levels for this product category production',
              parameters: [
                {
                  title: 'Deforestation',
                  category: 'environmentalImpact',
                },
                {
                  title: 'Recycling',
                  category: 'environmentalImpact',
                },
                {
                  title: 'Raw material efficiency',
                  category: 'environmentalImpact',
                },
                {
                  title: 'Waste management, chemicals and hazardous materials',
                  category: 'environmentalImpact',
                },
              ],
              answer: 50,
              isManualAssessment: false,
              answerType: 'percentage',
              translationType: 'increments',
              translation: '',
              score: 0,
            },
            {
              question: 'Current post-consumer recycled fibre use levels in this product category',
              parameters: [
                {
                  title: 'Deforestation',
                  category: 'environmentalImpact',
                },
                {
                  title: 'Recycling',
                  category: 'environmentalImpact',
                },
                {
                  title: 'Raw material efficiency',
                  category: 'environmentalImpact',
                },
              ],
              answer: 20,
              isManualAssessment: false,
              answerType: 'percentage',
              translationType: 'increments',
              translation: '',
              score: 0,
            },
            {
              question:
                'Please describe efforts and results to increase product resource efficiency',
              parameters: [
                {
                  title: 'Deforestation',
                  category: 'environmentalImpact',
                },
                {
                  title: 'Environmental management & suppliers',
                  category: 'environmentalImpact',
                },
                {
                  title: 'Raw material efficiency',
                  category: 'environmentalImpact',
                },
              ],
              answer:
                'We make sure to quantify the amount of wood harvested and compares it with the annual growth and the remaining potential.',
              isManualAssessment: false,
              answerType: 'text',
              translationType: 'binary',
              translation: '',
              score: 0,
            },
            {
              question: 'If the company has a sustainability strategy, please upload here',
              parameters: [
                {
                  title: 'Carbon management plan',
                  category: 'environmentalImpact',
                },
                {
                  title: 'Environmental management & suppliers',
                  category: 'environmentalImpact',
                },
              ],
              answer: 'Dummy report',
              isManualAssessment: false,
              answerType: 'documentUpload',
              translationType: 'binary',
              translation: '',
              score: 0,
            },
            {
              question:
                'Describe any defined time-bound relative GHG reduction targets (reduction of tonnes CO2 / tonne of product)?',
              parameters: [
                {
                  title: 'GHG footprint',
                  category: 'environmentalImpact',
                },
                {
                  title: 'Carbon management plan',
                  category: 'environmentalImpact',
                },
              ],
              answer: 'Reduction of 0.5 kg CO2/tonne by 2025',
              isManualAssessment: false,
              answerType: 'text',
              translationType: 'increments',
              translation: 'medium',
              score: 50,
            },
          ],
        },
      ],
    },
    history: [
      {
        status: 'open',
        timestamp: 'Oct 10, 10:27 AM',
        message: '',
      },
      {
        status: 'sent',
        timestamp: 'Oct 12, 2:49 PM',
        message: '',
      },
      {
        status: 'correctionsNeeded',
        timestamp: 'Oct 13, 11:31 AM',
        message: '',
        corrections: [
          {
            sectionIndex: 0,
            itemIndex: 2,
            message: 'Please provide a more thorough description of your efforts and results.',
          },
        ],
      },
    ],
  },
  {
    id: uuid(),
    customer: {
      companyName: "John's Ice Cream Parlour",
      requesterName: 'John Shufflebottom',
      requesterEmail: 'john@icecream.com',
    },
    dataRequest: {
      type: 'certificate',
      /** metadata like description, website, etc, is in translations[title] */
      title: 'Ice Cream Alliance',
    },
    history: [
      {
        status: 'open',
        timestamp: 'Oct 13, 11:05 AM',
        message:
          'Hi! We ask all our UK ice cream suppliers to get an Ice Cream Alliance certificate. Plz',
      },
    ],
  },
  {
    id: uuid(),
    customer: {
      companyName: 'Some company',
      requesterName: 'Michael John',
      requesterEmail: 'michael@john.com',
    },
    dataRequest: {
      type: 'certificate',
      title: 'Ecovadis',
    },
    history: [
      {
        status: 'open',
        timestamp: 'Oct 9, 10:27 AM',
        message:
          "Hi! We heard from a client that you have an Ecovadis certificate. We'd love to have that available in Responsibly. Please supply the certificate or make it public",
      },
      {
        status: 'sent',
        timestamp: 'Oct 9, 2:49 PM',
        message: '',
      },
      {
        status: 'approved',
        timestamp: 'Oct 12, 8:31 AM',
        message: '',
      },
    ],
  },
  {
    id: uuid(),
    customer: {
      companyName: 'Company Inc',
      requesterName: 'Mick Mick',
      requesterEmail: 'mick@company.com',
    },
    dataRequest: {
      type: 'questionnaire',
      title: 'Supplier questionnaire',
      content: [
        {
          title: 'Responsible Wood Fibre Sourcing',
          description:
            'How much of this product category paper production currently comes from responsible fibre sources and what is done to increase resource efficiency in the supply chain?',
          questions: [
            {
              question:
                'Current overall recycled fibre and agricultural waste use levels for this product category production',
              parameters: [
                {
                  title: 'Deforestation',
                  category: 'environmentalImpact',
                },
                {
                  title: 'Recycling',
                  category: 'environmentalImpact',
                },
                {
                  title: 'Raw material efficiency',
                  category: 'environmentalImpact',
                },
                {
                  title: 'Waste management, chemicals and hazardous materials',
                  category: 'environmentalImpact',
                },
              ],
              answer: '',
              isManualAssessment: false,
              answerType: 'percentage',
              translationType: 'increments',
              translation: '',
              score: 0,
            },
            {
              question: 'Current post-consumer recycled fibre use levels in this product category',
              parameters: [
                {
                  title: 'Deforestation',
                  category: 'environmentalImpact',
                },
                {
                  title: 'Recycling',
                  category: 'environmentalImpact',
                },
                {
                  title: 'Raw material efficiency',
                  category: 'environmentalImpact',
                },
              ],
              answer: '',
              isManualAssessment: false,
              answerType: 'percentage',
              translationType: 'increments',
              translation: '',
              score: 0,
            },
            {
              question:
                'Please describe efforts and results to increase product resource efficiency',
              parameters: [
                {
                  title: 'Deforestation',
                  category: 'environmentalImpact',
                },
                {
                  title: 'Environmental management & suppliers',
                  category: 'environmentalImpact',
                },
                {
                  title: 'Raw material efficiency',
                  category: 'environmentalImpact',
                },
              ],
              answer: '',
              isManualAssessment: false,
              answerType: 'text',
              translationType: 'binary',
              translation: '',
              score: 0,
            },
            {
              question: 'If the company has a sustainability strategy, please upload here',
              parameters: [
                {
                  title: 'Carbon management plan',
                  category: 'environmentalImpact',
                },
                {
                  title: 'Environmental management & suppliers',
                  category: 'environmentalImpact',
                },
              ],
              answer: '',
              isManualAssessment: false,
              answerType: 'documentUpload',
              translationType: 'binary',
              translation: '',
              score: 0,
            },
            {
              question:
                'Describe any defined time-bound relative GHG reduction targets (reduction of tonnes CO2 / tonne of product)?',
              parameters: [
                {
                  title: 'GHG footprint',
                  category: 'environmentalImpact',
                },
                {
                  title: 'Carbon management plan',
                  category: 'environmentalImpact',
                },
              ],
              answer: '',
              isManualAssessment: false,
              answerType: 'text',
              translationType: 'increments',
              translation: 'medium',
              score: 50,
              selectOptions: ['Low', 'Medium', 'High'],
            },
          ],
        },
      ],
    },
    history: [
      {
        status: 'open',
        timestamp: 'Oct 5, 10:27 AM',
        message: 'Please fill out this questionnaire',
      },
    ],
  },
]

/** DATA */

export interface DataParameterProps {
  title: string
  category: ParameterCategory | 'supplier_management'
  dataSources: string[]
  customerAssessingParameter: string[]
  hasPartialAudittedData: boolean
  hasSelfAssessedData: boolean
  hasDocumentAudit: boolean
  hasPhysicalAudit: boolean
}

export interface DataParameterCategoryProps {
  [key: string]: DataParameterProps[]
}

// leave dataSources empty like [] if no data on the parameter
// same with customerAssessingParameter. Leave empty like [] if no customers assessing it
// Order of things matters :)
// category can be socialImpact or environmentalImpact or what you make up besides that

export const socialImpactParameters: DataParameterCategoryProps = {
  'Labor and human rights': [
    {
      title: 'Minimum age and child labor',
      category: 'socialImpact',
      dataSources: ['Rainforest Alliance', 'FSC', 'Sedex'],
      customerAssessingParameter: ['SucroSan', 'FashionX'],
      hasSelfAssessedData: true,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: true,
    },
    {
      title: 'Forced or compulsory labor',
      category: 'socialImpact',
      dataSources: ['Sedex', 'Fair Trade', 'FSC'],
      customerAssessingParameter: ['FARA', 'FashionX', 'Globex Corp.'],
      hasSelfAssessedData: true,
      hasPartialAudittedData: false,
      hasDocumentAudit: true,
      hasPhysicalAudit: true,
    },
    {
      title: 'Working contract',
      category: 'socialImpact',
      dataSources: ['ProTerra Foundation', 'SA8000 Standard', 'GRI'],
      customerAssessingParameter: ['Initech', 'Hooli', 'Soylent Ltd'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: true,
    },
    {
      title: 'Minimum wage',
      category: 'socialImpact',
      dataSources: ['RSPO', 'UTZ'],
      customerAssessingParameter: ['Burns Industries', 'InGen', 'Fruitz'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: true,
      hasPhysicalAudit: true,
    },
    {
      title: 'Living wage',
      category: 'socialImpact',
      dataSources: [],
      customerAssessingParameter: ['NERV', 'Oscorp', 'Relaxicab'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Working hours',
      category: 'socialImpact',
      dataSources: ['Ecovadis', 'GRI', 'Rainforest Alliance'],
      customerAssessingParameter: ['Oscorp', 'Xubaba'],
      hasSelfAssessedData: true,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: false,
    },
    {
      title: 'Paid overtime',
      category: 'socialImpact',
      dataSources: [],
      customerAssessingParameter: ['Pura Olive Oil', 'Cococha', 'YUMI'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Precarious work',
      category: 'socialImpact',
      dataSources: ['Rainforest Alliance', 'Ecovadis', 'Fairtrade'],
      customerAssessingParameter: ['YUMI', 'FedOx', 'AnimalLand'],
      hasSelfAssessedData: true,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: true,
    },
    {
      title: 'Freedom of association & collective bargaining',
      category: 'socialImpact',
      dataSources: [],
      customerAssessingParameter: ['Oscorp', 'Burns Industries', 'Initech'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Retirement scheme',
      category: 'socialImpact',
      dataSources: ['IAF', 'NMSDC', 'B Corporations'],
      customerAssessingParameter: ['Oceanic Airlines', 'SteelForYou', 'Suber'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: true,
      hasDocumentAudit: false,
      hasPhysicalAudit: true,
    },
    {
      title: 'Paid employment leaves',
      category: 'socialImpact',
      dataSources: ['IAF', 'NMSDC', 'B Corporations'],
      customerAssessingParameter: ['Suber', 'Claranx', 'PlantWorld'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: true,
      hasDocumentAudit: false,
      hasPhysicalAudit: true,
    },
    {
      title: 'Protection of vulnerable groups',
      category: 'socialImpact',
      dataSources: ['FSC', 'UTZ', 'CSRHub'],
      customerAssessingParameter: ['PlantWorld', 'Anima', 'INDIGO'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Workplace health and safety',
      category: 'socialImpact',
      dataSources: ['CSRHub', 'Artisans du Monde'],
      customerAssessingParameter: ['LaVie', 'TechX', 'YADAtech'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: true,
      hasPhysicalAudit: true,
    },
    {
      title: 'Workplace harassment and mistreatment',
      category: 'socialImpact',
      dataSources: ['United Nations Global Compact', 'Sedex', 'IAF'],
      customerAssessingParameter: ['Anima', 'Fodo Alliance', 'Omni Consumer Products'],
      hasSelfAssessedData: true,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: true,
    },
    {
      title: 'Workplace grievance mechanism',
      category: 'socialImpact',
      dataSources: [],
      customerAssessingParameter: ['Burns Industries', 'FashionX', 'FARA'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Workplace systems and procedures',
      category: 'socialImpact',
      dataSources: ['B Corporations', 'IAF', 'Ecovadis'],
      customerAssessingParameter: ['Oceanic airlines', 'PlantWorld', 'Cococha'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Career management, education and training',
      category: 'socialImpact',
      dataSources: [],
      customerAssessingParameter: ['YUMI', 'FedOx', 'NERVO'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
  ],
  'Diversity and inclusion': [
    {
      title: 'Equal opportunity, rights and treatment',
      category: 'socialImpact',
      dataSources: ['Rainforest Alliance', 'United Nations Global Compact', 'B Corporations'],
      customerAssessingParameter: ['Burns Industries', 'Oceanic Airlines', 'NERVO'],
      hasSelfAssessedData: true,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: false,
    },
    {
      title: 'Gender pay gap',
      category: 'socialImpact',
      dataSources: ['Gender Pay Gap', 'Proforest', 'GRI'],
      customerAssessingParameter: ['LAND', 'Omni Consumer Products', 'Fruitz'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: true,
    },
    {
      title: 'Diversity ownership',
      category: 'socialImpact',
      dataSources: ['GRI', 'B Corporations', 'Gender Pay Gap'],
      customerAssessingParameter: ['Omni Consumer Products', 'FashionX', 'FARA'],
      hasSelfAssessedData: true,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: true,
    },
    {
      title: 'Diversity in management',
      category: 'socialImpact',
      dataSources: [],
      customerAssessingParameter: ['FARA', 'Relaxicab', 'Oscorp'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
  ],
  'Business Ethics': [
    {
      title: 'Corruption',
      category: 'socialImpact',
      dataSources: ['Sedex', 'B Corporations'],
      customerAssessingParameter: ['Sucro Ltd', 'Amcor'],
      hasSelfAssessedData: true,
      hasPartialAudittedData: true,
      hasDocumentAudit: false,
      hasPhysicalAudit: true,
    },
    {
      title: 'Anticompetitive practices',
      category: 'socialImpact',
      dataSources: ['Sedex', 'Fair Trade'],
      customerAssessingParameter: ['AR Munich', 'Starvpot', 'Abaris Printing'],
      hasSelfAssessedData: true,
      hasPartialAudittedData: true,
      hasDocumentAudit: false,
      hasPhysicalAudit: true,
    },
    {
      title: 'Contracts with controversial parties',
      category: 'socialImpact',
      dataSources: [],
      customerAssessingParameter: ['Duns&co', 'Larry Fisheries', 'Abaris Printing'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Responsible marketing and selling',
      category: 'socialImpact',
      dataSources: [],
      customerAssessingParameter: ['Sucro LTd', 'Abaris Printing'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Ownership by controversial parties',
      category: 'socialImpact',
      dataSources: ['Amfori', 'GRI'],
      customerAssessingParameter: ['Starvpot', 'Canson SA'],
      hasSelfAssessedData: true,
      hasPartialAudittedData: true,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Compliance to local laws and regulations',
      category: 'socialImpact',
      dataSources: ['SA8000', 'Sedex'],
      customerAssessingParameter: ['AR Munich', 'Canson SA', 'Fruitz'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: true,
      hasDocumentAudit: false,
      hasPhysicalAudit: true,
    },
    {
      title: 'Consumer data protection',
      category: 'socialImpact',
      dataSources: ['GRI', 'SA8000'],
      customerAssessingParameter: ['SucroLtd', 'Schenker Gmbh', 'Oscorp'],
      hasSelfAssessedData: true,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: true,
    },
  ],
  'External impact': [
    {
      title: 'Local economy spending',
      category: 'socialImpact',
      dataSources: [],
      customerAssessingParameter: ['Barry Callebaut', 'Banacom', 'Schenker Gmbh'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Local jobs supported',
      category: 'socialImpact',
      dataSources: [],
      customerAssessingParameter: ['Leyton', 'VGP', 'Oceanic Alliance'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Local community development',
      category: 'socialImpact',
      dataSources: ['Sedex', 'Bonsucro'],
      customerAssessingParameter: ['Leyton', 'Abaris', 'Starvpot'],
      hasSelfAssessedData: true,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: true,
    },
    {
      title: 'Indigenous people',
      category: 'socialImpact',
      dataSources: ['Ecovadis', 'WEConnect'],
      customerAssessingParameter: ['Barry Callebaut', 'Starvpot'],
      hasSelfAssessedData: true,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: true,
    },
    {
      title: 'Product safety',
      category: 'socialImpact',
      dataSources: ['Organic EU/USDA', 'UTZ'],
      customerAssessingParameter: ['Banacom', 'Leyton', 'SucroSan'],
      hasSelfAssessedData: true,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: true,
    },
    {
      title: 'Forcefully seized land',
      category: 'socialImpact',
      dataSources: ['Fair Trade', 'UTZ'],
      customerAssessingParameter: ['Starvpot', 'VGP'],
      hasSelfAssessedData: true,
      hasPartialAudittedData: true,
      hasDocumentAudit: false,
      hasPhysicalAudit: true,
    },
  ],
}

export const environmentalImpactParameters: DataParameterCategoryProps = {
  'Resource use': [
    {
      title: 'Water consumption',
      category: 'environmentalImpact',
      dataSources: ['Ecovadis', 'Bonsucro'],
      customerAssessingParameter: ['Starvpot', 'VGP', 'Burns Industries'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: false,
    },
    {
      title: 'Energy and fuel consumption',
      category: 'environmentalImpact',
      dataSources: ['Ecovadis', 'Refinitiv'],
      customerAssessingParameter: ['Schenker Gmbh', 'Banacom', 'Oscorp'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: false,
    },
    {
      title: 'Energy and fuel efficiency',
      category: 'environmentalImpact',
      dataSources: ['Ecovadis', 'Bonsucro'],
      customerAssessingParameter: ['Barry Callebaut', 'Sucro Ltd'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: false,
    },
    {
      title: 'Renewable energy',
      category: 'environmentalImpact',
      dataSources: ['Ecovadis', 'CDP'],
      customerAssessingParameter: ['Helle Max SL', 'Leyton'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: false,
    },
    {
      title: 'Raw material efficiency',
      category: 'environmentalImpact',
      dataSources: [],
      customerAssessingParameter: ['Leyton', 'Prince graphics', 'Xubaba'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Conflict minerals',
      category: 'environmentalImpact',
      dataSources: ['Amfori', 'Refinitiv'],
      customerAssessingParameter: ['Schenker Gmbh', 'VGP'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: false,
    },
    {
      title: 'Recycled materials',
      category: 'environmentalImpact',
      dataSources: ['FSC', 'SFI'],
      customerAssessingParameter: ['Cans SA', 'Dia Corp'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: false,
    },
  ],
  'GHG footprint': [
    {
      title: 'GHG scope 1 emissions',
      category: 'environmentalImpact',
      dataSources: ['CDP', 'Ecovadis'],
      customerAssessingParameter: ['Tony Fisher', 'G&T Ltd'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: false,
    },
    {
      title: 'GHG scope 2 emissions',
      category: 'environmentalImpact',
      dataSources: ['CDP', 'Ecovadis'],
      customerAssessingParameter: ['Niger&Co', 'Amcor', 'Cococha'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: false,
    },
    {
      title: 'GHG scope 3 emissions',
      category: 'environmentalImpact',
      dataSources: [],
      customerAssessingParameter: ['Banacom', 'Sucro Ltd'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Offsetting and neutrality',
      category: 'environmentalImpact',
      dataSources: ['Ecovadis', 'ISCC'],
      customerAssessingParameter: ['Leyton', 'Abaris Printing', 'SteelForYou'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: true,
    },
    {
      title: 'Carbon management plan',
      category: 'environmentalImpact',
      dataSources: ['Ecovadis', 'B Corporations'],
      customerAssessingParameter: ['Starvpot', 'VGP', 'Relaxicab'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: true,
      hasPhysicalAudit: false,
    },
  ],
  Waste: [
    {
      title: 'Wastewater',
      category: 'environmentalImpact',
      dataSources: ['Alliance for Water Stewardship', 'Roundtable on Responsible Palm Oil'],
      customerAssessingParameter: ['Kepan Inc', 'BDN'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Waste recycling',
      category: 'environmentalImpact',
      dataSources: ['UTZ', 'ISCC'],
      customerAssessingParameter: ['NatureCom', 'Brainfoods ltd', 'Initech'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: true,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Product end-of-use',
      category: 'environmentalImpact',
      dataSources: ['B Corporations', 'CSR Hub'],
      customerAssessingParameter: ['Diadesde, Pall Ingredients'],
      hasSelfAssessedData: true,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: false,
    },
  ],
  Pollution: [
    {
      title: 'Land, soil and groundwater pollution',
      category: 'environmentalImpact',
      dataSources: ['Fair Trade', 'Organic EU/USDA'],
      customerAssessingParameter: ['Fine Foods', 'TES Technologies'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: false,
    },
    {
      title: 'Air pollution',
      category: 'environmentalImpact',
      dataSources: ['CDP', 'ISCC'],
      customerAssessingParameter: ['Ling and Sons', 'WDS Enterprises'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Pesticides use',
      category: 'environmentalImpact',
      dataSources: ['Organic EU/USDA', 'Ecovadis'],
      customerAssessingParameter: ['Joel Foods', 'Hill Farms'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Noise, odour, light and vibrations pollution',
      category: 'environmentalImpact',
      dataSources: ['Responsible Business Alliance', 'Ecovadis'],
      customerAssessingParameter: ['Yumyum', 'Santo Trading'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Pollution incident prevention process',
      category: 'environmentalImpact',
      dataSources: ['FSC', 'Alliance for Water Stewardship'],
      customerAssessingParameter: ['Chocamocca', 'Fruita Inc'],
      hasSelfAssessedData: true,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: false,
    },
    {
      title: 'Organic farming',
      category: 'environmentalImpact',
      dataSources: ['Organic EU/USDA', 'Ecovadis'],
      customerAssessingParameter: ['InterGreens', 'Meats and More'],
      hasSelfAssessedData: true,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: false,
    },
  ],
  'Nature conservation': [
    {
      title: 'Soil health',
      category: 'environmentalImpact',
      dataSources: ['Organic EU/USDA', 'ISCC'],
      customerAssessingParameter: ['Tomatillo inc', 'Plantas'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Marine health',
      category: 'environmentalImpact',
      dataSources: ['Marine Stewardship Council', 'Aquaculture Stewardship Council'],
      customerAssessingParameter: ['Soniosan Fisheries', 'Lofoten Marine Company'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: true,
      hasPhysicalAudit: false,
    },
    {
      title: 'Deforestation',
      category: 'environmentalImpact',
      dataSources: ['FSC', 'PEFC CoC'],
      customerAssessingParameter: ['Makari Pulps', 'Johnson Inc'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Biodiversity',
      category: 'environmentalImpact',
      dataSources: ['Rainforest Alliance', 'Bonsucro Chain of Custody'],
      customerAssessingParameter: ['Azucar Ltd.', 'Highgrounds'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: true,
      hasPhysicalAudit: false,
    },
    {
      title: 'Conservation of habitats',
      category: 'environmentalImpact',
      dataSources: ['Forests in Focus', 'FSC Forest Management (FM)'],
      customerAssessingParameter: ['Lenueve', 'Mitzi & Sons'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Protection of threatened and endangered species',
      category: 'environmentalImpact',
      dataSources: ['Ecovadis', 'Rainforest Alliance'],
      customerAssessingParameter: ['Coocoo Cocoa', 'Hart Oil Ingredients'],
      hasSelfAssessedData: true,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: false,
    },
  ],
  'Animal practices': [
    {
      title: 'Animal testing',
      category: 'environmentalImpact',
      dataSources: ['Leaping Bunny', 'Choose Cruelty Free (CCF)'],
      customerAssessingParameter: ['Blush Cosmetics', 'Blooms'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Animal welfare',
      category: 'environmentalImpact',
      dataSources: ['UTZ', 'Farm Sustainability assessment (FSA) by SAI platform'],
      customerAssessingParameter: ['Main Farms', 'Cale Meats'],
      hasSelfAssessedData: true,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: false,
    },
    {
      title: 'Antibiotics in animal breeding',
      category: 'environmentalImpact',
      dataSources: ['Organic EU/USDA', 'Antibiotic-free (ATBF) certification'],
      customerAssessingParameter: ["Moore's Mutton", "Branson's"],
      hasSelfAssessedData: true,
      hasPartialAudittedData: true,
      hasDocumentAudit: true,
      hasPhysicalAudit: false,
    },
  ],
  'Environmental management': [
    {
      title: 'Environment management system',
      category: 'environmentalImpact',
      dataSources: ['IAF', 'Ecovadis'],
      customerAssessingParameter: ['Bring Co.', 'Jouls'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
  ],
}

export const supplierManagementParameters: DataParameterCategoryProps = {
  'Supplier management': [
    {
      title: 'Supplier diversity',
      category: 'supplier_management',
      dataSources: ['WBENC', 'Gender Pay Gap'],
      customerAssessingParameter: ['Xin Toys Imports', 'World Flavours'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
    {
      title: 'Supply chain traceability',
      category: 'supplier_management',
      dataSources: ['Sourcemap', 'Responsible Business Alliance'],
      customerAssessingParameter: ['Presients', 'Chile Fruit Company'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: true,
      hasPhysicalAudit: true,
    },
    {
      title: 'Supplier environmental assessments',
      category: 'supplier_management',
      dataSources: ['Ecovadis', 'CDP'],
      customerAssessingParameter: ['TGE Exports', 'Honest Co.'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: true,
      hasPhysicalAudit: true,
    },
    {
      title: 'Supplier education and training',
      category: 'supplier_management',
      dataSources: ['SA8000', 'B Corporations'],
      customerAssessingParameter: ['Winter Mills', 'High Hills'],
      hasSelfAssessedData: false,
      hasPartialAudittedData: false,
      hasDocumentAudit: false,
      hasPhysicalAudit: false,
    },
  ],
}

export interface CustomersProps {
  name: string
  country: string
  countryCode: string
  tags: string[]
  categories: string[]
  usingData?: number
  id: string
}

export const customers: CustomersProps[] = [
  {
    id: uuid(),
    name: 'GerZuck',
    country: 'Germany',
    countryCode: 'DE',
    tags: ['Tier 1'],
    categories: ['Sugar'],
    usingData: 11,
  },
  {
    id: uuid(),
    name: 'Chelmza GmbH',
    country: 'Germany',
    countryCode: 'DE',
    tags: [],
    categories: ['Sugar'],
    usingData: 7,
  },
  {
    id: uuid(),
    name: 'Racecourse Sugar',
    country: 'Brazil',
    countryCode: 'BR',
    categories: ['Sugar'],
    tags: ['Some tag'],
    usingData: 5,
  },
]

export interface DocumentLifecycleProps {
  id: string
  title: string
  type: 'certificate' | 'questionnaire'
  usedByCustomers: number
  isPublic: boolean
  expireDays: number
  expireDate?: string
}

export const documentLifecycle: DocumentLifecycleProps[] = [
  {
    id: uuid(),
    title: 'ISCC',
    type: 'certificate',
    expireDays: 44,
    isPublic: true,
    usedByCustomers: 11,
  },
  {
    id: uuid(),
    title: 'Our supply chain',
    type: 'questionnaire',
    expireDays: 76,
    isPublic: true,
    usedByCustomers: 8,
  },
  {
    id: uuid(),
    title: 'FSC',
    type: 'certificate',
    expireDays: 92,
    isPublic: false,
    usedByCustomers: 6,
  },
]
