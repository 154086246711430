import { Components } from '@mui/material/styles'
import spacing from '../spacing'
import shape from '../shape'

const MuiMenuItem: Components['MuiMenuItem'] = {
  styleOverrides: {
    root: {
      marginLeft: spacing,
      marginRight: spacing,
      borderRadius: shape.borderRadius,
      paddingTop: spacing,
      paddingBottom: spacing,
      minWidth: 140,
    },
  },
}

export default MuiMenuItem
