import {
  Grid,
  ListItem,
  ListItemText,
  ListItemAvatar,
  SxProps,
  Theme,
  Skeleton,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Donut } from 'components'
import { useTranslation } from 'react-i18next'
import { usePermissionContext } from 'utils'

const PREFIX = 'Cards'

const classes = {
  performanceCard: `${PREFIX}-performanceCard`,
  dataCoverageCard: `${PREFIX}-dataCoverageCard`,
  content: `${PREFIX}-content`,
  progressText: `${PREFIX}-progressText`,
  title: `${PREFIX}-title`,
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.performanceCard}`]: {
    border: `1px solid ${theme.palette.background.default}`,
  },

  [`& .${classes.dataCoverageCard}`]: {
    border: `1px solid ${theme.palette.background.default}`,
  },

  [`& .${classes.content}`]: {
    padding: theme.spacing(1.5),
  },

  [`& .${classes.progressText}`]: {
    fontWeight: 600,
    color: theme.palette.text.primary,
  },

  [`& .${classes.title}`]: {
    fontWeight: 600,
    marginTop: theme.spacing(),
  },
}))

interface Props {
  score?: number
  dataCoverage?: number
  itemsAmount?: number
  dataEntriesAmount?: number
  sx?: SxProps<Theme>
  parametersOnly?: boolean
  loading?: boolean
}

const Cards = ({
  score,
  dataCoverage,
  itemsAmount,
  dataEntriesAmount,
  parametersOnly,
  sx,
  loading,
}: Props) => {
  const { isNewScoringScaleOrg } = usePermissionContext()
  const { t } = useTranslation(['scorecards'])

  return (
    <StyledGrid container spacing={2} sx={{ marginTop: '2px', marginBottom: 1, ...sx }}>
      {!isNewScoringScaleOrg && (
        <Grid item xs={6}>
          <ListItem className={classes.dataCoverageCard} style={{ cursor: 'default' }}>
            <ListItemAvatar style={{ width: 55, minWidth: 55, marginRight: 10 }}>
              {loading ? (
                <Skeleton variant="circular" width={50} height={50} />
              ) : (
                <Donut
                  value={dataCoverage}
                  typographyVariant="h5"
                  withBrackets={false}
                  size={65}
                  margin="-7px"
                />
              )}
            </ListItemAvatar>
            <ListItemText
              primary={
                loading ? (
                  <Skeleton variant="text" width={50} />
                ) : (
                  <strong>{t('Data_Coverage')}</strong>
                )
              }
              secondary={
                dataEntriesAmount !== undefined
                  ? t('Data_Coverage_FromDataEntriesWithCount', { count: dataEntriesAmount })
                  : ''
              }
              secondaryTypographyProps={{
                style: {
                  whiteSpace: 'nowrap',
                },
              }}
            />
          </ListItem>
        </Grid>
      )}
      <Grid item xs={isNewScoringScaleOrg ? 12 : 6}>
        <ListItem className={classes.performanceCard} style={{ cursor: 'default' }}>
          <ListItemAvatar
            style={{ width: 55, minWidth: isNewScoringScaleOrg ? 65 : 55, marginRight: 10 }}
          >
            {loading ? (
              <Skeleton variant="circular" width={50} height={50} />
            ) : (
              (isNewScoringScaleOrg && (
                <Donut
                  value={score}
                  typographyVariant="body1"
                  size={65}
                  gaugeMarginTop={-15}
                  centerTextSx={{
                    pt: '21px',
                  }}
                />
              )) || <Donut value={score} typographyVariant="h5" size={65} margin="-7px" />
            )}
          </ListItemAvatar>
          <ListItemText
            primary={
              loading ? (
                <Skeleton variant="text" width={50} />
              ) : (
                <strong>{t('Overall_Performance')}</strong>
              )
            }
            secondary={
              itemsAmount !== undefined
                ? `${
                    parametersOnly
                      ? t('Overall_Performance_AcrossParametersWithCount', { count: itemsAmount })
                      : t('Overall_Performance_AcrossItemsWithCount', { count: itemsAmount })
                  }`
                : ''
            }
          />
        </ListItem>
      </Grid>
    </StyledGrid>
  )
}

export default Cards
