import { ReportHandler, Metric } from 'web-vitals'

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      // Cumulative Layout Shift
      getCLS(onPerfEntry)
      // First Input Delay
      getFID(onPerfEntry)
      // First Contentful Paint
      getFCP(onPerfEntry)
      // Largest Contentful Paint
      getLCP(onPerfEntry)
      // Time to First Byte
      getTTFB(onPerfEntry)
    })
  }
}

export const sendWebVitalsToMixpanel = (metric: Metric) => {
  // eslint-disable-next-line
  if (process.env.NODE_ENV === 'development') console.log('WEB VITALS:', metric)
  // if (process.env.NODE_ENV === 'production') {}
  // const body = JSON.stringify(metric)
  // const url = 'https://example.com/analytics'

  // Use `navigator.sendBeacon()` if available, falling back to `fetch()`
  /* if (navigator.sendBeacon) {
    navigator.sendBeacon(url, body)
  } else {
    fetch(url, { body, method: 'POST', keepalive: true })
  } */
}

export default reportWebVitals
