import { Components } from '@mui/material/styles'
import typography from '../typography'
import palette from '../palette'
import spacing from '../spacing'
import shape from '../shape'

const MuiTableCell: Components['MuiTableCell'] = {
  styleOverrides: {
    root: {
      borderBottom: `0px solid ${palette.background.default}`,
      padding: `${spacing}px ${spacing}px ${spacing}px ${spacing}px`,
      fontSize: typography.body1.fontSize,
      '&:first-of-type': {
        borderTopLeftRadius: shape.borderRadius,
        borderBottomLeftRadius: shape.borderRadius,
      },
      '&:last-of-type': {
        borderTopRightRadius: shape.borderRadius,
        borderBottomRightRadius: shape.borderRadius,
      },
    },
    head: {
      fontSize: typography.body1.fontSize,
      fontWeight: 600,
    },
  },
}

export default MuiTableCell
