import { useInfiniteQuery } from 'react-query'
import { ResourceListApiKeyApiDto } from 'typescript-axios'
import { AxiosError } from 'axios'
import { getApiKeys } from './api'
import queryKeys from './queryKeys'

const { apiKeyPage } = queryKeys

// eslint-disable-next-line
export const useGetApiKeys = (pageSize = 50, options?: { enabled?: boolean }) => {
  return useInfiniteQuery<
    ResourceListApiKeyApiDto,
    AxiosError,
    ResourceListApiKeyApiDto,
    ReturnType<typeof apiKeyPage>
  >(apiKeyPage({ pageSize }), ({ pageParam = 1 }) => getApiKeys({ page: pageParam, pageSize }), {
    getNextPageParam: (lastPage) => lastPage.paging.nextPage,
    ...options,
  })
}
