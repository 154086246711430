import { Components } from '@mui/material/styles'
import spacing from '../spacing'

const MuiDialogContentText: Components['MuiDialogContentText'] = {
  styleOverrides: {
    root: {
      marginBottom: spacing * 1.5,
    },
  },
}

export default MuiDialogContentText
