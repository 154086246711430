import { Components } from '@mui/material/styles'

const MuiFormHelperText: Components['MuiFormHelperText'] = {
  styleOverrides: {
    contained: {
      marginLeft: 0,
    },
  },
}

export default MuiFormHelperText
