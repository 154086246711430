import { Components } from '@mui/material/styles'
import spacing from '../spacing'
import shape from '../shape'

const MuiSkeleton: Components['MuiSkeleton'] = {
  styleOverrides: {
    text: {
      borderRadius: spacing / 2,
    },
    rectangular: {
      borderRadius: shape.borderRadius,
    },
  },
}

export default MuiSkeleton
