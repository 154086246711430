import React, { useState, useEffect } from 'react'
import { ClearRounded as ClearIcon, SearchRounded as SearchIcon } from '@mui/icons-material'
import { TextFieldProps, TextField, InputAdornment, IconButton, Fade } from '@mui/material'
import { useDebounce } from 'utils'

interface CustomProps {
  withDebounce?: boolean
  debounceInterval?: number
  initialValue?: any
  withMargin?: boolean
  withClear?: boolean
  withSearchIcon?: boolean
}

export interface CustomTextFieldProps
  extends CustomProps,
    Omit<TextFieldProps, 'onChange' | 'name'> {
  onChange?: (name: string, value: any) => void
  name?: string
}

const TextFieldWrapper = ({
  onChange,
  value,
  withDebounce,
  debounceInterval,
  initialValue,
  name,
  withMargin = true,
  withClear,
  withSearchIcon,
  ...rest
}: CustomTextFieldProps) => {
  const [innerValue, setInnerValue] = useState(initialValue || '')
  const [debouncedUpdate] = useDebounce(innerValue, debounceInterval)

  const handleDebouncedOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInnerValue(e.target.value)
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange && name) onChange(name, e.target.value)
  }

  useEffect(() => {
    if (withDebounce && onChange && name && debouncedUpdate !== value) {
      onChange(name, innerValue)
    }
  }, [debouncedUpdate])

  useEffect(() => {
    if (withDebounce && value !== innerValue && value !== undefined) {
      setInnerValue(value)
    }
  }, [value])

  const textFieldValue = withDebounce ? innerValue : value
  const textFieldOnChange = withDebounce ? handleDebouncedOnChange : handleOnChange

  return (
    <TextField
      onChange={textFieldOnChange}
      value={textFieldValue}
      name={name}
      sx={{
        ...rest.sx,
        marginBottom: withMargin ? 2 : 0,
      }}
      InputProps={{
        ...rest.InputProps,
        startAdornment: withSearchIcon ? (
          <InputAdornment position="start">
            <SearchIcon sx={{ ml: -0.5 }} />
          </InputAdornment>
        ) : (
          rest.InputProps?.startAdornment
        ),
        endAdornment: withClear ? (
          <InputAdornment position="end">
            <Fade in={Boolean(textFieldValue && textFieldValue.length > 0)}>
              <IconButton
                size="small"
                edge="end"
                sx={{ mr: -0.85, padding: '2px' }}
                onClick={() =>
                  withDebounce ? setInnerValue('') : onChange && name && onChange(name, '')
                }
              >
                <ClearIcon sx={{ width: 20, height: 20 }} />
              </IconButton>
            </Fade>
          </InputAdornment>
        ) : (
          rest.InputProps?.endAdornment
        ),
      }}
      {...rest}
    />
  )
}

export default TextFieldWrapper
