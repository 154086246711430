import { useQueryClient, useMutation } from 'react-query'
import { useSnackbar } from 'notistack'
import { getErrorMessage } from 'utils'
import { ApiMutateError } from 'services/apiClient'
import {
  sendDataRequest,
  updateDataRequestQuestion,
  createDataRequestQuestion,
  createDataRequestTemplate,
  updateDataRequestTemplate,
  updateDataRequestDueDate,
  saveEmailTemplate,
  updateEmailTemplate,
} from './api'
import queryKeys from './queryKeys'

export const useCreateDataRequestEmailTemplate = () => {
  return useMutation(saveEmailTemplate)
}

export const useUpdateDataRequestEmailTemplate = () => {
  return useMutation(updateEmailTemplate)
}

export const useCreateDataRequestTemplate = () => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(createDataRequestTemplate, {
    onError: (err: ApiMutateError) => {
      enqueueSnackbar(
        err.response?.status === 409
          ? 'Template name already exists. Please update and try again.'
          : getErrorMessage(err),
        { variant: 'error' }
      )
    },
  })
}

export const useUpdateDataRequestTemplate = () => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(updateDataRequestTemplate, {
    onError: (err: ApiMutateError) => {
      enqueueSnackbar(
        err.response?.status === 409
          ? 'Template name already exists. Please update and try again.'
          : getErrorMessage(err),
        { variant: 'error' }
      )
    },
  })
}

export const useCreateDataRequestQuestion = () => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(createDataRequestQuestion, {
    onError: (err: ApiMutateError) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
    },
  })
}

export const useUpdateDataRequestQuestion = () => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(updateDataRequestQuestion, {
    onError: (err: ApiMutateError) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
    },
  })
}

export const useSendDataRequest = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(sendDataRequest, {
    onError: (err: ApiMutateError) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.dataRequestPages)
    },
  })
}

export const useUpdateDataRequestDueDate = () => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(updateDataRequestDueDate, {
    onError: (err: ApiMutateError) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
    },
  })
}
