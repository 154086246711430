import { Components } from '@mui/material/styles'

const MuiTablePagination: Components['MuiTablePagination'] = {
  styleOverrides: {
    selectIcon: {
      top: 2,
    },
  },
}

export default MuiTablePagination
