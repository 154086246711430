const teamMembers = ['teamMembers']
const teamMemberInvites = [...teamMembers, 'teamMemberInvites']

const experimentalAdmins = ['experimentalAdmins']
const allExperimentalAdmins = [...experimentalAdmins, 'allExperimentalAdmins'] as const

export default {
  experimentalAdmins,
  allExperimentalAdmins,
  teamMembers,
  teamMemberInvites,
}
