import { Components } from '@mui/material/styles'
import shape from '../shape'
import spacing from '../spacing'
import typography from '../typography'

const MuiButton: Components['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
    color: 'primary',
    disableElevation: true,
  },
  styleOverrides: {
    root: {
      borderRadius: shape.borderRadius,
      textTransform: 'initial',
      whiteSpace: 'nowrap',
    },
    sizeSmall: {
      fontSize: typography.body2.fontSize,
    },
    containedPrimary: {
      minWidth: 100,
      height: 34,
    },
    endIcon: {
      marginLeft: spacing / 2,
    },
    outlinedPrimary: {
      height: 34,
    },
    textPrimary: {
      '&:hover': {
        backgroundColor: 'rgb(0 0 0 / 4%)',
      },
    },
    textSecondary: {
      '&:hover': {
        backgroundColor: 'rgb(0 0 0 / 4%)',
      },
    },
    text: {
      paddingLeft: spacing * 1.5,
      paddingRight: spacing * 1.5,
    },
  },
}

export default MuiButton
