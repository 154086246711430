import { Components } from '@mui/material/styles'
import palette from '../palette'

const MuiInputAdornment: Components['MuiInputAdornment'] = {
  styleOverrides: {
    root: {
      color: palette.text.primary,
    },
  },
}

export default MuiInputAdornment
