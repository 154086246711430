import { Components } from '@mui/material/styles'

const MuiFormControlLabel: Components['MuiFormControlLabel'] = {
  styleOverrides: {
    label: {
      paddingTop: 0,
    },
  },
}

export default MuiFormControlLabel
