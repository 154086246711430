const files = ['files']
const onboardingFiles = [...files, 'onboardingFiles']
const allSupplierFiles = [...files, 'allSupplierFiles']
const supplierFiles = (ids?: string[]) => [...allSupplierFiles, ids] as const
const allDataEntryFiles = [...files, 'allDataEntryFiles']
const dataEntryFiles = (ids?: string[]) => [...allDataEntryFiles, ids] as const
const allDataRequestFiles = [...files, 'allDataRequestFiles'] as const
const dataRequestFiles = (id: string) => [...allDataRequestFiles, id] as const

export default {
  files,
  onboardingFiles,
  allSupplierFiles,
  supplierFiles,
  allDataEntryFiles,
  dataEntryFiles,
  allDataRequestFiles,
  dataRequestFiles,
}
