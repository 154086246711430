import { Components } from '@mui/material/styles'
import spacing from '../spacing'
import typography from '../typography'

const MuiDialogTitle: Components['MuiDialogTitle'] = {
  styleOverrides: {
    root: {
      fontWeight: 600,
      fontSize: typography.h4.fontSize,
      marginTop: spacing / 2,
      paddingBottom: spacing / 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
}

export default MuiDialogTitle
