import { Avatar, alpha } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  DoneRounded as SuccessIcon,
  PriorityHighRounded as FailedIcon,
  MailRounded as OpenIcon,
  SendRounded as SendIcon,
  AutorenewRounded as CorrectionsNeededIcon,
} from '@mui/icons-material'
import { dataRequestStatusTypes } from 'dummyData/supplierPortalDummyData'

const PREFIX = 'DataRequestStatusIcon'

const classes = {
  correctionsNeeded: `${PREFIX}-correctionsNeeded`,
  sent: `${PREFIX}-sent`,
  rejectedBySupplier: `${PREFIX}-rejectedBySupplier`,
  rejectedByCustomer: `${PREFIX}-rejectedByCustomer`,
  approved: `${PREFIX}-approved`,
  open: `${PREFIX}-open`,
}

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  [`& .${classes.correctionsNeeded}`]: {
    backgroundColor: alpha(theme.palette.error.main, 0.1),
    color: theme.palette.error.main,
  },

  [`& .${classes.sent}`]: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
    color: theme.palette.secondary.main,
  },

  [`& .${classes.rejectedBySupplier}`]: {
    backgroundColor: alpha(theme.palette.error.main, 0.15),
    color: theme.palette.error.main,
  },

  [`& .${classes.rejectedByCustomer}`]: {
    backgroundColor: alpha(theme.palette.error.main, 0.15),
    color: theme.palette.error.main,
  },

  [`& .${classes.approved}`]: {
    backgroundColor: alpha(theme.palette.success.main, 0.15),
    color: theme.palette.success.main,
  },

  [`& .${classes.open}`]: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    color: theme.palette.primary.main,
  },
}))

interface Props {
  status: (typeof dataRequestStatusTypes)[keyof typeof dataRequestStatusTypes]
  size: number
  withAvatar?: boolean
}

const DataRequestStatusIcon = ({ status, size, withAvatar = true }: Props) => {
  const sharedProps = {
    sx: { width: size, height: size },
  }

  const icon = (
    <>
      {status === dataRequestStatusTypes.OPEN && <OpenIcon {...sharedProps} />}
      {status === dataRequestStatusTypes.APPROVED && <SuccessIcon {...sharedProps} />}
      {status === dataRequestStatusTypes.CORRECTIONS_NEEDED && (
        <CorrectionsNeededIcon {...sharedProps} />
      )}
      {status === dataRequestStatusTypes.REJECTED_BY_CUSTOMER && <FailedIcon {...sharedProps} />}
      {status === dataRequestStatusTypes.REJECTED_BY_SUPPLIER && <FailedIcon {...sharedProps} />}
      {status === dataRequestStatusTypes.SENT && <SendIcon {...sharedProps} />}
    </>
  )

  return withAvatar ? (
    <StyledAvatar className={classes[status]} sx={{ width: size * 1.7, height: size * 1.7 }}>
      {icon}
    </StyledAvatar>
  ) : (
    icon
  )
}

export default DataRequestStatusIcon
