import { useQueryClient, useMutation } from 'react-query'
import { useSnackbar } from 'notistack'
import { getErrorMessage } from 'utils'
import { ApiMutateError } from 'services/apiClient'
import {
  createSupplierContact,
  deleteSupplierContact,
  updateSupplierContact,
  setDefaultSupplierContact,
  createSupplier,
  updateSupplier,
  linkGlobalSupplier,
  unlinkGlobalSupplier,
  globalSupplierSearch,
} from './api'
import queryKeys from './queryKeys'
import dataEntriesQueryKeys from '../dataEntries/queryKeys'
import scorecardQueryKeys from '../scorecards/queryKeys'
import riskQueryKeys from '../risk/queryKeys'

export const useCreateSupplier = () => {
  return useMutation(createSupplier)
}

export const useLinkSupplier = ({
  withCacheInvalidation,
  withErrorSnackbar,
}: {
  withCacheInvalidation?: boolean
  withErrorSnackbar?: boolean
}) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(linkGlobalSupplier, {
    onError: (err: ApiMutateError) => {
      if (withErrorSnackbar) {
        enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
      }
    },
    onSettled: (data) => {
      if (withCacheInvalidation) {
        queryClient.invalidateQueries(queryKeys.supplierPages)
        if (data) {
          queryClient.invalidateQueries(queryKeys.singleSupplier(data.data.data.id))
          queryClient.invalidateQueries(scorecardQueryKeys.allScorecardPages)
          queryClient.invalidateQueries(dataEntriesQueryKeys.dataEntries)
        }
      }
    },
  })
}

export const useUnlinkSupplier = ({
  withCacheInvalidation,
}: {
  withCacheInvalidation: boolean
}) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(unlinkGlobalSupplier, {
    onError: (err: ApiMutateError) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
    },
    onSettled: (data) => {
      if (withCacheInvalidation) {
        queryClient.invalidateQueries(queryKeys.supplierPages)
        if (data) {
          queryClient.invalidateQueries(queryKeys.singleSupplier(data.data.data.id))
          queryClient.invalidateQueries(scorecardQueryKeys.allScorecardPages)
          queryClient.invalidateQueries(dataEntriesQueryKeys.dataEntries)
        }
      }
    },
  })
}

export const useUpdateSupplier = ({
  withCacheInvalidation,
}: {
  withCacheInvalidation: boolean
}) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(updateSupplier, {
    onError: (err: ApiMutateError) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
    },
    onSettled: (data) => {
      if (withCacheInvalidation) {
        queryClient.invalidateQueries(queryKeys.supplierPages)
        if (data) {
          queryClient.invalidateQueries(queryKeys.singleSupplier(data.data.data.id))
          queryClient.invalidateQueries(riskQueryKeys.risk)
        }
      }
    },
  })
}

export const useCreateSupplierContact = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(createSupplierContact, {
    onError: (err: ApiMutateError) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.supplierContactPages)
    },
  })
}

export const useUpdateSupplierContact = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(updateSupplierContact, {
    onError: (err: ApiMutateError) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.supplierContactPages)
      queryClient.invalidateQueries(
        queryKeys.singleSupplierContact(variables.supplierId, variables.id)
      )
    },
  })
}

export const useDeleteSupplierContact = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(deleteSupplierContact, {
    onError: (err: ApiMutateError) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.supplierContactPages)
      queryClient.invalidateQueries(
        queryKeys.singleSupplierContact(variables.supplierId, variables.id)
      )
    },
  })
}

export const useSetDefaultSupplierContact = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(setDefaultSupplierContact, {
    onError: (err: ApiMutateError) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.supplierContactPages)
      queryClient.invalidateQueries(
        queryKeys.singleSupplierContact(variables.supplierId, variables.id)
      )
    },
  })
}

export const useSearchGlobalSuppliers = () => {
  return useMutation(globalSupplierSearch)
}
