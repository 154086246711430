import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const PREFIX = 'WizardTitle'

const classes = {
  container: `${PREFIX}-container`,
  subtitle: `${PREFIX}-subtitle`,
  title: `${PREFIX}-title`,
  tagline: `${PREFIX}-tagline`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    marginBottom: theme.spacing(),
    maxWidth: '85%',
    margin: 'auto',
  },

  [`& .${classes.subtitle}`]: {
    marginBottom: theme.spacing(),
    fontWeight: 600,
  },

  [`& .${classes.title}`]: {
    marginBottom: theme.spacing(1.5),
    fontWeight: 600,
  },

  [`& .${classes.tagline}`]: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(),
  },
}))

interface Props {
  category?: React.ReactNode
  title: string
  subtitle?: string
}

const WizardTitle = ({ category, title, subtitle }: Props) => {
  return (
    <Root className={classes.container}>
      {(category && typeof category === 'string' && (
        <Typography color="secondary" align="center" className={classes.subtitle}>
          {category}
        </Typography>
      )) ||
        category}
      <Typography variant="h1" align="center" className={classes.title}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="body1" align="center" className={classes.tagline}>
          {subtitle}
        </Typography>
      )}
    </Root>
  )
}

export default WizardTitle
