import { Tooltip, Avatar, Skeleton } from '@mui/material'
import { useGetDataEntry, useGetDataSource } from 'hooks'
import { RiskCategoryIcon } from 'components'
import config from 'constants/config'

interface Props {
  id: string
  size?: number
  onClick?: () => void
}

const InlineDataSourceAvatar = ({ id, size = 23, onClick }: Props) => {
  const { data, error } = useGetDataEntry(id)

  const { data: dataSourceData, error: dataSourceError } = useGetDataSource(
    data?.data.dataSourceId || '',
    {
      enabled: Boolean(data?.data.dataSourceId),
    }
  )

  const isLoading = !dataSourceError && !error && !data && !dataSourceData

  return (
    <Tooltip
      title={
        dataSourceData?.name
          ? `${dataSourceData?.name}${
              data?.data.dataOriginName.toLowerCase() === 'claim' ? ' (claim)' : ''
            }`
          : ''
      }
    >
      <Avatar
        sx={[
          {
            backgroundColor:
              (isLoading && 'transparent !important') ||
              (dataSourceData && !dataSourceData?.imgUrl && 'transparent') ||
              'common.white',
            border: (theme) => `1px solid ${theme.palette.common.white}`,
            width: size,
            height: size,
            boxShadow: 'none',
            marginLeft: '-3px',
          },
          (theme) =>
            onClick
              ? {
                  cursor: 'pointer',
                  transition: theme.transitions.create('opacity', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                  }),
                  '&:hover': {
                    opacity: 0.7,
                  },
                }
              : {},
        ]}
        alt={dataSourceData?.name || ''}
        src={
          dataSourceData?.imgUrl
            ? `${config.largeMediaUrl}${dataSourceData?.imgUrl}?nf_resize=fit&h=${size}`
            : undefined
        }
      >
        {isLoading && <Skeleton variant="circular" width={size} height={size} />}
        {data && dataSourceData && (
          <RiskCategoryIcon size={16} withBackground type={dataSourceData.dataSourceType} />
        )}
      </Avatar>
    </Tooltip>
  )
}

export default InlineDataSourceAvatar
