import { AxiosError } from 'axios'
import { ConflictError, BadRequestError } from 'typescript-axios'

export default (error: AxiosError<ConflictError | BadRequestError>) => {
  const errorToObject = { ...error }
  if (!errorToObject || !errorToObject.response?.data) return 'An error occurred'

  if ((errorToObject.response.data as any) === 'Internal server error')
    return 'Internal server error'
  if (typeof errorToObject.response.data === 'string') return errorToObject.response.data
  if (
    'conflicts' in errorToObject.response.data &&
    errorToObject.response.data.conflicts &&
    errorToObject.response.data.conflicts.length > 0
  ) {
    return errorToObject.response.data.conflicts.map((x) => x.message).join('. ')
  }
  if (
    'errors' in errorToObject.response.data &&
    errorToObject.response.data.errors &&
    errorToObject.response.data.errors.length > 0
  ) {
    return errorToObject.response.data.errors.map((x) => x.message).join('. ')
  }
  return 'An error occurred'
}
