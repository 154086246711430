import { ListItemAvatar, SxProps, Box, Theme } from '@mui/material'
import { RiskCategoryIcon } from 'components'
import {
  ExpandMoreRounded as ExpandMoreIcon,
  ExpandLessRounded as ExpandLessIcon,
} from '@mui/icons-material'

interface Props {
  logoUrl?: string
  type: string
  category?: string
  isExpanded?: boolean
  sx?: SxProps<Theme>
}

const ScorecardItemAvatar = ({ logoUrl, isExpanded, type, category, sx }: Props) => {
  const isDataSource = type === 'certificate' || type === 'dataSource' || type === 'custom'

  return (
    <ListItemAvatar sx={{ minWidth: 38, zIndex: 1, ...sx }}>
      <Box
        component="span"
        sx={{
          display: 'flex',
          width: 30,
          height: 30,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {type === 'group' && (
          <Box component="span">{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Box>
        )}
        {type !== 'group' && !isDataSource && (
          <RiskCategoryIcon
            type={type === 'parameter' ? category || '' : type}
            withBackground
            size={16}
          />
        )}
        {isDataSource && !logoUrl && <RiskCategoryIcon type={type} withBackground size={16} />}
        {isDataSource && logoUrl && (
          <img src={logoUrl} alt="Data source logo" style={{ width: 24 }} />
        )}
      </Box>
    </ListItemAvatar>
  )
}

export default ScorecardItemAvatar
