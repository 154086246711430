import {
  StepConnector,
  StepLabel,
  Step,
  Stepper,
  stepConnectorClasses,
  stepLabelClasses,
} from '@mui/material'
import StepIcon from './StepIcon'

interface TopbarProps {
  currentStep: number
  steps: string[]
}

const TopbarStepper = ({ currentStep, steps }: TopbarProps) => {
  return (
    <Stepper
      sx={{
        padding: 0,
        backgroundColor: 'transparent',
      }}
      alternativeLabel
      activeStep={currentStep}
      connector={
        <StepConnector
          sx={{
            top: 9,
            left: 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)',
            padding: 0,
            [`&.${stepConnectorClasses.active}`]: {
              [`& .${stepConnectorClasses.line}`]: {
                backgroundColor: (theme) => `${theme.palette.success.main} !important`,
              },
            },
            [`&.${stepConnectorClasses.completed}`]: {
              [`& .${stepConnectorClasses.line}`]: {
                backgroundColor: (theme) => `${theme.palette.success.main} !important`,
              },
            },
            [`& .${stepConnectorClasses.line}`]: {
              backgroundColor: 'backgroundDark',
              height: 4,
              borderRadius: 1,
              transition: (theme) =>
                theme.transitions.create(['background-color', 'color'], {
                  duration: theme.transitions.duration.complex,
                }),
              border: 'none',
            },
          }}
        />
      }
    >
      {steps.map((label: string) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={StepIcon}
            sx={{
              [`& .${stepLabelClasses.labelContainer}`]: {
                display: 'none',
              },
            }}
          >
            {' '}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default TopbarStepper
