import { Components } from '@mui/material/styles'

const MuiToolbar: Components['MuiToolbar'] = {
  styleOverrides: {
    root: {
      minHeight: 'auto',
      width: '100%',
      justifyContent: 'space-between',
    },
  },
}

export default MuiToolbar
