import { useState, useEffect } from 'react'
import { Paper, PaperContent, Dialog, DialogTitle } from 'components'
import { RouteComponentProps } from 'react-router-dom'
import { Box, Typography, Button, SxProps } from '@mui/material'
import { ElectricBoltRounded as ElectricBoltIcon } from '@mui/icons-material'
import config from 'constants/config'
import { useDocumentTitle } from 'utils'

const restrictionContent = {
  scorecards: {
    title: 'Tailor your assessments',
    description:
      'Build custom scorecards to align with your strategic company goals. Prioritize suppliers to engage with based on their sustainability maturity ranking.',
    image: '/images/scorecards_onboarding.svg',
    buttonText: 'Book a demo',
  },
  dataRequests: {
    title: 'Engage your suppliers with ease',
    description:
      'Save your suppliers time and energy by only engaging them on topics not disclosed elsewhere publicly. See performance scores update in real-time based on responses.',
    image: '/images/data_requests_onboarding.svg',
    buttonText: 'Book a demo',
  },
  dataSources: {
    title: 'Gain full data transparency',
    description:
      'Access certifications, assessments, and questionnaires previously disclosed by your suppliers. See how hundreds of certification frameworks map to ESG impact and performance.',
    image: '/images/data_sources_onboarding.svg',
    buttonText: 'Book a demo',
  },
  risk: {
    title: 'Start mitigating risks',
    description:
      'Identify assessments which allow your suppliers to qualify for compliance, receive recommendations for improvement, and track progress towards compliance.',
    image: '/images/risk_compliance_onboarding.svg',
    buttonText: 'Book a demo',
  },
  objectives: {
    title: 'Align supplier goals with your targets',
    description:
      'Monitor legislative compliance and/or strategic company initiatives. Quickly analyze and act upon risk hotspots.',
    image: '/images/objectives_onboarding.svg',
    buttonText: 'Book a demo',
  },
  files: {
    title: 'Store all sustainability data in one place',
    description:
      'Easily access all supplier sustainability data from one location. Quickly reference documentation for supplier meetings and audits.',
    image: '/images/files_onboarding.svg',
    buttonText: 'Book a demo',
  },
  integrations: {
    title: 'Guide decision making across the organization',
    description:
      'Integrate sustainability data and performance analysis into all of your internal systems to facilitate decision making across the organization.',
    image: '/images/integrations_onboarding.svg',
    buttonText: 'Book a demo',
  },
}

interface Props extends Partial<RouteComponentProps> {
  children?: React.ReactNode
  /** if prop is passed, renders as modal */
  handleCloseDialog?: () => void
  sx?: SxProps
  contentKey: keyof typeof restrictionContent
}

const PaymentRequired = ({ children, handleCloseDialog, sx, contentKey }: Props) => {
  const isDialog = Boolean(handleCloseDialog)
  const [open, setOpen] = useState(false)
  const [bookDemoOpen, setBookDemoOpen] = useState(false)

  useEffect(() => {
    if (isDialog) setOpen(true)
  }, [])

  const handleCloseWithTimeout = () => {
    if (isDialog && handleCloseDialog) {
      setOpen(false)
      setTimeout(() => handleCloseDialog(), 300)
    }
  }

  const handleCloseBookDemoDialog = () => setBookDemoOpen(false)

  const content = restrictionContent[contentKey]

  useDocumentTitle(content.title, true)

  const PaymentRequiredComponent = (
    <Paper
      sx={{
        maxWidth: 500,
        margin: 'auto',
        backgroundColor: 'transparent',
      }}
    >
      <PaperContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          component="img"
          src={`${config.largeMediaUrl}${content.image}`}
          sx={{ width: '100%', minHeight: 274, maxWidth: isDialog ? '100%' : 320 }}
        />
        <Typography variant="h4" align="center" sx={{ mt: 2 }}>
          <strong>{content.title}</strong>
        </Typography>
        <Typography variant="body1" align="center" sx={{ mt: 1.5 }}>
          {content.description}
        </Typography>
        {children}
        <Button
          startIcon={<ElectricBoltIcon />}
          fullWidth
          sx={{ minHeight: 42, mt: 2 }}
          onClick={() => setBookDemoOpen(true)}
        >
          <strong>{content.buttonText}</strong>
        </Button>
      </PaperContent>
    </Paper>
  )

  const BookADemoComponent = (
    <>
      {isDialog && <DialogTitle title="Book a demo" handleBack={() => setBookDemoOpen(false)} />}
      <Box
        component="iframe"
        src="https://calendly.com/responsibly-tech/responsibly-introduction?embed_domain=www.responsibly.tech&embed_type=Inline&primary_color=f3766b"
        title="Calendly"
        width="100%"
        height="750px"
        sx={{
          borderRadius: 1,
          border: 'none',
        }}
      />
    </>
  )

  if (isDialog) {
    return (
      <Dialog
        open={open}
        onClose={handleCloseWithTimeout}
        maxWidth={bookDemoOpen ? 'sm' : 'xs'}
        contentPadding={false}
      >
        {bookDemoOpen ? (
          <>
            <Box>{BookADemoComponent}</Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
              <Button sx={{ minHeight: 42 }} onClick={handleCloseWithTimeout}>
                <strong>Close</strong>
              </Button>
            </Box>
          </>
        ) : (
          <Box sx={{ pt: 3, pb: 2 }}>{PaymentRequiredComponent}</Box>
        )}
      </Dialog>
    )
  }

  return (
    <Box sx={{ ...sx }}>
      {PaymentRequiredComponent}
      {!isDialog && bookDemoOpen && (
        <Dialog
          open={bookDemoOpen}
          onClose={handleCloseBookDemoDialog}
          maxWidth="sm"
          contentPadding={false}
        >
          <Box>{BookADemoComponent}</Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
            <Button sx={{ minHeight: 42 }} onClick={handleCloseBookDemoDialog}>
              <strong>Close</strong>
            </Button>
          </Box>
        </Dialog>
      )}
    </Box>
  )
}

export default PaymentRequired
