import { useInfiniteQuery, useQuery, UseQueryOptions } from 'react-query'
import { ResourceListDataEntryV2ApiDto, ResourceFoundDataEntryV2ApiDto } from 'typescript-axios'
import { AxiosError } from 'axios'
import { getEntries, getEntry } from './api'
import queryKeys, { DataEntriesFilterProps } from './queryKeys'

const { dataEntriesPage, singleDataEntry } = queryKeys

export const useGetDataEntries = (filters: DataEntriesFilterProps, enabled?: boolean) => {
  return useInfiniteQuery<
    ResourceListDataEntryV2ApiDto,
    AxiosError,
    ResourceListDataEntryV2ApiDto,
    ReturnType<typeof dataEntriesPage>
  >(dataEntriesPage(filters), ({ pageParam = 1 }) => getEntries({ page: pageParam, ...filters }), {
    getNextPageParam: (lastPage) => lastPage.paging.nextPage,
    staleTime: Infinity,
    enabled,
  })
}

export const useGetDataEntry = (
  id: string,
  options?: UseQueryOptions<
    ResourceFoundDataEntryV2ApiDto,
    AxiosError,
    ResourceFoundDataEntryV2ApiDto,
    ReturnType<typeof singleDataEntry>
  >
) => {
  return useQuery(singleDataEntry(id), () => getEntry(id), {
    ...options,
    staleTime: Infinity,
  })
}
