export default (coverage?: number) => {
  return coverage
  // Backend will handle, remove for now
  // if (coverage === undefined) return coverage
  // // 2 decimals
  // if (coverage < 0.1) return Math.round(coverage * 100) / 100
  // // 1 decimal
  // if (coverage >= 0.1 && coverage < 1) return Math.round(coverage * 10) / 10
  // // round to nearest integer
  // return Math.round(coverage)
}
