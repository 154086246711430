const emissions = ['emissions'] as const
const allSupplierEmissions = [...emissions, 'allSupplierEmissions'] as const
const singleSupplierEmissions = (filter: {
  supplierId: string
  startYear?: number
  endYear?: number
}) => [...allSupplierEmissions, 'singleSupplierEmissions', filter] as const

export default {
  emissions,
  allSupplierEmissions,
  singleSupplierEmissions,
}
