import { Components } from '@mui/material/styles'
import spacing from '../spacing'

const MuiMenu: Components['MuiMenu'] = {
  defaultProps: {
    elevation: 0,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    PaperProps: {
      style: {
        minWidth: 245,
      },
    },
  },
  styleOverrides: {
    root: {
      marginTop: -spacing,
    },
    list: {
      paddingLeft: spacing,
      paddingRight: spacing,
    },
    paper: {
      boxShadow:
        '0px 5px 5px -3px rgb(0 0 0 / 3%), 0px 8px 10px 1px rgb(0 0 0 / 3%), 0px 3px 14px 2px rgb(0 0 0 / 3%) !important',
    },
  },
}

export default MuiMenu
