import { Grid, Typography } from '@mui/material'

interface Props {
  identifier?: string
}

const InvitationExpired = ({ identifier }: Props) => {
  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
        <img
          src="https://uploads-ssl.webflow.com/60ca14a2e0cff73910bdba92/60ca14f3ec397edf89baf312_responsibly.svg"
          style={{ height: 52, paddingBottom: 16, margin: 'auto' }}
          alt="Responsibly logo"
        />
        <Typography
          style={{ maxWidth: 320, margin: 'auto', fontSize: '14px', marginBottom: 8 }}
          gutterBottom
        >
          <strong>Invitation not found or expired</strong>
        </Typography>
        <Typography style={{ maxWidth: 320, margin: 'auto', fontSize: '14px' }}>
          Thanks for signing up{identifier ? `, ${identifier}` : ''}! Unfortunately, the invitation
          you clicked couldn&apos;t be found or has expired. Please contact your administrator or
          Responsibly to obtain a new invitation.
        </Typography>
        <Typography>
          <a
            href="mailto:support@responsibly.tech"
            style={{
              textTransform: 'unset',
              marginTop: 16,
              borderColor: '#f3766b',
              color: '#f3766b',
              textDecoration: 'none',
              display: 'block',
            }}
          >
            Contact us
          </a>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default InvitationExpired
