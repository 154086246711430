import { useQueryClient, useInfiniteQuery } from 'react-query'
import {
  ResourceListPolicyParameterApiDto,
  PolicyParameterApiDto,
  PolicyCategoryApiDto,
} from 'typescript-axios'
import { AxiosError } from 'axios'
import { getParameterCategory } from 'utils'
import { getParameters } from './api'
import queryKeys from './queryKeys'

const { parameterList } = queryKeys

export interface ParameterWithTopLevelCategoryProps extends PolicyParameterApiDto {
  topLevelCategory: PolicyCategoryApiDto | undefined
}

export const useGetParameters = () => {
  return useInfiniteQuery<
    ResourceListPolicyParameterApiDto,
    AxiosError,
    ParameterWithTopLevelCategoryProps[],
    typeof parameterList
  >(parameterList, ({ pageParam = 1 }) => getParameters({ page: pageParam, pageSize: 250 }), {
    // pagination seems to be broken, so counting totalItems
    getNextPageParam: (lastPage, allPages) => {
      const flattenedAllPages = allPages.map((x) => x.data || []).flat()
      return flattenedAllPages.length < lastPage.paging.totalItemCount
        ? lastPage.paging.page + 1
        : undefined
    },
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      return {
        pages: data.pages.map((x) => {
          return (
            x.data?.map((y) => {
              return {
                ...y,
                topLevelCategory: getParameterCategory(y),
              }
            }) || []
          )
        }),
        pageParams: data.pageParams,
      }
    },
  })
}

export const usePrefetchParameters = () => {
  const queryClient = useQueryClient()
  return queryClient.prefetchInfiniteQuery(
    parameterList,
    ({ pageParam = 1 }) => getParameters({ page: pageParam, pageSize: 50 }),
    {
      getNextPageParam: (lastPage) => lastPage.paging.nextPage,
      staleTime: Infinity,
    }
  )
}

/*
export const useGetParameter = ({ id }: { id: string }) => {
  return useQuery(parameterList, getParameters, {
    staleTime: Infinity,
    refetchOnMount: false,
    select: (data) => data.data?.find((x) => x.id === id),
  })
}
*/
