import { styled } from '@mui/material/styles'
import classNames from 'classnames'
import { Typography, Tooltip } from '@mui/material'

const PREFIX = 'Diff'

const classes = {
  diff: `${PREFIX}-diff`,
  withTooltip: `${PREFIX}-withTooltip`,
  positive: `${PREFIX}-positive`,
  negative: `${PREFIX}-negative`,
  text: `${PREFIX}-text`,
}

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  [`& .${classes.diff}`]: {
    paddingLeft: theme.spacing(0.5),
  },
  [`& .${classes.withTooltip}`]: {
    cursor: 'pointer',
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      opacity: 0.5,
    },
  },
  [`& .${classes.positive}`]: {
    color: '#4ea77c',
  },
  [`& .${classes.negative}`]: {
    color: theme.palette.error.main,
  },
  [`& .${classes.text}`]: {
    color: theme.palette.primary.main,
  },
}))

interface Props {
  difference?: number
  text?: string
  tooltip?: string
  withPercentage?: boolean
}

const Diff = ({ difference, text, tooltip = '', withPercentage = true }: Props) => {
  const diffClassName = classNames([classes.diff], {
    [classes.positive]: difference && difference >= 0,
    [classes.negative]: difference && difference < 0,
    [classes.text]: text,
    [classes.withTooltip]: tooltip,
  })

  const positive = `+${difference}${withPercentage ? '%' : ''}`
  const negative = `${difference}${withPercentage ? '%' : ''}`

  return (
    <StyledTooltip title={tooltip} placement="right">
      <Typography variant="body1" display="inline" className={diffClassName} noWrap>
        {difference ? (difference >= 0 && positive) || negative : null}
        {text}
      </Typography>
    </StyledTooltip>
  )
}

export default Diff
