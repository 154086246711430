import { Components } from '@mui/material/styles'
import shape from '../shape'
import spacing from '../spacing'

const MuiListItem: Components['MuiListItem'] = {
  styleOverrides: {
    root: {
      borderRadius: shape.borderRadius,
      '&.Mui-selected': {
        backgroundColor: `rgba(0, 0, 0, 0.04)`,
        '&:hover': {
          backgroundColor: `rgba(0, 0, 0, 0.04)`,
        },
      },
    },
    gutters: {
      paddingLeft: spacing,
      paddingRight: spacing,
    },
  },
}

export default MuiListItem
