import apiClient from 'services/apiClient'

export const getDataSources = async ({
  page,
  pageSize,
  searchString,
  ids,
  policyParameter,
  includeEmpty,
}: {
  page: number
  pageSize: number
  searchString?: string
  ids?: string[]
  policyParameter?: string[]
  includeEmpty?: boolean
}) => {
  const { data } = await apiClient.getV2DataSources(
    ids,
    searchString,
    policyParameter,
    includeEmpty,
    page,
    pageSize
  )
  return data
}

export const getDataSource = async ({ id }: { id: string }) => {
  const { data } = await apiClient.getV2DataSourcesId(id)
  return data
}

export const getDataSourceParameters = async ({ id }: { id: string }) => {
  const { data } = await apiClient.getV2DataSourcesIdCoveredparameters(id)
  return data
}

export const getAllDataSourcesInSystem = async () => {
  const { data } = await apiClient.getV2RefDataSources()
  return data
}
