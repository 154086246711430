import { alpha, Box, Tooltip, BoxProps } from '@mui/material'

interface Props extends BoxProps {
  tooltip?: string
  onClick?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void
  maxWidth?: number | 'fit-content'
  children: React.ReactNode
  verticalPadding?: number
  horizPadding?: number
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

const ClickableContainer = ({
  tooltip,
  onClick,
  maxWidth = 148,
  children,
  verticalPadding = 10,
  onMouseEnter,
  onMouseLeave,
  horizPadding,
  ...rest
}: Props) => {
  const handleOnClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
    if (onClick) onClick(event)
  }

  return (
    <Box>
      <Tooltip title={tooltip || ''}>
        <Box
          {...rest}
          sx={{
            cursor: 'pointer',
            borderRadius: 1,
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            transition: (theme) =>
              theme.transitions.create('background-color', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.short,
              }),
            '&:hover': {
              backgroundColor: (theme) => alpha(theme.palette.common.black, 0.04),
            },
          }}
          maxWidth={maxWidth}
          onClick={handleOnClick}
          paddingTop={verticalPadding === undefined ? 1 : `${verticalPadding}px`}
          paddingBottom={verticalPadding === undefined ? 1 : `${verticalPadding}px`}
          paddingLeft={horizPadding === undefined ? 1 : `${verticalPadding}px`}
          paddingRight={horizPadding === undefined ? 1 : `${verticalPadding}px`}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {children}
        </Box>
      </Tooltip>
    </Box>
  )
}

export default ClickableContainer
