const dataSources = ['dataSources'] as const
const dataSourcePages = [...dataSources, 'pages'] as const
const dataSourcePage = ({
  pageSize,
  ids,
  includeEmpty,
  searchString,
  policyParameterIds,
}: {
  pageSize: number
  ids?: string[]
  policyParameterIds?: string[]
  includeEmpty?: boolean
  searchString?: string
}) =>
  [
    ...dataSourcePages,
    'page',
    pageSize,
    ids,
    includeEmpty,
    searchString,
    policyParameterIds,
  ] as const
const singleDataSources = [...dataSources, 'singleDataSources'] as const
const singleDataSource = (id: string) => [...singleDataSources, id] as const
const dataSourceCount = () => [...dataSources, 'dataSourceCount'] as const
/** will not be invalidated by invalidating dataSources */
const singleDataSourceParameters = (id: string) => ['singleDataSourceParameters', id]
const allDataSourcesInSystem = [...dataSources, 'allDataSourcesInSystem']

export default {
  dataSources,
  dataSourcePages,
  dataSourcePage,
  singleDataSources,
  singleDataSource,
  singleDataSourceParameters,
  dataSourceCount,
  allDataSourcesInSystem,
}
