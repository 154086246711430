export default (errors: any) => {
  const errorsArray =
    (Array.isArray(errors) && errors[0] && errors[0].message && errors) ||
    errors.conflicts ||
    errors.errors
  if (Array.isArray(errorsArray)) {
    const initialValue: any = {}
    return errorsArray.reduce((obj, item) => {
      return item.key in obj
        ? {
            ...obj,
            [item.key]: `${obj[item.key]} - ${item.message}`,
          }
        : {
            ...obj,
            [item.key]: item.message,
          }
    }, initialValue)
  }
  return {}
}
