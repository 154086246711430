import apiClient from 'services/apiClient'

export const getCountry = async ({ iso3Code }: { iso3Code: string }) => {
  const { data } = await apiClient.getV1GeoCountriesIso3code(iso3Code)
  return data
}

export const getCountries = async () => {
  const { data } = await apiClient.getV1GeoCountries()
  return data
}

export const getRegions = async () => {
  const { data } = await apiClient.getV1GeoRegions()
  return data
}

export const getRegion = async ({ regionName }: { regionName: string }) => {
  const { data } = await apiClient.getV1GeoRegionsRegionname(regionName)
  return data
}
