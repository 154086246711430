import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  ListItemSecondaryAction,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import classNames from 'classnames'
import { ChevronLeft as ChevronLeftIcon, MoreHoriz as MoreIcon } from '@mui/icons-material'

const PREFIX = 'DrawerTopBar'

const classes = {
  list: `${PREFIX}-list`,
  listMargin: `${PREFIX}-listMargin`,
  backAnchor: `${PREFIX}-backAnchor`,
  backListItem: `${PREFIX}-backListItem`,
}

const StyledList = styled(List)(({ theme }) => ({
  [`& .${classes.list}`]: {
    padding: '0px',
  },

  [`& .${classes.listMargin}`]: {
    marginLeft: theme.spacing(3),
  },

  [`& .${classes.backAnchor}`]: {
    marginLeft: theme.spacing(-0.5),
    fontSize: theme.typography.body2.fontSize,
  },

  [`& .${classes.backListItem}`]: {
    padding: 0,
    marginTop: theme.spacing(),
  },
}))

interface Props {
  goBack: () => void
  loading?: boolean
  handleMoreOnClick?: () => void
  backAnchor: string
  withMargin?: boolean
}

const DrawerTopBar = ({
  goBack,
  loading,
  backAnchor,
  handleMoreOnClick,
  withMargin = true,
}: Props) => {
  const listClassName = classNames([classes.list], {
    [classes.listMargin]: withMargin,
  })

  return (
    <StyledList className={listClassName}>
      <ListItem disableGutters className={classes.backListItem}>
        <ListItemIcon>
          <IconButton edge="start" onClick={goBack}>
            <ChevronLeftIcon />
          </IconButton>
        </ListItemIcon>
        <ListItemText
          secondary={backAnchor}
          classes={{
            secondary: classes.backAnchor,
          }}
        />
        {!loading && Boolean(handleMoreOnClick) && (
          <ListItemSecondaryAction>
            <IconButton edge="end" onClick={handleMoreOnClick}>
              <MoreIcon />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </StyledList>
  )
}

export default DrawerTopBar
