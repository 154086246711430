import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import config from 'constants/config'
import Fallback from './Fallback'

const shouldConnectLog = process.env.NODE_ENV === 'production'
const sentryConfig: Sentry.BrowserOptions = {}

if (shouldConnectLog) {
  Object.assign(sentryConfig, {
    environment: process.env.NODE_ENV,
    release: process.env.REACT_APP_COMMIT_REF,
    dsn: config.sentryDsn,
    tunnel: config.sentryProxyUrl,
    autoSessionTracking: true,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.5, // https://docs.sentry.io/platforms/javascript/performance/
    ignoreErrors: [/Loading chunk [\d]+ failed/],
  } as Sentry.BrowserOptions)
}

export const initSentry = () => {
  Sentry.init(sentryConfig)
}

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  return <Sentry.ErrorBoundary fallback={Fallback}>{children}</Sentry.ErrorBoundary>
}

export default ErrorBoundary
