import { Components } from '@mui/material/styles'
import palette from '../palette'

const MuiInputLabel: Components['MuiInputLabel'] = {
  styleOverrides: {
    root: {
      backgroundColor: palette.common.white,
    },
    shrink: {
      boxShadow: '0px 0px 6px 2px #fff',
      borderRadius: '10px',
    },
    outlined: {
      zIndex: 4,
    },
  },
}

export default MuiInputLabel
