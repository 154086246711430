import React from 'react'
import { Box, SxProps } from '@mui/material'

interface Props {
  sx?: SxProps
  noDivider?: boolean
  alignRight?: boolean
  noPadding?: boolean
  alignSpaceBetween?: boolean
  children: React.ReactNode
}

const PaperFooter = ({
  sx,
  noDivider = true,
  alignRight,
  noPadding,
  alignSpaceBetween,
  children,
}: Props) => {
  return (
    <Box
      sx={{
        paddingTop: noPadding ? 0 : 1,
        padding: noPadding ? 0 : 2,
        borderTopWidth: noDivider ? 0 : 1,
        borderTopColor: 'divider',
        borderBottomLeftRadius: '2px',
        borderBottomRightRadius: '2px',
        display: alignRight ? 'flex' : 'unset',
        justifyContent:
          (alignRight && 'flex-end') || (alignSpaceBetween && 'space-between') || 'unset',
        alignItems: alignRight ? 'center' : 'unset',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

export default PaperFooter
