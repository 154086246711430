import { useQuery, UseQueryOptions, useInfiniteQuery } from 'react-query'
import {
  ResourceListOrganizationSupplierV2ApiDto,
  ResourceFoundOrganizationSupplierV2ApiDto,
  ResourceListSupplierContactV2ApiDto,
  ResourceListGlobalSupplierSearchResultApiDto,
  ResourceFoundSupplierContactV2ApiDto,
  ResourceListGlobalSupplierApiDto,
  SearchSupplierIn,
  ResourceListOrganizationSupplierWithScoreAndCoverageV3ApiDto,
} from 'typescript-axios'
import { AxiosError } from 'axios'
import { FilterCriteriaProps } from 'views/buyer/Scorecards/helpers'
import {
  getSuppliers,
  getSupplier,
  getSupplierContact,
  getSupplierContacts,
  searchGlobalSuppliers,
  getGlobalSupplier,
  getSuppliersWithScoreAndCoverageByScorecardId,
} from './api'
import queryKeys, { SupplierFilterProps, PaginatedSupplierFilterProps } from './queryKeys'

const {
  supplierPage,
  singleSupplier,
  supplierContactPage,
  singleSupplierContact,
  paginatedSuppliers,
  globalSupplierSearch,
  globalSuppliers,
  suppliersWithScoreAndCoverageByScorecardId,
} = queryKeys

export const useGetSuppliers = (
  { pageSize, filters, supplierIds }: SupplierFilterProps,
  // TODO: having some TS trouble with UseInfiniteQueryOptions
  options?: { enabled?: boolean }
) => {
  return useInfiniteQuery<
    ResourceListOrganizationSupplierV2ApiDto,
    AxiosError,
    ResourceListOrganizationSupplierV2ApiDto,
    ReturnType<typeof supplierPage>
  >(
    supplierPage({ pageSize, filters, supplierIds }),
    ({ pageParam = 1 }) => getSuppliers({ page: pageParam, pageSize, filters, supplierIds }),
    {
      getNextPageParam: (lastPage) => lastPage.paging.nextPage,
      ...options,
      retry: true,
      staleTime: 500000,
    }
  )
}

export const useGetPaginatedSuppliers = (
  { pageSize, page, filters, supplierIds }: PaginatedSupplierFilterProps,
  options?: UseQueryOptions<
    ResourceListOrganizationSupplierV2ApiDto,
    AxiosError,
    ResourceListOrganizationSupplierV2ApiDto,
    ReturnType<typeof paginatedSuppliers>
  >
) => {
  return useQuery(
    paginatedSuppliers({ pageSize, page, filters, supplierIds }),
    () => getSuppliers({ page, pageSize, filters, supplierIds }),
    {
      ...options,
    }
  )
}

export const useGetSupplier = (
  id: string,
  options?: UseQueryOptions<
    ResourceFoundOrganizationSupplierV2ApiDto,
    AxiosError,
    ResourceFoundOrganizationSupplierV2ApiDto,
    ReturnType<typeof singleSupplier>
  >
) => {
  return useQuery(singleSupplier(id), () => getSupplier({ id }), {
    ...options,
    staleTime: 500000,
  })
}

export const useGetSuppliersCount = (
  filters?: FilterCriteriaProps[],
  options?: { enabled?: boolean }
) => {
  return useQuery(
    queryKeys.supplierCount(filters),
    () => getSuppliers({ filters, pageSize: 1, page: 1 }),
    {
      staleTime: 500000,
      select: (data) => data.paging.totalItemCount,
      retry: false,
      ...options,
    }
  )
}

export const useGetSupplierContacts = (
  { supplierId, pageSize }: { supplierId: string; pageSize: number },
  options?: { enabled?: boolean }
) => {
  return useInfiniteQuery<
    ResourceListSupplierContactV2ApiDto,
    AxiosError,
    ResourceListSupplierContactV2ApiDto,
    ReturnType<typeof supplierContactPage>
  >(
    supplierContactPage(supplierId, pageSize),
    ({ pageParam = 1 }) => getSupplierContacts({ page: pageParam, pageSize, supplierId }),
    {
      getNextPageParam: (lastPage) => lastPage.paging.nextPage,
      ...options,
      staleTime: 500000,
    }
  )
}

export const useGetSupplierContact = (
  { supplierId, id }: { supplierId: string; id: string },
  options?: UseQueryOptions<
    ResourceFoundSupplierContactV2ApiDto,
    AxiosError,
    ResourceFoundSupplierContactV2ApiDto,
    ReturnType<typeof singleSupplierContact>
  >
) => {
  return useQuery(
    singleSupplierContact(supplierId, id),
    () => getSupplierContact({ supplierId, id }),
    {
      staleTime: 500000,
      ...options,
    }
  )
}

export const useGlobalSupplierSearch = (
  { supplierName, distance, countryIso3Code }: SearchSupplierIn,
  options?: UseQueryOptions<
    ResourceListGlobalSupplierSearchResultApiDto,
    AxiosError,
    ResourceListGlobalSupplierSearchResultApiDto,
    ReturnType<typeof globalSupplierSearch>
  >
) => {
  return useQuery(
    globalSupplierSearch({ supplierName, distance, countryIso3Code }),
    () => searchGlobalSuppliers({ supplierName, distance, countryIso3Code }),
    {
      ...options,
    }
  )
}

export const useGetGlobalSuppliers = (
  { ids }: { ids: string[] },
  options?: UseQueryOptions<
    ResourceListGlobalSupplierApiDto,
    AxiosError,
    ResourceListGlobalSupplierApiDto,
    ReturnType<typeof globalSuppliers>
  >
) => {
  return useQuery(globalSuppliers(ids), () => getGlobalSupplier({ ids }), {
    ...options,
  })
}

export const useGetSuppliersWithScoreAndCoverageByScorecardId = (
  scorecardId: string,
  { pageSize, filters, sortBy, sortOrder }: SupplierFilterProps,
  options?: { enabled?: boolean }
) => {
  return useInfiniteQuery<
    ResourceListOrganizationSupplierWithScoreAndCoverageV3ApiDto,
    AxiosError,
    ResourceListOrganizationSupplierWithScoreAndCoverageV3ApiDto,
    ReturnType<typeof suppliersWithScoreAndCoverageByScorecardId>
  >(
    suppliersWithScoreAndCoverageByScorecardId(scorecardId, {
      pageSize,
      filters,
      sortBy,
      sortOrder,
    }),
    ({ pageParam = 1 }) =>
      getSuppliersWithScoreAndCoverageByScorecardId(scorecardId, {
        page: pageParam,
        pageSize,
        filters,
        sortBy,
        sortOrder,
      }),
    {
      getNextPageParam: (lastPage) => lastPage.paging.nextPage,
      ...options,
      retry: false,
      staleTime: 500000,
    }
  )
}
