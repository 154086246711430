import { Components } from '@mui/material/styles'
import { avatarGroupClasses, avatarClasses } from '@mui/material'
import palette from '../palette'

const MuiAvatar: Components['MuiAvatarGroup'] = {
  styleOverrides: {
    root: {
      justifyContent: 'flex-end',
      [`& .${avatarGroupClasses.avatar}`]: {
        [`& .${avatarClasses.root}`]: {
          border: 'none',
        },
      },
    },
    avatar: {
      color: palette.common.black,
      fontSize: 11,
      backgroundColor: 'transparent',
      border: `none !important`,
      width: 26,
      height: 26,
      boxShadow: 'none',
      marginLeft: '7px !important',
    },
  },
}

export default MuiAvatar
