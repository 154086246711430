import { useQuery, UseQueryOptions } from 'react-query'
import { ResourceListLanguageApiDto } from 'typescript-axios'
import { AxiosError } from 'axios'

import { getLanguages } from './api'
import * as queryKeys from './queryKeys'

export const useGetLanguages = (
  options?: UseQueryOptions<
    ResourceListLanguageApiDto,
    AxiosError,
    ResourceListLanguageApiDto,
    typeof queryKeys.languages
  >
) => {
  return useQuery(queryKeys.languages, () => getLanguages(), {
    staleTime: Infinity,
    ...options,
  })
}
