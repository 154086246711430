import { FilterCriteriaProps } from 'views/buyer/Scorecards/helpers'
import { SearchSupplierIn, SortingOptionApiDto, SortingOrderApiDto } from 'typescript-axios'

export interface SupplierFilterProps {
  pageSize: number
  filters?: FilterCriteriaProps[]
  supplierIds?: string[]
  sortBy?: 'Coverage' | 'Name' | 'NumericScore'
  sortOrder?: 'asc' | 'desc'
}

export interface PaginatedSupplierFilterProps extends SupplierFilterProps {
  page: number
}

const suppliers = ['suppliers'] as const
const supplierPages = [...suppliers, 'pages'] as const
const supplierPage = (filters: SupplierFilterProps) => [...supplierPages, filters] as const
const supplierCount = (filters?: FilterCriteriaProps[]) =>
  [...supplierPages, 'count', filters] as const
const singleSuppliers = [...suppliers, 'singleSuppliers'] as const
const singleSupplier = (id: string) => [...singleSuppliers, id] as const
const paginatedSuppliers = (filters: PaginatedSupplierFilterProps) =>
  [...suppliers, filters] as const

const supplierContacts = ['contacts'] as const
const supplierContactPages = [...supplierContacts, 'supplierContactPages']
const supplierContactPage = (supplierId: string, pageSize: number) =>
  [...supplierContactPages, supplierId, pageSize] as const
const singleSupplierContacts = [...supplierContacts, 'supplierContacts'] as const
const singleSupplierContact = (supplierId: string, id: string) =>
  [...supplierContacts, supplierId, id] as const
const globalSupplierSearch = (filters: SearchSupplierIn) =>
  [...suppliers, 'globalSupplierSearch', filters] as const
const globalSuppliers = (ids: string[]) => [...suppliers, 'globalSuppliers', ids] as const

const singleScorecardSupplierPages = ['scorecard', ...supplierPages] as const
const suppliersWithScoreAndCoverageByScorecardId = (
  scorecardId: string,
  filterProps: SupplierFilterProps
) => [...singleScorecardSupplierPages, scorecardId, filterProps] as const

export default {
  suppliers,
  supplierPages,
  supplierPage,
  supplierCount,
  singleSuppliers,
  singleSupplier,
  paginatedSuppliers,
  supplierContacts,
  supplierContactPages,
  supplierContactPage,
  singleSupplierContacts,
  singleSupplierContact,
  globalSupplierSearch,
  globalSuppliers,
  singleScorecardSupplierPages,
  suppliersWithScoreAndCoverageByScorecardId,
}
