import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Grid, Typography, Box, Avatar, alpha } from '@mui/material'
import { CheckRounded as CheckIcon } from '@mui/icons-material'

interface Props {
  identifier?: string
}

const NeedsEmailVerification = ({ identifier }: Props) => {
  const { logout } = useAuth0()

  useEffect(() => {
    setTimeout(() => {
      logout({
        returnTo: 'https://app.responsibly.tech',
      })
    }, 7500)
  }, [])

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
        <Box sx={{ width: '100%', justifyContent: 'center', display: 'flex', mb: 4 }}>
          <Avatar
            sx={{
              backgroundColor: (theme) => alpha(theme.palette.success.main, 0.12),
              width: 50,
              height: 50,
            }}
          >
            <CheckIcon color="success" sx={{ width: 28, height: 28 }} />
          </Avatar>
        </Box>
        <Typography
          style={{ maxWidth: 320, margin: 'auto', fontSize: '14px', marginBottom: 8 }}
          gutterBottom
        >
          <strong>Verify your email</strong>
        </Typography>
        <Typography style={{ maxWidth: 320, margin: 'auto', fontSize: '14px' }}>
          Thanks for signing up{identifier ? `, ${identifier}` : ''}! Please check your email for a
          verification link.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default NeedsEmailVerification
