import { Box, Tooltip } from '@mui/material'

interface Data {
  percentage: number
  tooltip?: string
  backgroundColor: string
  textColor: string
}

interface Props {
  data: Data[]
  overallTooltip?: React.ReactNode
  height?: number
}

const StackedProgressBar = ({ data, overallTooltip, height = 8 }: Props) => {
  return (
    <Tooltip title={overallTooltip || ''}>
      <Box
        sx={{
          display: 'flex',
          overflow: 'hidden',
          position: 'relative',
          width: '100%',
          height,
          backgroundColor: 'background.default',
          borderRadius: 1,
          '&:hover': {
            opacity: 0.75,
          },
        }}
      >
        {data.map((x, index) => {
          const isFirst = index === 0
          const isLast = index === data.length - 1
          return (
            <Box
              key={`${x.backgroundColor}${x.percentage}`}
              role="progressbar"
              sx={{
                textAlign: 'center',
                position: 'relative',
                whiteSpace: 'nowrap',
                height,
                borderBottomLeftRadius: isFirst ? (theme) => theme.shape.borderRadius : 0,
                borderTopLeftRadius: isFirst ? (theme) => theme.shape.borderRadius : 0,
                borderBottomRightRadius: isLast ? (theme) => theme.shape.borderRadius : 0,
                borderTopRightRadius: isLast ? (theme) => theme.shape.borderRadius : 0,
                width: `${x.percentage}%`,
                color: x.textColor,
                backgroundColor: x.backgroundColor,
                zIndex: isLast ? 1 : 2,
              }}
              aria-valuenow={x.percentage}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              {/* x.percentage > 4 && (
                  <Box component="span">
                    <Typography sx={{ fontSize: '8px', fontWeight: 600, mt: '-7px' }}>
                      {x.percentage}%
                    </Typography>
                  </Box>
                ) */}
            </Box>
          )
        })}
      </Box>
    </Tooltip>
  )
}

export default StackedProgressBar
