import { Components } from '@mui/material/styles'

const MuiAlert: Components['MuiAlert'] = {
  styleOverrides: {
    standardError: {},
    standardInfo: {},
    standardSuccess: {},
    standardWarning: {},
  },
}

export default MuiAlert
