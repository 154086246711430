import React, { Suspense } from 'react'
import { useHistory } from 'react-router-dom'
import { CircularProgress, Box } from '@mui/material'
import { Auth0Provider, AppState } from '@auth0/auth0-react'
import config from 'constants/config'
import NeedsEmailVerification from 'auth/NeedsEmailVerification'
import NotAllowed from '../NotAllowed'
import InvitationExpired from '../InvitationExpired'
import GeneralError from '../GeneralError'

interface Props {
  children: React.ReactNode
}

const Auth0ProviderWithHistory = ({ children }: Props) => {
  const history = useHistory()
  const urlParams = new URLSearchParams(window.location.search)
  const isSignup = Boolean(urlParams.get('signup'))
  const getError = urlParams.get('error')
  const getErrorDescription = urlParams.get('error_description')
  const hasError = Boolean(getError)
  const isInviteError = getErrorDescription?.includes('invitation not found or already used')
  const isNeedEmailVerificationError = getErrorDescription?.includes(
    'email address should be verified'
  )

  const onRedirectCallback = (appState?: AppState) => {
    history.push(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={config.auth0Domain}
      clientId={config.auth0ClientId}
      redirectUri={window.location.origin}
      audience={config.auth0Audience}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      screenHint={isSignup ? 'signup' : 'login'}
    >
      <Suspense
        fallback={
          <Box sx={{ textAlign: 'center', paddingTop: 6 }}>
            <CircularProgress />
          </Box>
        }
      >
        {hasError ? (
          <Box component="span">
            {(isInviteError && <InvitationExpired />) ||
              (isNeedEmailVerificationError && <NeedsEmailVerification />) || (
                <GeneralError errorMessage={getErrorDescription || 'An error occurred'} />
              )}
          </Box>
        ) : (
          children
        )}
      </Suspense>
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
