import React from 'react'
import { styled } from '@mui/material/styles'
import { Drawer } from '@mui/material'

const PREFIX = 'Sidebar'

const classes = {
  root: `${PREFIX}-root`,
  drawerPaper: `${PREFIX}-drawerPaper`,
}

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    overflowY: 'auto',
    height: '100%',
    justifyContent: 'space-between',
  },

  [`& .${classes.drawerPaper}`]: {
    border: 'none',
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create(['width'], {
      duration: theme.transitions.duration.shorter,
    }),
    // boxShadow: `0px 0px 6px 0px #00000014`,
    borderRight: `1px solid ${theme.palette.backgroundDark}`,
  },
}))

interface Props {
  open: boolean
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void
  isMobile?: boolean
  children: React.ReactNode
  dockOrientation?: 'left' | 'top' | 'right' | 'bottom'
  width?: number
  paperStyle?: React.CSSProperties
}

const Sidebar = ({
  open,
  onClose,
  isMobile = false,
  children,
  dockOrientation = 'left',
  width = 300,
  paperStyle,
}: Props) => {
  return (
    <StyledDrawer
      anchor={dockOrientation}
      classes={{ paper: classes.drawerPaper }}
      onClose={onClose}
      open={open}
      variant={isMobile ? 'temporary' : 'permanent'}
      style={{ height: '100%' }}
      PaperProps={{
        style: {
          width: `${width}px`,
          ...paperStyle,
        },
      }}
    >
      <nav className={classes.root}>{children}</nav>
    </StyledDrawer>
  )
}

export default Sidebar
