import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import classNames from 'classnames'
import {
  Grid,
  Button,
  Box,
  Typography,
  Menu,
  MenuItem,
  SxProps,
  Theme,
  Pagination,
  PaginationProps,
} from '@mui/material'
import { ArrowDropDownRounded as DropdownIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

const PREFIX = 'TablePagination'

const classes = {
  container: `${PREFIX}-container`,
  count: `${PREFIX}-count`,
  menuItem: `${PREFIX}-menuItem`,
  button: `${PREFIX}-button`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.container}`]: {
    marginTop: theme.spacing(),
  },

  [`& .${classes.count}`]: {
    display: 'block',
    marginLeft: theme.spacing(),
  },

  [`& .${classes.menuItem}`]: {
    margin: 0,
  },

  [`& .${classes.button}`]: {
    marginTop: -2,
    marginLeft: theme.spacing(-0.6),
  },
}))

interface Props {
  rowsPerPage: number
  page: number
  totalCount?: number
  pageCount: number
  setPage: (newPage: number) => void
  setRowsPerPage?: (newRows: number) => void
  rowsPerPageOptions?: number[]
  pageFromZero?: boolean
  size?: PaginationProps['size']
  className?: string
  withRowsPerPageSelect?: boolean
  sx?: SxProps<Theme>
  disabled?: boolean
}

const TablePagination = ({
  rowsPerPage,
  page,
  totalCount = 0,
  pageCount,
  setPage,
  setRowsPerPage,
  rowsPerPageOptions = [10, 25, 50],
  pageFromZero,
  size = 'large',
  className,
  withRowsPerPageSelect = true,
  sx,
  disabled,
}: Props) => {
  const { t } = useTranslation(['dataRequests'])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const containerClassName = classNames([classes.container], className)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  const handleChange = (_event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(pageFromZero ? newPage - 1 : newPage)
  }

  const handleSetRowsPerPage = (newValue: number) => {
    setAnchorEl(null)
    if (setRowsPerPage) setRowsPerPage(newValue)
  }

  const pageAdjust = pageFromZero ? page : page - 1

  const showingTo = pageAdjust * rowsPerPage + rowsPerPage
  const showingToLabel = showingTo >= totalCount ? totalCount : showingTo

  return (
    <StyledBox sx={{ ...sx }}>
      <Grid
        container
        justifyContent={withRowsPerPageSelect ? 'space-between' : 'center'}
        className={containerClassName}
      >
        {withRowsPerPageSelect && (
          <div>
            <Button
              variant="text"
              color="primary"
              onClick={handleClick}
              disabled={disabled}
              endIcon={<DropdownIcon />}
              className={size === 'large' ? classes.button : ''}
              size={size === 'large' ? 'medium' : 'small'}
            >
              {rowsPerPage} {t('AnswerDataRequest.data_rows_per_page')}
            </Button>
            {totalCount > 0 && (
              <Typography variant="caption" className={classes.count}>
                {t('AnswerDataRequest.data_showing_count', {
                  from: (pageAdjust * rowsPerPage + 1).toLocaleString(),
                  to: showingToLabel.toLocaleString(),
                  total: totalCount?.toLocaleString(),
                })}
              </Typography>
            )}
          </div>
        )}
        <Pagination
          count={pageCount}
          page={pageFromZero ? page + 1 : page}
          shape="rounded"
          size={size}
          onChange={handleChange}
        />
      </Grid>
      {!withRowsPerPageSelect && (
        <Typography
          variant="caption"
          className={classes.count}
          align="center"
          style={{ marginTop: 8 }}
        >
          {t('AnswerDataRequest.data_showing_count', {
            from: (pageAdjust * rowsPerPage + 1).toLocaleString(),
            to: showingToLabel.toLocaleString(),
            total: totalCount?.toLocaleString(),
          })}
        </Typography>
      )}
      {withRowsPerPageSelect && (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          {rowsPerPageOptions.map((x) => {
            return (
              <MenuItem
                key={x}
                onClick={() => handleSetRowsPerPage(x)}
                className={classes.menuItem}
                disabled={rowsPerPage === x}
              >
                {x}
              </MenuItem>
            )
          })}
        </Menu>
      )}
    </StyledBox>
  )
}

export default TablePagination
