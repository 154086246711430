import { Chip, alpha } from '@mui/material'

interface Props {
  label: string
  active?: boolean
  onClick: (value: string) => void
  disabled?: boolean
}

const FilterChip = ({ label, active, onClick, disabled }: Props) => {
  return (
    <Chip
      sx={[
        {
          minWidth: 70,
          fontWeight: 600,
          margin: 0.3,
          transition: (theme) =>
            theme.transitions.create(['background-color', 'color'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          boxShadow: 'none !important',
          '&:hover': {
            backgroundColor: (theme) => alpha(theme.palette.backgroundDark || '#000', 0.9),
            cursor: 'pointer',
          },
        },
        () =>
          active
            ? {
                color: 'common.white',
                backgroundColor: 'common.black',
                fontWeight: 600,
                '&:focus': {
                  backgroundColor: 'common.black',
                  cursor: 'pointer',
                },
                '&:hover': {
                  backgroundColor: 'common.black',
                },
              }
            : {
                backgroundColor: 'backgroundDark',
                '&:focus': {
                  backgroundColor: 'backgroundDark',
                },
              },
      ]}
      label={label}
      disabled={disabled}
      onClick={() => onClick(label.toLowerCase())}
    />
  )
}

export default FilterChip
