import { PolicyParameterApiDto, PolicyCategoryApiDto } from 'typescript-axios'

export default (parameter: PolicyParameterApiDto) => {
  let val: PolicyCategoryApiDto | undefined
  const traverse = (category: PolicyCategoryApiDto) => {
    if (category.parentPolicyCategory) {
      traverse(category.parentPolicyCategory)
    } else {
      val = category
    }
  }
  traverse(parameter.policyCategory)
  return val
}
