import { Components } from '@mui/material/styles'
import palette from '../palette'

const MuiListItemAvatar: Components['MuiListItemAvatar'] = {
  styleOverrides: {
    root: {
      color: palette.text.primary,
      minWidth: 36,
    },
  },
}

export default MuiListItemAvatar
