import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  useQueries,
  UseInfiniteQueryOptions,
} from 'react-query'
import {
  ResourceFoundScorecardV3ApiDto,
  ResourceListScorecardV3ApiDto,
  ResourceListSupplierScoreAndCoverageV2ApiDto,
  PagingOut,
} from 'typescript-axios'
import { AxiosError } from 'axios'
import { getScorecards, getScorecard, getOverallScoreAcrossScorecards } from './api'
import queryKeys, { ScorecardFilters } from './queryKeys'

const { scorecardPages, singleScorecard, overallScoreAndCoverageForSuppliers } = queryKeys

export interface Test {
  paging: PagingOut
  pages: {
    data: ResourceListScorecardV3ApiDto[]
  }[]
}

export const useGetScorecards = (
  filters: ScorecardFilters,
  options?: UseInfiniteQueryOptions<
    ResourceListScorecardV3ApiDto,
    AxiosError,
    ResourceListScorecardV3ApiDto,
    ResourceListScorecardV3ApiDto,
    ReturnType<typeof scorecardPages>
  >
) => {
  return useInfiniteQuery(
    scorecardPages(filters),
    ({ pageParam = 1 }) =>
      getScorecards({
        page: pageParam,
        pageSize: filters.pageSize,
        supplierIds: filters.supplierIds,
        scorecardIds: filters.scorecardIds,
      }),
    {
      ...options,
      getNextPageParam: (lastPage) => lastPage.paging.nextPage,
      staleTime: 2000000,
    }
  )
}

export const useGetScorecard = (
  scorecardId: string,
  supplierIdd?: string,
  options?: UseQueryOptions<
    ResourceFoundScorecardV3ApiDto,
    AxiosError,
    ResourceFoundScorecardV3ApiDto,
    ReturnType<typeof singleScorecard>
  >
) => {
  return useQuery(
    singleScorecard(scorecardId, supplierIdd),
    () => getScorecard({ id: scorecardId, supplierId: supplierIdd }),
    {
      ...options,
    }
  )
}

export const useGetMultipleScorecards = (
  scorecards: { id: string; supplierId?: string; enabled?: boolean }[]
) => {
  return useQueries(
    scorecards.map((x) => {
      return {
        queryKey: singleScorecard(x.id, x.supplierId),
        queryFn: () => getScorecard({ id: x.id, supplierId: x.supplierId }),
        enabled: x.enabled,
        staleTime: 250000,
      }
    })
  )
}

export const useGetOverallScoreAndCoverage = (
  supplierIds: string[],
  options?: UseQueryOptions<
    ResourceListSupplierScoreAndCoverageV2ApiDto,
    AxiosError,
    ResourceListSupplierScoreAndCoverageV2ApiDto,
    ReturnType<typeof overallScoreAndCoverageForSuppliers>
  >
) => {
  return useQuery(
    overallScoreAndCoverageForSuppliers({ supplierIds }),
    () => getOverallScoreAcrossScorecards({ supplierIds }),
    {
      ...options,
    }
  )
}
