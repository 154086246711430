import { Components } from '@mui/material/styles'

const iOS = typeof process !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

const MuiDrawer: Components['MuiDrawer'] = {
  defaultProps: {
    anchor: 'left',
    variant: 'permanent',
    PaperProps: {
      style: {
        width: '300px',
      },
    },
  },
}

export default MuiDrawer

/* MuiSwipeableDrawer: {
      defaultProps: {
        anchor: 'right',
        disableBackdropTransition: !iOS,
        disableDiscovery: iOS,
        transitionDuration: 275,
        PaperProps: {
          style: {
            width: '90%',
            maxWidth: '600px',
            overflowX: 'hidden',
            zIndex: 1600,
            paddingBottom: 24 * spacing,
            paddingTop: 2 * spacing,
            paddingLeft: 3 * spacing,
            paddingRight: 3 * spacing,
          },
        },
      },
    }, */
