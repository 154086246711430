import { Components } from '@mui/material/styles'
import palette from '../palette'

const MuiTooltip: Components['MuiTooltip'] = {
  defaultProps: {
    arrow: true,
  },
  styleOverrides: {
    tooltip: {
      backgroundColor: palette.text.primary,
      padding: 6,
      paddingLeft: 8,
      paddingRight: 8,
      fontSize: 12,
      borderRadius: 10,
    },
    arrow: {
      color: palette.text.primary,
    },
  },
}

export default MuiTooltip
