import { styled } from '@mui/material/styles'
import classNames from 'classnames'
import { LockOutlined as LockIcon } from '@mui/icons-material'
import { Tabs, Tab, TabsProps, Skeleton, SxProps, Theme } from '@mui/material'

const PREFIX = 'TabsWrapper'

const classes = {
  root: `${PREFIX}-root`,
  tabSkeleton: `${PREFIX}-tabSkeleton`,
  tabSkeletonContainer: `${PREFIX}-tabSkeletonContainer`,
  scrollableScroller: `${PREFIX}-scrollableScroller`,
  withMargin: `${PREFIX}-withMargin`,
  tabRoot: `${PREFIX}-tabRoot`,
  count: `${PREFIX}-count`,
  countSkeleton: `${PREFIX}-countSkeleton`,
  flex: `${PREFIX}-flex`,
}

const StyledTabs = styled(Tabs)(({ theme }) => ({
  [`& .${classes.root}`]: {},

  [`& .${classes.tabSkeleton}`]: {
    width: 70,
    maxWidth: '20%',
    marginRight: 16,
  },

  [`& .${classes.tabSkeletonContainer}`]: {
    display: 'flex',
    marginBottom: -4,
  },

  [`& .${classes.scrollableScroller}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.withMargin}`]: {
    marginBottom: theme.spacing(5),
  },

  [`& .${classes.tabRoot}`]: {
    transition: theme.transitions.create(['color', 'opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  [`& .${classes.count}`]: {
    height: 18,
    minWidth: 18,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(),
    fontSize: theme.typography.body2.fontSize,
  },

  [`& .${classes.countSkeleton}`]: {
    width: 18,
    height: 18,
    marginLeft: theme.spacing(),
  },

  [`& .${classes.flex}`]: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const a11yProps = (index: number) => {
  return {
    id: `navigation-tab-${index}`,
    'aria-controls': `navigation-tabpanel-${index}`,
  }
}

export interface TabProps {
  value: string
  label: React.ReactNode
  disabled: boolean
  hidden?: boolean
  count?: number
  countLoading?: boolean
}

interface Props {
  currentValue: string
  handleChange: (value: string) => void
  tabs: TabProps[]
  rootClassName?: string
  withMargin?: boolean
  variant?: TabsProps['variant']
  orientation?: TabsProps['orientation']
  loading?: boolean
  sx?: SxProps<Theme>
}

const TabsWrapper = ({
  currentValue,
  handleChange,
  tabs,
  rootClassName,
  withMargin,
  sx,
  variant = 'standard',
  orientation = 'horizontal',
  loading,
}: Props) => {
  const rootClassNameCombined = classNames(
    [classes.root],
    {
      [classes.withMargin]: withMargin,
    },
    rootClassName
  )

  const handleTabChange = (_event: React.ChangeEvent<{}>, value: string) => {
    handleChange(value)
  }

  if (loading) {
    return (
      <div className={classes.tabSkeletonContainer}>
        <Skeleton className={classes.tabSkeleton} />
        <Skeleton className={classes.tabSkeleton} />
        <Skeleton className={classes.tabSkeleton} />
        <Skeleton className={classes.tabSkeleton} />
        <Skeleton className={classes.tabSkeleton} />
      </div>
    )
  }

  return (
    <StyledTabs
      orientation={orientation}
      variant={variant}
      value={currentValue}
      allowScrollButtonsMobile
      onChange={handleTabChange}
      scrollButtons
      sx={{ ...sx }}
      aria-label="navigation tabs"
      TabIndicatorProps={{ children: <span /> }}
      classes={{
        root: rootClassNameCombined,
        scroller: variant === 'scrollable' ? classes.scrollableScroller : undefined,
      }}
    >
      {tabs.map((x, index) => {
        return x.hidden ? null : (
          <Tab
            key={x.value}
            label={
              x.count || x.countLoading ? (
                <div className={classes.flex}>
                  {x.disabled && <LockIcon />}
                  {x.label}{' '}
                  {x.countLoading && (
                    <Skeleton className={classes.countSkeleton} variant="circular" />
                  )}
                  {x.count && typeof x.count === 'number' && (
                    <div className={classes.count}>{x.count}</div>
                  )}
                </div>
              ) : (
                <div className={classes.flex}>
                  {x.disabled && <LockIcon sx={{ width: 18, height: 18, mr: 0.5 }} />}
                  {x.label}
                </div>
              )
            }
            value={x.value}
            disabled={x.disabled}
            {...a11yProps(index)}
            classes={{
              root: classes.tabRoot,
            }}
          />
        )
      })}
    </StyledTabs>
  )
}

export default TabsWrapper
