import React from 'react'
import { styled } from '@mui/material/styles'
import classNames from 'classnames'
import { InputBase, Box } from '@mui/material'
import { SearchRounded as SearchIcon } from '@mui/icons-material'

const PREFIX = 'UtilityInput'

const classes = {
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
  inputRoot: `${PREFIX}-inputRoot`,
  input: `${PREFIX}-input`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.search}`]: {
    height: 34,
    display: 'flex',
    position: 'relative',
    borderRadius: 25,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    width: '100%',
    opacity: 1,
    transition: theme.transitions.create('opacity'),
    '&:hover': {
      opacity: 1,
    },
  },
  [`& .${classes.searchIcon}`]: {
    width: theme.spacing(7),
    color: theme.palette.text.primary,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  [`& .${classes.inputRoot}`]: {
    color: 'inherit',
    width: '100%',
  },
  [`& .${classes.input}`]: {
    padding: '0px 0px 0px 50px',
    height: 34,
    width: '100%',
    transition: theme.transitions.create('width'),
    /* [theme.breakpoints.up('sm')]: {
      width: ({ fullWidth }) => (fullWidth ? '100%' : '16ch'),
      '&:focus': ({ fullWidth }) =>
        fullWidth
          ? {}
          : {
              width: '24ch',
            },
    }, */
  },
}))

interface Props {
  placeholder: string
  onChange: (search: string) => void
  value: string
  className?: string
  autoFocus?: boolean
  maxWidth?: number
  fontSize?: number
  fullWidth?: boolean
}

const UtilityInput = ({
  placeholder,
  onChange,
  value,
  className,
  autoFocus,
  maxWidth = 225,
  fontSize = 14,
  fullWidth = false,
}: Props) => {
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  const rootClassName = classNames([classes.search], className)

  return (
    <StyledBox className={rootClassName} sx={{ maxWidth }}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        fullWidth
        value={value}
        placeholder={placeholder}
        type="text"
        autoFocus={autoFocus}
        onChange={handleSearch}
        classes={{
          root: classes.inputRoot,
          input: classes.input,
        }}
        inputProps={{
          'aria-label': placeholder,
          style: { fontSize: `${fontSize}px` },
          sx: {
            width: 20,
          },
        }}
      />
    </StyledBox>
  )
}

export default UtilityInput
