const industries = ['industries']
const industryPages = [...industries, 'pages'] as const
const industryPage = ({
  code,
  includeUnassigned,
}: {
  code?: string[]
  includeUnassigned: boolean
}) => [...industryPages, code, includeUnassigned] as const

export default {
  industries,
  industryPages,
  industryPage,
}
