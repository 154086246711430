import { Components } from '@mui/material/styles'
import shape from '../shape'

const MuiAvatar: Components['MuiAvatar'] = {
  styleOverrides: {
    rounded: {
      borderRadius: shape.borderRadius,
    },
  },
}

export default MuiAvatar
