import { Components } from '@mui/material/styles'
import palette from '../palette'

const MuiOutlinedInput: Components['MuiOutlinedInput'] = {
  styleOverrides: {
    root: {
      backgroundColor: palette.common.white,
      border: `1px solid ${palette.divider}`,
      transitionDuration: '150ms',
      transitionProperty: 'border, border-color, box-shadow',
      transitionTimingFunction: 'ease',
      '&:hover': {
        borderColor: palette.background.default,
        boxShadow: '0 0 0 3px rgb(234 76 137 / 10%)',
        zIndex: 1,
      },
      '&.Mui-focused': {
        borderColor: '#f3766ba1',
        boxShadow: '0 0 0 4px rgb(234 76 137 / 10%)',
        zIndex: 2,
      },
      '&.Mui-disabled': {
        boxShadow: '#aaa2a242 0 0 0 1px',
        border: 0,
      },
      '&.Mui-OutlinedInput-notchedOutline': {
        borderWidth: '0px !important',
      },
    },
    notchedOutline: {
      borderWidth: '0px !important',
    },
    input: {
      fontWeight: 600,
    },
    /* inputMarginDense: {
      // borderRadius: 25,
      // paddingTop: 11.5,
      // paddingBottom: 11.5,
    }, */
  },
}

export default MuiOutlinedInput
