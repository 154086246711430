import { useQuery, UseQueryOptions, useQueryClient } from 'react-query'
import { ResourceListCountryApiDto, ResourceListGeographicRegionApiDto } from 'typescript-axios'
import { AxiosError } from 'axios'
import { getCountries, getRegions } from './api'
import queryKeys from './queryKeys'

const { countriesList, regionsList } = queryKeys

export const useGetCountries = (
  options?: UseQueryOptions<
    ResourceListCountryApiDto,
    AxiosError,
    ResourceListCountryApiDto,
    typeof countriesList
  >
) => {
  return useQuery(countriesList, getCountries, {
    staleTime: options?.staleTime || Infinity,
    refetchOnMount: options?.refetchOnMount || false,
    ...options,
  })
}

// will only be called if not in cache already
export const usePrefetchCountries = () => {
  const queryClient = useQueryClient()
  return queryClient.prefetchQuery(countriesList, getCountries, {
    staleTime: Infinity,
  })
}

/** selecting from list instead of fetching single country. Countries list will be in cache for sure */
export const useGetCountry = ({
  iso3Code,
  enabled = true,
}: {
  iso3Code: string
  enabled?: boolean
}) => {
  return useQuery(countriesList, getCountries, {
    staleTime: Infinity,
    refetchOnMount: false,
    select: (data) => data?.data?.find((x) => x.iso3Code === iso3Code),
    enabled,
  })
}

export const useGetRegions = (
  options?: UseQueryOptions<
    ResourceListGeographicRegionApiDto,
    AxiosError,
    ResourceListGeographicRegionApiDto,
    typeof regionsList
  >
) => {
  return useQuery(regionsList, getRegions, {
    staleTime: options?.staleTime || Infinity,
    refetchOnMount: options?.refetchOnMount || false,
    ...options,
  })
}

/** selecting from list instead of fetching single region. Regions list will be in cache for sure */
export const useGetRegion = ({
  regionName,
  enabled = true,
}: {
  regionName: string
  enabled?: boolean
}) => {
  return useQuery(regionsList, getRegions, {
    staleTime: Infinity,
    refetchOnMount: false,
    select: (data) => data?.data?.find((x) => x.name === regionName),
    enabled,
  })
}
