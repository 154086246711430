import { Components } from '@mui/material/styles'
import palette from '../palette'

const MuiCheckbox: Components['MuiCheckbox'] = {
  styleOverrides: {
    root: {
      color: palette.text.primary,
    },
  },
}

export default MuiCheckbox
