import { SxProps, Box } from '@mui/material'

interface Props {
  children: React.ReactNode
  sx?: SxProps
}

const PaperToolbar = ({ children, sx }: Props) => {
  return (
    <Box
      sx={{
        justifyContent: 'flex-end',
        alignItems: 'center',
        display: 'flex',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

export default PaperToolbar
