import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Typography, Card, CardActionArea, CardContent, Fade, Skeleton } from '@mui/material'
import classNames from 'classnames'

const PREFIX = 'ChannelCard'

const classes = {
  root: `${PREFIX}-root`,
  transparent: `${PREFIX}-transparent`,
  white: `${PREFIX}-white`,
  disabled: `${PREFIX}-disabled`,
  content: `${PREFIX}-content`,
  selected: `${PREFIX}-selected`,
  title: `${PREFIX}-title`,
  alignTop: `${PREFIX}-alignTop`,
}

const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.root}`]: {
    boxShadow: 'none',
    overflow: 'hidden',
  },

  [`& .${classes.transparent}`]: {
    backgroundColor: 'transparent',
  },

  [`& .${classes.white}`]: {
    height: '100%',
    backgroundColor: theme.palette.common.white,
  },

  [`& .${classes.disabled}`]: {
    opacity: 0.7,
  },

  [`& .${classes.content}`]: {
    textAlign: 'center',
  },

  [`& .${classes.selected}`]: {
    backgroundColor: theme.palette.background.default,
  },

  [`& .${classes.title}`]: {
    marginTop: theme.spacing(0.5),
  },

  [`& .${classes.alignTop}`]: {
    height: '100%',
    display: 'flex',
    alignItems: 'start',
  },
}))

type Props = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  title?: string
  description?: React.ReactNode
  selected?: boolean
  icon?: React.ReactNode
  loading?: boolean
  disabled?: boolean
  hoverDescription?: string
  className?: string
  skeleton?: boolean
  noWrap?: boolean
  white?: boolean
}

const ChannelCard = ({
  title,
  description,
  selected,
  icon,
  onClick,
  loading,
  disabled,
  hoverDescription,
  className,
  skeleton,
  noWrap,
  white,
}: Props) => {
  const [showHoverDesc, setShowHoverDesc] = useState(false)

  const root = classNames(
    [classes.root],
    {
      [classes.white]: white,
      [classes.transparent]: !white,
      [classes.disabled]: disabled,
      [classes.selected]: selected,
    },
    className
  )

  const handleShowHoverDesc = () => setShowHoverDesc(true)
  const handleHideHoverDesc = () => setShowHoverDesc(false)

  return skeleton ? (
    <StyledCard className={root} style={{ width: '100%', height: '100%' }}>
      <CardContent className={classes.content} style={{ width: '100%', height: '100%' }}>
        <Skeleton animation="wave" width="100%" height="100%" variant="rectangular" />
      </CardContent>
    </StyledCard>
  ) : (
    <StyledCard
      className={root}
      onMouseEnter={(Boolean(hoverDescription && !loading) && handleShowHoverDesc) || undefined}
      onMouseLeave={(Boolean(hoverDescription && !loading) && handleHideHoverDesc) || undefined}
    >
      <CardActionArea
        onClick={onClick}
        disabled={disabled || loading}
        className={white ? classes.alignTop : ''}
      >
        <CardContent className={classes.content}>
          {icon}
          <Typography
            variant="body2"
            color="textPrimary"
            gutterBottom
            noWrap={noWrap}
            className={classes.title}
          >
            {loading ? <Skeleton variant="text" /> : <strong>{title}</strong>}
          </Typography>
          {showHoverDesc && (
            <Fade in mountOnEnter unmountOnExit>
              <Typography color="textSecondary" variant="body2">
                {hoverDescription}
              </Typography>
            </Fade>
          )}
          {!showHoverDesc && (
            <Fade in mountOnEnter unmountOnExit>
              <Typography color="textSecondary" variant="body2" component="span">
                {loading ? <Skeleton variant="text" /> : description}
              </Typography>
            </Fade>
          )}
        </CardContent>
      </CardActionArea>
    </StyledCard>
  )
}

export default ChannelCard
