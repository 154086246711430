export interface DataEntriesFilterProps {
  dataEntryIds?: string[]
  supplierId?: string
  dataRequestId?: string
  dataSourceId?: string
  pageSize: number
  daysTillExpiredMin?: number
  daysTillExpiredMax?: number
}

export const defaultDataEntriesFilters: DataEntriesFilterProps = {
  pageSize: 15,
}

const dataEntries = ['dataEntries'] as const
const dataEntriesPages = [...dataEntries, 'pages'] as const
const dataEntriesPage = (filters: DataEntriesFilterProps) => [...dataEntriesPages, filters] as const
const singleDataEntries = [...dataEntries, 'singleDataEntries'] as const
const singleDataEntry = (id: string) => [...singleDataEntries, id] as const
const entriesFiles = (ids: string[]) => [...dataEntries, 'entriesFiles', ids] as const

export default {
  dataEntries,
  dataEntriesPages,
  dataEntriesPage,
  singleDataEntries,
  singleDataEntry,
  entriesFiles,
}
