import { useEffect, useState, RefObject } from 'react'

interface Props {
  target?: RefObject<any>
  onIntersect: () => void
  enabled?: boolean
}

export default ({ target, onIntersect, enabled }: Props) => {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    if (!enabled) {
      setIntersecting(false)
      return
    }

    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting)
    })

    const el = target && target.current

    if (!el) return

    observer.observe(el)

    // eslint-disable-next-line
    return () => {
      observer.unobserve(el)
    }
  }, [target?.current, enabled])

  useEffect(() => {
    if (isIntersecting && enabled) {
      onIntersect()
    }
  }, [isIntersecting, enabled, target?.current])
}
