import { UseQueryOptions, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { ResourceListUserApiDto, ResourceListUserInviteApiDto } from 'typescript-axios'
import { getExperimentalAdmins, getTeamMembers, getTeamInvites } from './api'
import queryKeys from './queryKeys'

const { allExperimentalAdmins, teamMembers, teamMemberInvites } = queryKeys

export const useGetExperimentalAdmins = (
  options?: UseQueryOptions<string[], AxiosError, string[], typeof allExperimentalAdmins>
) => {
  return useQuery(allExperimentalAdmins, () => getExperimentalAdmins(), {
    ...options,
  })
}

export const useGetTeamMembers = (
  options?: UseQueryOptions<
    ResourceListUserApiDto,
    AxiosError,
    ResourceListUserApiDto,
    typeof teamMembers
  >
) => {
  return useQuery(teamMembers, () => getTeamMembers(), {
    ...options,
  })
}

export const useGetTeamMemberInvites = (
  options?: UseQueryOptions<
    ResourceListUserInviteApiDto,
    AxiosError,
    ResourceListUserInviteApiDto,
    typeof teamMemberInvites
  >
) => {
  return useQuery(teamMemberInvites, () => getTeamInvites(), {
    ...options,
  })
}
