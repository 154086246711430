import { useQuery, UseQueryOptions, useInfiniteQuery } from 'react-query'
import {
  ResourceListDataSourceWithCoverageApiDto,
  ResourceFoundDataSourceWithCoverageApiDto,
  DataSourceApiDto,
  ResourceListDataSourcePolicyParameterApiDto,
  DataSourceApiRefDto,
  ResourceListDataSourceApiRefDto,
  DataSourcePolicyParameterApiDto,
  PolicyCategoryApiDto,
} from 'typescript-axios'
import { AxiosError } from 'axios'
import { getParameterCategory } from 'utils'
import staticMetaData, { DataSourceStaticMetadata } from './staticMetaData'
import {
  getDataSource,
  getDataSources,
  getDataSourceParameters,
  getAllDataSourcesInSystem,
} from './api'
import queryKeys from './queryKeys'

export interface DataSourceParameterWithTopLevelCategoryProps
  extends DataSourcePolicyParameterApiDto {
  topLevelCategory: PolicyCategoryApiDto | undefined
}

const ROWS_PER_PAGE = 20
const { dataSourcePage, singleDataSource, singleDataSourceParameters } = queryKeys

export interface ExtendedDataSourceProps extends DataSourceApiDto, DataSourceStaticMetadata {
  coveredSuppliers?: number
  coveredPolicyParameters?: DataSourcePolicyParameterApiDto[]
}

export interface ExtendedRefDataSourceProps extends DataSourceApiRefDto, DataSourceStaticMetadata {}

export const useGetInfiniteDataSources = ({
  pageSize = ROWS_PER_PAGE,
  ids,
  includeEmpty,
  policyParameterIds,
  searchString,
  enabled,
}: {
  ids?: string[]
  includeEmpty?: boolean
  searchString?: string
  policyParameterIds?: string[]
  enabled?: boolean
  pageSize: number
}) => {
  return useInfiniteQuery<
    ResourceListDataSourceWithCoverageApiDto,
    AxiosError,
    { data: ExtendedDataSourceProps[]; totalCount: number },
    ReturnType<typeof dataSourcePage>
  >(
    dataSourcePage({ pageSize, ids, includeEmpty, searchString, policyParameterIds }),
    ({ pageParam = 1 }) =>
      getDataSources({
        page: pageParam,
        pageSize,
        ids,
        includeEmpty,
        searchString,
        policyParameter: policyParameterIds,
      }),
    {
      getNextPageParam: (lastPage) => lastPage.paging.nextPage,
      enabled,
      select: (data) => {
        return {
          pages: data.pages.map((x) => {
            return {
              data:
                x.data?.map((y) => {
                  const findStaticMetadata =
                    y.dataSource.id in staticMetaData ? staticMetaData[y.dataSource.id] : {}
                  return {
                    ...y.dataSource,
                    ...findStaticMetadata,
                    coveredPolicyParameters: y.coveredPolicyParameters,
                    coveredSuppliers: y.coveredSuppliers,
                  }
                }) || [],
              totalCount: x.paging.totalItemCount,
            }
          }),
          pageParams: data.pageParams,
        }
      },
    }
  )
}

export const useGetDataSource = (
  id: string,
  options?: UseQueryOptions<
    ResourceFoundDataSourceWithCoverageApiDto,
    AxiosError,
    ExtendedDataSourceProps,
    ReturnType<typeof singleDataSource>
  >
) => {
  return useQuery(singleDataSource(id), () => getDataSource({ id }), {
    ...options,
    select: (data) => {
      const findStaticMetadata =
        data.data.dataSource.id in staticMetaData ? staticMetaData[data.data.dataSource.id] : {}
      return {
        ...data.data.dataSource,
        ...findStaticMetadata,
        coveredPolicyParameters: data.data.coveredPolicyParameters,
        coveredSuppliers: data.data.coveredSuppliers,
      }
    },
  })
}

export const useGetDataSourceParameters = (
  id: string,
  options?: UseQueryOptions<
    ResourceListDataSourcePolicyParameterApiDto,
    AxiosError,
    DataSourceParameterWithTopLevelCategoryProps[],
    ReturnType<typeof singleDataSourceParameters>
  >
) => {
  return useQuery(singleDataSourceParameters(id), () => getDataSourceParameters({ id }), {
    ...options,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    select: (data) => {
      return (
        data.data?.map((x) => {
          return {
            ...x,
            topLevelCategory: getParameterCategory(x.policyParameter),
          }
        }) || []
      )
    },
  })
}

export const useGetDataSourceCount = (options?: { enabled?: boolean }) => {
  return useQuery(queryKeys.dataSourceCount(), () => getDataSources({ pageSize: 1, page: 1 }), {
    staleTime: 500000,
    select: (data) => data.paging.totalItemCount,
    retry: false,
    ...options,
  })
}

export const useGetDataSourcesInSystem = (
  options?: UseQueryOptions<
    ResourceListDataSourceApiRefDto,
    AxiosError,
    ExtendedRefDataSourceProps[],
    typeof queryKeys.allDataSourcesInSystem
  >
) => {
  return useQuery(queryKeys.allDataSourcesInSystem, () => getAllDataSourcesInSystem(), {
    staleTime: Infinity,
    select: (data) => {
      return (
        data.data
          ?.map((y) => {
            const findStaticMetadata = y.id in staticMetaData ? staticMetaData[y.id] : {}
            return {
              ...y,
              ...findStaticMetadata,
            }
          })
          .sort((a, b) => a.name.localeCompare(b.name)) || []
      )
    },
    ...options,
  })
}
