const allParameters = ['parameters'] as const
const parameterList = [...allParameters, 'list'] as const
const singleParameters = [...allParameters, 'singleParameters'] as const
const singleParameter = (id: string) => [...singleParameters, id] as const

export default {
  allParameters,
  parameterList,
  singleParameters,
  singleParameter,
}
