import React from 'react'
import { Box, SxProps } from '@mui/material'

interface Props {
  sx?: SxProps
  noDivider?: boolean
  noPadding?: boolean
  children: React.ReactNode
}

const PaperHeader = ({ noDivider = true, noPadding, children, sx }: Props) => {
  return (
    <Box
      sx={{
        alignItems: 'flex-start',
        borderBottomWidth: noDivider ? 0 : 1,
        borderColor: `divider`,
        borderTopLeftRadius: '2px',
        borderTopRightRadius: '2px',
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: noPadding ? 0 : 2,
        paddingRight: noPadding ? 0 : 2,
        paddingTop: noPadding ? 0 : 1.8,
        position: 'relative',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

export default PaperHeader
