import apiClient from 'services/apiClient'
import {
  SupplierCategoryIn,
  SuppliersAddToCategoryIn,
  SuppliersRemoveFromCategoryIn,
  SupplierCategoryPatchApiDto,
} from 'typescript-axios'

export const getCategories = async ({ page, pageSize }: { page: number; pageSize: number }) => {
  const { data } = await apiClient.getV1SupplierCategories(page, pageSize)
  return data
}

export const getCategory = async ({ id }: { id: string }) => {
  const { data } = await apiClient.getV1SupplierCategoriesId(id)
  return data
}

export const createCategory = async ({ name }: SupplierCategoryIn) => {
  const { data } = await apiClient.postV1SupplierCategories({ name })
  return data
}

export interface AssignCategoryProps extends SuppliersAddToCategoryIn {
  // used to optimistically update cache if present. Not ideal, but having some trouble passing props to useMutation hook
  categoryName?: string
}

export const assignCategory = async ({
  categoryId,
  includeSupplierIds,
  excludeSupplierIds,
  categoryName,
}: AssignCategoryProps) => {
  const { data } = await apiClient.postV2SuppliersActionsAddToCategory({
    categoryId,
    includeSupplierIds,
    excludeSupplierIds,
  })
  return data
}

export const removeCategory = async ({
  categoryId,
  includeSupplierIds,
  excludeSupplierIds,
}: SuppliersRemoveFromCategoryIn) => {
  const { data } = await apiClient.postV2SuppliersActionsRemoveFromCategory({
    categoryId,
    includeSupplierIds,
    excludeSupplierIds,
  })
  return data
}

export const deleteCategory = async ({ categoryId }: { categoryId: string }) => {
  const { data } = await apiClient.deleteV1SupplierCategoriesId(categoryId)
  return data
}

export const updateCategory = async ({
  id,
  name,
}: SupplierCategoryPatchApiDto & { id: string }) => {
  const { data } = await apiClient.patchV1SupplierCategoriesId(id, { name })
  return data
}
