import React from 'react'
import {
  ListItem,
  List,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Tooltip,
  SxProps,
  Skeleton,
} from '@mui/material'
import { KeyboardArrowDownRounded as ChevronDownIcon } from '@mui/icons-material'

interface Props {
  primaryText?: string
  secondaryText?: string
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  hasChevron?: boolean
  avatarOnly?: boolean
  sx?: SxProps
  backgroundColor?: string
  skeleton?: boolean
  avatarUrl?: string
}

const OrganisationSelect = ({
  primaryText,
  secondaryText,
  onClick,
  hasChevron = true,
  avatarOnly,
  sx,
  backgroundColor,
  skeleton,
  avatarUrl,
}: Props) => {
  const avatarChar = primaryText?.charAt(0)

  // having some weird "button" prop ts errors on ListItem
  const newOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (onClick) {
      onClick(event)
    }
  }

  return (
    <List disablePadding sx={{ ...sx }}>
      <Tooltip title={avatarOnly ? primaryText || '' : ''} placement="right" arrow>
        <ListItem
          button
          sx={[
            {
              borderRadius: 1,
              '&:hover': !onClick
                ? {
                    cursor: 'default',
                    backgroundColor: 'transparent',
                  }
                : {},
            },
            () =>
              avatarOnly
                ? {
                    paddingLeft: 1,
                  }
                : {},
          ]}
          onClick={(event) => newOnClick(event)}
        >
          <ListItemAvatar>
            {skeleton ? (
              <Skeleton width={40} height={40} />
            ) : (
              <Avatar
                sx={{
                  color: 'common.white',
                  fontWeight: 600,
                  backgroundColor: backgroundColor || 'warning.main',
                }}
                variant="rounded"
                src={avatarUrl}
              >
                {avatarChar}
              </Avatar>
            )}
          </ListItemAvatar>
          {!avatarOnly && (
            <ListItemText
              primary={skeleton ? <Skeleton /> : primaryText}
              secondary={skeleton ? <Skeleton /> : secondaryText}
              primaryTypographyProps={{
                noWrap: true,
                sx: {
                  fontWeight: 600,
                  color: 'common.black',
                },
              }}
              secondaryTypographyProps={{
                noWrap: true,
                sx: {
                  color: 'text.secondary',
                },
              }}
              sx={{
                margin: 0,
              }}
            />
          )}
          {hasChevron && !avatarOnly && !skeleton && <ChevronDownIcon />}
        </ListItem>
      </Tooltip>
    </List>
  )
}

export default OrganisationSelect
