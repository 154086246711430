import { Table, TableBody, TableCell, TableRow, Skeleton } from '@mui/material'

interface Props {
  colSpan?: number
  rows?: number
  rowHeight?: number
  withTable?: boolean
}

const LoadingTableRow = ({ colSpan = 8, rows = 4, rowHeight = 52, withTable }: Props) => {
  const tableRows = (
    <>
      {Array.from(Array(rows).keys()).map((x) => {
        return (
          <TableRow key={x} sx={{ backgroundColor: 'transparent', height: rowHeight }}>
            <TableCell colSpan={colSpan} padding="none" sx={{ padding: 0 }}>
              <Skeleton variant="rectangular" animation="wave" width="100%" height={rowHeight} />
            </TableCell>
          </TableRow>
        )
      })}
    </>
  )

  return withTable ? (
    <Table>
      <TableBody>{tableRows}</TableBody>
    </Table>
  ) : (
    tableRows
  )
}

export default LoadingTableRow
