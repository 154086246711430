import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import classNames from 'classnames'
import { ListItem, ListItemIcon, ListItemText, Tooltip, alpha, Collapse, Box } from '@mui/material'
import {
  ExpandMoreRounded as ExpandMoreIcon,
  ExpandLessRounded as ExpandLessIcon,
} from '@mui/icons-material'

const PREFIX = 'NavLink'

const classes = {
  listItem: `${PREFIX}-listItem`,
  activeListItem: `${PREFIX}-activeListItem`,
  iconOnlyListItem: `${PREFIX}-iconOnlyListItem`,
  listItemIcon: `${PREFIX}-listItemIcon`,
  iconOnlyListItemIcon: `${PREFIX}-iconOnlyListItemIcon`,
  listItemText: `${PREFIX}-listItemText`,
  subMenu: `${PREFIX}-subMenu`,
}

const Root = styled(Box)(({ theme }) => ({
  [`& .${classes.listItem}`]: {
    cursor: 'pointer',
    height: 44,
    transition: theme.transitions.create(['background-color', 'color'], {
      duration: theme.transitions.duration.shortest,
    }),
    [`& .${classes.listItemText}`]: {
      color: theme.palette.common.black,
      fontWeight: 600,
    },
    [`& .${classes.listItemIcon}`]: {
      color: theme.palette.common.black,
    },
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(0.5),
  },
  [`& .${classes.activeListItem}`]: {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.15)} !important`,
    [`& .${classes.listItemText}`]: {
      color: theme.palette.primary.dark,
    },
    [`& .${classes.listItemIcon}`]: {
      color: theme.palette.primary.dark,
    },
  },
  [`& .${classes.iconOnlyListItem}`]: {
    height: 43,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  [`& .${classes.listItemIcon}`]: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.8),
    alignItems: 'center',
    transition: theme.transitions.create(['color'], {
      duration: theme.transitions.duration.shortest,
    }),
    '& svg': {
      width: 26,
      height: 26,
    },
  },
  [`& .${classes.iconOnlyListItemIcon}`]: {
    margin: 0,
    minWidth: 0,
  },
  [`& .${classes.listItemText}`]: {
    transition: theme.transitions.create(['color'], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  [`& .${classes.subMenu}`]: {
    // paddingLeft: 48,
  },
}))

interface Props {
  active: boolean
  path: string
  changeRoute: (path: string) => void
  icon?: React.ReactNode
  tooltip?: string
  disabled?: boolean
  primaryText: string
  secondaryText?: string
  secondaryAction?: React.ReactNode
  iconOnly: boolean
  children?: React.ReactNode
  initialExpanded?: boolean
}

const NavLink = ({
  active,
  path,
  icon,
  tooltip = '',
  disabled = false,
  primaryText,
  secondaryText,
  secondaryAction,
  changeRoute,
  iconOnly,
  children,
  initialExpanded,
}: Props) => {
  const [expanded, setExpanded] = useState(Boolean(initialExpanded))

  const listItemClasses = classNames([classes.listItem], {
    [classes.activeListItem]: active,
    [classes.iconOnlyListItem]: iconOnly,
  })

  const listItemIconClasses = classNames([classes.listItemIcon], {
    [classes.iconOnlyListItemIcon]: iconOnly,
  })

  const handleChangeRoute = () => changeRoute(path)

  const handleToggleExpanded = () => setExpanded(!expanded)

  return (
    <Root>
      <Tooltip title={iconOnly ? tooltip || '' : ''} placement="right" arrow>
        <ListItem
          disabled={disabled}
          className={listItemClasses}
          button
          selected={expanded}
          onClick={children ? handleToggleExpanded : handleChangeRoute}
        >
          {icon && <ListItemIcon className={listItemIconClasses}>{icon}</ListItemIcon>}
          {!iconOnly && (
            <>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary={primaryText}
                secondary={secondaryText}
              />
              {secondaryAction}
            </>
          )}
          {children && !iconOnly && (
            <Box component="span">{expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Box>
          )}
        </ListItem>
      </Tooltip>
      {children && (
        <Collapse in={expanded}>
          <div className={classes.subMenu}>{children}</div>
        </Collapse>
      )}
    </Root>
  )
}

export default NavLink
