import config from 'constants/config'

// Used to show a flag based on the language tag
const languageTagToIso2CodeMap = {
  am: 'et',
  ar: 'ae',
  eu: null,
  bn: 'bd',
  bg: 'bg',
  ca: null,
  chr: null,
  'zh-Hans': 'cn',
  hr: 'hr',
  cs: 'cz',
  da: 'dk',
  nl: 'nl',
  en: 'gb',
  et: 'ee',
  fil: 'ph',
  fi: 'fi',
  fr: 'fr',
  de: 'de',
  el: 'gr',
  gu: 'in',
  iw: 'il',
  hi: 'in',
  hu: 'hu',
  is: 'is',
  id: 'id',
  it: 'it',
  ja: 'jp',
  kn: 'in',
  ko: 'kp',
  lv: 'lv',
  lt: 'lt',
  ms: 'my',
  ml: 'in',
  mr: 'in',
  no: 'no',
  pl: 'pl',
  pt: 'pt',
  ro: 'ro',
  ru: 'ru',
  sr: 'rs',
  sk: 'sk',
  sl: 'si',
  es: 'es',
  sw: 'ke',
  sv: 'se',
  ta: 'in',
  te: 'in',
  th: 'th',
  tr: 'tr',
  uk: 'ua',
  ur: 'pk',
  vi: 'vn',
  cy: null,
} as { [key: string]: string | null }

export default (languageTag: string) => {
  const tag =
    languageTag in languageTagToIso2CodeMap
      ? languageTagToIso2CodeMap[languageTag]
      : languageTag.toUpperCase()

  return `${config.largeMediaUrl}/images/flags/${tag}.svg`
}
