import apiClient from 'services/apiClient'

export const getSupplierEmissions = async ({
  supplierId,
  startYear,
  endYear,
}: {
  supplierId: string
  startYear?: number
  endYear?: number
}) => {
  const { data } = await apiClient.getV2SuppliersSupplieridEmissionData(
    supplierId,
    startYear,
    endYear
  )
  return data
}
