import apiClient from 'services/apiClient'
import { GenerateSupplierPreSignedUploadUrlInput } from 'typescript-axios'

export const getDataRequestFiles = async (dataRequestId: string) => {
  const { data } = await apiClient.getV2FilesDataRequestsDatarequestid(dataRequestId)
  return data
}

export const getEntriesFiles = async (dataEntryIds?: string[]) => {
  const { data } = await apiClient.getV2FilesDataEntries(dataEntryIds || [])
  return data
}

export const getDownloadFileUrl = async (id: string, preview?: boolean) => {
  const { data } = await apiClient.getV2FilesFileid(id, preview)
  return data
}

export const getSupplierFiles = async ({ supplierIds }: { supplierIds?: string[] }) => {
  const { data } = await apiClient.getV2FilesSuppliers(supplierIds || [])
  return data
}

export const postOnboardingUploadUrls = async ({
  fileName,
  contentType,
}: GenerateSupplierPreSignedUploadUrlInput) => {
  return apiClient.postV1OnboardingFiles({ fileName, contentType })
}

export const postSupplierFilesUploadUrls = async ({
  supplierId,
  fileName,
  contentType,
  dataEntryId,
}: GenerateSupplierPreSignedUploadUrlInput & { supplierId: string }) => {
  return apiClient.postV2SuppliersIdFiles(supplierId, {
    fileName,
    contentType,
    dataEntryId,
  })
}

export const getUploadedOnboardingFiles = async () => {
  const { data } = await apiClient.getV1OnboardingFiles()
  return data
}
