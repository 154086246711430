// Service Worker Helper
// Example from: https://dev.to/atonchev/flawless-and-silent-upgrade-of-the-service-worker-2o95
// --> https://github.com/a-tonchev/react-boilerplate/blob/master/src/components/helpers/SWHelper.js

const SWHelper = {
  async getWaitingWorker() {
    const registrations = (await navigator?.serviceWorker?.getRegistrations()) || []
    const registrationWithWaiting = registrations.find((reg) => reg.waiting)
    return registrationWithWaiting?.waiting
  },

  async skipWaiting() {
    return (await SWHelper.getWaitingWorker())?.postMessage({ type: 'SKIP_WAITING_WHEN_SOLO' })
  },

  async prepareCachesForUpdate() {
    return (await SWHelper.getWaitingWorker())?.postMessage({ type: 'PREPARE_CACHES_FOR_UPDATE' })
  },
}

export default SWHelper
