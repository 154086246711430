import { useState, createContext, useMemo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useMixpanel } from 'services/mixpanel'
import removeTrailingSlash from '../../removeTrailingSlash'

interface RouterContextProps {
  currentPath: string
  previousPath?: string
}

export const RouterContext = createContext<RouterContextProps>({
  currentPath: '',
  previousPath: undefined,
})

export const RouterContextProvider = ({ children }: { children: React.ReactNode }) => {
  const mixpanel = useMixpanel()
  const { pathname } = useLocation()

  const [routeContext, setRouteContext] = useState<{ currentPath: string; previousPath?: string }>({
    currentPath: pathname,
    previousPath: undefined,
  })

  useEffect(() => {
    const currentPath = removeTrailingSlash(pathname)
    setRouteContext((prev) => ({
      currentPath,
      previousPath:
        removeTrailingSlash(prev.currentPath) === removeTrailingSlash(pathname)
          ? undefined
          : removeTrailingSlash(prev.currentPath),
    }))

    const replaceIds = currentPath
      .split('/')
      .map((x) => (/^[a-z0-9]{26}$/.test(x) ? '{id}' : x))
      .join('/')

    mixpanel?.track('Page View', {
      'Page Title': document.title,
      URL: currentPath,
      'Relative URL': replaceIds.length > 0 ? replaceIds : '/',
    })
    mixpanel?.track(replaceIds.length > 0 ? replaceIds : '/', {
      'Page Title': document.title,
      URL: currentPath,
      'Relative URL': replaceIds.length > 0 ? replaceIds : '/',
    })
  }, [pathname])

  const values = useMemo(() => {
    return {
      ...routeContext,
    }
  }, [routeContext])

  return <RouterContext.Provider value={values}>{children}</RouterContext.Provider>
}
