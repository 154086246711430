import { UseQueryOptions, useQuery } from 'react-query'
import {
  ResourceListDataEntryUploadedFileDto,
  ResourceListSupplierUploadedFileDto,
  ResourceFoundUploadedFilesListingDto,
} from 'typescript-axios'
import { AxiosError } from 'axios'
import {
  getEntriesFiles,
  getSupplierFiles,
  getUploadedOnboardingFiles,
  getDataRequestFiles,
} from './api'
import queryKeys from './queryKeys'

const { dataEntryFiles, supplierFiles, onboardingFiles, dataRequestFiles } = queryKeys

export const useGetDataRequestFiles = (
  dataRequestId: string,
  options?: UseQueryOptions<
    ResourceListSupplierUploadedFileDto,
    AxiosError,
    ResourceListSupplierUploadedFileDto,
    ReturnType<typeof dataRequestFiles>
  >
) => {
  return useQuery(dataRequestFiles(dataRequestId), () => getDataRequestFiles(dataRequestId), {
    ...options,
  })
}

export const useGetDataEntriesFiles = (
  dataEntryIds?: string[],
  options?: UseQueryOptions<
    ResourceListDataEntryUploadedFileDto,
    AxiosError,
    ResourceListDataEntryUploadedFileDto,
    ReturnType<typeof dataEntryFiles>
  >
) => {
  return useQuery(dataEntryFiles(dataEntryIds), () => getEntriesFiles(dataEntryIds), {
    ...options,
  })
}

export const useGetSuppliersFiles = (
  supplierIds?: string[],
  options?: UseQueryOptions<
    ResourceListSupplierUploadedFileDto,
    AxiosError,
    ResourceListSupplierUploadedFileDto,
    ReturnType<typeof supplierFiles>
  >
) => {
  return useQuery(supplierFiles(supplierIds), () => getSupplierFiles({ supplierIds }), {
    ...options,
  })
}

export const useGetOnboardingFiles = (
  options?: UseQueryOptions<
    ResourceFoundUploadedFilesListingDto,
    AxiosError,
    ResourceFoundUploadedFilesListingDto,
    typeof onboardingFiles
  >
) => {
  return useQuery(onboardingFiles, () => getUploadedOnboardingFiles(), {
    ...options,
  })
}
