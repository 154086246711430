import { DefaultApiFactory, BadRequestError, ConflictError } from 'typescript-axios'
import config from 'constants/config'
import axios, { AxiosError } from 'axios'

export type ApiMutateError = AxiosError<BadRequestError | ConflictError>

/** had issues removing token for separate calls only, so created a new instance for now */
export const cleanAxiosInstance = axios.create()

export const tokenAxiosInstance = axios.create({
  timeout: 50000,
})

export const setAxiosTokenInterceptor = async (accessToken: string): Promise<void> => {
  tokenAxiosInstance.interceptors.request.use(
    (axiosConfig) => {
      Object.assign(axiosConfig, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      return axiosConfig
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      return Promise.reject(error)
    }
  )
}

// Configuration is not provided. Instead, we pass an Axios instance so that we may intercept and set bearer
const apiClient = DefaultApiFactory(undefined, config.apiBaseUrl, tokenAxiosInstance as any)

export default apiClient
