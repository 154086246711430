import { useMutation } from 'react-query'
import { useSnackbar } from 'notistack'
import { getErrorMessage } from 'utils'
import { ApiMutateError } from 'services/apiClient'
import {
  postExperimentalAdmins,
  inviteTeamMember,
  deleteInvitation,
  startOnboardingEmailFlow,
} from './api'

export const useUpdateAdminList = () => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(postExperimentalAdmins, {
    onError: (err: ApiMutateError) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
    },
  })
}

export const useInviteTeamMember = () => {
  return useMutation(inviteTeamMember)
}

export const useDeleteInvitation = () => {
  return useMutation(deleteInvitation)
}

export const useStartOnboardingEmailFlow = () => {
  return useMutation(startOnboardingEmailFlow)
}
