import { Chip, SxProps, Theme, alpha } from '@mui/material'

const BetaChip = ({ title, sx }: { title?: string; sx?: any }) => {
  return (
    <Chip
      size="small"
      label={title || 'BETA'}
      sx={[
        {
          ml: 0.5,
          whiteSpace: 'nowrap',
          borderRadius: '2px',
          fontWeight: 700,
          height: 15,
          marginTop: '-2px',
          paddingTop: '1px',
          '& .MuiChip-label': {
            paddingLeft: '5px',
            paddingRight: '5px',
            fontSize: 9,
            letterSpacing: '0.5px',
          },
          ...sx,
        },
        (theme) => ({
          color: theme.palette.info.main,
          backgroundColor: alpha(theme.palette.secondary.main, 0.06),
          '&:focus, &:hover': {
            color: theme.palette.info.main,
            backgroundColor: alpha(theme.palette.secondary.main, 0.06),
          },
        }),
      ]}
    />
  )
}

export default BetaChip
