/** score number to likert scale */
export default (
  score: number,
  asNumber = false,
  isNewScoringScale = false,
  toNearestInteger = false
) => {
  if (isNewScoringScale) {
    const num = parseFloat((score / 33.33).toFixed(2))
    return toNearestInteger ? Math.round(num) : num
  }

  if (score > 0 && score <= 20) return asNumber ? 1 : 'very low'
  if (score > 20 && score <= 40) return asNumber ? 2 : 'low'
  if (score > 40 && score <= 60) return asNumber ? 3 : 'medium'
  if (score > 60 && score <= 80) return asNumber ? 4 : 'good'
  if (score > 80 && score <= 100) return asNumber ? 5 : 'very good'
  return undefined
}
