import React from 'react'
import { styled } from '@mui/material/styles'
import classNames from 'classnames'
import { Typography, Skeleton, Box } from '@mui/material'

const PREFIX = 'SectionBar'

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
  content: `${PREFIX}-content`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
  },

  [`& .${classes.title}`]: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.text.primary,
  },

  [`& .${classes.toolbar}`]: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
  },

  [`& .${classes.content}`]: {
    paddingBottom: theme.spacing(2),
  },
}))

interface Props {
  title?: string
  toolbar?: React.ReactNode
  skeleton?: boolean
  className?: string
  children?: React.ReactNode
}

const SectionBar = ({ title, toolbar, className, children, skeleton = false }: Props) => {
  const rootClassName = classNames(
    {
      [classes.root]: true,
    },
    className
  )

  return (
    <StyledBox>
      {skeleton ? (
        <Skeleton variant="rectangular" width="100%" className={rootClassName} />
      ) : (
        <>
          {title && (
            <div className={rootClassName}>
              <Typography variant="h5" className={classes.title}>
                {title}
              </Typography>
              {toolbar && <div className={classes.toolbar}>{toolbar}</div>}
            </div>
          )}
          {children && <div className={classes.content}>{children}</div>}
        </>
      )}
    </StyledBox>
  )
}

export default SectionBar
