import { Components } from '@mui/material/styles'

const MuiSvgIcon: Components['MuiSvgIcon'] = {
  styleOverrides: {
    root: {
      height: '22px',
      width: '22px',
      fontSize: '22px',
    },
  },
}

export default MuiSvgIcon
