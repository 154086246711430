import { Chip, Tooltip, alpha, Box, SxProps, Theme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { RiskCategoryIcon } from 'components'

const PREFIX = 'ParameterCategoryChip'

const classes = {
  chip: `${PREFIX}-chip`,
  chipLabel: `${PREFIX}-chipLabel`,
  bgChipLabel: `${PREFIX}-bgChipLabel`,
  environmental_impact: `${PREFIX}-environmental_impact`,
  social_impact: `${PREFIX}-social_impact`,
  operations: `${PREFIX}-operations`,
  supplier_management: `${PREFIX}-supplier_management`,
  data_source: `${PREFIX}-data_source`,
  environmentalImpact: `${PREFIX}-environmentalImpact`,
  socialImpact: `${PREFIX}-socialImpact`,
  supplierManagement: `${PREFIX}-supplierManagement`,
  dataSource: `${PREFIX}-dataSource`,
  bgChip: `${PREFIX}-bgChip`,
  Quality: `${PREFIX}-Quality`,
  quality: `${PREFIX}-quality`,
  supplier: `${PREFIX}-supplier`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.chip}`]: {
    fontWeight: 600,
    paddingLeft: 6,
    height: 15,
    margin: theme.spacing(0.3),
    marginLeft: 0,
    pt: '1px',
    borderRadius: '2px',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },

  [`& .${classes.chipLabel}`]: {
    paddingTop: 1,
    whiteSpace: 'nowrap',
    paddingLeft: '5px',
    fontWeight: 700,
    paddingRight: '8px',
    fontSize: 9,
    letterSpacing: '0.5px',
  },

  [`& .${classes.bgChipLabel}`]: {
    paddingLeft: 4,
    paddingRight: 9,
  },

  [`& .${classes.environmental_impact}`]: {
    backgroundColor: alpha(theme.palette.policy.sustainability || '#000', 0.14),
  },

  [`& .${classes.social_impact}`]: {
    backgroundColor: alpha(theme.palette.policy.humanRights || '#000', 0.07),
  },

  [`& .${classes.operations}`]: {
    backgroundColor: alpha(theme.palette.policy.operations || '#000', 0.07),
  },

  [`& .${classes.supplier_management}`]: {
    backgroundColor: alpha(theme.palette.policy.sustainability || '#000', 0.07),
  },

  [`& .${classes.data_source}`]: {
    backgroundColor: alpha(theme.palette.secondary.main || '#000', 0.15),
  },

  [`& .${classes.environmentalImpact}`]: {
    backgroundColor: alpha(theme.palette.policy.sustainability || '#000', 0.14),
  },

  [`& .${classes.socialImpact}`]: {
    backgroundColor: alpha(theme.palette.policy.humanRights || '#000', 0.07),
  },

  [`& .${classes.supplierManagement}`]: {
    backgroundColor: alpha(theme.palette.policy.sustainability || '#000', 0.07),
  },

  [`& .${classes.dataSource}`]: {
    backgroundColor: alpha(theme.palette.secondary.main || '#000', 0.15),
  },

  [`& .${classes.bgChip}`]: {
    backgroundColor: theme.palette.backgroundDark,
    color: theme.palette.common.black,
  },

  [`& .${classes.Quality}`]: {
    backgroundColor: '#fff3c8',
    color: '#e9b100',
  },

  [`& .${classes.quality}`]: {
    backgroundColor: '#fff3c8',
    color: '#e9b100',
  },
  [`& .${classes.supplier}`]: {
    backgroundColor: 'transparent',
    color: '#000',
  },
}))

interface Props {
  category?:
    | 'environmentalImpact'
    | 'socialImpact'
    | 'operations'
    | 'supplierManagement'
    | 'dataSource'
    | 'quality'
    | string
  label: React.ReactNode
  tooltip?: React.ReactNode
  style?: React.CSSProperties
  onClick?: () => void
  onDelete?: () => void
  maxWidth?: number | string
  sx?: SxProps<Theme>
}

const ParameterCategoryChip = ({
  onClick,
  onDelete,
  category,
  label,
  tooltip,
  style,
  maxWidth = '100%',
  sx,
}: Props) => {
  const newCategory =
    (category === 'Environmental impact' && 'environmental_impact') ||
    (category === 'Social impact' && 'social_impact') ||
    (category === 'Supplier management' && 'supplier_management') ||
    category
  return (
    <StyledBox sx={{ display: 'inline', ...sx }}>
      <Tooltip title={tooltip || ''}>
        <Chip
          style={{ ...style }}
          size="small"
          onClick={onClick}
          onDelete={onDelete}
          icon={newCategory ? <RiskCategoryIcon type={newCategory} size={14} /> : undefined}
          className={
            newCategory
              ? (newCategory in classes && classes[newCategory as keyof typeof classes]) || ''
              : classes.bgChip
          }
          label={label}
          classes={{
            label: category ? classes.chipLabel : classes.bgChipLabel,
            root: classes.chip,
          }}
          sx={{ maxWidth, borderRadius: '4px', ...sx }}
        />
      </Tooltip>
    </StyledBox>
  )
}

export default ParameterCategoryChip
