import { Components } from '@mui/material/styles'

const MuiTextField: Components['MuiTextField'] = {
  defaultProps: {
    variant: 'outlined',
    fullWidth: true,
  },
}

export default MuiTextField
