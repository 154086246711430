import { Components } from '@mui/material/styles'
import typography from '../typography'
import palette from '../palette'
import spacing from '../spacing'

const MuiTab: Components['MuiTab'] = {
  styleOverrides: {
    root: {
      fontWeight: 600,
      fontSize: typography.body1.fontSize,
      textTransform: 'none',
      minWidth: 72,
      marginRight: spacing,
      '&:hover': {
        color: palette.primary.main,
        opacity: 1,
      },
      '&:focus': {
        color: palette.primary.main,
      },
      '&.Mui-selected': {
        color: palette.primary.main,
      },
    },
    textColorPrimary: {
      color: palette.text.primary,
    },
  },
}

export default MuiTab
