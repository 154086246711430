import {
  DataRequestFilterV2ApiDto,
  DataRequestAnswerReviewState,
  EmailTemplateType,
} from 'typescript-axios'

const dataRequests = ['dataRequests'] as const
const dataRequestPages = [...dataRequests, 'pages'] as const
const dataRequestPage = (
  props: {
    pageSize?: number
  } & DataRequestFilterV2ApiDto
) => [...dataRequestPages, ...Object.values(props)] as const

const singleDataRequests = [...dataRequests, 'singleDataRequests'] as const
const singleDataRequest = (id: string) => [...singleDataRequests, id] as const
const dataRequestSupplierContext = (id: string) => ['dataRequestSupplierContext', id]
const dataRequestTemplate = [...dataRequests, 'dataRequestTemplate'] as const
const dataRequestTemplatePreview = ({
  supplierName,
  contactEmail,
  contactName,
}: {
  supplierName: string
  contactEmail: string
  contactName?: string
}) => [...dataRequestTemplate, supplierName, contactEmail, contactName] as const

const dataRequestQuestions = [...dataRequests, 'dataRequestQuestions']
const dataRequestQuestionsPage = (
  pageSize: number,
  ids?: string[],
  dataSourceId?: string,
  policyParameterId?: string,
  dataRequestId?: string[],
  title?: string
) =>
  [
    ...dataRequestQuestions,
    pageSize,
    ids,
    dataSourceId,
    policyParameterId,
    dataRequestId,
    title,
  ] as const
const dataRequestQuestion = (id: string) => [...dataRequestQuestions, id] as const

const dataRequestTemplates = [...dataRequests, 'dataRequestTemplates']
const dataRequestTemplatesPage = (
  pageSize: number,
  ids?: string[],
  questionIds?: string[],
  name?: string
) => [...dataRequestTemplates, pageSize, ids, questionIds, name] as const
const dataRequestTemplateId = (id: string) => [...dataRequestTemplates, id] as const

const dataRequestAnswers = [...dataRequests, 'dataRequestAnswers']
const dataRequestAnswersPage = ({
  supplierId,
  dataRequestId,
  dataRequestQuestionId,
  reviewState,
  latest,
  pageSize,
}: {
  pageSize: number
  supplierId?: string[]
  dataRequestId?: string[]
  dataRequestQuestionId?: string[]
  reviewState?: DataRequestAnswerReviewState[]
  latest?: boolean
}) =>
  [
    ...dataRequestAnswers,
    pageSize,
    supplierId,
    dataRequestId,
    dataRequestQuestionId,
    reviewState,
    latest,
  ] as const

const dataRequestAnswerDataEntries = (dataRequestId: string) => [
  ...dataRequestAnswers,
  'dataRequestAnswerDataEntries',
  dataRequestId,
]

const dataRequestAnswerFiles = (answerId: string) => [
  ...dataRequestAnswers,
  'dataRequestAnswerFiles',
  answerId,
]

const dataRequestFiles = (dataRequestId: string) => [
  ...dataRequestAnswers,
  'dataRequestFiles',
  dataRequestId,
]

const dataRequestQuestionEditValidation = (questionId: string) => [
  ...dataRequestQuestions,
  'dataRequestQuestionEditValidation',
  questionId,
]

const allDataRequestEmailTemplates = [...dataRequests, 'dataRequestEmailTemplates'] as const

const dataRequestEmailTemplates = ({
  type,
  pageSize,
}: {
  type?: EmailTemplateType
  pageSize: number
}) => [...allDataRequestEmailTemplates, 'dataRequestEmailTemplates', type, pageSize] as const

export default {
  dataRequests,
  dataRequestPages,
  singleDataRequests,
  dataRequestPage,
  singleDataRequest,
  dataRequestSupplierContext,
  dataRequestTemplate,
  dataRequestTemplatePreview,
  dataRequestQuestion,
  dataRequestQuestions,
  dataRequestQuestionsPage,
  dataRequestTemplates,
  dataRequestTemplatesPage,
  dataRequestTemplateId,
  dataRequestAnswersPage,
  dataRequestAnswerDataEntries,
  dataRequestAnswerFiles,
  dataRequestFiles,
  dataRequestQuestionEditValidation,
  dataRequestEmailTemplates,
  allDataRequestEmailTemplates,
}
