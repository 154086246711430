import apiClient from 'services/apiClient'
import { UpdateAdminHackIn, UserInviteIn } from 'typescript-axios'

export const getExperimentalAdmins = async () => {
  const { data } = await apiClient.getExperimentalAdmins()
  return data
}

export const postExperimentalAdmins = async ({ emailAddress, isAdmin }: UpdateAdminHackIn) => {
  return apiClient.postExperimentalAdmins({ emailAddress, isAdmin })
}

export const getTeamMembers = async () => {
  const { data } = await apiClient.getV1Users()
  return data
}

export const getTeamInvites = async () => {
  const { data } = await apiClient.getV1UsersInvites()
  return data
}

export const inviteTeamMember = ({ inviteeEmail }: UserInviteIn) => {
  return apiClient.postV1UsersInvites({ inviteeEmail })
}

export const deleteInvitation = ({ id }: { id: string }) => {
  return apiClient.deleteV1UsersInvitesUserinviteid(id)
}

export const startOnboardingEmailFlow = (body: string) => {
  return apiClient.postV1Signup(body)
}
