import { useQuery, UseQueryOptions } from 'react-query'
import { ResourceListGicsIndustryApiDto } from 'typescript-axios'
import { AxiosError } from 'axios'
import { getIndustries } from './api'
import queryKeys from './queryKeys'

const { industryPage } = queryKeys

export const useGetIndustries = (
  industryCode?: string[],
  options?: UseQueryOptions<
    ResourceListGicsIndustryApiDto,
    AxiosError,
    ResourceListGicsIndustryApiDto,
    ReturnType<typeof industryPage>
  >,
  includeUnassigned = true,
  subIndustriesOnly = false
) => {
  return useQuery(
    industryPage({ code: industryCode, includeUnassigned }),
    () => getIndustries({ code: industryCode, includeUnassigned }),
    {
      ...options,
      select: (data) => {
        const newData = { ...data }
        if (subIndustriesOnly) {
          newData.data = data?.data?.filter((industry) => industry.hierarchyLevel === 'SubIndustry')
        }
        return newData
      },
    }
  )
}
