import mixpanel from 'mixpanel-browser'
import config from 'constants/config'
import { useGetSettings } from 'hooks'
import { useLocation } from 'react-router-dom'
import { cernId } from 'routes/buyerRoutes'

export default () => {
  mixpanel.init(config.mixpanelProjectToken, {
    api_host: config.mixpanelProxyUrl,
    ip: true,
  })
}

export const useMixpanel = () => {
  const { pathname } = useLocation()
  const isSupplierPortal = pathname.includes('/supplier-portal/')

  const { data } = useGetSettings({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !isSupplierPortal,
  })

  const isResolved = Boolean(data || isSupplierPortal)
  const isProd = process.env.NODE_ENV === 'production'
  const isCern = data && data.data.id === cernId
  const shouldReturnMixpanel = isResolved && isProd && (!isCern || isSupplierPortal)

  return shouldReturnMixpanel ? mixpanel : undefined
}
