import { useEffect, useState, useRef } from 'react'
import { InfoOutlined as InfoIcon } from '@mui/icons-material'
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  SxProps,
  Tooltip,
  IconButton,
  alpha,
} from '@mui/material'

export interface HeadCellProps {
  disablePadding: boolean
  id: string
  label?: string | React.ReactNode
  numeric: boolean
  sortable: boolean
  hideCell?: boolean
  sticky?: boolean
  description?: string
  // had problems with sx, so...
  asFlip?: boolean
  paddingLeft?: number
}

export interface TableHeadProps {
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void
  order?: 'asc' | 'desc'
  orderBy?: string
  utilityIcon?: JSX.Element
  headCells: HeadCellProps[]
  sx?: SxProps
  small?: boolean
  noWrap?: boolean
  withBackground?: boolean
  sticky?: boolean
  selectedToolbar?: React.ReactNode
  withBorder?: boolean
}

const EnhancedTableHead = ({
  order,
  orderBy,
  onRequestSort,
  headCells,
  utilityIcon,
  sx,
  small,
  noWrap = true,
  withBackground,
  sticky,
  selectedToolbar,
  withBorder = true,
}: TableHeadProps) => {
  const stickyRef = useRef<HTMLTableSectionElement>(null)
  const [whiteBackground, setWhiteBackground] = useState(withBackground)

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) =>
    onRequestSort && onRequestSort(event, property)

  const isScrollToHeader = () => {
    const distanceToScrollTop = stickyRef?.current?.getBoundingClientRect().top
    setWhiteBackground(Boolean(distanceToScrollTop && distanceToScrollTop < 60))
  }

  useEffect(() => {
    if (sticky) document.addEventListener('scroll', isScrollToHeader)
    return () => {
      if (sticky) document.removeEventListener('scroll', isScrollToHeader)
    }
  }, [])

  return (
    <TableHead sx={{ ...sx }} ref={sticky ? stickyRef : undefined}>
      <TableRow
        sx={[
          {
            height: 36,
            backgroundColor: 'transparent',
          },
          (theme) => ({
            borderBottom: withBorder ? `3px solid ${theme.palette.background.default}` : 'none',
          }),
        ]}
      >
        {selectedToolbar ? (
          <TableCell
            colSpan={100}
            sx={[
              {
                fontSize: small ? '11px' : 'body2.fontSize',
                textTransform: small ? 'none' : 'uppercase',
                color: 'text.primary',
                fontWeight: 600,
                padding: 0,
                paddingLeft: 2,
                height: 41.5,
                transition: (theme) =>
                  theme.transitions.create('background-color', {
                    easing: theme.transitions.easing.easeInOut,
                    duration: theme.transitions.duration.shortest,
                  }),
              },
              (theme) => ({
                backgroundColor: `${theme.palette.common.white} !important`,
              }),
              () =>
                noWrap
                  ? {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }
                  : {},
              () =>
                sticky
                  ? {
                      position: 'sticky',
                      top: 62,
                      zIndex: 999,
                      backgroundColor: 'transparent',
                    }
                  : {},
            ]}
          >
            {selectedToolbar}
          </TableCell>
        ) : (
          <>
            {utilityIcon && (
              <TableCell
                padding="none"
                sx={[
                  {
                    width: 10,
                    fontSize: small ? '11px' : 'body2.fontSize',
                    textTransform: small ? 'none' : 'uppercase',
                    color: 'text.primary',
                    fontWeight: 600,
                    paddingTop: 1,
                    paddingBottom: 1,
                    transition: (theme) =>
                      theme.transitions.create('background-color', {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.shortest,
                      }),
                  },
                  () =>
                    noWrap
                      ? {
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }
                      : {},
                  (theme) =>
                    whiteBackground
                      ? {
                          backgroundColor: `${theme.palette.common.white} !important`,
                        }
                      : {},
                  () =>
                    sticky
                      ? {
                          position: 'sticky',
                          top: 62,
                          zIndex: 999,
                          backgroundColor: 'transparent',
                        }
                      : {},
                ]}
              >
                {utilityIcon}
              </TableCell>
            )}
            {headCells
              .filter((headCell) => !headCell.hideCell)
              .map((headCell, index) => {
                const sortingActive = orderBy === headCell.id
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sx={[
                      {
                        paddingLeft: headCell.paddingLeft || 1,
                        width: 10,
                        fontSize: small ? '11px' : 'body2.fontSize',
                        textTransform: small ? 'none' : 'uppercase',
                        color: 'text.primary',
                        fontWeight: 600,
                        paddingTop: 1,
                        paddingBottom: 1,
                        transition: (theme) =>
                          theme.transitions.create('background-color', {
                            easing: theme.transitions.easing.easeInOut,
                            duration: theme.transitions.duration.shortest,
                          }),
                      },
                      (theme) =>
                        headCell.asFlip
                          ? {
                              backgroundColor: alpha(theme.palette.primary.main, 0.15),
                              borderBottomLeftRadius: 0,
                              borderBottomRightRadius: '0px !important',
                              borderTopRightRadius: '10px',
                              borderTopLeftRadius: '10px',
                            }
                          : {},
                      () =>
                        noWrap
                          ? {
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }
                          : {},
                      () =>
                        headCell.sticky
                          ? {
                              position: 'sticky',
                              left: 0,
                              backgroundColor: 'common.white',
                            }
                          : {},
                      (theme) =>
                        whiteBackground
                          ? {
                              backgroundColor: `${theme.palette.common.white} !important`,
                            }
                          : {},
                      () =>
                        sticky
                          ? {
                              position: 'sticky',
                              top: 62,
                              zIndex: 999,
                              backgroundColor: 'transparent',
                            }
                          : {},
                      () => (index === 0 && utilityIcon ? { paddingLeft: 0 } : {}),
                    ]}
                    sortDirection={sortingActive ? order : false}
                  >
                    {headCell.sortable ? (
                      <TableSortLabel
                        active={sortingActive}
                        direction={sortingActive ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                        sx={[
                          (theme) => ({
                            '& .Mui-active': {
                              color: `${theme.palette.primary.main} !important`,
                              transition: theme.transitions.create('opacity', {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.shortest,
                              }),
                              '&:hover': {
                                opacity: 0.7,
                              },
                            },
                            '& .Mui-icon': {
                              color: `${theme.palette.primary.main} !important`,
                              transition: theme.transitions.create('opacity', {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.shortest,
                              }),
                              '&:hover': {
                                opacity: 0.7,
                              },
                            },
                          }),
                        ]}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    ) : (
                      <>
                        {headCell.label}{' '}
                        {headCell.description && (
                          <Tooltip title={headCell.description}>
                            <IconButton size="small" edge="start" sx={{ mt: -0.5, ml: '-2px' }}>
                              <InfoIcon sx={{ width: 17, height: 17 }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </TableCell>
                )
              })}
          </>
        )}
      </TableRow>
    </TableHead>
  )
}

export default EnhancedTableHead
