import { useInfiniteQuery } from 'react-query'
import { ResourceListSupplierCategoryApiDto } from 'typescript-axios'
import { AxiosError } from 'axios'
import { getCategories } from './api'
import queryKeys from './queryKeys'

const { categoryPage } = queryKeys

// eslint-disable-next-line
export const useGetInfiniteCategories = (pageSize = 200, options?: { enabled?: boolean }) => {
  return useInfiniteQuery<
    ResourceListSupplierCategoryApiDto,
    AxiosError,
    ResourceListSupplierCategoryApiDto,
    ReturnType<typeof categoryPage>
  >(
    categoryPage({ pageSize }),
    ({ pageParam = 1 }) => getCategories({ page: pageParam, pageSize }),
    {
      getNextPageParam: (lastPage) => lastPage.paging.nextPage,
      ...options,
    }
  )
}
