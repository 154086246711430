const allCountries = ['countries'] as const
const countriesList = [...allCountries, 'list'] as const
const allSingleCountries = [...allCountries, 'country'] as const
const singleCountry = (id: string) => [...allSingleCountries, id] as const

const allRegions = ['regions'] as const
const regionsList = [...allRegions, 'list'] as const
const allSingleRegions = [...allRegions, 'region'] as const
const singleRegion = (regionName: string) => [...allSingleRegions, regionName] as const

export default {
  allCountries,
  countriesList,
  allSingleCountries,
  singleCountry,
  allRegions,
  regionsList,
  allSingleRegions,
  singleRegion,
}
