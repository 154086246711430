import { useState, useEffect } from 'react'
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Grow,
  Box,
  Theme,
  SxProps,
} from '@mui/material'

interface Props {
  step: number
  requiredStep: number
  totalSteps: number
  staticStep?: boolean
  stepLabel: string
  children?: React.ReactNode
  maxWidth?: number
  withGrow?: boolean
  withStepCircle?: boolean
  sx?: SxProps<Theme>
}

const StepContainer = ({
  step,
  requiredStep,
  totalSteps,
  stepLabel,
  staticStep = false,
  children,
  maxWidth,
  withGrow = true,
  withStepCircle = true,
  sx,
}: Props) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (staticStep && step >= requiredStep) {
      setShow(true)
    } else if (!staticStep && step === requiredStep) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [step, staticStep, requiredStep, show])

  const content = (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        margin: 'auto',
        paddingRight: 2,
        paddingLeft: 2,
        paddingBottom: 6,
        maxWidth: maxWidth || 665,
        ...sx,
      }}
    >
      <List>
        <ListItem disableGutters>
          <ListItemAvatar
            sx={{
              minWidth: 40,
            }}
          >
            <Avatar
              sx={{
                width: 30,
                opacity: withStepCircle ? 1 : 0,
                height: 30,
                borderRadius: '100%',
                borderLeft: `7px solid #dc685f`,
                fontSize: 14,
                fontWeight: 600,
                color: 'common.white',
                backgroundColor: requiredStep < totalSteps ? 'primary.main' : 'success.main',
              }}
            >
              <Box component="span" sx={{ marginLeft: -0.5 }}>
                {requiredStep + 1}
              </Box>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<Box sx={{ fontSize: 'h4.fontSize', fontWeight: 600 }}>{stepLabel}</Box>}
            disableTypography
          />
        </ListItem>
      </List>
      <Box
        sx={{
          marginLeft: 5,
          paddingRight: '2px',
        }}
      >
        {children}
      </Box>
    </Box>
  )

  return (
    <Box>
      {withGrow ? (
        <Grow in={show} mountOnEnter unmountOnExit>
          {content}
        </Grow>
      ) : (
        content
      )}
    </Box>
  )
}

export default StepContainer
