import { ParameterWithTopLevelCategoryProps } from 'hooks'

export default (parameters: (ParameterWithTopLevelCategoryProps | undefined)[]) => {
  return parameters.reduce(
    (prev, curr) => {
      if (!curr || !curr.topLevelCategory) return prev
      return {
        ...prev,
        [curr.topLevelCategory.name]: prev[curr.topLevelCategory.name]
          ? {
              count: prev[curr.topLevelCategory.name].count + 1,
              parameterNames: [...prev[curr.topLevelCategory.name].parameterNames, curr.name],
            }
          : {
              count: 1,
              parameterNames: [curr.name],
            },
      }
    },
    {} as {
      [key: string]: {
        count: number
        parameterNames: string[]
      }
    }
  )
}
