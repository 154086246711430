import React from 'react'
import { styled } from '@mui/material/styles'
import { ListItem, ListItemIcon, ListItemText, List, Fade, Skeleton } from '@mui/material'
import classNames from 'classnames'

const PREFIX = 'DrawerHeader'

const classes = {
  list: `${PREFIX}-list`,
  listMargin: `${PREFIX}-listMargin`,
  primary: `${PREFIX}-primary`,
  secondary: `${PREFIX}-secondary`,
  listItemText: `${PREFIX}-listItemText`,
  icon: `${PREFIX}-icon`,
  largeIcon: `${PREFIX}-largeIcon`,
}

const StyledList = styled(List)(({ theme }) => ({
  [`& .${classes.list}`]: {
    padding: '0px',
  },

  [`& .${classes.listMargin}`]: {
    marginLeft: theme.spacing(3),
  },

  [`& .${classes.primary}`]: {
    fontSize: 16,
    lineHeight: '25px',
    fontWeight: 600,
  },

  [`& .${classes.secondary}`]: {
    fontSize: 14,
    lineHeight: '20px',
  },

  [`& .${classes.listItemText}`]: {
    marginTop: 0,
    marginLeft: theme.spacing(2),
  },

  [`& .${classes.icon}`]: {
    maxWidth: 60,
    maxHeight: 60,
    width: 60,
    height: 60,
  },

  [`& .${classes.largeIcon}`]: {
    maxWidth: 100,
    maxHeight: 100,
    width: 100,
    height: 100,
  },
}))

interface Props {
  primary?: string
  secondary?: React.ReactNode
  icon: JSX.Element
  loading?: boolean
  size?: 'medium' | 'large'
  withMargin?: boolean
}

const DrawerHeader = ({
  primary,
  secondary,
  icon,
  loading,
  size = 'medium',
  withMargin = true,
}: Props) => {
  const iconWithProps = React.cloneElement(icon, {
    className: `${size === 'medium' ? classes.icon : classes.largeIcon} ${icon.props.className}`,
  })

  const listClassName = classNames([classes.list], {
    [classes.listMargin]: withMargin,
  })

  return (
    <StyledList className={listClassName}>
      <ListItem disableGutters>
        <ListItemIcon>
          {loading ? (
            <Skeleton
              variant="circular"
              className={size === 'medium' ? classes.icon : classes.largeIcon}
            />
          ) : (
            <Fade in={!loading}>{iconWithProps}</Fade>
          )}
        </ListItemIcon>
        <ListItemText
          className={classes.listItemText}
          primary={loading ? <Skeleton variant="text" width="60%" height={56} /> : primary}
          secondary={loading ? <Skeleton variant="text" width={112} /> : secondary}
          secondaryTypographyProps={{
            component: 'span',
          }}
          classes={{ primary: classes.primary, secondary: classes.secondary }}
        />
      </ListItem>
    </StyledList>
  )
}

export default DrawerHeader
