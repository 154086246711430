const black = '#202121'
const white = '#FFFFFF'

export default {
  common: {
    black,
    white,
    neutral: '#E4E7EB',
    muted: '#9586A5',
  },
  primary: {
    contrastText: white,
    main: '#F3766B',
    light: '#fef1f0',
    dark: '#e26f6a',
  },
  secondary: {
    contrastText: white,
    main: '#10253e',
    light: '#e4edf9',
    dark: '#6468a2',
  },
  success: {
    contrastText: white,
    main: '#60b386',
    light: '#7cc69d',
    dark: '#009D62',
  },
  info: {
    contrastText: white,
    main: '#66ADD3',
    light: '#eff6fa',
    dark: '#2E6B8C',
  },
  warning: {
    contrastText: white,
    main: '#ffcd2e',
    light: '#ffedb5',
    dark: '#E9B100',
  },
  // https://material.io/design/color/color-usage.html#meaning
  // "Since red is brand color, use alternative error colors (yellow)"
  error: {
    contrastText: white,
    main: '#E9B100',
    light: '#ffedb5',
    dark: '#A83B3B',
  },
  text: {
    primary: '#050505',
    secondary: '#666',
    disabled: '#A6B1BB',
    hint: '#d6d6d6',
  },
  background: {
    default: '#F7EFE5',
    paper: white,
  },
  backgroundDark: '#edddca',
  divider: '#d6d6d6',
  policy: {
    sustainability: '#60b386',
    humanRights: '#e516cd',
    diversity: '#000',
    financialRisk: '#266baf',
    logisticsRisk: '#000',
    operations: '#266baf',
  },
}
