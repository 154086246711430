import MuiButton from './MuiButton'
import MuiChip from './MuiChip'
import MuiIconButton from './MuiIconButton'
import MuiLinearProgress from './MuiLinearProgress'
import MuiListItemIcon from './MuiListItemIcon'
import MuiTextField from './MuiTextField'
import MuiOutlinedInput from './MuiOutlinedInput'
import MuiSvgIcon from './MuiSvgIcon'
import MuiDrawer from './MuiDrawer'
import MuiInputAdornment from './MuiInputAdornment'
import MuiTab from './MuiTab'
import MuiTable from './MuiTable'
import MuiTableCell from './MuiTableCell'
import MuiTableRow from './MuiTableRow'
import MuiMenu from './MuiMenu'
import MuiMenuItem from './MuiMenuItem'
import MuiTablePagination from './MuiTablePagination'
import MuiInputLabel from './MuiInputLabel'
import MuiListItem from './MuiListItem'
import MuiDialogContent from './MuiDialogContent'
import MuiFormControlLabel from './MuiFormControlLabel'
import MuiFormHelperText from './MuiFormHelperText'
import MuiSkeleton from './MuiSkeleton'
import MuiListItemText from './MuiListItemText'
import MuiTooltip from './MuiTooltip'
import MuiCardHeader from './MuiCardHeader'
import MuiToolbar from './MuiToolbar'
import MuiListItemAvatar from './MuiListItemAvatar'
import MuiAvatar from './MuiAvatar'
import MuiPaper from './MuiPaper'
import MuiDialogTitle from './MuiDialogTitle'
import MuiAutocomplete from './MuiAutocomplete'
import MuiButtonBase from './MuiButtonBase'
import MuiDialogContentText from './MuiDialogContentText'
import MuiCheckbox from './MuiCheckbox'
import MuiAlert from './MuiAlert'
import MuiAvatarGroup from './MuiAvatarGroup'

const components = {
  MuiButtonBase,
  MuiAlert,
  MuiAvatarGroup,
  MuiCheckbox,
  MuiDialogContentText,
  MuiButton,
  MuiChip,
  MuiListItemText,
  MuiDialogTitle,
  MuiTablePagination,
  MuiIconButton,
  MuiInputAdornment,
  MuiLinearProgress,
  MuiListItemIcon,
  MuiTextField,
  MuiDrawer,
  MuiDialogContent,
  MuiCardHeader,
  MuiOutlinedInput,
  MuiSvgIcon,
  MuiTable,
  MuiTab,
  MuiTableCell,
  MuiMenu,
  MuiTableRow,
  MuiMenuItem,
  MuiInputLabel,
  MuiTooltip,
  MuiListItem,
  MuiFormControlLabel,
  MuiFormHelperText,
  MuiSkeleton,
  MuiToolbar,
  MuiListItemAvatar,
  MuiAvatar,
  MuiPaper,
  MuiAutocomplete,
}

export default components
