import { Components } from '@mui/material/styles'
import typography from '../typography'

const MuiListItemText: Components['MuiListItemText'] = {
  styleOverrides: {
    primary: {
      fontWeight: 600,
      fontSize: typography.body1.fontSize,
    },
  },
}

export default MuiListItemText
