import { useEffect, useState } from 'react'
import { Typography, Button, CircularProgress, Box } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { Paper, PaperContent } from 'components'
import { setWithExpiry, getWithExpiry } from './chunkExpiry'

const EmojiFaces = () => {
  return (
    <Box
      sx={{
        fontSize: '70px',
        textAlign: 'center',
        lineHeight: '1.5',
        '&::before': {
          display: 'block',
          content: '"🥺"',
          animation: `$emojiFacesAnim 2.5s infinite`,
        },
        '@keyframes emojiFacesAnim': {
          '0%': {
            content: '"🥺"',
          },
          '100%': {
            content: '"😭"',
          },
        },
      }}
    />
  )
}

// Fallback will retrieve helper props from Sentry.ErrorBoundary as described
// > https://docs.sentry.io/platforms/javascript/guides/react/components/errorboundary
type Props = {
  error: Error
  resetError: () => void
}

const Fallback = ({ error, resetError }: Props) => {
  const history = useHistory()
  /** avoid glitchy UI rendering while checking if chunk failed because of new deploy */
  const [showUi, setShowUi] = useState(false)

  const navigateBack = () => {
    resetError()
    history.push('/getting-started')
  }

  useEffect(() => {
    // If error is due to chunk failing to be retrieved - most likely because of new deploy/different hashes in filename compared to what's cached - reload the page
    const chunkFailedMessage = /Loading chunk [\d]+ failed/
    if (error?.message && chunkFailedMessage.test(error.message)) {
      setShowUi(false)
      // prevent infinite refresh
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000)
        window.location.reload()
      }
    } else {
      setShowUi(true)
    }
  }, [])

  return showUi ? (
    <Paper sx={{ maxWidth: '450px', margin: 'auto', marginTop: '50px' }}>
      <PaperContent>
        <EmojiFaces />
        <Typography variant="h3" align="center" gutterBottom>
          Oops! An unexpected error has occurred.
        </Typography>
        <Typography variant="h6" align="center" style={{ margin: '1rem' }}>
          Our team has been notified and is working hard on fixing the issue. You don&apos;t have to
          do anything from here.
        </Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 4,
            marginBottom: 16,
          }}
        >
          <Button onClick={navigateBack} color="primary" variant="text">
            Back to the app
          </Button>
        </div>
      </PaperContent>
    </Paper>
  ) : (
    <Box sx={{ textAlign: 'center', paddingTop: 6 }}>
      <CircularProgress />
    </Box>
  )
}

const FallbackWrapFC = ({ error, resetError }: Props) => {
  return <Fallback error={error} resetError={resetError} />
}

export default FallbackWrapFC
