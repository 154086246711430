import React from 'react'
import { styled } from '@mui/material/styles'
import classNames from 'classnames'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Box,
  DialogProps,
  IconButton,
  alpha,
  SxProps,
  Theme,
} from '@mui/material'
import { ArrowBackRounded as ArrowBackIcon, CloseRounded as CloseIcon } from '@mui/icons-material'

const PREFIX = 'DialogWrapper'

const classes = {
  paper: `${PREFIX}-paper`,
  noContentPadding: `${PREFIX}-noContentPadding`,
  contentPadding: `${PREFIX}-contentPadding`,
  backBtn: `${PREFIX}-backBtn`,
  closeIconButton: `${PREFIX}-closeIconButton`,
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  [`& .${classes.noContentPadding}`]: {
    padding: '0px !important',
  },

  [`& .${classes.contentPadding}`]: {
    paddingTop: '8px !important',
  },

  [`& .${classes.backBtn}`]: {
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(-1.5),
    marginBottom: theme.spacing(-1),
    color: theme.palette.common.black,
    marginRight: theme.spacing(),
  },

  [`& .${classes.closeIconButton}`]: {
    color: theme.palette.common.black,
    marginTop: theme.spacing(-1.5),
    marginBottom: theme.spacing(-1),
    marginRight: theme.spacing(-1.5),
  },
}))

interface Props extends DialogProps {
  open: boolean
  children?: React.ReactNode
  title?: string
  buttons?: JSX.Element
  withDivider?: boolean
  description?: string | JSX.Element
  onClose: () => void
  contentPadding?: boolean
  onFormSubmit?: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  withForm?: boolean
  className?: string
  paperClassName?: string
  contentClassName?: string
  /** Disables maxWidth prop and controls current width with animation. */
  currentWidth?: number | 'fit-content'
  secondTitle?: React.ReactNode
  back?: () => void
  withClose?: boolean
  icon?: React.ReactNode
  titleLink?: () => void
  contentRef?: any
  contentSx?: SxProps<Theme>
}

const DialogWrapper = ({
  open,
  maxWidth = 'sm',
  onClose,
  title,
  description,
  buttons,
  icon,
  withDivider = false,
  contentPadding = true,
  children,
  onFormSubmit,
  titleLink,
  secondTitle,
  withForm = true,
  className,
  paperClassName,
  contentClassName,
  currentWidth,
  withClose,
  back,
  contentRef,
  contentSx,
  ...rest
}: Props) => {
  const paperClassNames = classNames([classes.paper], paperClassName)

  return (
    <StyledDialog
      open={open}
      fullWidth
      maxWidth={currentWidth !== undefined ? false : maxWidth}
      onClose={onClose}
      className={className}
      classes={{ paper: paperClassNames }}
      PaperProps={{
        style: {
          width: currentWidth || '100%',
        },
      }}
      {...rest}
    >
      {title && (
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {back && (
              <IconButton className={classes.backBtn} onClick={() => back()}>
                <ArrowBackIcon />
              </IconButton>
            )}
            <Box
              onClick={titleLink}
              sx={{
                display: 'flex',
                alignItem: 'center',
                paddingLeft: titleLink ? 0.5 : 0,
                paddingRight: titleLink ? 0.5 : 0,
                margin: titleLink ? -0.4 : 0,
                '&:hover': titleLink && {
                  cursor: 'pointer',
                  borderRadius: 1,
                  backgroundColor: (theme) => alpha(theme.palette.common.black, 0.04),
                },
              }}
            >
              {icon && <Box sx={{ mr: 1.5, display: 'flex', alignItems: 'center' }}>{icon}</Box>}
              {title}
            </Box>
            {secondTitle}
          </Box>
          {withClose && (
            <IconButton onClick={onClose} className={classes.closeIconButton}>
              <CloseIcon color="inherit" />
            </IconButton>
          )}
        </DialogTitle>
      )}
      {withForm ? (
        <form onSubmit={onFormSubmit}>
          <DialogContent
            sx={{ ...contentSx }}
            dividers={withDivider}
            className={`${contentPadding ? classes.contentPadding : classes.noContentPadding} ${
              contentClassName || ''
            }`}
            ref={contentRef}
          >
            {description && (
              <DialogContentText sx={{ mt: -1 }} component="div">
                <span style={{ display: 'block' }}>{description}</span>
              </DialogContentText>
            )}
            {children}
          </DialogContent>
          {buttons && <DialogActions>{buttons}</DialogActions>}
        </form>
      ) : (
        <>
          <DialogContent
            sx={{ ...contentSx }}
            dividers={withDivider}
            ref={contentRef}
            className={`${contentPadding ? classes.contentPadding : classes.noContentPadding} ${
              contentClassName || ''
            }`}
          >
            {description && (
              <DialogContentText component="div" sx={{ mt: -1 }}>
                {description}
              </DialogContentText>
            )}
            {children}
          </DialogContent>
          {buttons && <DialogActions>{buttons}</DialogActions>}
        </>
      )}
    </StyledDialog>
  )
}

export default DialogWrapper
