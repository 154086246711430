import React from 'react'
import { styled } from '@mui/material/styles'
import classNames from 'classnames'
import {
  Typography,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  Box,
  Tooltip,
  Badge,
  SxProps,
  Theme,
  Skeleton,
} from '@mui/material'
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material'
import InnerItem from './InnerItem'

const PREFIX = 'ListItemWrapper'

const classes = {
  smallestListItem: `${PREFIX}-smallestListItem`,
  smallListItem: `${PREFIX}-smallListItem`,
  mediumListItem: `${PREFIX}-mediumListItem`,
  largeListItem: `${PREFIX}-largeListItem`,
  cta: `${PREFIX}-cta`,
  chevron: `${PREFIX}-chevron`,
  avatar: `${PREFIX}-avatar`,
  selectedAvatar: `${PREFIX}-selectedAvatar`,
  smallest: `${PREFIX}-smallest`,
  small: `${PREFIX}-small`,
  medium: `${PREFIX}-medium`,
  large: `${PREFIX}-large`,
  listItemAvatar: `${PREFIX}-listItemAvatar`,
  hoverStyle: `${PREFIX}-hoverStyle`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.smallestListItem}`]: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },

  [`& .${classes.smallListItem}`]: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },

  [`& .${classes.mediumListItem}`]: {
    minHeight: '61px',
    paddingTop: theme.spacing(0.6),
    paddingBottom: theme.spacing(0.6),
    paddingLeft: theme.spacing(2),
  },

  [`& .${classes.largeListItem}`]: {
    minHeight: '70px',
    paddingTop: theme.spacing(0.6),
    paddingBottom: theme.spacing(0.6),
  },

  [`& .${classes.cta}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${classes.chevron}`]: {
    color: theme.palette.text.secondary,
    maxWidth: 22,
    maxHeight: 22,
    width: 22,
    height: 22,
  },

  [`& .${classes.avatar}`]: {
    color: theme.palette.text.primary,
    // backgroundColor: theme.palette.divider,
    backgroundColor: theme.palette.backgroundDark,
  },

  [`& .${classes.selectedAvatar}`]: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.background.default,
  },

  [`& .${classes.smallest}`]: {
    width: 30,
    height: 30,
    '& svg': {
      width: 20,
      height: 20,
    },
  },

  [`& .${classes.small}`]: {
    width: 36,
    height: 36,
  },

  [`& .${classes.medium}`]: {
    width: 40,
    height: 40,
  },

  [`& .${classes.large}`]: {
    width: 46,
    height: 46,
  },

  [`& .${classes.listItemAvatar}`]: {
    minWidth: 48,
  },

  [`& .${classes.hoverStyle}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    textDecoration: 'none',
  },
}))

interface Props {
  loading?: boolean
  disabled?: boolean
  tooltip?: string
  icon?: React.ReactNode
  primaryText?: string | React.ReactNode
  secondaryText?: string | React.ReactNode
  goToText?: string | React.ReactNode
  secondaryAction?: React.ReactNode
  onClick?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  noIcon?: boolean
  skeleton?: boolean
  avatarBackgroundColor?: string
  withChevron?: boolean
  size?: 'smallest' | 'small' | 'medium' | 'large'
  listItemSize?: 'smallestListItem' | 'smallListItem' | 'mediumListItem' | 'largeListItem'
  alignItems?: 'flex-start' | 'center'
  selected?: boolean
  marginBottom?: number
  hasHoverStyle?: boolean
  className?: string
  sx?: SxProps<Theme>
  actionComponent?: React.ReactNode
}

const ListItemWrapper = ({
  loading,
  skeleton,
  disabled,
  tooltip = '',
  icon,
  primaryText,
  secondaryText,
  goToText,
  secondaryAction,
  onClick,
  noIcon,
  avatarBackgroundColor,
  withChevron,
  size = 'medium',
  listItemSize = 'mediumListItem',
  selected,
  marginBottom,
  alignItems = 'center',
  hasHoverStyle,
  className,
  sx,
  actionComponent,
}: Props) => {
  const avatarRoot = classNames({
    [classes[size]]: true,
    [classes.avatar]: !selected,
    [classes.selectedAvatar]: selected,
  })

  return (
    <StyledBox>
      {skeleton ? (
        <ListItem className={classes[listItemSize]} sx={{ ...sx }}>
          <ListItemAvatar>
            <Skeleton variant="circular" className={classes[size]} />
          </ListItemAvatar>
          <ListItemText
            primary={<Skeleton height={14} width="60%" style={{ marginBottom: 6 }} />}
            secondary={<Skeleton height={8} width="40%" />}
          />
        </ListItem>
      ) : (
        <InnerItem
          onClick={onClick}
          loading={loading}
          disabled={disabled}
          listItemSize={listItemSize}
          selected={selected}
          marginBottom={marginBottom}
          alignItems={alignItems}
          hasHoverStyle={hasHoverStyle}
          className={className}
        >
          {!noIcon && (
            <Tooltip placement="right" title={tooltip || ''} arrow>
              <ListItemAvatar style={{ minWidth: size === 'smallest' ? 42 : 48 }}>
                <Avatar
                  className={avatarRoot}
                  style={
                    (avatarBackgroundColor && { backgroundColor: avatarBackgroundColor }) || {}
                  }
                >
                  <Badge color="primary" variant="dot" invisible={Boolean(!tooltip) || loading}>
                    {loading ? <CircularProgress size={18} /> : icon}
                  </Badge>
                </Avatar>
              </ListItemAvatar>
            </Tooltip>
          )}
          <ListItemText
            primary={primaryText}
            secondary={loading ? 'Loading...' : secondaryText || undefined}
            primaryTypographyProps={{
              style: {
                fontWeight: 600,
              },
            }}
          />
          {goToText && (
            <Typography
              variant="body2"
              color="textSecondary"
              display="inline"
              style={{ whiteSpace: 'nowrap', marginLeft: 8 }}
            >
              {goToText}
            </Typography>
          )}
          {withChevron && <ChevronRightIcon className={classes.chevron} />}
          {secondaryAction && <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>}
          {actionComponent}
        </InnerItem>
      )}
    </StyledBox>
  )
}

export default ListItemWrapper
