import { useQuery, UseQueryOptions, useInfiniteQuery } from 'react-query'
import {
  ResourceListDataRequestV2ApiDto,
  ResourceFoundDataRequestV2ApiDto,
  ResourceListDataRequestQuestionV2ApiDto,
  ResourceListDataRequestTemplateV3ApiDto,
  ResourceListDataRequestAnswerV2ApiDto,
  ResourceListSupplierUploadedFileDto,
  ResourceListDataEntryV2ApiDto,
  DataRequestFilterV2ApiDto,
  DataRequestAnswerReviewState,
  ResourceFoundDataRequestQuestionV2ValidationApiDto,
  ResourceListEmailTemplateApiDto,
  EmailTemplateType,
} from 'typescript-axios'
import { AxiosError } from 'axios'
import {
  getDataRequests,
  getDataRequest,
  getDataRequestQuestions,
  getDataRequestTemplates,
  getDataRequestAnswers,
  getDataRequestAnswersDataEntries,
  getDataRequestAnswersFiles,
  getAllDataRequestFiles,
  getDataRequestQuestionEditValidation,
  getEmailTemplates,
} from './api'
import queryKeys from './queryKeys'

const ROWS_PER_PAGE = 15
const {
  singleDataRequest,
  dataRequestPage,
  dataRequestEmailTemplates,
  dataRequestQuestionsPage,
  dataRequestTemplatesPage,
  dataRequestAnswersPage,
  dataRequestAnswerFiles,
  dataRequestAnswerDataEntries,
  dataRequestFiles,
  dataRequestQuestionEditValidation,
} = queryKeys

export const useGetDataRequests = ({
  ids,
  supplierFilter,
  dataRequestTemplateId,
  dataRequestQuestionIdsIn,
  dueDateFrom,
  dueDateTo,
  state,
  pageSize = ROWS_PER_PAGE,
  enabled,
}: {
  pageSize?: number
  enabled?: boolean
} & DataRequestFilterV2ApiDto) => {
  return useInfiniteQuery<
    ResourceListDataRequestV2ApiDto,
    AxiosError,
    ResourceListDataRequestV2ApiDto,
    ReturnType<typeof dataRequestPage>
  >(
    dataRequestPage({
      ids,
      supplierFilter,
      dataRequestTemplateId,
      dataRequestQuestionIdsIn,
      dueDateFrom,
      dueDateTo,
      pageSize,
      state,
    }),
    ({ pageParam = 1 }) =>
      getDataRequests({
        ids,
        supplierFilter,
        dataRequestTemplateId,
        dataRequestQuestionIdsIn,
        state,
        dueDateFrom,
        dueDateTo,
        page: pageParam,
        pageSize,
      }),
    {
      getNextPageParam: (lastPage) => lastPage.paging.nextPage,
      enabled,
    }
  )
}

export const useGetDataRequestEmailTemplates = ({
  type,
  pageSize,
}: {
  type?: EmailTemplateType
  pageSize: number
}) => {
  return useInfiniteQuery<
    ResourceListEmailTemplateApiDto,
    AxiosError,
    ResourceListEmailTemplateApiDto,
    ReturnType<typeof dataRequestEmailTemplates>
  >(
    dataRequestEmailTemplates({ type, pageSize }),
    ({ pageParam = 1 }) => getEmailTemplates({ type, page: pageParam, pageSize }),
    {
      getNextPageParam: (lastPage) => lastPage.paging.nextPage,
    }
  )
}

export const useGetDataRequest = (
  id: string,
  options?: UseQueryOptions<
    ResourceFoundDataRequestV2ApiDto,
    AxiosError,
    ResourceFoundDataRequestV2ApiDto,
    ReturnType<typeof singleDataRequest>
  >
) => {
  return useQuery(singleDataRequest(id), () => getDataRequest({ id }), {
    ...options,
  })
}

export const useGetDataRequestQuestions = ({
  pageSize,
  ids,
  dataSourceId,
  policyParameterId,
  dataRequestId,
  title,
  enabled,
}: {
  pageSize: number
  ids?: string[]
  dataSourceId?: string
  policyParameterId?: string
  dataRequestId?: string[]
  title?: string
  enabled?: boolean
}) => {
  return useInfiniteQuery<
    ResourceListDataRequestQuestionV2ApiDto,
    AxiosError,
    ResourceListDataRequestQuestionV2ApiDto,
    ReturnType<typeof dataRequestQuestionsPage>
  >(
    dataRequestQuestionsPage(pageSize, ids, dataSourceId, policyParameterId, dataRequestId, title),
    ({ pageParam = 1 }) =>
      getDataRequestQuestions({
        page: pageParam,
        pageSize,
        ids,
        dataSourceId,
        policyParameterId,
        dataRequestId,
        title,
      }),
    {
      getNextPageParam: (lastPage) => lastPage.paging.nextPage,
      enabled,
    }
  )
}

export const useGetDataRequestTemplates = ({
  pageSize,
  ids,
  questionIds,
  name,
  enabled,
}: {
  pageSize: number
  ids?: string[]
  questionIds?: string[]
  name?: string
  enabled?: boolean
}) => {
  return useInfiniteQuery<
    ResourceListDataRequestTemplateV3ApiDto,
    AxiosError,
    ResourceListDataRequestTemplateV3ApiDto,
    ReturnType<typeof dataRequestTemplatesPage>
  >(
    dataRequestTemplatesPage(pageSize, ids, questionIds, name),
    ({ pageParam = 1 }) =>
      getDataRequestTemplates({ page: pageParam, pageSize, ids, questionIds, name }),
    {
      getNextPageParam: (lastPage) => lastPage.paging.nextPage,
      enabled,
    }
  )
}

export const useGetDataRequestAnswers = ({
  supplierId,
  dataRequestId,
  dataRequestQuestionId,
  reviewState,
  latest,
  pageSize,
  enabled,
}: {
  pageSize: number
  supplierId?: string[]
  dataRequestId?: string[]
  dataRequestQuestionId?: string[]
  reviewState?: DataRequestAnswerReviewState[]
  latest?: boolean
  enabled?: boolean
}) => {
  return useInfiniteQuery<
    ResourceListDataRequestAnswerV2ApiDto,
    AxiosError,
    ResourceListDataRequestAnswerV2ApiDto,
    ReturnType<typeof dataRequestAnswersPage>
  >(
    dataRequestAnswersPage({
      supplierId,
      dataRequestId,
      dataRequestQuestionId,
      reviewState,
      latest,
      pageSize,
    }),
    ({ pageParam = 1 }) =>
      getDataRequestAnswers({
        page: pageParam,
        pageSize,
        supplierId,
        dataRequestId,
        dataRequestQuestionId,
        reviewState,
        latest,
      }),
    {
      getNextPageParam: (lastPage) => lastPage.paging.nextPage,
      enabled,
    }
  )
}

export const useGetDataRequestAnswersDataEntries = (
  dataRequestId: string,
  options?: UseQueryOptions<
    ResourceListDataEntryV2ApiDto,
    AxiosError,
    ResourceListDataEntryV2ApiDto,
    ReturnType<typeof dataRequestAnswerDataEntries>
  >
) => {
  return useQuery(
    dataRequestAnswerDataEntries(dataRequestId),
    () => getDataRequestAnswersDataEntries({ dataRequestId }),
    {
      ...options,
    }
  )
}

export const useGetDataRequestAnswersFiles = (
  answerId: string,
  options?: UseQueryOptions<
    ResourceListSupplierUploadedFileDto,
    AxiosError,
    ResourceListSupplierUploadedFileDto,
    ReturnType<typeof dataRequestAnswerDataEntries>
  >
) => {
  return useQuery(
    dataRequestAnswerFiles(answerId),
    () => getDataRequestAnswersFiles({ answerId }),
    {
      ...options,
    }
  )
}

export const useGetAllDataRequestFiles = (
  dataRequestId: string,
  options?: UseQueryOptions<
    ResourceListSupplierUploadedFileDto,
    AxiosError,
    ResourceListSupplierUploadedFileDto,
    ReturnType<typeof dataRequestAnswerDataEntries>
  >
) => {
  return useQuery(
    dataRequestFiles(dataRequestId),
    () => getAllDataRequestFiles({ dataRequestId }),
    {
      ...options,
    }
  )
}

export const useGetDataRequestQuestionEditValidation = (
  questionId: string,
  options?: UseQueryOptions<
    ResourceFoundDataRequestQuestionV2ValidationApiDto,
    AxiosError,
    ResourceFoundDataRequestQuestionV2ValidationApiDto,
    ReturnType<typeof dataRequestQuestionEditValidation>
  >
) => {
  return useQuery(
    dataRequestQuestionEditValidation(questionId),
    () => getDataRequestQuestionEditValidation({ questionId }),
    {
      ...options,
    }
  )
}
