import React from 'react'
import { styled } from '@mui/material/styles'
import classNames from 'classnames'
import { ListItem, Box, SxProps, Theme } from '@mui/material'

const PREFIX = 'InnerItem'

const classes = {
  smallestListItem: `${PREFIX}-smallestListItem`,
  smallListItem: `${PREFIX}-smallListItem`,
  mediumListItem: `${PREFIX}-mediumListItem`,
  largeListItem: `${PREFIX}-largeListItem`,
  cta: `${PREFIX}-cta`,
  chevron: `${PREFIX}-chevron`,
  avatar: `${PREFIX}-avatar`,
  selectedAvatar: `${PREFIX}-selectedAvatar`,
  smallest: `${PREFIX}-smallest`,
  small: `${PREFIX}-small`,
  medium: `${PREFIX}-medium`,
  large: `${PREFIX}-large`,
  listItemAvatar: `${PREFIX}-listItemAvatar`,
  hoverStyle: `${PREFIX}-hoverStyle`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.smallestListItem}`]: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },

  [`& .${classes.smallListItem}`]: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },

  [`& .${classes.mediumListItem}`]: {
    minHeight: '61px',
    paddingTop: theme.spacing(0.6),
    paddingBottom: theme.spacing(0.6),
    paddingLeft: theme.spacing(2),
  },

  [`& .${classes.largeListItem}`]: {
    minHeight: '70px',
    paddingTop: theme.spacing(0.6),
    paddingBottom: theme.spacing(0.6),
  },

  [`& .${classes.cta}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${classes.chevron}`]: {
    color: theme.palette.text.secondary,
    maxWidth: 22,
    maxHeight: 22,
    width: 22,
    height: 22,
  },

  [`& .${classes.avatar}`]: {
    color: theme.palette.text.primary,
    // backgroundColor: theme.palette.divider,
    backgroundColor: theme.palette.backgroundDark,
  },

  [`& .${classes.selectedAvatar}`]: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.background.default,
  },

  [`& .${classes.smallest}`]: {
    width: 30,
    height: 30,
    '& svg': {
      width: 20,
      height: 20,
    },
  },

  [`& .${classes.small}`]: {
    width: 36,
    height: 36,
  },

  [`& .${classes.medium}`]: {
    width: 40,
    height: 40,
  },

  [`& .${classes.large}`]: {
    width: 46,
    height: 46,
  },

  [`& .${classes.listItemAvatar}`]: {
    minWidth: 48,
  },

  [`& .${classes.hoverStyle}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    textDecoration: 'none',
  },
}))

interface Props {
  loading?: boolean
  disabled?: boolean
  tooltip?: string
  icon?: React.ReactNode
  primaryText?: string | React.ReactNode
  secondaryText?: string | React.ReactNode
  goToText?: string | React.ReactNode
  secondaryAction?: React.ReactNode
  onClick?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  noIcon?: boolean
  skeleton?: boolean
  avatarBackgroundColor?: string
  withChevron?: boolean
  size?: 'smallest' | 'small' | 'medium' | 'large'
  listItemSize?: 'smallestListItem' | 'smallListItem' | 'mediumListItem' | 'largeListItem'
  alignItems?: 'flex-start' | 'center'
  selected?: boolean
  marginBottom?: number
  hasHoverStyle?: boolean
  className?: string
  sx?: SxProps<Theme>
}

interface ListItemProps extends Partial<Props> {
  children: React.ReactNode
}

const InnerItem = ({
  loading,
  disabled,
  children,
  onClick,
  listItemSize = 'mediumListItem',
  selected,
  marginBottom,
  alignItems,
  hasHoverStyle,
  className,
  sx,
}: ListItemProps) => {
  const root = classNames(
    [classes[listItemSize]],
    {
      [classes.hoverStyle]: hasHoverStyle,
    },
    className
  )

  return (
    <StyledBox>
      {onClick ? (
        <ListItem
          selected={selected}
          ContainerComponent="div"
          disabled={Boolean(disabled || loading)}
          className={root}
          onClick={onClick}
          button
          style={{ marginBottom }}
          alignItems={alignItems}
          sx={{ ...sx }}
        >
          {children}
        </ListItem>
      ) : (
        <ListItem
          selected={selected}
          ContainerComponent="div"
          disabled={Boolean(disabled || loading)}
          className={root}
          alignItems={alignItems}
          sx={{ ...sx }}
        >
          {children}
        </ListItem>
      )}
    </StyledBox>
  )
}

export default InnerItem
