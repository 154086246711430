import { useMutation } from 'react-query'
import { signUp, updateCustomerSettings, updateUserProfile } from './api'

export const useUpdateMe = () => {
  return useMutation(updateUserProfile)
}

export const useSignup = () => {
  return useMutation(signUp)
}

export const useUpdateSettings = () => {
  return useMutation(updateCustomerSettings)
}
