import { format, differenceInDays } from 'date-fns'
import { Typography, Tooltip, Box, alpha } from '@mui/material'
import {
  EventBusyRounded as ExpiredIcon,
  AlarmRounded as ExpiredSoonIcon,
} from '@mui/icons-material'

interface Props {
  issueDate?: string
  expiryDate?: string
}

const ExpiryDateLabel = ({ issueDate, expiryDate }: Props) => {
  const today = new Date()
  const toDate = expiryDate ? new Date(expiryDate) : undefined
  const difference = toDate ? differenceInDays(toDate, today) : undefined
  const isExpiringSoon = difference !== undefined && difference < 90 && difference > 0

  return (
    <Tooltip
      title={
        (isExpiringSoon &&
          `This data entry expires in ${difference} day${difference === 1 ? '' : 's'}`) ||
        ''
      }
    >
      <Box
        sx={[
          {
            display: 'flex',
            alignItems: 'center',
          },
          () =>
            (isExpiringSoon && {
              ml: -0.7,
              pt: 0.25,
              pb: 0.25,
              pl: 0.5,
              pr: 0.5,
              width: 'fit-content',
              '&:hover': {
                borderRadius: 1,
                backgroundColor: (theme) => alpha(theme.palette.common.black, 0.04),
              },
            }) ||
            {},
        ]}
      >
        {difference !== undefined && difference > 0 && issueDate && (
          <Typography
            variant="body2"
            sx={{
              display: 'flex',
              alignItems: 'center',
              mr: 0.5,
            }}
          >
            <strong>{format(new Date(issueDate), `dd LLL yyyy`)} - </strong>
          </Typography>
        )}
        <Typography
          variant="body2"
          sx={{
            display: 'flex',
            alignItems: 'center',
            color:
              difference !== undefined && difference < 0
                ? '#c9403a'
                : (expiryDate && 'text.primary') || 'text.secondary',
            textDecoration: difference !== undefined && difference < 0 ? 'line-through' : 'none',
          }}
        >
          {difference !== undefined && difference < 0 && (
            <ExpiredIcon sx={{ width: 20, height: 20, mr: 0.75, color: '#c9403a' }} />
          )}
          {isExpiringSoon && (
            <ExpiredSoonIcon color="warning" sx={{ width: 20, height: 20, mr: 0.75 }} />
          )}
          {expiryDate ? <strong>{format(new Date(expiryDate), `dd LLL yyyy`)}</strong> : <i>N/A</i>}
        </Typography>
      </Box>
    </Tooltip>
  )
}

export default ExpiryDateLabel
