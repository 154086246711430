import { useQueryClient, useMutation } from 'react-query'
import { useSnackbar } from 'notistack'
import { getErrorMessage } from 'utils'
import { ApiMutateError } from 'services/apiClient'
import { pushNotificationToSNS, runDataDiscovery, runSupplierDiscovery } from './api'
import queryKeys from './queryKeys'

export const useRunDataDiscovery = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(runDataDiscovery, {
    onError: (err: ApiMutateError) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.dataDiscoveryLastRunDataSetItems)
    },
  })
}

export const useRunSupplierDiscovery = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(runSupplierDiscovery, {
    onError: (err: ApiMutateError) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.supplierDiscoveryLastRunDataSetItems)
    },
  })
}

export const usePushNotificationToSNS = () => {
  return useMutation(pushNotificationToSNS)
}
