import { useState, createContext, useMemo, useEffect } from 'react'
import { PaymentPlan } from 'typescript-axios'

interface PermissionContextInitialProps {
  isAdmin: boolean
  isResponsiblyUser: boolean
  paymentPlan: PaymentPlan
  organizationId: string
}

interface PermissionContextProps extends PermissionContextInitialProps {
  showWorkInProgress: boolean
  isNewScoringScaleOrg: boolean
}

export const PermissionContext = createContext<
  PermissionContextProps & { toggleWorkInProgress: () => void }
>({
  isAdmin: false,
  isResponsiblyUser: false,
  paymentPlan: PaymentPlan.Freemium,
  showWorkInProgress: false,
  toggleWorkInProgress: () => {},
  organizationId: '',
  isNewScoringScaleOrg: true,
})

const getShowWorkInProgressLocalStorage = localStorage.getItem('swip__a')

export const PermissionContextProvider = ({
  isAdmin,
  isResponsiblyUser,
  paymentPlan,
  organizationId,
  children,
}: { children: React.ReactNode } & PermissionContextInitialProps) => {
  // default to show WIP if responsibly user.
  const initialShowWorkInProgress = isResponsiblyUser
    ? (!getShowWorkInProgressLocalStorage && false) ||
      Boolean(getShowWorkInProgressLocalStorage && getShowWorkInProgressLocalStorage === 'show')
    : false
  const [showWorkInProgressState, setShowWorkInProgressState] = useState(initialShowWorkInProgress)

  const toggleWorkInProgress = () => {
    setShowWorkInProgressState(!showWorkInProgressState)
  }

  useEffect(() => {
    localStorage.setItem('swip__a', showWorkInProgressState ? 'show' : 'hide')
  }, [showWorkInProgressState])

  const values = useMemo(() => {
    return {
      isAdmin,
      isResponsiblyUser,
      paymentPlan,
      showWorkInProgress: showWorkInProgressState,
      toggleWorkInProgress,
      organizationId,
      isNewScoringScaleOrg: true,
    }
  }, [isAdmin, paymentPlan, showWorkInProgressState, organizationId, isResponsiblyUser])

  return <PermissionContext.Provider value={values}>{children}</PermissionContext.Provider>
}
