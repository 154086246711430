import apiClient from 'services/apiClient'

export const getIndustries = async ({
  code,
  includeUnassigned,
}: {
  code?: string[]
  includeUnassigned?: boolean
}) => {
  const { data } = await apiClient.getV2IndustryGics(code, includeUnassigned, 1)
  return data
}

export const assignIndustry = async ({
  supplierId,
  industryCode,
}: {
  supplierId: string
  industryCode: string
}) => {
  const { data } = await apiClient.postV2SuppliersSupplieridActionsAddIndustryIndustrycode(
    supplierId,
    industryCode
  )
  return data
}

export const removeIndustry = async ({
  supplierId,
  industryCode,
}: {
  supplierId: string
  industryCode: string
}) => {
  const { data } = await apiClient.postV2SuppliersSupplieridActionsRemoveIndustryIndustrycode(
    supplierId,
    industryCode
  )
  return data
}
