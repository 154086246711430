import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardActions,
  Box,
  Skeleton,
} from '@mui/material'
import {
  OfflineBoltOutlined as InactiveIcon,
  FiberManualRecordRounded as CheckIcon,
} from '@mui/icons-material'

interface Props {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  title?: string | React.ReactNode
  description?: React.ReactNode
  buttonText?: string
  activeText?: string
  inactiveText?: string
  imgUrl?: string
  icon?: React.ReactNode
  backgroundColor?: string
  active?: boolean
  skeleton?: boolean
  onButtonClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  action?: React.ReactNode
  imageHeight?: number | string
  disabled?: boolean
  withBorder?: boolean
  className?: string
}

const ImageCard = ({
  title,
  description,
  buttonText,
  activeText,
  inactiveText,
  active,
  backgroundColor,
  imgUrl,
  icon,
  onClick,
  skeleton,
  imageHeight,
  onButtonClick,
  action,
  disabled,
  withBorder,
  className,
}: Props) => {
  return skeleton ? (
    <Card
      sx={[
        {
          boxShadow: 'none',
          height: '100%',
          alignItems: 'start',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
        (theme) =>
          (withBorder && {
            border: `1px solid ${theme.palette.divider}`,
          }) ||
          {},
        (theme) =>
          (disabled && {
            opacity: 0.7,
          }) ||
          {},
      ]}
    >
      {imgUrl && (
        <Skeleton
          animation="wave"
          height={imageHeight || 118}
          width="100%"
          variant="rectangular"
          sx={{
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        />
      )}
      <CardContent style={{ width: '100%' }}>
        <Skeleton animation="wave" height={30} width="75%" />
        <Skeleton animation="wave" height={16} width="100%" />
        <Skeleton animation="wave" height={16} width="100%" />
      </CardContent>
      <CardActions
        sx={{
          justifyContent: 'space-between',
          paddingLeft: 1.5,
          paddingRight: 1.5,
          marginTop: -1,
          paddingBottom: 0.5,
          width: '100%',
        }}
      >
        <Skeleton animation="wave" height={30} width="25%" />
        <Skeleton animation="wave" height={30} width="25%" />
      </CardActions>
    </Card>
  ) : (
    <Card
      sx={[
        {
          boxShadow: 'none',
          height: '100%',
          alignItems: 'start',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
        (theme) =>
          (withBorder && {
            border: `1px solid ${theme.palette.divider}`,
          }) ||
          {},
        () =>
          (disabled && {
            opacity: 0.7,
          }) ||
          {},
      ]}
    >
      <CardActionArea
        onClick={onClick}
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'start',
          flexDirection: 'column',
        }}
        disabled={disabled}
      >
        {(imgUrl || icon) && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              color: 'common.white',
              width: '100%',
              paddingLeft: 2,
              marginBottom: '-6px',
              backgroundColor,
              height: imageHeight || 118,
              '& img': {
                height: 38,
              },
            }}
          >
            {imgUrl && (
              <img
                src={imgUrl}
                alt={
                  (typeof title === 'string' && title) ||
                  (typeof description === 'string' && description) ||
                  'Responsibly'
                }
              />
            )}
            {icon}
          </Box>
        )}
        <CardContent sx={{ width: '100%', paddingTop: imgUrl || icon ? 0 : 2 }}>
          <Typography
            gutterBottom
            variant="h5"
            sx={{ fontWeight: 600 }}
            component="span"
            display="block"
          >
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="span" display="block">
            {description}
          </Typography>
          {typeof active === 'boolean' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '-3px',
                marginTop: 1.3,
              }}
            >
              <Typography
                variant="body2"
                noWrap
                sx={[
                  {
                    display: 'flex',
                    alignItems: 'center',
                  },
                  (theme) =>
                    active
                      ? { color: theme.palette.success.main }
                      : { color: theme.palette.text.secondary },
                ]}
              >
                {active ? (
                  <>
                    <CheckIcon
                      sx={{
                        marginRight: 0.6,
                        width: 9,
                        height: 9,
                      }}
                    />
                    {activeText}
                  </>
                ) : (
                  <>
                    <InactiveIcon sx={{ marginRight: 0.6, width: 9, height: 9 }} />
                    {inactiveText}
                  </>
                )}
              </Typography>
              {buttonText && (
                <Typography
                  component="span"
                  variant="body2"
                  color="primary"
                  onClick={onButtonClick}
                  sx={{
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {buttonText}
                </Typography>
              )}
            </Box>
          )}
        </CardContent>
      </CardActionArea>
      {Boolean(action) && (
        <CardActions
          sx={{
            justifyContent: 'space-between',
            paddingLeft: 1.5,
            paddingRight: 1.5,
            marginTop: -1,
            paddingBottom: 0.5,
            width: '100%',
          }}
        >
          {action}
        </CardActions>
      )}
    </Card>
  )
}

export default ImageCard
