import apiClient from 'services/apiClient'
import { InsertScorecardIn, UpdateScorecardIn } from 'typescript-axios'

export const getScorecards = async (
  {
    page,
    pageSize,
    supplierIds,
    scorecardIds,
  }: {
    page: number
    pageSize: number
    supplierIds?: string[]
    scorecardIds?: string[]
  },
  opt?: { abortSignal?: AbortSignal }
) => {
  const { data } = await apiClient.getV3Scorecards(scorecardIds, supplierIds, page, pageSize, {
    timeout: 150000,
    signal: opt?.abortSignal,
  })
  return data
}

export const getScorecard = async ({ id, supplierId }: { id: string; supplierId?: string }) => {
  const { data } = await apiClient.getV3ScorecardsId(id, supplierId ? [supplierId] : undefined)
  return data
}

export const createScorecard = async (payload: InsertScorecardIn) => {
  const { data } = await apiClient.postV3Scorecards(payload)
  return data
}

export const editScorecard = async ({ id, body }: { id: string; body: UpdateScorecardIn }) => {
  const { data } = await apiClient.putV3ScorecardsId(id, body)
  return data
}

export const deleteScorecard = async (id: string) => {
  const { data } = await apiClient.deleteV3ScorecardsId(id)
  return data
}

export const getOverallScoreAcrossScorecards = async (
  { supplierIds }: { supplierIds: string[] },
  opt?: { abortSignal?: AbortSignal }
) => {
  const { data } = await apiClient.getV3ScorecardsSupplierScoreAndCoverage(supplierIds, {
    timeout: 150000,
    signal: opt?.abortSignal,
  })
  return data
}
