import { useQueryClient, useMutation, UseMutationOptions } from 'react-query'
import { useSnackbar } from 'notistack'
import { getErrorMessage } from 'utils'
import {
  InsertScorecardIn,
  UpdateScorecardIn,
  ResourceInsertedScorecardV3ApiDto,
  ResourceUpdatedScorecardV3ApiDto,
  ResourceDeletedScorecardV3ApiDto,
} from 'typescript-axios'
import { ApiMutateError } from 'services/apiClient'
import { createScorecard, editScorecard, deleteScorecard } from './api'
import queryKeys from './queryKeys'
import suppliersQueryKeys from '../suppliers/queryKeys'

export const useCreateScorecard = (
  options?: UseMutationOptions<ResourceInsertedScorecardV3ApiDto, ApiMutateError, InsertScorecardIn>
) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(createScorecard, {
    onError: (err) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.allScorecardPages)
      queryClient.invalidateQueries(queryKeys.singleScorecards)
      queryClient.invalidateQueries(suppliersQueryKeys.singleScorecardSupplierPages)
    },
    ...options,
  })
}

export const useEditScorecard = (
  options?: UseMutationOptions<
    ResourceUpdatedScorecardV3ApiDto,
    ApiMutateError,
    { id: string; body: UpdateScorecardIn }
  >,
  withoutCacheInvalidate?: boolean
) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(editScorecard, {
    onError: (err) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
    },
    onSettled: (response) => {
      if (response && withoutCacheInvalidate !== true) {
        queryClient.invalidateQueries(queryKeys.allScorecardPages)
        queryClient.invalidateQueries(queryKeys.singleScorecards)
        queryClient.invalidateQueries(suppliersQueryKeys.singleScorecardSupplierPages)
      }
    },
    ...options,
  })
}

export const useDeleteScorecard = (
  options?: UseMutationOptions<ResourceDeletedScorecardV3ApiDto, ApiMutateError, string>,
  onSuccessCallback?: (response?: ResourceDeletedScorecardV3ApiDto) => void
) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(deleteScorecard, {
    ...options,
    onError: (err) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries(queryKeys.allScorecardPages)
      queryClient.invalidateQueries(queryKeys.singleScorecards)
      queryClient.invalidateQueries(suppliersQueryKeys.singleScorecardSupplierPages)
      enqueueSnackbar('Scorecard deleted!', { variant: 'success' })
      if (onSuccessCallback) onSuccessCallback(response)
    },
  })
}
