import { useTheme } from '@mui/material'
import { grey } from '@mui/material/colors'

/** if reversed, a low number is considered "positive" */
export default (score?: number, reversed?: boolean, staticColor?: boolean) => {
  const theme = useTheme()

  if (staticColor) return theme.palette.primary.main

  if (score === undefined) return theme.palette.common.white
  if (score > 0 && score <= 20) return reversed ? theme.palette.success.main : grey[300]
  if (score > 20 && score <= 40) return reversed ? 'rgb(184 220 135)' : '#FFEF39' // 'rgb(242 223 162)'
  if (score > 40 && score <= 60) return theme.palette.warning.main
  if (score > 60 && score <= 80) return reversed ? '#FFEF39' : 'rgb(184 220 135)'
  if (score > 80) return reversed ? theme.palette.warning.main : theme.palette.success.main
  return theme.palette.background.default
}
