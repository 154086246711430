import { useQuery, UseQueryOptions } from 'react-query'
import { ResourceFoundUserApiDto, ResourceFoundOrganizationApiDto } from 'typescript-axios'
import { AxiosError } from 'axios'
import { getMe, getSettings } from './api'
import queryKeys from './queryKeys'

export const useGetMe = (
  options?: UseQueryOptions<
    ResourceFoundUserApiDto,
    AxiosError,
    ResourceFoundUserApiDto,
    typeof queryKeys.me
  >
) => {
  return useQuery(queryKeys.me, () => getMe(), {
    staleTime: Infinity,
    ...options,
  })
}

export const useGetSettings = (
  options?: UseQueryOptions<
    ResourceFoundOrganizationApiDto,
    AxiosError,
    ResourceFoundOrganizationApiDto,
    typeof queryKeys.settings
  >
) => {
  return useQuery(queryKeys.settings, () => getSettings(), {
    staleTime: Infinity,
    ...options,
  })
}
