import { useQueryClient, useMutation } from 'react-query'
import { useSnackbar } from 'notistack'
import { getErrorMessage } from 'utils'
import {
  ResourceListOrganizationSupplierV2ApiDto,
  SuppliersRemoveFromCategoryIn,
  ResourceListSupplierCategoryApiDto,
  SupplierCategoryIn,
  SupplierCategoryApiDto,
  ResourceInsertedSupplierCategoryApiDto,
  ResourceFoundOrganizationSupplierV2ApiDto,
} from 'typescript-axios'
import { ApiMutateError } from 'services/apiClient'
import {
  assignCategory,
  removeCategory,
  AssignCategoryProps,
  createCategory,
  updateCategory,
  deleteCategory,
} from './api'
import categoriesQueryKeys from './queryKeys'
import suppliersQueryKeys from '../suppliers/queryKeys'
import scorecardsQueryKeys from '../scorecards/queryKeys'
import riskQueryKeys from '../risk/queryKeys'

interface UseCategoryProps {
  /** used to optimistically update category on single suppliers page */
  supplierPageQueryKey?: ReturnType<(typeof suppliersQueryKeys)['supplierPage']>
  /** used to optimistically update category on supplier */
  supplierId?: string
}

interface SupplierPagingContext {
  pageParams: number[]
  pages: ResourceListOrganizationSupplierV2ApiDto[]
}

interface CategoriesPagingContext {
  pageParams: number[]
  pages: ResourceListSupplierCategoryApiDto[]
}

interface UseAssignCategoryProps extends UseCategoryProps {
  onSuccessCallback?: (payload?: AssignCategoryProps) => void
}

export const useAssignCategoryClean = () => {
  return useMutation(assignCategory)
}

export const useRemoveCategoryClean = () => {
  return useMutation(removeCategory)
}

export const useAssignCategory = ({
  supplierPageQueryKey,
  supplierId,
  onSuccessCallback,
}: UseAssignCategoryProps) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(assignCategory, {
    onMutate: async (newCategory: AssignCategoryProps) => {
      const context: {
        previousSupplierPaging?: SupplierPagingContext
        previousSupplier?: ResourceFoundOrganizationSupplierV2ApiDto
      } = {}
      const newCategoryItem = {
        name: newCategory.categoryName || '',
        id: newCategory.categoryId,
      }
      if (supplierId) {
        const queryKey = suppliersQueryKeys.singleSupplier(supplierId)
        await queryClient.cancelQueries(queryKey)
        context.previousSupplier = queryClient.getQueryData(queryKey)

        if (context.previousSupplier?.data) {
          const newSupplier = { ...context.previousSupplier }
          newSupplier.data.categories =
            newSupplier.data.categories && newSupplier.data.categories.length > 0
              ? [...newSupplier.data.categories, newCategoryItem]
              : [newCategoryItem]

          queryClient.setQueryData<ResourceFoundOrganizationSupplierV2ApiDto>(queryKey, newSupplier)
        }
      }
      // if supplierPageQueryKey, hook is used in the suppliers overview and not on single supplier. Optimistically update page
      if (supplierPageQueryKey) {
        await queryClient.cancelQueries(supplierPageQueryKey)

        // snapshot the previous value
        context.previousSupplierPaging = queryClient.getQueryData(supplierPageQueryKey)

        // optimistically update with the new value
        if (context.previousSupplierPaging?.pages && newCategory.categoryName) {
          const newSupplierPages = context.previousSupplierPaging.pages.map((x) => {
            return {
              ...x,
              data: x.data?.map((y) => {
                if (y.id === supplierId) {
                  return {
                    ...y,
                    categories: y.categories
                      ? [...y.categories, newCategoryItem]
                      : [newCategoryItem],
                  }
                }
                return y
              }),
            }
          })

          queryClient.setQueryData<SupplierPagingContext>(supplierPageQueryKey, {
            ...context.previousSupplierPaging,
            pages: newSupplierPages,
          })
        }
      }
      return context
    },
    onError: (err: ApiMutateError, _variables, context) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
      // roll back the optimistic update if mutation fails
      if (context?.previousSupplierPaging && supplierPageQueryKey) {
        queryClient.setQueryData<SupplierPagingContext>(
          supplierPageQueryKey,
          context.previousSupplierPaging
        )
      }
      if (context?.previousSupplier && supplierId) {
        queryClient.setQueryData<ResourceFoundOrganizationSupplierV2ApiDto>(
          suppliersQueryKeys.singleSupplier(supplierId),
          context.previousSupplier
        )
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(suppliersQueryKeys.singleSuppliers)
      queryClient.invalidateQueries(suppliersQueryKeys.supplierPages)
      queryClient.invalidateQueries(scorecardsQueryKeys.scorecards)
      queryClient.invalidateQueries(riskQueryKeys.risk)
    },
    onSuccess: (_data, variables) => {
      if (onSuccessCallback) onSuccessCallback(variables)
    },
  })
}

export const useRemoveCategory = ({
  supplierPageQueryKey,
  supplierId,
  onSuccessCallback,
}: UseCategoryProps & {
  onSuccessCallback?: (variables: SuppliersRemoveFromCategoryIn) => void
}) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(removeCategory, {
    onMutate: async (categoryToRemove: SuppliersRemoveFromCategoryIn) => {
      const context: {
        previousSupplierPaging?: SupplierPagingContext
        previousSupplier?: ResourceFoundOrganizationSupplierV2ApiDto
      } = {}
      if (supplierId) {
        const queryKey = suppliersQueryKeys.singleSupplier(supplierId)
        await queryClient.cancelQueries(queryKey)
        context.previousSupplier = queryClient.getQueryData(queryKey)

        if (context.previousSupplier?.data) {
          const newSupplier = { ...context.previousSupplier }
          newSupplier.data.categories = newSupplier.data.categories?.filter(
            (x) => x.id !== categoryToRemove.categoryId
          )

          queryClient.setQueryData<ResourceFoundOrganizationSupplierV2ApiDto>(queryKey, newSupplier)
        }
      }
      if (supplierPageQueryKey) {
        await queryClient.cancelQueries(supplierPageQueryKey)

        context.previousSupplierPaging =
          queryClient.getQueryData<SupplierPagingContext>(supplierPageQueryKey)

        if (
          context.previousSupplierPaging?.pages &&
          context.previousSupplierPaging.pages.length > 0
        ) {
          const newSupplierPages = context.previousSupplierPaging.pages.map((x) => {
            return {
              ...x,
              data: x.data?.map((y) => {
                if (y.id === supplierId) {
                  return {
                    ...y,
                    categories: y.categories?.filter((z) => z.id !== categoryToRemove.categoryId),
                  }
                }
                return y
              }),
            }
          })
          queryClient.setQueryData<SupplierPagingContext>(supplierPageQueryKey, {
            ...context.previousSupplierPaging,
            pages: newSupplierPages,
          })
        }
      }
      return context
    },
    onError: (err: ApiMutateError, _variables, context) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
      // roll back the optimistic update if mutation fails
      if (context?.previousSupplierPaging && supplierPageQueryKey) {
        queryClient.setQueryData<SupplierPagingContext>(
          supplierPageQueryKey,
          context.previousSupplierPaging
        )
      }
      if (context?.previousSupplier && supplierId) {
        queryClient.setQueryData<ResourceFoundOrganizationSupplierV2ApiDto>(
          suppliersQueryKeys.singleSupplier(supplierId),
          context.previousSupplier
        )
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(suppliersQueryKeys.singleSuppliers)
      queryClient.invalidateQueries(suppliersQueryKeys.supplierPages)
      queryClient.invalidateQueries(scorecardsQueryKeys.scorecards)
      queryClient.invalidateQueries(riskQueryKeys.risk)
    },
    onSuccess: (_data, variables) => {
      if (onSuccessCallback) onSuccessCallback(variables)
    },
  })
}

interface UseCreateCategoryProps extends UseCategoryProps {
  onSuccessCallback?: (variables: ResourceInsertedSupplierCategoryApiDto) => void
}

export const useCreateCategory = ({ onSuccessCallback }: UseCreateCategoryProps) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(createCategory, {
    onMutate: async (newCategory: SupplierCategoryIn) => {
      // cancel any outgoing refetches (so they don't overwrite our optimistic update)
      /* await queryClient.cancelQueries(categoriesQueryKeys.categories)

      // snapshot the previous value
      const previousCategories = queryClient.getQueryData<ResourceListSupplierCategoryApiDto>(
        categoriesQueryKeys.categories
      )

      // optimistically update with the new value
      if (previousCategories) {
        queryClient.setQueryData<ResourceListSupplierCategoryApiDto>(
          categoriesQueryKeys.categories,
          {
            ...previousCategories,
            data:
              previousCategories.data && previousCategories.data.length > 0
                ? [...previousCategories.data, { name: newCategory.name, id: uuid() }]
                : [],
          }
        )
      }

      return { previousCategories } */
    },
    onError: (err: ApiMutateError, _variables, context) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
      // roll back the optimistic update if mutation fails
      /* if (context?.previousCategories) {
        queryClient.setQueryData<ResourceListSupplierCategoryApiDto>(
          categoriesQueryKeys.categories,
          context.previousCategories
        )
      } */
    },
    onSettled: () => {
      // refetch on success or error
      queryClient.invalidateQueries(categoriesQueryKeys.categories)
    },
    onSuccess: (response) => {
      if (onSuccessCallback) onSuccessCallback(response)
    },
  })
}

export const useUpdateCategory = ({
  supplierPageQueryKey,
  supplierId,
  onSuccessCallback,
}: UseCategoryProps & { onSuccessCallback?: (variables: SupplierCategoryApiDto) => void }) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(updateCategory, {
    onMutate: async (updatedCategory: SupplierCategoryApiDto) => {
      const context: {
        previousSupplierPaging?: SupplierPagingContext
        previousSupplier?: ResourceFoundOrganizationSupplierV2ApiDto
        // previousCategories?: ResourceListSupplierCategoryApiDto
      } = {}
      if (supplierId) {
        const queryKey = suppliersQueryKeys.singleSupplier(supplierId)
        await queryClient.cancelQueries(queryKey)
        context.previousSupplier = queryClient.getQueryData(queryKey)

        if (context.previousSupplier?.data) {
          const newSupplier = { ...context.previousSupplier }
          newSupplier.data.categories = newSupplier.data.categories?.map((x) => {
            return x.id === updatedCategory.id ? { ...x, name: updatedCategory.name } : x
          })

          queryClient.setQueryData<ResourceFoundOrganizationSupplierV2ApiDto>(queryKey, newSupplier)
        }
      }
      // if supplierPageQueryKey, component is used in the overview and not on single supplier
      if (supplierPageQueryKey) {
        await queryClient.cancelQueries(supplierPageQueryKey)

        context.previousSupplierPaging =
          queryClient.getQueryData<SupplierPagingContext>(supplierPageQueryKey)

        if (
          context.previousSupplierPaging?.pages &&
          context.previousSupplierPaging.pages.length > 0
        ) {
          const newSupplierPages = context.previousSupplierPaging.pages.map((x) => {
            return {
              ...x,
              data: x.data?.map((y) => {
                // if category is assigned to the supplier in view, update it (and rest of page) to avoid option-missing-error in multiselect
                return {
                  ...y,
                  categories: y.categories?.map((z) => {
                    return z.id === updatedCategory.id
                      ? {
                          ...z,
                          name: updatedCategory.name,
                        }
                      : z
                  }),
                }
              }),
            }
          })
          queryClient.setQueryData<SupplierPagingContext>(supplierPageQueryKey, {
            ...context.previousSupplierPaging,
            pages: newSupplierPages,
          })
        }
      }
      /* context.previousCategories = queryClient.getQueryData<ResourceListSupplierCategoryApiDto>(
        categoriesQueryKeys.categories
      )

      if (context.previousCategories && context.previousCategories.data) {
        const newCategories = context.previousCategories.data.map((x) => {
          return x.id === updatedCategory.id
            ? {
                ...x,
                name: updatedCategory.name,
              }
            : x
        })
        queryClient.setQueryData<ResourceListSupplierCategoryApiDto>(
          categoriesQueryKeys.categories,
          {
            ...context.previousCategories,
            data: newCategories,
          }
        )
      } */
      return context
    },
    onError: (err: ApiMutateError, _variables, context) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
      if (context?.previousSupplierPaging && supplierPageQueryKey) {
        queryClient.setQueryData<SupplierPagingContext>(
          supplierPageQueryKey,
          context.previousSupplierPaging
        )
      }
      /* if (context?.previousCategories) {
        queryClient.setQueryData<ResourceListSupplierCategoryApiDto>(
          categoriesQueryKeys.categories,
          context.previousCategories
        )
      } */
      if (context?.previousSupplier && supplierId) {
        queryClient.setQueryData<ResourceFoundOrganizationSupplierV2ApiDto>(
          suppliersQueryKeys.singleSupplier(supplierId),
          context.previousSupplier
        )
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(suppliersQueryKeys.singleSuppliers)
      queryClient.invalidateQueries(suppliersQueryKeys.supplierPages)
      queryClient.invalidateQueries(categoriesQueryKeys.categories)
      queryClient.invalidateQueries(scorecardsQueryKeys.scorecards)
      queryClient.invalidateQueries(riskQueryKeys.risk)
    },
    onSuccess: (_data, variables) => {
      if (onSuccessCallback) onSuccessCallback(variables)
    },
  })
}

export const useDeleteCategory = ({
  supplierPageQueryKey,
  onSuccessCallback,
}: UseCategoryProps & { onSuccessCallback?: (variables: { categoryId: string }) => void }) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(deleteCategory, {
    onMutate: async ({ categoryId: deletedCategoryId }: { categoryId: string }) => {
      // if supplierPageQueryKey, component is used in the overview and not on single supplier
      if (supplierPageQueryKey) {
        await queryClient.cancelQueries(supplierPageQueryKey)

        const previousSupplierPaging =
          queryClient.getQueryData<SupplierPagingContext>(supplierPageQueryKey)

        if (previousSupplierPaging?.pages && previousSupplierPaging.pages.length > 0) {
          const newSupplierPages = previousSupplierPaging.pages.map((x) => {
            return {
              ...x,
              data: x.data?.map((y) => {
                return {
                  ...y,
                  categories: y.categories?.filter((z) => z.id !== deletedCategoryId),
                }
              }),
            }
          })
          queryClient.setQueryData<SupplierPagingContext>(supplierPageQueryKey, {
            ...previousSupplierPaging,
            pages: newSupplierPages,
          })
        }

        return { previousSupplierPaging }
      }
      return {}
    },
    onError: (err: ApiMutateError, _variables, context) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
      if (context?.previousSupplierPaging && supplierPageQueryKey) {
        queryClient.setQueryData<SupplierPagingContext>(
          supplierPageQueryKey,
          context.previousSupplierPaging
        )
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(categoriesQueryKeys.categories)
      queryClient.invalidateQueries(suppliersQueryKeys.suppliers)
      queryClient.invalidateQueries(scorecardsQueryKeys.scorecards)
      queryClient.invalidateQueries(riskQueryKeys.risk)
    },
    onSuccess: (_response, variables) => {
      enqueueSnackbar('Category deleted!', { variant: 'success' })
      if (onSuccessCallback) onSuccessCallback(variables)
    },
  })
}
