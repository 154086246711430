import { usePermissionContext, getPerformanceColors } from 'utils'
import {
  Box,
  BoxProps,
  Typography,
  useTheme,
  TypographyVariant,
  Skeleton,
  Theme,
  SxProps,
  alpha,
} from '@mui/material'
import GaugeComponent from 'react-gauge-component'
import { PieChart, Pie, Cell } from 'recharts'

const gaugeZeroLength = 0.09
const bracketLength = (1 - gaugeZeroLength) / 6

export const getGaugeValue = (val: number) => {
  const minPositiveValue = 55
  const minNegativeValue = 45.5
  if (val > 0) {
    const newVal = 53.5 + val / 2
    return newVal > minPositiveValue ? (newVal > 100 && 100) || newVal : minPositiveValue
  }
  if (val < 0) {
    const newVal = 48.5 + val / 2
    return newVal > minNegativeValue ? minNegativeValue : newVal
  }

  return 51.5
}

// const createGaugeColorArray = (val: number) => {
//   return [
//     alpha('#FF4500', val >= -100 && val < -66 ? 1 : 0.2),
//     alpha('#FFA500', val >= -66 && val < -33 ? 1 : 0.2),
//     alpha('#FFD700', val >= -33 && val < 0 ? 1 : 0.2),
//     alpha('#edddca', val === 0 ? 1 : 0.2),
//     alpha('#ADFF2F', val > 0 && val < 33 ? 1 : 0.2),
//     alpha('#32CD32', val >= 33 && val < 66 ? 1 : 0.2),
//     alpha('#008000', val >= 66 && val <= 100 ? 1 : 0.2),
//   ]
// }

// const getGaugeValue = (val: number) => {
//   if (val === 0) return 51.5
//   if (val > 0 && val <= 1) return 65
//   if (val > 2 && val <= 2) return 75
//   if (val > 2 && val <= 3) return 85
//   if (val < 0 && val > -1) return 45
//   if (val < -1 && val >= -2) return 25
//   if (val < -1 && val >= -3) return 15

//   return 51.5
// }

const createGaugeColorArray = (val: number) => {
  return [
    alpha('#FF4500', val >= -2 && val <= -3 ? 1 : 0.1),
    alpha('#FFA500', val >= -2 && val < -1 ? 1 : 0.1),
    alpha('#FFD700', val >= -1 && val < 0 ? 1 : 0.1),
    alpha('#a1a1a1', val === 0 ? 1 : 0.1),
    alpha('#ADFF2F', val > 0 && val <= 1 ? 1 : 0.1),
    alpha('#32CD32', val > 1 && val <= 2 ? 1 : 0.1),
    alpha('#008000', val > 2 && val <= 3 ? 1 : 0.1),
  ]
}

interface Props extends BoxProps {
  value?: number
  size?: number
  typographyVariant?: TypographyVariant
  loading?: boolean
  /** show the donut with 5 brackets, used for performance */
  withBrackets?: boolean
  withHoverContainer?: boolean
  /** if reversed colors, a low number is considered "positive" */
  reversedColors?: boolean
  centerText?: string
  staticColor?: boolean
  /** TODO clean up */
  staticColorString?: string
  whiteBackground?: boolean
  fontSize?: number
  centerTextSx?: SxProps<Theme>
  gaugeMarginTop?: number
  roundToNearestInteger?: boolean
}

const performanceBrackets = [20, 40, 60, 80, 100]

const Donut = ({
  value,
  typographyVariant = 'body2',
  loading,
  size = 46,
  withBrackets = true,
  withHoverContainer = true,
  reversedColors,
  centerText,
  staticColor,
  staticColorString,
  whiteBackground,
  fontSize,
  centerTextSx = {
    pt: '26px',
  },
  gaugeMarginTop = -23,
  roundToNearestInteger,
  ...rest
}: Props) => {
  // const { isNewScoringScaleOrg } = usePermissionContext()
  const isNewScoringScaleOrg = true
  const newSize = isNewScoringScaleOrg && size === 46 ? 40 : size

  const theme = useTheme()

  const valueAsPercentage =
    value !== undefined && withBrackets ? Math.min(Math.max(Math.ceil(value) * 20, 0), 100) : value

  const pathColor =
    staticColorString || getPerformanceColors(valueAsPercentage, reversedColors, staticColor)

  const donutData = withBrackets
    ? [
        { name: '1', value: 20 },
        { name: '2', value: 20 },
        { name: '3', value: 20 },
        { name: '4', value: 20 },
        { name: '5', value: 20 },
      ]
    : (value !== undefined &&
        value === 0 && [
          { name: '1', value: 0 },
          { name: '2', value: 100 },
        ]) ||
      (value !== undefined && value === 100 && [{ name: '1', value: 100 }]) || [
        { name: '1', value },
        { name: '2', value: value !== undefined ? 100 - value + 0.01 : 0 },
      ]

  const bracketColors = performanceBrackets.map((_x, index, arr) => {
    const previousValue = index > 0 ? arr[index - 1] : 0
    const shouldHaveColor = valueAsPercentage && valueAsPercentage > previousValue
    return shouldHaveColor ? pathColor : theme.palette.background.default
  })

  const bracketNumber = value !== undefined ? Math.ceil(value) : undefined

  const convertToV2Scale = value || 0
  const gaugeValue = getGaugeValue(value || 0)

  return isNewScoringScaleOrg && withBrackets ? (
    <Box
      sx={{
        ...rest.sx,
        position: 'relative',
      }}
      {...rest}
      style={{
        height: newSize,
        width: newSize,
        maxWidth: newSize,
        maxHeight: newSize,
      }}
    >
      {loading ? (
        <Skeleton variant="circular" width={newSize} height={newSize} />
      ) : (
        <Box>
          <Typography
            variant={typographyVariant}
            sx={{
              fontWeight: 600,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              lineHeight: 0,
              zIndex: 9,
              fontSize: fontSize ? `${fontSize}px !important` : undefined,
              ...centerTextSx,
            }}
            color="textPrimary"
          >
            {value === undefined ? (
              '0'
            ) : (
              <Box component="span">
                {centerText}
                {!centerText && withBrackets
                  ? convertToV2Scale
                  : !centerText &&
                    `${value > 0 && value < 1 ? '<1' : Math.round(value * 10) / 10}%`}
              </Box>
            )}
          </Typography>
          <GaugeComponent
            value={gaugeValue}
            type="radial"
            id="gauge-component"
            style={{
              width: newSize,
              height: newSize,
              marginTop: gaugeMarginTop,
            }}
            labels={{
              valueLabel: {
                hide: true,
              },
              tickLabels: {
                hideMinMax: true,
              },
            }}
            arc={{
              cornerRadius: 0,
              subArcs: [
                {
                  showTick: false,
                  length: bracketLength,
                },
                {
                  showTick: false,
                  length: bracketLength,
                },
                {
                  showTick: false,
                  length: bracketLength,
                },
                {
                  showTick: false,
                  length: gaugeZeroLength,
                  limit: 0,
                },
                {
                  showTick: false,
                  length: bracketLength,
                },
                {
                  showTick: false,
                  length: bracketLength,
                },
                {
                  showTick: false,
                  length: bracketLength,
                },
              ],
              padding: 0.02,
              width: 0.16,
              colorArray: createGaugeColorArray(value || 0),
            }}
            pointer={{
              animate: false,
              elastic: false,
              animationDelay: 0,
              type: 'arrow',
              color: '#edddca',
              baseColor: '#000',
              width: 0,
            }}
          />
        </Box>
      )}
    </Box>
  ) : (
    <Box
      sx={{
        ...rest.sx,
        position: 'relative',
      }}
      {...rest}
      style={{
        height: size,
        width: size,
        maxWidth: size,
        maxHeight: size,
      }}
    >
      {loading ? (
        <Skeleton variant="circular" width={size} height={size} />
      ) : (
        <>
          <Typography
            variant={typographyVariant}
            sx={{
              fontWeight: 600,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              lineHeight: 0,
              zIndex: 9,
              fontSize: fontSize ? `${fontSize}px !important` : undefined,
            }}
            color="textPrimary"
          >
            {value === undefined ? (
              '0/5'
            ) : (
              <Box component="span">
                {centerText}
                {!centerText && withBrackets
                  ? `${bracketNumber}/${performanceBrackets.length}`
                  : !centerText &&
                    `${
                      valueAsPercentage !== undefined &&
                      valueAsPercentage > 0 &&
                      valueAsPercentage < 1
                        ? '<1'
                        : (valueAsPercentage && Math.round(valueAsPercentage * 10) / 10) ||
                          valueAsPercentage
                    }%`}
              </Box>
            )}
          </Typography>
          <div
            style={{
              /** apparently RECHARTS fills up the donut from right to left with no props to turn direction. Super weird? */
              transform: 'scale(-1, 1)',
            }}
          >
            <PieChart width={size} height={size} margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
              <Pie
                data={donutData}
                cx="50%"
                cy="50%"
                innerRadius={withBrackets ? '65%' : '69%'}
                startAngle={-268}
                endAngle={92}
                paddingAngle={withBrackets ? 6 : 0}
                dataKey="value"
                isAnimationActive={false}
              >
                {donutData.map((entry, index) => (
                  <Cell
                    key={`cell-${entry.name}`}
                    stroke={withBrackets ? theme.palette.common.white : ''}
                    fill={
                      withBrackets
                        ? bracketColors[index % bracketColors.length]
                        : (index === 0 && pathColor) ||
                          (whiteBackground && theme.palette.common.white) ||
                          theme.palette.background.default
                    }
                  />
                ))}
              </Pie>
            </PieChart>
          </div>
        </>
      )}
    </Box>
  )
}

export default Donut
