import {
  Typography,
  Grid,
  Grow,
  Box,
  Button,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Skeleton,
} from '@mui/material'
import { ArrowBackRounded as BackIcon } from '@mui/icons-material'

interface Props {
  title?: string
  subtitle?: React.ReactNode
  children?: React.ReactNode
  toggleComponent?: React.ReactNode
  skeleton?: boolean
  backLink?: () => void
  backAnchor?: string
  avatarChar?: string
  withMargin?: boolean
}

const PageTitle = ({
  title,
  subtitle,
  children,
  toggleComponent,
  skeleton,
  backLink,
  backAnchor,
  avatarChar,
  withMargin = false,
}: Props) => {
  return (
    <Box
      sx={{
        marginTop: withMargin ? 4 : 2,
        marginBottom: withMargin ? 5 : 2,
      }}
    >
      {backLink && backAnchor && (
        <Grow in>
          <Button
            variant="text"
            sx={{
              marginTop: 2,
              marginLeft: -0.5,
              marginBottom: -1,
            }}
            onClick={backLink}
            startIcon={<BackIcon />}
            color="primary"
            size="small"
          >
            {backAnchor}
          </Button>
        </Grow>
      )}
      <Grid container justifyContent="space-between" alignItems="center">
        <ListItem disableGutters>
          {avatarChar && (
            <ListItemAvatar>
              <Avatar
                variant="rounded"
                sx={[
                  {
                    width: 75,
                    height: 75,
                    fontSize: '28px',
                    marginRight: 2,
                    marginBottom: -0.5,
                    backgroundColor: 'palette.divider',
                    color: 'text.primary',
                  },
                  (theme) => ({
                    [theme.breakpoints.down('md')]: {
                      width: 75,
                      height: 75,
                      fontSize: '24px',
                    },
                    [theme.breakpoints.down('sm')]: {
                      width: 65,
                      height: 65,
                      fontSize: '22px',
                    },
                  }),
                ]}
              >
                {avatarChar}
              </Avatar>
            </ListItemAvatar>
          )}
          <ListItemText
            primary={
              <>
                <Typography variant="h1">
                  {skeleton ? (
                    <Skeleton variant="text" width={400} />
                  ) : (
                    <strong style={{ fontWeight: 700 }}>{title}</strong>
                  )}
                  {toggleComponent && !skeleton && (
                    <Box
                      sx={{
                        display: 'inline-flex',
                        paddingLeft: 3,
                        verticalAlign: 'middle',
                      }}
                    >
                      {toggleComponent}
                    </Box>
                  )}
                </Typography>
                {children && !skeleton && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>{children}</Box>
                )}
              </>
            }
            primaryTypographyProps={{
              sx: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              },
            }}
            secondaryTypographyProps={{
              component: 'span',
            }}
            secondary={
              subtitle &&
              !skeleton && (
                <Typography
                  component={typeof subtitle === 'string' ? 'p' : 'span'}
                  variant="body1"
                  sx={{
                    paddingTop: 1,
                    maxWidth: 898,
                  }}
                >
                  {subtitle}
                </Typography>
              )
            }
          />
        </ListItem>
      </Grid>
    </Box>
  )
}

export default PageTitle
