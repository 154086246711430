import React from 'react'
import { styled } from '@mui/material/styles'
import { Breadcrumbs, Chip, Box, Fade, Skeleton } from '@mui/material'
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material'

const PREFIX = 'DrawerBreadcrumbs'

const classes = {
  root: `${PREFIX}-root`,
  clickable: `${PREFIX}-clickable`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.root}`]: {
    backgroundColor: theme.palette.backgroundDark,
    cursor: 'pointer',
    boxShadow: 'none',
    '&:hover': {
      opacity: 0.8,
      backgroundColor: theme.palette.backgroundDark,
    },
    '&:focus': {
      backgroundColor: theme.palette.backgroundDark,
    },
  },

  [`& .${classes.clickable}`]: {
    boxShadow: 'none !important',
  },
}))

interface LabelProps {
  label: string
  url: string
  show?: boolean
}

interface Props {
  handleClose: () => void
  changeRoute: (route?: string) => void
  resolved: boolean
  labels: LabelProps[]
}

const DrawerBreadcrumbs = ({ handleClose, changeRoute, labels, resolved }: Props) => {
  const close = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault()
    handleClose()
  }

  const handleChangeRoute = (event: React.MouseEvent<Element, MouseEvent>, route: string) => {
    event.preventDefault()
    changeRoute(route)
  }

  const handlePreventDefaultClick = (event: React.MouseEvent<Element, MouseEvent>) =>
    event.preventDefault()

  return (
    <StyledBox paddingTop={2} paddingBottom={1}>
      <Breadcrumbs aria-label="breadcrumb">
        {(!('show' in labels[0]) || labels[0].show) && (
          <Chip
            classes={{
              clickable: classes.clickable,
              root: classes.root,
            }}
            component="a"
            href="#"
            label={labels[0].label}
            icon={<ChevronLeftIcon fontSize="small" />}
            onClick={close}
          />
        )}
        {resolved ? (
          <Fade in={Boolean(labels && labels[1])}>
            <Chip
              classes={{
                clickable: classes.clickable,
                root: classes.root,
              }}
              component="a"
              href="#"
              label={labels[1].label}
              onClick={(event: React.MouseEvent<Element, MouseEvent>) =>
                handleChangeRoute(event, labels[1].url)
              }
            />
          </Fade>
        ) : (
          <Skeleton variant="text" width={100} />
        )}
        {labels.map((x, index) => {
          return index > 1 && x.show ? (
            <Fade in={x.show} key={x.label} mountOnEnter unmountOnExit>
              <Chip
                classes={{
                  clickable: classes.clickable,
                  root: classes.root,
                }}
                component="a"
                href="#"
                label={x.label}
                onClick={handlePreventDefaultClick}
              />
            </Fade>
          ) : null
        })}
      </Breadcrumbs>
    </StyledBox>
  )
}

export default DrawerBreadcrumbs
