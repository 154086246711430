import { useMutation } from 'react-query'
import { useSnackbar } from 'notistack'
import { getErrorMessage } from 'utils'
import { ApiMutateError } from 'services/apiClient'
import { postOnboardingUploadUrls, postSupplierFilesUploadUrls } from './api'

export const useGetUploadFileUrl = () => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(postOnboardingUploadUrls, {
    onError: (err: ApiMutateError) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
    },
  })
}

export const useGetSupplierUploadFileUrl = () => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(postSupplierFilesUploadUrls, {
    onError: (err: ApiMutateError) => {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' })
    },
  })
}
