import classNames from 'classnames'
import { styled } from '@mui/material/styles'
import { Paper, PaperContent } from 'components'
import {
  CloseRounded as CloseIcon,
  CheckCircleOutlineRounded as SuccessIcon,
  InfoOutlined as InfoIcon,
  WarningAmberRounded as WarningIcon,
  ErrorOutlineRounded as ErrorIcon,
} from '@mui/icons-material'
import { Typography, IconButton, Box, Alert, AlertTitle, AlertProps } from '@mui/material'

const PREFIX = 'CustomAlert'

const classes = {
  root: `${PREFIX}-root`,
  withMargin: `${PREFIX}-withMargin`,
  alertTitle: `${PREFIX}-alertTitle`,
  gutters: `${PREFIX}-gutters`,
  message: `${PREFIX}-message`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.root}`]: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.primary,
  },

  [`& .${classes.withMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.alertTitle}`]: {
    marginTop: 0,
    marginBottom: 0,
  },

  [`& .${classes.gutters}`]: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },

  [`& .${classes.message}`]: {
    paddingTop: 9,
  },
}))

interface Props extends AlertProps {
  message: React.ReactNode
  title?: string
  withMargin?: boolean
  className?: string
  withGutters?: boolean
  onClose?: () => void
  withPaper?: boolean
}

const CustomAlert = ({
  message,
  title,
  withMargin,
  severity = 'info',
  className,
  withGutters,
  onClose,
  withPaper,
  ...rest
}: Props) => {
  const root = classNames(
    {
      [classes.root]: true,
      [classes.withMargin]: withMargin,
      [classes.gutters]: withGutters,
    },
    className
  )

  const alert = (
    <Alert
      className={root}
      severity={severity}
      iconMapping={{
        success: (
          <SuccessIcon
            fontSize="inherit"
            sx={{
              color: 'success.main',
            }}
          />
        ),
        error: (
          <ErrorIcon
            fontSize="inherit"
            sx={{
              color: 'warning.main',
            }}
          />
        ),
        warning: (
          <WarningIcon
            fontSize="inherit"
            sx={{
              color: 'warning.main',
            }}
          />
        ),
        info: (
          <InfoIcon
            fontSize="inherit"
            sx={{
              color: 'info.main',
            }}
          />
        ),
      }}
      {...rest}
      classes={{ message: classes.message }}
      action={
        onClose ? (
          <IconButton size="small">
            <CloseIcon />
          </IconButton>
        ) : undefined
      }
    >
      {title && (
        <AlertTitle className={classes.alertTitle}>
          <Typography>{title}</Typography>
        </AlertTitle>
      )}
      {message}
    </Alert>
  )

  return (
    <StyledBox>
      {withPaper ? (
        <Paper>
          <PaperContent
            sx={{
              padding: 1,
            }}
          >
            {alert}
          </PaperContent>
        </Paper>
      ) : (
        alert
      )}
    </StyledBox>
  )
}

export default CustomAlert
