import { Components } from '@mui/material/styles'

const MuiCardHeader: Components['MuiCardHeader'] = {
  styleOverrides: {
    root: {
      display: 'flex',
    },
  },
}

export default MuiCardHeader
