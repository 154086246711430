import { Toolbar, Box, Theme, SxProps } from '@mui/material'

interface Props {
  children: React.ReactNode
  position?: React.CSSProperties['position']
  top?: number | 'unset'
  bottom?: number | 'unset'
  height?: number
  withBorderBottom?: boolean
  withBorderTop?: boolean
  sx?: SxProps<Theme>
}

const AppBar = ({
  children,
  position = 'fixed',
  top = 0,
  bottom = undefined,
  height = 62,
  withBorderBottom = true,
  withBorderTop = false,
  sx,
}: Props) => {
  return (
    <Box
      sx={{
        backgroundColor: 'common.white',
        display: 'flex',
        alignItems: 'center',
        height,
        left: 0,
        borderBottom: (theme) =>
          withBorderBottom ? `1px solid ${theme.palette.backgroundDark}` : undefined,
        borderTop: (theme) =>
          withBorderTop ? `1px solid ${theme.palette.backgroundDark}` : undefined,
        top,
        bottom,
        transition: (theme) =>
          theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        width: '100%',
        zIndex: 1000,
        position,
        ...sx,
      }}
    >
      <Toolbar>{children}</Toolbar>
    </Box>
  )
}

export default AppBar
