import { Suspense, lazy, useState, useEffect } from 'react'
import connectMixpanel from 'services/mixpanel'
import ErrorBoundary, { initSentry } from 'utils/components/ErrorBoundary'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { CircularProgress, Box } from '@mui/material'

import ThemedSnackbarProvider from 'utils/components/ThemedSnackbarProvider'

const LoginHandler = lazy(() => import('auth/LoginHandler'))
const SignUp = lazy(() => import('views/shared/SignUp'))
const AnswerDataRequest = lazy(() => import('views/supplier/AnswerDataRequest'))

const SW_CACHE_VARIABLE = process.env.REACT_APP_COMMIT_REF as string

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

// LEFT if we ned to clear cache, lets try without first
// const clearFullCache = (withReload = false) => {
//   // eslint-disable-next-line
//   console.log('A new version of the app is available. Clearing chache and refreshing the page...') // TODO: REMOVE AFTER DEBUG
//   const delCacheKeys: any = []

//   if (caches) {
//     caches.keys().then((names) => {
//       names.forEach((name) => delCacheKeys.push(caches.delete(name)))
//     })
//   }

//   Promise.all(delCacheKeys).finally(() => {
//     // eslint-disable-next-line
//     console.log('Cache cleared. Reloading the page...') // TODO: REMOVE AFTER DEBUG
//     if (withReload) {
//       window.location.reload()
//     }
//   })
// }

const checkForNewVersion = !isLocalhost && process.env.NODE_ENV === 'production'
let newVersionAvailable = false

if (checkForNewVersion) {
  setInterval(() => {
    fetch('/.netlify/functions/getCacheVariable').then((response) => {
      response.json().then((data) => {
        if (
          data &&
          data.value !== '' &&
          data.value !== '$COMMIT_REF' &&
          data.value !== SW_CACHE_VARIABLE
        ) {
          newVersionAvailable = true
        }
      })
    })
  }, 60000) // 60 seconds
}

const App = () => {
  const [trackingInitialized, setTrackingInitialized] = useState(false)
  const { pathname } = useLocation()

  if (!trackingInitialized) {
    connectMixpanel()
    initSentry()
    setTrackingInitialized(true)
  }

  // when new version is available, reload the page on the next route change
  useEffect(() => {
    if (checkForNewVersion && newVersionAvailable) {
      window.location.reload()
    }
  }, [pathname])

  return (
    <ErrorBoundary>
      <ThemedSnackbarProvider>
        <Suspense
          fallback={
            <Box sx={{ textAlign: 'center', paddingTop: 6 }}>
              <CircularProgress />
            </Box>
          }
        >
          <Switch>
            <Route
              path="/supplier-portal/answer-data-request"
              exact
              component={AnswerDataRequest}
            />
            <Route path="/sign-up" exact component={SignUp} />
            <Redirect from="/signup" to="/sign-up" />
            <Redirect
              from="/supplier-portal/data-request"
              to="/supplier-portal/answer-data-request"
            />
            <Route path="*" exact component={LoginHandler} />
          </Switch>
        </Suspense>
      </ThemedSnackbarProvider>
    </ErrorBoundary>
  )
}

export default App
