import { Box } from '@mui/material'

interface Props {
  sidebarContent: React.ReactNode
  children: React.ReactNode
  hasFixedSidebar?: boolean
  sidebarMaxWidth?: number
  noPadding?: boolean
}

const SharedDialogBody = ({
  sidebarContent,
  children,
  hasFixedSidebar = true,
  sidebarMaxWidth = 255,
  noPadding,
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        marginTop: 2,
        minHeight: 400,
      }}
    >
      <Box
        sx={[
          {
            width: '100%',
            maxWidth: sidebarMaxWidth,
            minWidth: sidebarMaxWidth,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
          (theme) => ({
            borderRight: `1px solid ${theme.palette.divider}`,
          }),
        ]}
      >
        <Box
          component="span"
          sx={{
            // position: hasFixedSidebar ? 'fixed' : 'unset',
            width: sidebarMaxWidth - 10,
            overflow: 'auto',
            // maxHeight: '90vh',
          }}
        >
          {sidebarContent}
        </Box>
      </Box>
      <Box
        sx={{
          paddingLeft: noPadding ? 1 : 3,
          paddingRight: noPadding ? 0 : 2,
          width: '100%',
          position: 'relative',
          overflow: 'auto',
          marginRight: noPadding ? -1.5 : 0,
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default SharedDialogBody
