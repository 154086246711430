import { ListItem, ListItemText, List, ListSubheader, ListItemIcon, Skeleton } from '@mui/material'
import DialogBody from '../index'

const listItemSx = {
  paddingTop: 0,
  paddingBottom: 0,
}

const LoadingDialogBody = () => {
  return (
    <DialogBody
      hasFixedSidebar={false}
      sidebarContent={
        <List
          subheader={
            <ListSubheader
              disableGutters
              disableSticky
              style={{ lineHeight: '28px', fontSize: '12px' }}
            >
              <Skeleton width="40%" height={16} />
            </ListSubheader>
          }
        >
          <ListItem sx={{ ...listItemSx }} disableGutters>
            <ListItemIcon style={{ color: '#000' }}>
              <Skeleton variant="circular" width={22} height={22} />
            </ListItemIcon>
            <ListItemText primary={<Skeleton width="95%" />} secondary={<Skeleton width="50%" />} />
          </ListItem>
          <ListItem sx={{ ...listItemSx }} disableGutters>
            <ListItemIcon style={{ color: '#000' }}>
              <Skeleton variant="circular" width={22} height={22} />
            </ListItemIcon>
            <ListItemText primary={<Skeleton width="95%" />} secondary={<Skeleton width="50%" />} />
          </ListItem>
          <ListItem sx={{ ...listItemSx }} disableGutters>
            <ListItemIcon style={{ color: '#000' }}>
              <Skeleton variant="circular" width={22} height={22} />
            </ListItemIcon>
            <ListItemText primary={<Skeleton width="95%" />} secondary={<Skeleton width="50%" />} />
          </ListItem>
          <ListItem sx={{ ...listItemSx }} disableGutters>
            <ListItemIcon style={{ color: '#000' }}>
              <Skeleton variant="circular" width={22} height={22} />
            </ListItemIcon>
            <ListItemText primary={<Skeleton width="95%" />} secondary={<Skeleton width="50%" />} />
          </ListItem>
        </List>
      }
    >
      <Skeleton width="100%" height={75} variant="rectangular" />
    </DialogBody>
  )
}

export default LoadingDialogBody
