import { Components } from '@mui/material/styles'
import spacing from '../spacing'
import palette from '../palette'

const MuiChip: Components['MuiChip'] = {
  defaultProps: {
    size: 'small',
  },
  styleOverrides: {
    root: {
      backgroundColor: palette.backgroundDark,
      color: palette.text.primary,
      '&:focus': {
        backgroundColor: palette.backgroundDark,
      },
      fontWeight: 600,
      borderRadius: '6px',
    },
    avatar: {
      height: spacing * 1.2,
      width: spacing * 1.2,
    },
    labelSmall: {
      paddingLeft: spacing * 1.5,
      paddingRight: 10,
      fontSize: '11px',
    },
    sizeSmall: {
      height: 18,
    },
    iconSmall: {
      width: spacing * 1.75,
      height: spacing * 1.75,
      marginLeft: spacing,
      marginRight: -spacing,
      color: palette.text.primary,
    },
    clickableColorSecondary: {
      '&:hover, &:focus, &:active': {
        backgroundColor: palette.backgroundDark,
      },
    },
    deletable: {
      '&:focus': {
        backgroundColor: palette.backgroundDark,
      },
    },
    deleteIconSmall: {
      color: palette.common.black,
      width: spacing * 2,
      height: spacing * 2,
      '&:hover': {
        opacity: 0.9,
      },
    },
  },
}

export default MuiChip
