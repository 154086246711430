import classNames from 'classnames'
import { styled } from '@mui/material/styles'
import Check from '@mui/icons-material/Check'

const PREFIX = 'StepIcon'

const classes = {
  root: `${PREFIX}-root`,
  active: `${PREFIX}-active`,
  circle: `${PREFIX}-circle`,
  completed: `${PREFIX}-completed`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    color: theme.palette.backgroundDark,
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },

  [`&.${classes.active}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${classes.circle}`]: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },

  [`& .${classes.completed}`]: {
    color: theme.palette.success.main,
    zIndex: 1,
    fontSize: 18,
  },
}))

interface StepIconProps {
  active: boolean
  completed: boolean
}

const StepIcon = ({ active, completed }: StepIconProps) => {
  return (
    <Root
      className={classNames(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </Root>
  )
}

export default StepIcon
