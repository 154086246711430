import { Box } from '@mui/material'
import { ChevronRightRounded as ChevronRightIcon } from '@mui/icons-material'

const TableRowChevron = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
      <ChevronRightIcon />
    </Box>
  )
}

export default TableRowChevron
