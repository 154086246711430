import apiClient from 'services/apiClient'
import { CreateDataEntryIn } from 'typescript-axios'
import { DataEntriesFilterProps } from './queryKeys'

interface GetEntriesProps extends DataEntriesFilterProps {
  page: number
}

export const getEntries = async ({
  dataEntryIds,
  supplierId,
  dataSourceId,
  dataRequestId,
  page,
  pageSize,
  daysTillExpiredMin,
  daysTillExpiredMax,
}: GetEntriesProps) => {
  const { data } = await apiClient.getV3DataEntries(
    dataEntryIds,
    supplierId,
    dataSourceId,
    dataRequestId,
    daysTillExpiredMin,
    daysTillExpiredMax,
    page,
    pageSize
  )
  return data
}

export const getEntry = async (dataEntryId: string) => {
  const { data } = await apiClient.getV3DataEntriesId(dataEntryId)
  return data
}

export const getEntriesFiles = async (dataEntryIds: string[]) => {
  const { data } = await apiClient.getV2FilesDataEntries(dataEntryIds)
  return data
}

export const getDownloadFileUrl = async (id: string) => {
  const { data } = await apiClient.getV2FilesFileid(id)
  return data
}

export const createEntry = async (supplierId: string, dataSourceId: CreateDataEntryIn) => {
  return apiClient.postV2SuppliersIdDataEntries(supplierId, dataSourceId)
}
