import { Components } from '@mui/material/styles'

const MuiLinearProgress: Components['MuiLinearProgress'] = {
  styleOverrides: {
    root: {
      height: 5,
      borderRadius: '4px',
      overflow: 'hidden',
    },
  },
}

export default MuiLinearProgress
