import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

const currentLanguageStorageKey = 'currentLanguage'
const currentLanguage = localStorage.getItem(currentLanguageStorageKey)
const languages = ['en']
const options = {
  lng: languages.find((lng) => lng === currentLanguage) || 'en',
  fallbackLng: 'en',
  ns: ['common'],
  preload: languages,
  backend: {
    loadPath: `/locales/{{ns}}/{{lng}}.json`,
  },
  debug: process.env.NODE_ENV !== 'production',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
  },
}

// NOTE. prevented setting this on bind language change, when we implemented the system translations for data requests,
// .. could be revisited in the future, to keep 2 states perhaps HOC provider (https://react.i18next.com/legacy-v9/i18nextprovider) or hook or what ever we come up with... this was a quickfix
// i18n.on('languageChanged', (lang) => localStorage.setItem(currentLanguageStorageKey, lang))

i18n.use(Backend).use(initReactI18next).init(options)

export default i18n
