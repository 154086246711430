import { Components } from '@mui/material/styles'

const MuiListItemAvatar: Components['MuiListItemAvatar'] = {
  styleOverrides: {
    root: {
      minWidth: 48,
    },
  },
}

export default MuiListItemAvatar
