import { Components } from '@mui/material/styles'
import palette from '../palette'

const MuiTableRow: Components['MuiTableRow'] = {
  styleOverrides: {
    root: {
      backgroundColor: palette.common.white,
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      '&.Mui-selected': {
        backgroundColor: '#f5f5f5',
      },
      '&.MuiTableRow-hover': {
        '&:hover': {
          backgroundColor: '#f5f5f5',
          cursor: 'pointer',
        },
      },
    },
    /* selected: {
      backgroundColor: '#f5f5f5',
    }, */
  },
}

export default MuiTableRow
