import { SnackbarProvider } from 'notistack'
import { Box } from '@mui/material'
import {
  CheckCircleRounded as SuccessIcon,
  InfoRounded as InfoIcon,
  ErrorRounded as ErrorIcon,
  ReportProblemRounded as WarningIcon,
} from '@mui/icons-material'
import { styled } from '@mui/material/styles'

const bg = '#242837 !important'

const PREFIX = 'ThemedSnackbarProvider'

const classes = {
  allVariants: `${PREFIX}-allVariants`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.allVariants}`]: {
    backgroundColor: bg,
    borderRadius: theme.shape.borderRadius,
  },
}))

interface Props {
  children: React.ReactNode
}

const ThemedSnackbarProvider = ({ children }: Props) => {
  return (
    <StyledBox>
      <SnackbarProvider
        maxSnack={1}
        disableWindowBlurListener
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        dense
        autoHideDuration={5000}
        style={{
          backgroundColor: bg,
        }}
        classes={{
          variantSuccess: classes.allVariants,
          variantError: classes.allVariants,
          variantInfo: classes.allVariants,
          variantWarning: classes.allVariants,
        }}
        iconVariant={{
          success: (
            <SuccessIcon
              sx={{
                color: 'success.main',
                marginRight: 1,
                marginLeft: -1,
              }}
            />
          ),
          error: (
            <ErrorIcon
              sx={{
                color: 'error.main',
                marginRight: 1,
                marginLeft: -1,
              }}
            />
          ),
          warning: (
            <WarningIcon
              sx={{
                color: 'warning.main',
                marginRight: 1,
                marginLeft: -1,
              }}
            />
          ),
          info: (
            <InfoIcon
              sx={{
                color: 'info.main',
                marginRight: 1,
                marginLeft: -1,
              }}
            />
          ),
        }}
      >
        {children}
      </SnackbarProvider>
    </StyledBox>
  )
}

export default ThemedSnackbarProvider
